import React, { useState } from "react";
import ActivityExpansionImg from "../../../../assets/images/site/activity_expansion_img 1.svg";
import RestaurantExpansionImg from "../../../../assets/images/site/restaurant_expansion_img 1.svg";
import NightClubExpansionImg from "../../../../assets/images/site/nightclub 1.png";
import {
  ExpActivitylogo1,
  ExpActivitylogo2,
  ExpActivitylogo3,
  ExpActivitylogo4,
  ExpActivitylogo5,
  Exp_Restaurant_logo1,
  Exp_Restaurant_logo2,
  Exp_Restaurant_logo3,
  Exp_Restaurant_logo4,
  Exp_Restaurant_logo5,
  RestlistLogo,
  RestlistLogo2,
  RestlistLogo3,
  NightclubLogo1,
  NightclubLogo2,
} from "../../../../assets/images/icons";
import Vision from "../Vision/Vision";
import EarlyRegistration from "../EarlyRegistration/EarlyRegistration";
import BookNow from "../BookNow/BookNow";
import StepTwo from "../StepTwo/StepTwo";
import BookDemo from "../../restaurantLand/BookDemo/BookDemo";
import MenuSection from "../../restaurantLand/MenuSection/MenuSection";
import NightClubBook from "../../nightClubLand/NightClubBook/NightClubBook";
import EventDetails from "../../nightClubLand/EventDetails/EventDetails";
import EventDetails2 from "../../nightClubLand/EventDetails2/EventDetails2";
import "./Register.scss";

const Register = () => {
  const [visibleSection, setVisibleSection] = useState(null);
  const [activeButton, setActiveButton] = useState(null);

  const handleToggle = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
    setActiveButton(section === activeButton ? null : section);
  };

  return (
    <div className="register_wrapper mt-5 container" id="register">
      <div
        className="heading mt-5"
        id="registerheading"
        style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px" }}
      >
        REGISTERING IS COMPLETELY FREE
      </div>
      <div
        className="text-content text-center text-justify mb-1 mt-1"
        id="textcont1"
        style={{ fontSize: "17px", color: "#DADADA" }}
      >
        You will receive a full 55-inch listing that showcases your business
        which will allow you to reach your target <br /> market. Please select
        below, the category that BEST describes your business:
      </div>

      <div
        className="row mt-5 mb-5 align-items-center justify-content-center"
        style={{
          // backgroundColor: "#14141499",
          border: "border: 1px solid #111111",
          fontSize: "12px",
        }}
      >
        <div
          className="col-12 col-lg-4 d-flex flex-column align-items-center"
          style={{ marginTop: "10px" }}
        >
          <button
            className="text-justify"
            id="customBtn"
            style={{
              fontSize: "16px",
              height: "80px",
              background:
                "linear-gradient(185.72deg, #EE8351 4.49%, #D24C42 95.45%)",
              width: "80%",
              padding: "5px",
              // fontWeight: activeButton === "activity" ? "600" : "normal",
            }}
            onClick={() => handleToggle("activity")}
          >
            ARE YOU AN ACTIVITY PROVIDER?
          </button>
        </div>

        <div
          className="col-12 col-lg-4 d-flex flex-column align-items-center"
          style={{ marginTop: "10px" }}
        >
          <button
            className="text-justify"
            id="customBtn"
            style={{
              fontSize: "16px",
              height: "80px",
              background:
                "linear-gradient(185.72deg, #F09056 4.49%, #F48656 95.45%)",
              width: "80%",
              padding: "5px",
              // fontWeight: activeButton === "restaurant" ? "600" : "normal",
            }}
            onClick={() => handleToggle("restaurant")}
          >
            ARE YOU A CAFE/RESTAURANT/DINING VENUE?
          </button>
        </div>

        <div
          className="col-12 col-lg-4 d-flex flex-column align-items-center"
          style={{ marginTop: "10px" }}
        >
          <button
            className="text-justify"
            id="customBtn"
            style={{
              fontSize: "16px",
              height: "80px",
              background:
                "linear-gradient(185.72deg, #DEA841 4.49%, #CD9B26 95.45%)",
              width: "80%",
              padding: "5px",
              // fontWeight: activeButton === "nightclub" ? "600" : "normal",
            }}
            onClick={() => handleToggle("nightclub")}
          >
            ARE YOU A PUB/CLUB/NIGHTLIFE VENUE?
          </button>
        </div>
      </div>

      {/* Expansion Panel Content
      Activity Expansion */}
      {visibleSection === "activity" && (
        <div
          className="activity_wrapper details-section mt-5"
          style={{
            backgroundColor: "#14141499",
            border: "border: 1px solid #111111",
            borderRadius: "30px",
          }}
        >
          <div className="row mt-5 mb-5">
            <div className="col-12 col-lg-4 text-center">
              <img
                src={ActivityExpansionImg}
                className="w-10"
                height={722}
                width={295}
              />
            </div>

            <div className="col-12 col-lg-8 text-center">
              <div
                className="text-content text-start mt-0 mb-0"
                id="textcont1"
                style={{ color: "#BABABA", fontSize: "17px" }}
              >
                To ensure you get the maximum value for your money, there are:
              </div>
              {/* //Nested Row & column */}
              {/* Nested Row 1 Starts */}
              <div className="row mt-4 ">
                <div className="col-6 col-lg-6 text-center">
                  <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
                    <div className="icon-wrapper">
                      <img src={ExpActivitylogo1} height={28} width={28} />
                    </div>
                    <div
                      className="text-wrapper ms-3"
                      style={{
                        color: "#bababa",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
                      No Monthly Fees
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-6 text-center">
                  <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
                    <div className="icon-wrapper">
                      <img src={ExpActivitylogo2} height={28} width={28} />
                    </div>
                    <div
                      className="text-wrapper ms-3"
                      style={{
                        color: "#bababa",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
                      No Signup Fees
                    </div>
                  </div>
                </div>
              </div>

              {/* Nested Row 1 End */}

              {/* Nested Row 2 Starts */}

              <div className="row mt-5 ">
                <div className="col-6 col-lg-6 text-center">
                  <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
                    <div className="icon-wrapper">
                      <img src={ExpActivitylogo3} height={28} width={28} />
                    </div>
                    <div
                      className="text-wrapper ms-3"
                      style={{
                        color: "#bababa",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
                      No Ongoing Fees
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-6 text-center">
                  <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
                    <div className="icon-wrapper">
                      <img src={ExpActivitylogo4} height={28} width={28} />
                    </div>
                    <div
                      className="text-wrapper ms-3"
                      style={{
                        color: "#bababa",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Cancel at any time
                    </div>
                  </div>
                </div>
              </div>

              {/* Nested Row 2 End */}

              {/* Nested Row 3 Starts */}
              <div className="row mt-5 ">
                <div className="col-12 col-lg-12 text-center">
                  <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
                    <div className="icon-wrapper">
                      <img src={ExpActivitylogo5} height={28} width={28} />
                    </div>
                    <div
                      className="text-wrapper ms-3"
                      style={{
                        color: "#bababa",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Compliments existing promotions
                    </div>
                  </div>
                </div>
              </div>

              {/* Nested Row 3 End */}
              <div
                className="text-content text-start"
                id="textcont1"
                style={{ color: "#BABABA", fontSize: "17px" }}
              >
                Our kiosks are specifically designed to enhance your existing
                advertising, providing you with direct access to holiday makers.
                There’s no need to change anything about your current
                advertising strategy!
              </div>
              <div
                className="nestedheading text-start mt-5"
                style={{
                  fontSize: "21px",
                  fontFamily: "Poppins",
                  marginLeft: "30px",
                }}
              >
                YOU DON’T PAY ANYTHING UNTIL A BOOKING IS MADE
              </div>

              <div
                className="text-content text-start"
                id="textcont1"
                style={{ marginLeft: "30px" }}
              >
                When a booking is made, we charge a 20% booking fee, which{" "}
                <br /> covers all credit card transactions made via our kiosks,
                and we even <br /> can handle refunds!!
              </div>
            </div>
          </div>
          <EarlyRegistration />
          <BookNow />
          <StepTwo />
        </div>
      )}

      {/* Expansion Panel Content
      Activity Expansion Ends */}

      {/* Expansion Panel Content
      Restaurant Expansion Starts */}

      {visibleSection === "restaurant" && (
        <div
          className="restaurant_wrapper details-section mt-5"
          style={{
            backgroundColor: "#14141499",
            border: "border: 1px solid #111111",
            borderRadius: "30px",
          }}
        >
          <div className="row mt-5 mb-5">
            <div className="col-12 col-lg-4 text-center">
              <img
                src={RestaurantExpansionImg}
                className="w-10"
                height={722}
                width={295}
              />
            </div>

            <div className="col-12 col-lg-8 text-start mt-0">
              <div
                className="text-content mt-0"
                id="textcont1"
                style={{
                  fontSize: "17px",
                  color: "#DADADA",
                  marginTop: "10px",
                }}
              >
                We understand that reaching your target market can be an
                expensive and a complicated process. We want to change that with
                our revolutionary kiosk system.
              </div>
              <div
                className="smallheading text-start mt-5"
                style={{ fontSize: "18px", color: "#EEAD20" }}
              >
                You will receive :
              </div>
              <div className="d-flex align-items-center py-3 px-4 mt-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo1} height={39} width={38} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  {/* A full 55-inch featured listing showcasing your restaurant */}
                  A 55-inch, full-featured listing for your restaurant.
                </div>
              </div>

              <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo2} height={37} width={37} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  {/* Ability to showcase your menu (or selected menu items)
                  complete with images */}
                  Display up to 10 photos to showcase your venue.
                </div>
              </div>

              <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo3} height={34} width={32} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  {/* Ability to showcase selected menus based on both time and
                  season */}
                  Easily access to your business details, including name, phone,
                  address, hours, accessibility, and dietary options.
                </div>
              </div>

              <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo4} height={41} width={46} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  {/* Ability to reserve tables through our Seven Room integration */}
                  Plus, a 100-word description to highlight your unique
                  offerings—cuisine, special features, catering options, and
                  stunning views.
                </div>
              </div>

              {/* <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo5} height={45} width={48} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  Ability to receive takeout orders through our Lightspeed
                  partnership
                </div>
              </div> */}
            </div>
          </div>
          <div className="kioskheading">
            <div className="text-content" id="textcont1">
              <p style={{ marginTop: "-20px", fontSize: "17px" }}>
                Our kiosks are specifically designed to enhance your existing
                advertising, providing you with direct access to holiday makers.
              </p>
              <p>
                There’s no need to change anything about your current
                advertising strategy!
              </p>
            </div>
            <div
              className="smallheading text-start"
              style={{ fontSize: "17px", color: "#EEAD20" }}
            >
              The costs are as follows:
            </div>
          </div>
          <div className="restaurant2-wrapper mt-5">
            <div className="text-content text-center mt-3" id="textcont1">
              A fully featured listing
              {/* complete with an integrated menu: */}
            </div>
            <div
              className="smallheading text-center mt-3 mb-3"
              style={{ fontSize: "26px", color: "#EEAD20" }}
            >
              PRICE: Pay nothing until 1st of March then pay only $100 per
              month!
            </div>
          </div>
          <div className="kioskheading d-none">
            <div
              className="smallheading text-start mt-5"
              style={{ fontSize: "17px", color: "#EEAD20" }}
            >
              For example
            </div>
            <div className="text-content" id="textcont1">
              <p>
                <img src={RestlistLogo} height={15} width={15} /> Five Kiosks: 5
                x 10 = $50 per month
              </p>
              <p>
                <img src={RestlistLogo} height={15} width={15} /> Twenty-Five
                Kiosks: 25 x 10 = $250 per month
              </p>
              <p>
                <img src={RestlistLogo} height={15} width={15} /> Fifty Kiosks:
                50 x 10 ={" "}
                <span style={{ color: "#EEAD20" }}>
                  Still only $250 per month
                </span>
              </p>
              <p>
                <img src={RestlistLogo} height={15} width={15} /> One Hundred
                Kiosks: 100 x 10 ={" "}
                <span style={{ color: "#EEAD20" }}>
                  Still only $250 per month
                </span>
              </p>
            </div>
          </div>

          {/* <div
            className="smallheading text-center"
            style={{ fontSize: "25px", color: "#EEAD20" }}
          >
            FURTHER ENHANCEMENTS
          </div>
          <div className="row mt-2 mb-5">
            <div className="col-12 col-lg-6 text-center mt-3">
              <div className="d-flex align-items-center justify-content-center py-3 px-4 feature-wrapper-1">
                <div
                  className="text-wrapper"
                  style={{
                    color: "#EEAD20",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  SevenRoom online Reservation integration
                </div>
              </div>

              <div className="d-flex align-items-center py-4 px-4 feature-wrapper-2">
                <div className="icon-wrapper">
                  <img src={RestlistLogo2} height={52} width={38} />
                </div>
                <div
                  className="text-wrapper text-start ms-3"
                  id="restWrapper2"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  Integrate with your Sevenroom account to receive online
                  reservations for just{" "}
                  <span style={{ color: "#EEAD20" }}>$50.00 per month</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 text-center mt-3">
              <div className="d-flex align-items-center justify-content-center py-3 px-4 feature-wrapper-1">
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#EEAD20",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  Light Speed POS Integration
                </div>
              </div>
              <div className="d-flex align-items-center py-4 px-4 feature-wrapper-2">
                <div className="icon-wrapper">
                  <img src={RestlistLogo3} height={52} width={38} />
                </div>
                <div
                  className="text-wrapper text-start ms-3"
                  id="restWrapper2"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  $0 charge for takeaway orders. We harge the customer a{" "}
                  <span style={{ color: "#EEAD20", marginRight: "3px" }}>
                    $15.95
                  </span>
                  delivery fee
                </div>
              </div>
            </div>
          </div> */}
          <EarlyRegistration />
          <BookDemo />
          {/* <MenuSection /> */}
        </div>
      )}

      {/* Expansion Panel Content
      Restaurant Expansion Ends */}

      {/* Expansion Panel Content
      nightclub Expansion Starts */}

      {visibleSection === "nightclub" && (
        <div
          className="nightclub_wrapper details-section mt-5"
          style={{
            backgroundColor: "#14141499",
            border: "border: 1px solid #111111",
            borderRadius: "30px",
          }}
        >
          <div className="row mt-5 mb-5" id="nightrow1">
            <div
              className="col-12 col-lg-4 text-center"
              // style={{
              //   backgroundColor: "#14141499",
              //   border: "border: 1px solid #111111",
              // }}
            >
              <img
                src={NightClubExpansionImg}
                className="w-10"
                height={722}
                width={295}
                style={{ maxWidth: "289px" }}
              />
            </div>

            <div className="col-12 col-lg-8 text-center">
              <div className="text-content text-start mt-0" id="textcont1">
                We understand that reaching your target market can be an
                expensive and a complicated process. We want to change that with
                our revolutionary kiosk system.
              </div>
              <div
                className="smallheading text-start mt-5"
                style={{ fontSize: "18px", color: "#EEAD20" }}
              >
                You will receive :
              </div>
              <div className="d-flex align-items-center py-3 px-4 mt-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo1} height={39} width={38} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  A full 55-inch featured listing showcasing your venue
                </div>
              </div>

              <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={Exp_Restaurant_logo2} height={37} width={37} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  Ability to showcase your weekly events (for example student
                  night)
                </div>
              </div>

              <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={NightclubLogo1} height={34} width={32} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  Ability to showcase your one-off events
                </div>
              </div>

              <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-4">
                <div className="icon-wrapper">
                  <img src={NightclubLogo2} height={41} width={46} />
                </div>
                <div
                  className="text-wrapper ms-3"
                  style={{
                    color: "#bababa",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                  }}
                >
                  Ability to offer targeted drink promotions
                </div>
              </div>

              <div className="text-content text-start" id="textcont1">
                <p>
                  There’s no need to change anything about your current <br />
                  advertising strategy!
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-5" id="nightrow2">
            <div className="kioskheading">
              <div className="text-content" id="textcont1">
                <p style={{ marginTop: "-20px", fontSize: "17px" }}>
                  Our kiosks are specifically designed to enhance your existing
                  advertising, providing you with direct access to holiday
                  makers.
                </p>
                <p>
                  There’s no need to change anything about your current
                  advertising strategy!
                </p>
              </div>
              <div
                className="smallheading text-start"
                style={{ fontSize: "17px", color: "#EEAD20" }}
              >
                The costs are as follows:
              </div>
            </div>
            <div className="restaurant2-wrapper mt-5">
              <div className="text-content text-center mt-3" id="textcont1">
                A fully featured listing, complete with an integrated menu:
              </div>
              <div
                className="smallheading text-center mt-3 mb-3"
                style={{ fontSize: "26px", color: "#EEAD20" }}
              >
                PRICE: $10.00 per kiosk per month (Capped at $250 per month)
              </div>
            </div>
            <div className="kioskheading">
              <div
                className="smallheading text-start mt-5"
                style={{ fontSize: "17px", color: "#EEAD20" }}
              >
                For example
              </div>
              <div className="text-content" id="textcont1">
                <p>
                  <img src={RestlistLogo} height={15} width={15} /> Five Kiosks:
                  5 x 10 = $50 per month
                </p>
                <p>
                  <img src={RestlistLogo} height={15} width={15} /> Twenty-Five
                  Kiosks: 25 x 10 = $250 per month
                </p>
                <p>
                  <img src={RestlistLogo} height={15} width={15} /> Fifty
                  Kiosks: 50 x 10 ={" "}
                  <span style={{ color: "#EEAD20" }}>
                    Still only $250 per month
                  </span>
                </p>
                <p>
                  <img src={RestlistLogo} height={15} width={15} /> One Hundred
                  Kiosks: 100 x 10 ={" "}
                  <span style={{ color: "#EEAD20" }}>
                    Still only $250 per month
                  </span>
                </p>
              </div>
            </div>
          </div>
          <EarlyRegistration />
          <NightClubBook />
          {/* <EventDetails /> */}
          {/* <EventDetails2 /> */}
        </div>
      )}

      {/* Expansion Panel Content
      nightclub Expansion Ends */}
    </div>
  );
};

export default Register;
