import React, { useState } from "react";
import HotelKiosk from "../../../../assets/images/site/HotelKiosk.png";
import {
  HotelKioskLogo1,
  HotelKioskLogo2,
  HotelKioskLogo3,
  HotelKioskLogo4,
  HotelKiosk2Logo1,
  HotelKiosk2Logo2,
  HotelKiosk2Logo3,
  HotelKiosk2Logo4,
} from "../../../../assets/images/icons";
import KisokHand from "../../../../assets/images/site/KioskHand.png";
import curve1 from "../../../../assets/images/site/curve 1.png";
import HotelKiosk2 from "../../../../assets/images/site/HotelKiosk2.png";
import "./WhyChooseKiosk.scss";
import ContactFormPop from "../../../ContactFormPop";
import { useNavigate } from "react-router-dom";

const WhyChooseKiosk = ({ targetRef }) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const navigate = useNavigate();

  const handleContactButtonClick = () => {
    setShowContactForm(true);
  };

  const handleClose = () => {
    setShowContactForm(false);
  };

  const handlekioskClick = () => {
    const registerSection = document.getElementById("claimkiosk");
    if (registerSection) {
      registerSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="whyChooseKiosk_wrapper mt-5 container">
        <div
          className="heading"
          style={{
            fontFamily: "Arial Rounded MT Std",
            fontSize: "25px",
            marginTop: "100px",
          }}
        >
          Why Choose Our Kiosks?
        </div>
        <div className="row mt-3 align-items-center" style={{ height: "100%" }}>
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-lg-0">
            <img
              src={HotelKiosk}
              className="w"
              height={760}
              width={406}
              alt="HotelKiosk"
            />
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column align-items-start mt-0 mb-5">
            <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
              <div className="icon-wrapper">
                <img src={HotelKioskLogo1} height={62} width={65} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  100% Free. No Hidden Costs!
                </p>
                Enjoy a state-of-the-art information kiosk with no hidden costs
                or fees—just pure value for your hotel!
              </div>
            </div>

            <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
              <div className="icon-wrapper">
                <img src={HotelKioskLogo2} height={70} width={69} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  Earn from Every Booking
                </p>
                Receive a percentage of each booking generated through the
                kiosk—an easy way to boost your revenue!
              </div>
            </div>

            <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
              <div className="icon-wrapper">
                <img src={HotelKioskLogo3} height={68} width={70} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  Increase Foot Traffic
                </p>
                Attract more visitors to your hotel! Our kiosks are designed to
                engage and draw in potential guests.
              </div>
            </div>

            <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
              <div className="icon-wrapper">
                <img src={HotelKioskLogo4} height={69} width={69} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  Boost Local Economy
                </p>
                Promote local attractions and services, helping to stimulate
                economic growth in your community.
              </div>
            </div>
          </div>
        </div>

        {/* 2nd Row Starts */}

        <div className="row2">
          <div className="col-2 col-lg-1 d-flex flex-column justify-content-center align-items-center mt-lg-0 mt-5">
            {/* Wrapper div for vertical stacking */}
            <div className="d-flex flex-column align-items-center">
              <img src={curve1} alt="curve" className="small-logo" />
              <img src={KisokHand} alt="KisokHand" />
            </div>
          </div>

          <div className="col-10 col-lg-5 d-flex justify-content-left align-items-center mt-lg-0">
            <div className="text-content2 text-left">
              Step Into The Future. <br />
              Claim Your Kiosks Today!
            </div>
          </div>

          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center mt-lg-0 mt-5">
            <button className="kioskbutton" onClick={handlekioskClick}>
              Claim Your Kiosk Now!
            </button>
          </div>
        </div>
        <ContactFormPop show={showContactForm} close={handleClose} />

        {/* 3rd Row Starts */}
        <div className="row mt-5 align-items-center" style={{ height: "100%" }}>
          <div className="col-12 col-lg-6 d-flex flex-column align-items-start mt-0 mb-5">
            <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
              <div className="icon-wrapper">
                <img src={HotelKiosk2Logo1} height={73} width={67} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  Enhance the Tourism Experience
                </p>
                Provide travellers with easy access to local experiences,
                restaurants, nightlife events, bolstering the Gold Coast tourism
                industry.
              </div>
            </div>

            <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
              <div className="icon-wrapper">
                <img src={HotelKiosk2Logo2} height={70} width={64} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  Save Staff Time
                </p>
                Let the kiosk handle your guests’ bookings and inquiries,
                allowing your team to focus on the job of running a hotel.
              </div>
            </div>

            <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
              <div className="icon-wrapper">
                <img src={HotelKiosk2Logo3} height={68} width={68} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  No Maintenance Required
                </p>
                Our kiosks are self-sufficient and maintenance-free, ensuring a
                hassle-free experience for you
              </div>
            </div>

            <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
              <div className="icon-wrapper">
                <img src={HotelKiosk2Logo4} height={68} width={52} />
              </div>
              <div className="text-wrapper ms-3" style={{ fontSize: "16px" }}>
                <p style={{ color: "#EEAD20", fontSize: "18px" }}>
                  Clean and Efficient
                </p>
                Ditch the clutter of brochures! Our sleek, interactive kiosks
                provide a modern solution for guest information and bookings.
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-4 mt-lg-0">
            <img
              src={HotelKiosk2}
              className="w" // Ensure this class is defined properly in your CSS
              height={760}
              width={406}
              alt="HotelKiosk"
            />
          </div>
        </div>
        <div ref={targetRef}></div>

        <div className="gradient-line mb-5"></div>

        <div className="heading mt-5 mb-5" style={{ fontSize: "25px" }}>
          Enhance Your Hotel Without Any Investment. <br /> Claim Your
          Interactive Booking Kiosk Now!
        </div>
      </div>
    </>
  );
};

export default WhyChooseKiosk;
