import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AppConstants from "./appConstants";
import ScrollToTop from "./components/layouts/ScrollToTop";
import MachineConfiguration from "./components/admin/MachineConfiguration";

// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Payment from "./Stripe/Payment";

import {
  MainHomeComponents,
  HomeComponents,
  DinningHomePageComponents,
  NightlifeHomePageComponents,
  LoadingPageComponent,
  NightLifeComponent,
  TutorialPageComponent,
  AdvertiseLandingPageComponent,
  ContactPageComponent,
  RegisterComponent,
  LoginComponent,
  RestaurantDetailsPageComponents,
  SubPageListComponents,
  SeachListComponents,
  TourComponent,
  CompanyActivityListComponents,
  CompanyTypeComponent,
  PageNotFoundComponent,
  StripeCheckoutComponent,
  SubscriptionCheckoutComponent,
  SubscriptionCustomCheckoutComponent,
  PlanComponent,
  BudgetRunAnAdsComponent,
  BookingDetailsComponent,
  TerminalPaymentComponent
} from "./components";

import {
  indexRoutesTour,
  indexRoutesRestaurant,
  indexRoutesNightLife,
  indexRoutesAdmin,
} from "./routes";
import NewCheckoutForm from "./Stripe/NewCheckoutForm";

//Landing Page
import About from "./components/layouts/Landing/About/About";
import StepTwo from "./components/layouts/Landing/StepTwo/StepTwo";
import Vision from "./components/layouts/Landing/Vision/Vision";
import EarlyRegistration from "./components/layouts/Landing/EarlyRegistration/EarlyRegistration";
import GoldCoast from "./components/layouts/Landing/GoldCoast/GoldCoast";
import OurMachines from "./components/layouts/Landing/OurMachines/OurMachines";
import LandingPage from "./components/layouts/Landing/LandingPage/LandingPage";
import Register from "./components/layouts/Landing/Register/Register";
import HotelLanding from "./components/layouts/hotel/HotelLanding/HotelLanding";
import RestaurantLanding from "./components/layouts/restaurantLand/restaurantlanding";
import NightLanding from "./components/layouts/nightClubLand/NightLanding/NightLanding";
import VideoPage from "./components/layouts/hotel/VideoPage/VideoPage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function App() {
  return (
    <>
      <Elements stripe={stripePromise}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              exact
              path={AppConstants.routes.home}
              element={<MainHomeComponents />}
            />

            <Route
              exact
              path={AppConstants.routes.booking}
              element={<HomeComponents />}
            />
            <Route exact path="/booking/:name" element={<TourComponent />} />
            <Route
              exact
              path="/company/:name"
              element={<CompanyActivityListComponents />}
            />
            <Route
              exact
              path={AppConstants.routes.bookingSearch}
              element={<SeachListComponents />}
            />

            <Route
              exact
              path={AppConstants.routes.dinning}
              element={<DinningHomePageComponents />}
            />
            <Route
              exact
              path="/dining/:name"
              element={<RestaurantDetailsPageComponents />}
            />
            <Route
              exact
              path={AppConstants.routes.dinningSearch}
              element={<SeachListComponents />}
            />
            <Route
              exact
              path="/night-life/:name"
              element={<NightLifeComponent />}
            />

            <Route
              exact
              path={AppConstants.routes.nightlifelanding}
              element={<NightlifeHomePageComponents />}
            />
            <Route
              exact
              path={AppConstants.routes.nightlifeSearch}
              element={<SeachListComponents />}
            />

            <Route
              exact
              path="/booking/sub-page/:page/"
              element={<SubPageListComponents />}
            />
            <Route
              exact
              path="/dining/sub-page/:page/"
              element={<SubPageListComponents />}
            />
            <Route
              exact
              path="/night-life/sub-page/:page/"
              element={<SubPageListComponents />}
            />

            {/* order details */}
            <Route
              exact
              path="/booking-details/:shortName/:orderId/"
              element={<BookingDetailsComponent />}
            />

            <Route
              exact
              path="/stripe-terminal-payment"
              element={<TerminalPaymentComponent />}
            />

            {/* <Route exact path="/:page/:name" element={<TourComponent />} /> */}

            {/* <Route
              exact
              path={AppConstants.routes.landing}
              element={<LoadingPageComponent />}
            /> */}

            <Route
              exact
              path={AppConstants.routes.tutorial}
              element={<TutorialPageComponent />}
            />
            <Route
              exact
              path={AppConstants.routes.advertising}
              element={<AdvertiseLandingPageComponent />}
            />

            <Route
              exact
              path={AppConstants.routes.contact}
              element={<ContactPageComponent />}
            />

            <Route
              path={AppConstants.routes.register}
              element={<RegisterComponent />}
            />
            <Route
              path={AppConstants.routes.login}
              element={<LoginComponent />}
            />

            {/* //Landing Page Routes */}

            {/* <Route path={"/goldcoast"} element={<GoldCoast />}></Route>
            <Route path={"/landingregister"} element={<Register />}></Route>
            <Route path={"/landing"} element={<RestaurantLanding/>}></Route> */}

            {/* Restaurant Route */}
            <Route path={"/register"} element={<LandingPage />}></Route>
            {/* //Hotel Page Routes */}
            <Route path={"/venue"} element={<HotelLanding />}></Route>

            {/* NightClub Route */}
            {/* <Route
              path={"/nightclublanding"}
              element={<NightLanding />}
            ></Route> */}

            {/* Video Page Route */}
            <Route path={"/videopage"} element={<VideoPage />}></Route>

            {/* <Route
              exact
              path={AppConstants.routes.home}
              element={<HomeComponents />}
            /> */}

            {/* <Route exact path="/:page/" element={<ActivityListComponents />} /> */}

            <Route exact path="/:page/:name" element={<TourComponent />} />

            <Route
              path={AppConstants.routes.companyType}
              element={<CompanyTypeComponent />}
            />

            <Route
              path={AppConstants.routes.machineConfiguration}
              element={<MachineConfiguration />}
            />

            <Route path="stipeCheckout" element={<StripeCheckoutComponent />} />
            <Route
              path="subscriptionCheckout"
              element={<SubscriptionCheckoutComponent />}
            />
            <Route
              path="subscriptionCustomCheckout"
              element={<SubscriptionCustomCheckoutComponent />}
            />
            <Route
              path="/stripe"
              element={<Payment stripePromise={stripePromise} />}
            />

            <Route path="admin/plan" element={<PlanComponent />} />

            <Route path="newCheckout" element={<NewCheckoutForm />} />

            <Route
              path="admin/budget-ads"
              element={<BudgetRunAnAdsComponent />}
            />

            {/* Tour */}
            <Route exact path={AppConstants.routes.tour.home}>
              {indexRoutesTour.map((el, index) => (
                <Route
                  key={index}
                  exact={el.exact}
                  path={el.path}
                  index
                  element={<el.component />}
                />
              ))}
            </Route>

            {/* Restaurant */}
            <Route exact path={AppConstants.routes.restaurant.home}>
              {indexRoutesRestaurant.map((el, index) => (
                <Route
                  key={index}
                  exact={el.exact}
                  path={el.path}
                  index
                  element={<el.component />}
                />
              ))}
            </Route>

            {/* NighLife */}
            <Route exact path={AppConstants.routes.nightLife.home}>
              {indexRoutesNightLife.map((el, index) => (
                <Route
                  key={index}
                  exact={el.exact}
                  path={el.path}
                  index
                  element={<el.component />}
                />
              ))}
            </Route>

            {/* Admin */}
            <Route exact path={AppConstants.routes.admin.home}>
              {indexRoutesAdmin.map((el, index) => (
                <Route
                  key={index}
                  exact={el.exact}
                  path={el.path}
                  index
                  element={<el.component />}
                />
              ))}
            </Route>
            <Route path="*" element={<PageNotFoundComponent />} />
          </Routes>
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          // transition: Bounce,
        />
      </Elements>
    </>
  );
}

export default App;
