import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import AdminSidebar from "../layouts/AdminSidebar";
import AppConstants from "../../appConstants";
import VideoTutorialPopUp from "./VideoTutorialPopUp";
import { authCall } from "../../services";

const SettingVideo = AppConstants.tutorial.settings;

function Settings() {
  const { current } = useSelector((state) => state.user);
  const [videoURL, setVideoURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [stripeSetted, setStripeSetted] = useState("true");

  useEffect(() => {
    async function fetchData() {
      const resp = await authCall("checkStpAccSetup", { userId: current?.id });
      setStripeSetted(resp?.payload);
    }
    fetchData();
  }, []);

  const handleClickTutorial = () => {
    setVideoURL(SettingVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  const stripeConnectAccount = async () => {
    setLoading(true);
    const resp = await authCall("create-account-link", {
      userId: current?.id,
      email: current?.email,
      metaData: { details: "Activity provider stripe custom account" },
      returnUrl: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.onboardStatus}`,
      refreshUrl: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.onboardStatus}`,
    });
    if (resp?.type === "success") {
      window.location.href = resp?.payload?.accountLink;
    }
  };

  return (
    <>
      {console.log(current)}
      <div className="settings-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Settings</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper py-lg-4">
                  <div className="sub-title mb-3">
                    Please click below to update your settings:
                  </div>
                  <div
                    className="btn-wrapper d-flex flex-column gap-4 mx-auto py-2 py-lg-4"
                    style={{ maxWidth: "500px" }}
                  >
                    <Link
                      to={AppConstants.routes.admin.updateDetails}
                      // type="submit"
                      className="btn btn-custom rounded-4"
                    >
                      Update Details
                    </Link>
                    <Link
                      to={AppConstants.routes.admin.changeEmail}
                      type="submit"
                      className="btn btn-custom rounded-4"
                    >
                      Change Email
                    </Link>
                    <Link
                      to={AppConstants.routes.admin.changePassword}
                      type="submit"
                      className="btn btn-custom rounded-4"
                    >
                      Change Password
                    </Link>
                    
                    {current?.user_type === "Activity" && (
                      <Link
                        to={AppConstants.routes.admin.updateActivityPrice}
                        type="submit"
                        className="btn btn-custom rounded-4"
                      >
                        Update Cost Person
                      </Link>
                    )}

                    {current?.user_type === "Activity" && (
                      <button
                        type="submit"
                        className="btn btn-custom rounded-4"
                        onClick={stripeConnectAccount}
                        disabled={
                          stripeSetted === "true" || loading ? true : false
                        }
                      >
                        {loading ? "Loading..." : "Stripe Connect Account"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default Settings;
