import React from "react";
import LocationDetails from "../pages/activities/LocationDetails";
import { useNavigate } from "react-router-dom";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

function TourLocationDetailsAction() {
  const navigate = useNavigate();
  const handleClick = async () => {
    const resp = await webServices.createBooking();
    if (resp.type === "success") {
      navigate(AppConstants.routes.tour.tourPayments);
    }
  };

  return (
    <>
      <LocationDetails handleClick={handleClick} />
    </>
  );
}

export default TourLocationDetailsAction;
