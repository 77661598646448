import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setCompanyDetails } from "../../../redux/reducers/companyDetailsSlice";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import MenuDetailPage from "../../common/MenuDetailPage";
import AdminSidebar from "../../layouts/AdminSidebar";


function UpdateMenuDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const companyDetails = useSelector((state) => state.companyDetails);
  const { current } = useSelector((state) => state.user);
  const { item, type } = location.state || {};
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data, redirect) => {
    setLoading(true);
    data.userId = current?.id;
    data.menuType = "Breakfast";
    const resp = await webServices.createMenu(data);
    if (resp.type === "success") {
      setLoading(false);

      const updateData = {
        profileLavel:
          companyDetails?.profile_lavel > 4 ? companyDetails?.profile_lavel : 4,
        restaurantId: companyDetails?.id,
      };
      const res = await webServices.createRestaurant(updateData);
      if (resp.type === "success") {
        dispatch(setCompanyDetails(res?.payload));
      }
      toast.dismiss();
      toast.success(`Menu created successfully.`);

      navigate(redirect);
    }
    setLoading(false);
  };

  const buttons = [
    {
      id: 1,
      label: item ? "Update" : "Submit",
      type: type,
      redirect: AppConstants.routes.restaurant.lunchMenu,
    }
  ];

  return (
    <>
      <MenuDetailPage
        onSubmit={handleSubmit}
        loading={loading}
        restaurantId={companyDetails?.id}
        type={type}
        heading={item ? "Update Menu Details" : "Add Menu Details"}
        Sidebar={AdminSidebar}
        buttons={buttons}
        item={item}
      />
    </>
  );
}

export default UpdateMenuDetails;
