import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Badge } from "react-bootstrap";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall, fetchData } from "../../../services";
import UploadFinalContentPopUp from "./UploadFinalContentPopUp";
import waitingForAd from "../../../assets/images/waitingForAd.jpg";
import VideoTutorialPopUp from "../VideoTutorialPopUp";

function ContentsAdsList() {
  const [adsList, setAdsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  async function fetchRecord(load = true) {
    if (load) {
      setLoading(true);
    }
    const resp = await fetchData("getContentAdsList");
    if (resp?.type === "success") {
      const listData = resp?.payload;
      setAdsList(listData);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchRecord();
  }, []);

  const handleDownload = async (fileName) => {
    const url = `http://localhost/touch-it/public/api/v1/download/${fileName}`;
    window.location.href = url;
  };

  const handleClickUpload = async (item) => {
    setSelectedAd(item);
    setShowUpload(true);
  };

  const handleClickCancel = async (id) => {
    setShowUpload(false);
    if (id) {
      fetchRecord(false);
    }
  };

  const handleClickViewFinalContent = (vidoURL) => {
    setVideoURL(vidoURL);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Ads List</span>
                </div>
                <div className="details-wrapper py-lg-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          <th scope="col">Ads:</th>
                          <th scope="col">Contents:</th>
                          <th scope="col">Day of Weeks:</th>
                          <th scope="col">Time of Days:</th>
                          <th scope="col">Style:</th>
                          <th scope="col">Length:</th>
                          <th scope="col">Goal:</th>
                          <th scope="col">Plan:</th>
                          <th scope="col">Status:</th>
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (adsList.length > 0 ? (
                            adsList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                <td>
                                  <img
                                    src={waitingForAd}
                                    className="img-fluid1 rounded"
                                    alt="cropped"
                                    style={{
                                      width: 85,
                                      border: "1px solid #ddb12e",
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleClickViewFinalContent(
                                        el?.final_ads_contents
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  {el.ads_contents.map((url, index) => {
                                    return (
                                      <>
                                        <button
                                          key={index}
                                          type="button"
                                          className="btn btn-link fs-16 text-blue border-0 p-0"
                                          onClick={() => handleDownload(url)}
                                        >
                                          Download
                                        </button>
                                        <br />
                                      </>
                                    );
                                  })}
                                </td>
                                <td
                                  style={{ maxWidth: 135, textWrap: "balance" }}
                                >
                                  {el.day_of_week
                                    .map((day) => day.label)
                                    .join(", ")}
                                </td>
                                <td
                                  style={{ maxWidth: 110, textWrap: "balance" }}
                                >
                                  {el.time_of_day
                                    .map((day) => day.label)
                                    .join(", ")}
                                </td>
                                <td>{el.added_style}</td>
                                <td>{el.durations} Sec</td>
                                <td>{el.desired_plan}</td>
                                <td>${Math.floor(el.amount)}</td>
                                <td>
                                  <div className="icon-container">
                                    <Badge bg={el.status_bg}>{el.status}</Badge>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-custom btn-custom-sm"
                                    onClick={() => handleClickUpload(el)}
                                    style={{ minWidth: 0 }}
                                  >
                                    Upload
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadFinalContentPopUp
        modalStatus={showUpload}
        handleClose={handleClickCancel}
        handleClickCancel={handleClickCancel}
        selectedAd={selectedAd}
      />
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default ContentsAdsList;
