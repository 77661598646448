import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import AdminSidebar from "../../layouts/AdminSidebar";
import { useSelector } from "react-redux";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import NightlifeCategoryComp from "../../common/NightlifeCategoryComp";
import { authCall } from "../../../services";


function UpdateNighlifeCategory() {
  const location = useLocation();
  const { nightlifeId } = location.state || {};
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState(false);
  const { current } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  const buttons = [
    {
      id: 1,
      label: "Update",
    },
  ];

  useEffect(() => {
    // if (!nightlifeId) {
    //   navigate(AppConstants.routes.admin.nightlifeEditListing);
    // }

    async function fetchData() {
      const resp = await authCall("getCompanyDetails", {
        companyId: nightlifeId,
        companyType: "Bar",
      });
      if (resp?.type === "success") {
        setCompanyDetails(resp?.payload);
      }
    }
    fetchData();
  }, []);

  const handleSubmitForm = async (data) => {
    setLoading(true);
    const resp = await webServices.createNightLife({
      ...data,
      nightLifeId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      toast.dismiss();

      toast.success("Updated successfully.");
      if (current?.user_type === "Admin") {
        navigate(AppConstants.routes.admin.nightLifeList);
      } else {
        navigate(AppConstants.routes.admin.nightlifeEditListing);
      }
    }
  };

  return (
    <>
      <NightlifeCategoryComp
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        Sidebar={AdminSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default UpdateNighlifeCategory;
