import React, { useState } from "react";
import { loadStripeTerminal } from "@stripe/terminal-js";
import axios from "axios";
// const baseURL = "http://localhost/t-backend/public/index.php/api/v1";
const baseURL = "https://touch-it.com.au/api/public/api/v1";
let terminal;
const TerminalPayment = () => {
  const [reader, setReader] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  // Initialize Stripe Terminal
  const initializeTerminal = async () => {
    console.log("initializeTerminal function is running...");
    terminal = await loadStripeTerminal();
    if (!terminal) {
      console.error("Stripe Terminal SDK not loaded!");
      return;
    }

    terminal = terminal.create({
      onFetchConnectionToken: async () => {
        console.log("Fetching connection token...");

        try {
          const response = await axios.get(`${baseURL}/createTerminalToken`);
          console.log("Connection Token Response:", response.data);
          return response.data.payload.secret;
        } catch (error) {
          console.error("Error fetching connection token:", error);
        }
      },
      onUnexpectedReaderDisconnect: () => {
        console.warn("Reader disconnected!");
      },
    });

    console.log("Stripe Terminal instance created:", terminal);
    // setTerminal(terminalInstance);
    setStatus("Terminal initialized");
  };

  // Discover & Connect to a Reader
  const connectReader = async () => {
    if (!terminal) {
      console.error("Stripe Terminal is not initialized.");
      return;
    }

    try {
      setLoading(true);
      const { discoveredReaders } = await terminal.discoverReaders();
      // const { discoveredReaders } = await terminal.discoverReaders({
      //   discoveryMethod: "simulated", // Use "internet" for real devices and "simulated" for simulated
      // });
      console.log("check", discoveredReaders);


      console.log("Discovered Readers:", discoveredReaders);
      if (discoveredReaders.length === 0) {
        setStatus("No readers found");
        setLoading(false);
        return;
      }

      const { error, reader } = await terminal.connectReader(
        discoveredReaders[0]
      );
      if (error) {
        setStatus(`Error: ${error.message}`);
      } else {
        setReader(reader);
        setStatus(`Connected to ${reader.label}`);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error discovering readers:", error);
    }

    // const { readers } = await terminal.discoverReaders();
  };

  // Process Payment
  const processPayment = async (amount) => {
    if (!terminal || !reader) {
      setStatus("No connected reader");
      return;
    }

    setLoading(true);
    setStatus("Processing payment...");

    const res = await axios.post(`${baseURL}/createTerminalPaymentIntent`, {
      amount,
    });
    const paymentIntentclient_secret = res.data.payload.client_secret;
    // const paymentIntentId = res.data.payload.id;


    const { error, paymentIntent } = await terminal.collectPaymentMethod(paymentIntentclient_secret);
    if (error) {
      setStatus(`Payment failed: ${error.message}`);
    } else {
      const { error: confirmError } = await terminal.processPayment(
        paymentIntent
      );
      setStatus(
        confirmError ? `Failed: ${confirmError.message}` : "Payment successful"
      );
    }
    setLoading(false);
  };

  return (
    <div>
      <h2>POS Terminal Payment</h2>
      <button onClick={initializeTerminal}>Initialize Terminal</button>
      <button onClick={connectReader} disabled={!terminal || loading}>
        {loading ? "Connecting..." : "Connect to Reader"}
      </button>
      <button onClick={() => processPayment(10)} disabled={!reader || loading}>
        {loading ? "Processing..." : "Pay AUD $1"}
      </button>
      <p>{status}</p>
    </div>
  );
};

export default TerminalPayment;
