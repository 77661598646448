import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { getBookingDetails } from "../../redux/actions/booking.actions";
// import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

const TourSidebar = ({ setStep }) => {
  const location = useLocation();
  const { pathname } = location;
  const companyDetails = useSelector((state) => state.companyDetails);
  const [progress] = useState(10);
  const { profile_lavel, company_type } = companyDetails || {
    profile_lavel: 0,
    company_type: "",
  };

  const isCalendarIntegrationActive = [2, 3, 4, 5, 6, 7, 8, 9, 10].includes(
    +profile_lavel
  );
  const isCostPersonActive = [4, 5, 6, 7, 8, 9, 10].includes(+profile_lavel);
  const isCategorySelectionActive = [5, 6, 7, 8, 9, 10].includes(+profile_lavel);
  const isActivityDetailsActive = [6, 7, 8, 9, 10].includes(+profile_lavel);
  const isStopsActive = [8, 9, 10].includes(+profile_lavel);
  const isLocationDetailsActive = [9, 10].includes(+profile_lavel);
  const isExtraVariationActive = [10].includes(+profile_lavel);
  const skipMenu = [AppConstants.routes.admin.actiityCreateCompany].includes(
    pathname
  );

  return (
    <>
      <div className="progrss-bar-sec p-4">
        <div className="progress-label">
          {/* {loadingDetails && <Skeleton count={1} />} */}
          {`${profile_lavel * progress} % Progress`}
        </div>
        <div className="progress-bar-cover mb-3">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="23"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${profile_lavel * progress}%` }}
            ></div>
          </div>
        </div>
        <div className="menu-wrapper mb-3">
          <ul>
            <li>
              <Link className="active">Company Type</Link>
            </li>
            <li>
              <Link
                className={`${
                  isCalendarIntegrationActive ? "active" : ""
                } btn-link`}
              >
                Calendar Intergration
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  if (isCostPersonActive) {
                    setStep(4);
                  }
                }}
                className={`${isCostPersonActive ? "active" : ""} btn-link`}
              >
                Cost Per Person
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  if (isCategorySelectionActive) {
                    setStep(5);
                  }
                }}
                className={`${
                  isCategorySelectionActive ? "active" : ""
                } btn-link`}
              >
                Category Selection
              </Link>
            </li>

            <li>
              <Link
                onClick={() => {
                  if (isActivityDetailsActive) {
                    setStep(6);
                  }
                }}
                className={`${
                  isActivityDetailsActive ? "active" : ""
                } btn-link`}
              >
                Company Details
              </Link>
            </li>
            {company_type === "Tour" && (
              <li>
                <Link
                  onClick={() => {
                    if (isStopsActive) {
                      setStep(7);
                    }
                  }}
                  className={`${isStopsActive ? "active" : ""} btn-link`}
                >
                  Add Stops
                </Link>
              </li>
            )}
            <li>
              <Link
                onClick={() => {
                  if (isLocationDetailsActive) {
                    setStep(8);
                  }
                }}
                className={`${
                  isLocationDetailsActive ? "active" : ""
                } btn-link`}
              >
                Location Details
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  if (isExtraVariationActive) {
                    setStep(9);
                  }
                }}
                className={`${
                  isExtraVariationActive ? "active" : ""
                } btn-link`}
              >
                Extra Activities / Variations
              </Link>
            </li>
            {!skipMenu && (
              <li>
                <Link
                  onClick={() => {
                    if (isLocationDetailsActive) {
                      setStep(9);
                    }
                  }}
                  className="btn-link"
                >
                  Payment
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TourSidebar;
