import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

function BookingTextMessagePreviewPopUp({ show, handleClose, details }) {
  const [date] = useState(moment().format("dddd [the] Do MMMM"));
  const [time] = useState(moment().format("hh:mm A"));

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold fs-20">
            Booking Confirm Text Message Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <div className="qr-cover">
            You're confirmed to go{" "}
            {details?.activity_name ? details?.activity_name : "[Actvity Name]"}{" "}
            on {date}.
          </div>
          <div className="qr-cover">
            If you need to reach us, please call{" "}
            {details?.company_phone ? details?.company_phone : "[Phone]"}
          </div>
          <div className="qr-cover mt-3">
            Your confirmation number is: ORD9898
          </div>
          <div className="qr-cover mt-3">
            Please be at{" "}
            {details?.location
              ? `${details?.location?.addressLine}, ${details?.location?.city}, ${details?.location?.state?.value} ${details?.location?.postCode}`
              : "[Location]"}{" "}
            on {date}, at {time}.
          </div>
          <div className="qr-cover mt-2 mb-3">{details?.what_to_bring}</div>
          <div className="qr-cover mb-3">
            {details?.locationDescription
              ? details?.locationDescription
              : "[Location Description]"}
          </div>

          {details?.isBookingCancel ? (
            <>
              <div className="qr-cover">
                If you wish to cancel this booking for any reason, please click
                the link below within the next 24 hours.{" "}
              </div>
              <div className="qr-cover">
                <Link>https://www.touch-it.com.au/cancel</Link>
              </div>
            </>
          ) : (
            <>
              For any refund request, please contact {details?.company_name ? details?.company_name : "[company name]"}{" "} on {details?.company_phone ? details?.company_phone : "[Phone]"}.
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BookingTextMessagePreviewPopUp;
