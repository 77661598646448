import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import rectangle1 from "../../../../assets/images/site/Rectangle 14.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import Hotelbooking1 from "../../../../assets/images/site/HotelBooking.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import StopwatchIcon from "../../../../assets/images/site/EmailIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import { authCall } from "../../../../services";
import { handleNumericInput } from "../../../util/utils";
import "./Hotelbooking.scss";

const Hotelbooking = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const initialField = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    hotel: "",
  };
  const [values, setValues] = useState(initialField);
  const [loading, setLoading] = useState(false);

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phoneNumber: inputValue,
      });
    }
  };

  const handleBookRequest = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      const resp = await authCall("sendMailKioskClaimRequest", values);
      if (resp?.type === "success") {
        setLoading(false);
        toast.dismiss();
        toast.success("Your kiosk claim successfully!");
        restForm();
      }
    } else {
      setLoading(false);
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const restForm = () => {
    setValues(initialField);
  };

  return (
    <div
      className="hotelBookin_wrapper mt-5 container"
      id="claimkiosk"
      style={{ position: "relative" }}
    >
      <div className="gradient-line"></div>
      <img src={rectangle1} alt="Book Now" className="centered-image" />
      <div className="overlay-text" style={{ fontFamily: "Inter" }}>
        CLAIM YOUR KIOSK
      </div>
      <div className="contact-form-wrapper">
        <form
          className="contact-form mb-5"
          style={{ margin: "0px 35px" }}
          onSubmit={handleBookRequest}
        >
          <div className="row mt-3">
            {/* first name */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-5"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="firstNameInput"
                    name="firstName"
                    value={values.firstName}
                    placeholder="First Name"
                    onChange={handleChangeValue}
                  />
                </div>
                {validator.current.message(
                  "first name",
                  values.firstName,
                  "required"
                )}
              </div>
            </div>

            {/* last name */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-5"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="lastNameInput"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChangeValue}
                    placeholder="Last Name"
                  />
                </div>
                {validator.current.message(
                  "last name",
                  values.lastName,
                  "required"
                )}
              </div>
            </div>

            {/* Mobile number */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={ContactIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={24}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="phoneNumberInput"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChangePhone}
                    placeholder="Phone Number"
                  />
                </div>
                {validator.current.message(
                  "phone number",
                  values.phoneNumber,
                  "required"
                )}
              </div>
            </div>

            {/* Email */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={EmailtIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={20}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="email"
                    id="emailInput"
                    name="email"
                    value={values.email}
                    onChange={handleChangeValue}
                    placeholder="Email Address"
                  />
                </div>
                {validator.current.message(
                  "email",
                  values.email,
                  "required|email"
                )}
              </div>
            </div>

            {/* Hotel */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={Hotelbooking1}
                    alt="Name Icon"
                    className="input-icon"
                    height={26}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="hotelInput"
                    name="hotel"
                    value={values.hotel}
                    onChange={handleChangeValue}
                    placeholder="Hotel"
                  />
                </div>
                {validator.current.message("hotel", values.hotel, "required")}
              </div>
            </div>

            {/* Submit */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <button
                type="submit"
                className="hotelBooking ms-3"
                style={{
                  marginBottom: "15px",
                  textShadow: "0px 4px 4px 0px #000000",
                  width: "100%",
                }}
                disabled={loading || false}
              >
                {loading ? "Loading..." : " Claim Your Kiosk Now!"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        className="footer-text text-center mt-3"
        style={{ fontSize: "16px", color: "#323232" }}
      >
        copyright@2010-2024 Touch-iT.com All rights Reserved
      </div>
    </div>
  );
};

export default Hotelbooking;
