import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppConstants from "../../appConstants";

const RestaurantSidebar = ({ details }) => {
  const location = useLocation();
  const { pathname } = location;

  const isDesiredServiceActive =
    [AppConstants.routes.restaurant.desiredServices].includes(pathname) ||
    details?.progress > 0;

  const isCategoryActive =
    [AppConstants.routes.restaurant.restaurantCategory].includes(pathname) ||
    details?.progress > 20;

  const isDetailsActive =
    [AppConstants.routes.restaurant.restaurantDetails].includes(pathname) ||
    details?.progress > 40;

  const isCreateMenuActive =
    [AppConstants.routes.restaurant.restaurantMenu].includes(pathname) ||
    details?.progress > 60;

  const isRestaurantOptionActive =
    [AppConstants.routes.restaurant.newRestaurantOption].includes(pathname) ||
    details?.progress > 80;

  return (
    <>
      <div className="progrss-bar-sec p-4">
        <div className="progress-label">
          {/* {loadingDetails && <Skeleton count={1} />} */}
          {`${details?.progress || 0} % Progress`}
        </div>
        <div className="progress-bar-cover mb-3">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${details?.progress}%` }}
            ></div>
          </div>
        </div>
        {/* Restaurant registration */}
        {![AppConstants.routes.admin.restaurantEditListing].includes(
          pathname
        ) && (
          <div className="menu-wrapper mb-3">
            <ul>
              <li>
                <Link
                  to={
                    isDesiredServiceActive
                      ? AppConstants.routes.restaurant.desiredServices
                      : ""
                  }
                  className={`${
                    isDesiredServiceActive ? "active" : ""
                  } btn-link`}
                >
                  Desired Services
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCategoryActive
                      ? AppConstants.routes.restaurant.restaurantCategory
                      : ""
                  }
                  className={`${isCategoryActive ? "active" : ""} btn-link`}
                >
                  Restaurant Category
                </Link>
              </li>

              <li>
                <Link
                  to={
                    isDetailsActive
                      ? AppConstants.routes.restaurant.restaurantDetails
                      : ""
                  }
                  className={`${isDetailsActive ? "active" : ""} btn-link`}
                >
                  Restaurant Details
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCreateMenuActive
                      ? AppConstants.routes.restaurant.restaurantMenu
                      : ""
                  }
                  className={`${isCreateMenuActive ? "active" : ""} btn-link`}
                >
                  Add Menu
                </Link>
              </li>
              {![AppConstants.routes.admin.restaurantEditListing].includes(
                pathname
              ) && (
                <li>
                  <Link
                    to={
                      isRestaurantOptionActive
                        ? AppConstants.routes.restaurant.newRestaurantOption
                        : ""
                    }
                    className={`${
                      isRestaurantOptionActive ? "active" : ""
                    } btn-link`}
                  >
                    Restaurant Option
                  </Link>
                </li>
              )}

              {/* <li>
              <Link
                to={AppConstants.routes.restaurant.restaurantPayment}
                className={`${isBillingActive ? "active" : ""} btn-link`}
              >
                Billing
              </Link>
            </li> */}
            </ul>
          </div>
        )}

        {/* Restaurant create from admin */}
        {[AppConstants.routes.admin.restaurantEditListing].includes(
          pathname
        ) && (
          <div className="menu-wrapper mb-3">
            <ul>
              <li>
                <Link
                  to={
                    isDesiredServiceActive
                      ? AppConstants.routes.restaurant.desiredServices
                      : ""
                  }
                  className={`${
                    isDesiredServiceActive ? "active" : ""
                  } btn-link`}
                >
                  Desired Services
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCategoryActive
                      ? AppConstants.routes.restaurant.restaurantCategory
                      : ""
                  }
                  className={`${isCategoryActive ? "active" : ""} btn-link`}
                >
                  Restaurant Category
                </Link>
              </li>

              <li>
                <Link
                  to={
                    isDetailsActive
                      ? AppConstants.routes.restaurant.restaurantDetails
                      : ""
                  }
                  className={`${isDetailsActive ? "active" : ""} btn-link`}
                >
                  Restaurant Details
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCreateMenuActive
                      ? AppConstants.routes.restaurant.restaurantMenu
                      : ""
                  }
                  className={`${isCreateMenuActive ? "active" : ""} btn-link`}
                >
                  Add Menu
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default RestaurantSidebar;
