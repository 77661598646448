import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";
import { authCall } from "../services";

function StripeCustomCheckout() {
  
  const stripePromise = loadStripe(
    "pk_test_51Orr2IA3kborvutgkvumMoyDEYYbH0OUnJYBTlzt125Odz8MjZCn85c0YXdbGttxDNSgiFIiy1QGqTR4jGv0E1kL00mB8Ru2eI"
  );

  const [clientSecret, setClientSecret] = useState("");

  const options = {
    clientSecret:
      "cs_test_a16LfYLOeL3OYSe5KTT44p9lJNIgjJshagZHGxEN5ABwdUEAE6YsC8O428_secret_fidwbEhqYWAnPydgaGdgYWFgYScpJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ3dgYWx3YGZxSmtGamh1aWBxbGprJz8na2BzYHcneCUl",
  };

  useEffect(() => {
    async function fetchData() {
      const res = await authCall("createSubscriptionSession", {});
      if (res?.type === "success") {
        setClientSecret(res?.payload?.client_secret);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <h1>Payment</h1>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm />
        </Elements>
      )}
    </>
  );
}

export default StripeCustomCheckout;
