import React from "react";
import Stops from "../pages/activities/Stops";

function TourStopsAction() {
    return (
    <>
      <Stops />
    </>
  );
}

export default TourStopsAction;
