import React, { useState, useEffect } from "react";
import moment from "moment";
import "./AvailabilityCalendar.scss";
import webServices from "../../services/webServices";
import Spinner from "react-bootstrap/Spinner";

function AvailabilityCalendar({ selectedDate, totalPeople, activityCode }) {
  const [dateSelected, setDateSelected] = useState("");
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const today = moment();
  const datesList = Array.from({ length: 30 }, (_, i) =>
    today.clone().add(i, "days").format("YYYY-MM-DD")
  );

  useEffect(() => {
    async function fetchData() {
      const currentDt = moment().format("YYYY-MM-DD");
      const endDt = moment().add(30, "days").format("YYYY-MM-DD");
      const sarachData = {
        productCode: activityCode,
        startTimeLocal: `${currentDt} 00:00:00`,
        endTimeLocal: `${endDt}  23:59:59`,
        minAvailability: totalPeople,
      };
      setIsLoading(true);
      const resp = await webServices.searchAvailability(sarachData);
      if (resp.type === "success") {
        setSessions(resp?.payload?.sessions);
        setIsLoading(false);
      }
    }
    if (totalPeople > 0) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [totalPeople]);

  const onClickSeat = (date) => {
    selectedDate(date);
    setDateSelected(date);
  };

  // Check session availability
  const checkAvailability = (date) => {
    return sessions.some(
      (session) => moment(session.startTimeLocal).format("YYYY-MM-DD") === date
    );
  };

  return (
    <>
      <div className="container-cal">
        <div style={{ position: "relative" }}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <Spinner animation="border" variant="warning" />
            </div>
          )}
          <table className="grid">
            <tbody>
              <tr>
                {datesList.map((date, index) => (
                  <td
                    className={dateSelected === date ? "reserved" : "available"}
                    style={{
                      opacity:
                        !isLoading && !checkAvailability(date) ? 0.4 : "",
                      cursor: checkAvailability(date) ? "pointer" : "",
                    }}
                    key={index}
                    onClick={(e) =>
                      checkAvailability(date) ? onClickSeat(date) : ""
                    }
                  >
                    {/* <br /> */}
                    {checkAvailability(date) ? (
                      <>
                        <div>{moment(date).format("DD")}</div>
                        <div
                          style={{
                            color: "green",
                            fontSize: 11,
                            textAlign: "center",
                          }}
                        >
                          Available
                        </div>
                      </>
                    ) : (
                      <>
                        <div>{moment(date).format("DD")}</div>
                        <div
                          style={{
                            color: "red",
                            fontSize: 11,
                            textAlign: "center",
                            // opacity: 0.4,
                          }}
                        >
                          NA
                        </div>
                      </>
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
            {/* <Spinner animation="border" variant="warning" /> */}
          </table>
        </div>
      </div>
    </>
  );
}

export default AvailabilityCalendar;
