import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import AdminSidebar from "../../layouts/AdminSidebar";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { authCall } from "../../../services";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartPopUp from "../ChartPopUp";
// Register necessary components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  layout: {},
};

const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

function AdsReports() {
  const location = useLocation();
  const navigate = useNavigate();
  const { details } = location.state || {};
  const [locationList, setLocationList] = useState(details?.screens || []);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [chartType, setChartType] = useState("");
  const [chartData, setChartData] = useState({});

  const [dayViews, setDayViews] = useState(null);
  const [dayOfWeekViews, setDayOfWeekViews] = useState(null);
  const [hourViews, setHourViews] = useState(null);
  const [actionStatus, setActionStatus] = useState(false);
  const [chartAction, setChartAction] = useState("");

  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    async function fetchData() {
      if (!details) {
        navigate(AppConstants.routes.admin.listAds);
        return false;
      }

      setLoading(true);
      // const resp = await webServices.getTouchCounts("Activity");
      // if (resp?.type === "success") {
      //   setLocationList(resp?.payload);
      //   setLoading(false);
      // }
      // Day Wise Views
      const resp2 = await authCall("getDailyView", {
        ad_copy_id: details?.ad_copy_id,
        startDate,
        endDate,
      });
      if (resp2?.type === "success") {
        setDayViews(resp2?.payload);
      }

      // Day of week Wise Views
      const resp3 = await authCall("getDayOfWeekView", {
        ad_copy_id: details?.ad_copy_id,
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate,
      });
      if (resp3?.type === "success") {
        setDayOfWeekViews(resp3?.payload);
      }

      // Per hours Wise Views
      const resp4 = await authCall("getPerHoursView", {
        ad_copy_id: details?.ad_copy_id,
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate,
      });
      if (resp4?.type === "success") {
        setHourViews(resp4?.payload);
      }
    }
    fetchData();
  }, []);

  const filterChart = (title, Chart, chartData) => {
    setTitle(title);
    setShow(true);
    setChartType(Chart);
    setChartData(chartData);
  };

  const handleChangeFiter = async (startDate, endDate) => {
    if (chartAction === "day") {
      const resp = await authCall("getDailyView", {
        ad_copy_id: details?.ad_copy_id,
        startDate,
        endDate,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else if (chartAction === "week") {
      const resp = await authCall("getDayOfWeekView", {
        ad_copy_id: details?.ad_copy_id,
        startDate,
        endDate,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else {
      const resp = await authCall("getPerHoursView", {
        ad_copy_id: details?.ad_copy_id,
        startDate,
        endDate,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    }
  };

  // Pause or Resume Ads
  const handleChangeStatus = async () => {
    toast.dismiss();
    setActionStatus(true);
    const params = {
      adsId: details?.id,
      subscription_id: details?.stripe_subscription_id,
      type: details?.status === 1 ? "pause" : "resume",
    };
    const resp = await authCall("subscriptionAction", params);
    if (resp?.type === "success") {
      toast.success(resp?.message);
      setActionStatus(false);
      const updateState = { ...details, status: !details?.status };
      navigate(AppConstants.routes.admin.adsReports, {
        state: { details: updateState },
      });
    }
  };

  // Update ads
  const handleUpdate = () => {
    navigate(AppConstants.routes.admin.createAds, { state: { details } });
  };

  return (
    <>
      {console.log("details", details, chartAction)}
      <div className="dashboard-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title lh-1">Ads Reports:</div>
                <div className="details-wrapper pt-3 lh-sm mb-3 mb-lg-4">
                  {/* <div className="sub-title">Skyline Bourua</div>
                  <div className="lh-sm mb-3 mb-lg-4 fs-14">
                    <i>178 Fairy Springs Roads</i>
                  </div> */}

                  <div className="row pb-3 mt-3">
                    <div className="col-lg-5">
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">Views By Day</div>
                          {!dayViews && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayViews && (
                            <Line data={dayViews} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() => {
                              filterChart("Views By Day", Line, dayViews);
                              setChartAction("day");
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                            View By Days Of The Week
                          </div>
                          {!dayOfWeekViews && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayOfWeekViews && (
                            <Bar data={dayOfWeekViews} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() => {
                              filterChart(
                                "Total Views Days Of The Week",
                                Bar,
                                dayOfWeekViews
                              );
                              setChartAction("week");
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                            View By Hour Of The Day
                          </div>
                          {!hourViews && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {hourViews && (
                            <Bar data={hourViews} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() => {
                              filterChart(
                                "Total View Per Hour",
                                Bar,
                                hourViews
                              );
                              setChartAction("hour");
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-7 mb-4 mb-lg-0">
                      <div
                        className="table-responsive rounded-3"
                        style={{ border: "2px solid var(--theme-color1)" }}
                      >
                        <table
                          className="table theade-bg table-bordered m-0"
                          style={{ border: 0 }}
                        >
                          <thead>
                            <tr>
                              <th className="fs-md-18">Location</th>
                              <th className="fs-md-18 text-center">
                                Total Views
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {locationList.map((el, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="name fw-semibold">
                                    {el?.vanue_name}
                                  </div>
                                  <div className="fs-12">
                                    <i>
                                      {el?.street_address}, {el?.city},{" "}
                                      {el?.state} {el?.post_code}
                                    </i>
                                  </div>
                                </td>
                                <td>
                                  <div className="fs-md-18 count fw-semibold text-center">
                                    {el?.total_views || 0}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="btn-wrapper text-center mt-4">
                        <button
                          type="button"
                          className="btn btn-custom"
                          style={{ marginRight: 15 }}
                          onClick={handleChangeStatus}
                        >
                          {actionStatus
                            ? "Loading..."
                            : details?.status == 1
                            ? "Pause Ad"
                            : "Resume Ad"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-custom"
                          onClick={handleUpdate}
                        >
                          Update Ad
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChartPopUp
        handleClose={handleClose}
        modalStatus={show}
        title={title}
        Chart={chartType}
        data={chartData}
        options={options}
        handleChangeFiter={handleChangeFiter}
        startDt={startDate}
        endDt={endDate}
      />
    </>
  );
}

export default AdsReports;
