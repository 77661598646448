import React, { useState, useEffect } from "react";
import moment from "moment";
import "./AvailabilityCalendar.scss";
import webServices from "../../services/webServices";
import Spinner from "react-bootstrap/Spinner";
import { authCall } from "../../services";

function AvailabilityCalendarFareHarbor({
  selectedDate,
  totalPeople,
  activityCode,
  fareharborPKId,
}) {
  const [dateSelected, setDateSelected] = useState("");
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionLastDate, setSessionLastDate] = useState("");
  const [nextCount, setNextCount] = useState(1);

  const [currentWeekStart, setCurrentWeekStart] = useState(moment());

  const getWeekDates = (startDate) => {
    return Array.from({ length: 30 }, (_, i) =>
      startDate.clone().add(i, "days").format("YYYY-MM-DD")
    );
  };

  const datesList = getWeekDates(currentWeekStart);

  async function fetchData(currentDt, endDt) {
    setSessionLastDate(endDt);
    const sarachData = {
      shortName: activityCode,
      startTimeLocal: currentDt,
      endTimeLocal: endDt,
      productCode: fareharborPKId,
      capacity: totalPeople,
      month: false,
    };
    setIsLoading(true);
    const resp = await authCall("fareharborSearchAvailability", sarachData);
    if (resp.type === "success") {
      // setSessions(resp?.payload);
      setSessions([...sessions, ...resp?.payload]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (totalPeople > 0) {
      const currentDt = moment().format("YYYY-MM-DD");
      const endDt = moment().add(6, "days").format("YYYY-MM-DD");
      fetchData(currentDt, endDt);
    } else {
      setIsLoading(false);
    }
  }, [totalPeople]);

  useEffect(() => {
    if (totalPeople > 0) {
      const currentDt = currentWeekStart.format("YYYY-MM-DD");
      const endDt = currentWeekStart
        .clone()
        .add(6, "days")
        .format("YYYY-MM-DD");
      fetchData(currentDt, endDt);
    }
  }, [currentWeekStart]);

  const onClickSeat = (date) => {
    selectedDate(date);
    setDateSelected(date);
  };

  // Check session availability
  const checkAvailability = (date) => {
    return sessions.some(
      (session) => moment(session.start_at).format("YYYY-MM-DD") === date
    );
  };

  const handleNextWeek = () => {
    const startDT = moment(sessionLastDate).add(1, "days").format("YYYY-MM-DD");
    const endDT = moment(sessionLastDate).add(7, "days").format("YYYY-MM-DD");
    console.log(startDT, endDT);

    fetchData(startDT, endDT);
    setNextCount(nextCount + 1);
  };

  return (
    <>
      <div className="container-cal">
        <div style={{ position: "relative" }}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <Spinner animation="border" variant="warning" />
            </div>
          )}
          <table className="grid">
            <tbody>
              <tr>
                {datesList.map((date, index) => (
                  <td
                    className={dateSelected === date ? "reserved" : "available"}
                    style={{
                      opacity:
                        !isLoading && !checkAvailability(date) ? 0.4 : "",
                      cursor: checkAvailability(date) ? "pointer" : "",
                    }}
                    key={index}
                    onClick={(e) =>
                      checkAvailability(date) ? onClickSeat(date) : ""
                    }
                  >
                    {checkAvailability(date) ? (
                      <>
                        <div>{moment(date).format("DD")}</div>
                        <div
                          style={{
                            color: "green",
                            fontSize: 11,
                            textAlign: "center",
                          }}
                        >
                          Available
                        </div>
                      </>
                    ) : (
                      <>
                        <div>{moment(date).format("DD")}</div>
                        <div
                          style={{
                            color: "red",
                            fontSize: 11,
                            textAlign: "center",
                          }}
                        >
                          {/* NA */}
                        </div>
                      </>
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <div className="persons-info1 d-flex1 align-items-center justify-content-between gap-2">
                  <div
                    className="counter-box d-flex1 align-items-right justify-content-between"
                    style={{ width: "100%" }}
                  >
                    {/* <button
                      type="button"
                      id="sub3"
                      className="sub"
                      style={{
                        width: "95px",
                        opacity: totalPeople > 0 ? "" : 0.4,
                      }}
                      onClick={() =>
                        totalPeople > 0 ? handlePreviousWeek() : ""
                      }
                    >
                      <i className="bi bi-caret-left-fill"></i>
                      <span style={{ fontWeight: 600 }}>Previous</span>
                    </button> */}
                    <button
                      type="button"
                      id="add3"
                      className="add"
                      style={{
                        width: "95px",
                        opacity: totalPeople > 0 && nextCount < 5 ? "" : 0.4,
                        float: "right",
                      }}
                      onClick={() => (totalPeople > 0 ? handleNextWeek() : "")}
                      disabled={nextCount < 5 ? false : true}
                    >
                      <span style={{ fontWeight: 600 }}>Next</span>
                      <i className="bi bi-caret-right-fill"></i>
                    </button>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default AvailabilityCalendarFareHarbor;
