import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";

const CATEGORIES = [
  { value: "Business Event", label: "Business Event" },
  { value: "Classes, Lessons", label: "Classes and Lessons" },
  { value: "Community Event", label: "Community Event" },
  { value: "Concert, Performance", label: "Concert or Performance" },
  { value: "Exhibition", label: "Exhibition and Shows" },
  { value: "Festivals, Celebrations", label: "Festivals and Celebrations" },
  { value: "Food, Wine", label: "Food and Wine" },
  { value: "Markets", label: "Markets" },
  { value: "Sporting Events", label: "Sporting Events" },
];

const itemsPerPage = 10;

function ImportEventsList() {
  const [eventList, setEventList] = useState([]);
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState(CATEGORIES[0]);
  const [assignedCategory, setAssignedCategory] = useState(CATEGORIES[0]);
  const [assignedCategoryByID, setAssignedCategoryByID] = useState("");
  const [exclSkelArray] = useState(new Array(5).fill());
  const [mainEvent, setMainEvent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1000);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getATDWEvents", {
        category: "EVENT",
        occurrenceStart: moment().format("YYYY-MM-DD"),
        search: selectedOption?.value,
        page: 1,
        size: 10000,
      });
      if (resp?.type === "success") {
        const data = resp?.payload;
        setEventList(data);
        setLoading(false);
        // for pagination
        const totalPages = Math.ceil(data.length / itemsPerPage);
        setTotalPages(totalPages);
      }
    }
    fetchData();
  }, [selectedOption]);

  const closeToast = () => {
    toast.dismiss();
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleAssignCategory = (selected, productId) => {
    setAssignedCategoryByID(productId);
    setAssignedCategory(selected);
  };

  const currentData = eventList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleClickApproved = async (el) => {
    setLoadingStatus(`loading${el.productId}`);
    const resp = await authCall("importEvents", {
      userId: current?.id,
      productId: el.productId,
      eventPhoto: el.productImage,
      eventCategory:
        assignedCategoryByID === el.productId
          ? assignedCategory?.label
          : selectedOption?.label,
      mainEvent: mainEvent === el.productId ? true : false,
      action: "approved",
    });
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = eventList.filter(
        (item) => item.productId !== el.productId
      );
      setEventList(updatedItems);
      toast.success("Approved successfully.");
      // setLoadingStatus("");
    }
  };

  const handleClickReject = async (el) => {
    setLoadingStatus(`loadingReject${el.productId}`);
    const resp = await authCall("rejectEvents", {
      userId: current?.id,
      productId: el.productId,
      productName: el.productName,
      eventCategory: selectedOption?.label,
    });
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = eventList.filter(
        (item) => item.productId !== el.productId
      );
      setEventList(updatedItems);
      toast.success("Rejected successfully.");
    }
  };

  const handleChangeChecked = (event, id) => {
    const checked = event.target.checked || false;
    setMainEvent(checked ? id : "");
  };

  return (
    <>
      {console.log(assignedCategory)}
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Import Events</div>
                <div className="details-wrapper py-lg-4">
                  <Row className="align-items-center mb-4">
                    <Col xs={6}>
                      <span className="fs-sm">Filter by Category:</span>
                      <Select
                        className="text-body mt-1"
                        placeholder="Select"
                        value={selectedOption}
                        onChange={handleChange}
                        options={CATEGORIES}
                      />
                    </Col>
                  </Row>

                  <div className="row">
                    {loading &&
                      exclSkelArray.map((_, index) => (
                        <div
                          key={index}
                          className="col-xs-12 col-md-12 col-lg-12 mb-4 item"
                        >
                          <div className="join-blocks-import">
                            <div className="row">
                              <div className="col-xs-4 col-md-4 col-lg-3">
                                <Skeleton
                                  style={{
                                    width: 140,
                                    height: 100,
                                    borderRadius: 5,
                                    margin: 5,
                                  }}
                                />
                              </div>
                              <div
                                className="col-xs-4 col-md-4 col-lg-6 cursor-pointer"
                                style={{
                                  fontSize: 13,
                                }}
                              >
                                <div>
                                  <span className="text-custom-primary">
                                    <Skeleton width="60%" />
                                  </span>
                                </div>
                                <div>
                                  <span className="text-custom-primary">
                                    <Skeleton width="40%" />
                                  </span>
                                </div>
                                <div>
                                  <span className="text-custom-primary">
                                    <Skeleton width="70%" />
                                  </span>
                                </div>
                                <div>
                                  <span className="text-custom-primary">
                                    <Skeleton width="30%" />
                                  </span>
                                </div>

                                <div>
                                  <span className="text-custom-primary">
                                    <Skeleton width="10%" />
                                  </span>
                                </div>
                              </div>
                              <div className="col-xs-3 col-md-3 col-lg-3">
                                <div
                                  className="form-group d-flex align-items-center gap-2"
                                  style={{ marginTop: 10 }}
                                >
                                  <Skeleton
                                    style={{
                                      width: 25,
                                      height: 25,
                                    }}
                                  />
                                  <Skeleton
                                    style={{
                                      width: 100,
                                    }}
                                  />
                                </div>

                                <Skeleton
                                  style={{
                                    width: 120,
                                    height: 30,
                                  }}
                                />
                                <div className="mt-2 mb-2">
                                  <Skeleton
                                    style={{
                                      marginTop: 3,
                                      width: 120,
                                      height: 30,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {!loading &&
                      currentData.map((el, index) => {
                        return (
                          <div
                            key={index}
                            className="col-xs-12 col-md-12 col-lg-12 mb-4 item"
                          >
                            <div className="join-blocks-import">
                              <div className="row">
                                <div className="col-xs-4 col-md-4 col-lg-3">
                                  <img
                                    src={el?.productImage}
                                    className="img-fluid cursor-pointer"
                                    alt={el.name}
                                    style={{
                                      width: 140,
                                      height: 100,
                                      borderRadius: 5,
                                      margin: 5,
                                      border: "1px solid #ddb12e",
                                    }}
                                  />
                                </div>
                                <div
                                  className="col-xs-4 col-md-4 col-lg-6 cursor-pointer"
                                  style={{
                                    fontSize: 13,
                                  }}
                                >
                                  <div>
                                    <span className="text-custom-primary">
                                      Name:-{" "}
                                    </span>
                                    <span className="fs-12">
                                      {el?.productName}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="text-custom-primary">
                                      Start Date:-{" "}
                                    </span>
                                    <span className="fs-12">
                                      {moment(el?.startDate).format(
                                        "MMM D, YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="text-custom-primary">
                                      End Date:-{" "}
                                    </span>
                                    <span className="fs-12">
                                      {moment(el?.endDate).format(
                                        "MMM D, YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="text-custom-primary">
                                      Next Occurrence:-{" "}
                                    </span>
                                    <span className="fs-12">
                                      {moment(el?.nextOccurrence).format(
                                        "MMM D, YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="text-custom-primary">
                                      Address:-{" "}
                                    </span>
                                    <span className="fs-12">
                                      {el?.addresses[0].address_line}{" "}
                                      {el?.addresses[0].address_line2},{" "}
                                      {el?.addresses[0].city},{" "}
                                      {el?.addresses[0].state},{" "}
                                      {el?.addresses[0].postcode}
                                    </span>
                                  </div>
                                  <Row className="align-items-center mb-4">
                                    <Col xs={6}>
                                      <span className="text-custom-primary">
                                        Assign Category:-{" "}
                                      </span>
                                      <Select
                                        className="text-body mt-1"
                                        placeholder="Select"
                                        value={assignedCategoryByID === el.productId ? assignedCategory : selectedOption}
                                        // value={assignedCategory}
                                        onChange={(e) =>
                                          handleAssignCategory(e, el.productId)
                                        }
                                        options={CATEGORIES}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                <div className="col-xs-3 col-md-3 col-lg-3">
                                  <div
                                    className="form-group d-flex align-items-center gap-2"
                                    style={{ marginTop: 10 }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="mainEvent"
                                      id="mainEvent"
                                      checked={
                                        mainEvent === el.productId || false
                                      }
                                      onChange={(e) => {
                                        handleChangeChecked(e, el.productId);
                                      }}
                                    />
                                    <label className="m-0" htmlFor="mainEvent">
                                      Main Event
                                    </label>
                                  </div>

                                  <Button
                                    className="btn btn-custom btn-custom-sm fs-16 mt-0"
                                    onClick={() => handleClickApproved(el)}
                                    disabled={
                                      `loading${el.productId}` ===
                                        loadingStatus || false
                                    }
                                  >
                                    {`loading${el.productId}` === loadingStatus
                                      ? "Loading..."
                                      : "Approved"}
                                  </Button>
                                  <Button
                                    className="btn btn-custom-red btn-custom-sm fs-16 mt-3 mb-2"
                                    onClick={() => handleClickReject(el)}
                                    disabled={
                                      `loadingReject${el.productId}` ===
                                        loadingStatus || false
                                    }
                                  >
                                    {`loadingReject${el.productId}` ===
                                    loadingStatus
                                      ? "Loading..."
                                      : "Reject"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {/* Pagination Controls */}
                    <div className="text-center">
                      <Button
                        className="btn btn-custom btn-custom-sm fs-16 mt-0"
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                        disabled={currentPage === 1}
                      >
                        Previous{" "}
                      </Button>

                      <span>
                        {" "}
                        Page {currentPage} of {totalPages}{" "}
                      </span>

                      <Button
                        className="btn btn-custom btn-custom-sm fs-16 mt-0"
                        onClick={() =>
                          setCurrentPage((prev) =>
                            Math.min(prev + 1, totalPages)
                          )
                        }
                        disabled={currentPage === totalPages}
                      >
                        {" "}
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportEventsList;
