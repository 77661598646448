import React, { useEffect, useState, useRef } from "react";
import uploadIcon from "../../assets/images/upload-icon.svg";
import CropperModal from "./CropperModal";

const ImageUploads = ({ getImage, index, uploadedImage, isBackground, aspectRation, croppedSize }) => {
  const fileInputRef = useRef(null);
  const [profileImage, setProfileImage] = useState("");
  const [imageForCrop, setImageForCrop] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setProfileImage(uploadedImage);
  }, [uploadedImage]);

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageForCrop(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setShow(true);
  };

  const getCropData = (val) => {
    if (!isBackground) {
      setProfileImage(val);
    }
    getImage(val);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {profileImage === "" && (
        <div className="custom-input-file mb-2">
          <input
            type="file"
            name="img1"
            id={`file-111${index}`}
            className="inputfile"
            accept="image/*"
            onChange={(e) => onChange(e)}
          />
          <label htmlFor={`file-111${index}`} className="m-0">
            <span></span>
            <div className="icon">
              <img src={uploadIcon} alt="upload icon" />
            </div>
          </label>
        </div>
      )}
      {profileImage !== "" && (
        <>
          <div style={{ width: "120px" }} className="img-wrapper">
            <img
              src={profileImage}
              className="img-fluid rounded"
              alt="cropped"
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="d-flex flex-wrap align-items-center gap-2 mt-2">
            <button
              type="button"
              className="btn btn-custom btn-custom-sm"
              onClick={() => handleBrowseClick()}
            >
              Change
            </button>
          </div>
        </>
      )}
      <CropperModal
        show={show}
        handleClose={handleClose}
        image={imageForCrop}
        sendCropedDataToParent={getCropData}
        aspectRation={aspectRation}
        croppedSize={croppedSize}
      />
    </>
  );
};

export default ImageUploads;