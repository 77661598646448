import React from "react";
import Modal from "react-bootstrap/Modal";

function AdsPlanConfimPopUp({
  handleClose,
  modalStatus,
  planType,
  data,
  handleConfirm,
}) {
  return (
    <>
      <Modal
        show={modalStatus}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="md"
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">
            Plan Change Confirmation:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="details-wrapper">
                <div
                  style={{
                    color: "#00ffe7",
                    fontSize: 25,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {planType === "upgrade"
                    ? "Congratulations On Upgrading Your Ad"
                    : "We Have Downgraded Your Plan"}
                </div>
                <div className="fs-14 mt-2" style={{ textAlign: "justify" }}>
                  {planType === "upgrade"
                    ? `Your new subscription is for $${data.amount} per month, and will receive ${data.views}
                  guaranteed views every single month.`
                    : `Your current subscription will end in ${data.endDays} days on the ${data.endDate}.`}
                </div>
                <div className="fs-14 mt-3" style={{ textAlign: "justify" }}>
                  {planType === "upgrade"
                    ? `By Upgrading now, you will save $${
                        data.save
                      } on your first payment which
                  will be $${data.amount - data.save}.`
                    : `Your new subscription will start on ${data.startDate} and you will be charged $${data.amount} every thirty days.`}
                </div>

                {planType === "upgrade" && (
                  <div className="fs-14 mt-3" style={{ textAlign: "justify" }}>
                    Then you will be charged ${data.amount} every thirty days,
                    starting on {data.upgradeEndDate}.
                  </div>
                )}
                <div className="btn-wrapper text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-custom btn-custom-sm fs-18"
                    style={{
                      marginRight: 15,
                      backgroundColor: "#000",
                      color: "#fff",
                      border: "1px solid #ddb12e",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-custom btn-custom-sm fs-18"
                    onClick={handleConfirm}
                  >
                    {planType === "upgrade" ? "Upgrade" : "Downgrade"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdsPlanConfimPopUp;
