import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import AdminSidebar from "../layouts/AdminSidebar";
import AppConstants from "../../appConstants";
import { authCall } from "../../services";
import BannerImagesUploads from "../util/BannerImagesUploads";
import ImageUploads from "../util/ImageUploads";
import MultiVideoUploads from "../util/MultiVideoUploads";
import VideoTutorialPopUp from "../admin/VideoTutorialPopUp";

const UploadContentVideo = AppConstants.tutorial.uploadContent;

function UploadContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { adsDetails, selectedMachine } = location.state || {};
  // const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [amazingTurnOn, setAmazingTurnOn] = useState(false);
  const [addedContents, setAddedContents] = useState(
    adsDetails?.added_style === "Static Image" ? "" : []
  );
  const [videoURL, setVideoURL] = useState("");
  const showTutorialVideo = [
    AppConstants.routes.admin.uploadContent,
  ].includes(pathname);

  useEffect(() => {
    if (!adsDetails && !selectedMachine) {
      navigate(AppConstants.routes.admin.runAdd);
    }
  }, [adsDetails, selectedMachine, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const resp = await authCall("uploadAdsContents", {
      adsId: adsDetails?.id,
      addedContents: addedContents,
      amazingTurnOn: amazingTurnOn,
      contentType: adsDetails?.added_style,
    });
    if (resp?.type === "success") {
      toast.success(resp?.message);
      setLoading(false);
      // navigate(AppConstants.routes.admin.adsSubscription, {
      //   state: { adsDetails, selectedMachine },
      // });
      navigate(AppConstants.routes.admin.listAds);
    } else {
      toast.error(resp?.errormessage);
      setLoading(false);
    }
  };

  const getAdsVideos = (files) => {
    setAddedContents(files);
  };

  const getSlideImages = (images) => {
    console.log("images", images);
    setAddedContents(images);
  };

  const getStaticImage = (img) => {
    setAddedContents(img);
  };

  const handleClickTutorial = () => {
    setVideoURL(UploadContentVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      {console.log("adsDetails", adsDetails)}
      <div className="upload-your-content py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Upload Your Content</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    {showTutorialVideo && <QuestionCircle />}
                  </span>
                </div>
                <div className="details-wrapper pb-lg-4">
                  <div className="cover-box mb-4">
                    <div className="main-heading2 mb-1">Upload Add:</div>
                    <div className="fs-18 fw-semibold mb-1">
                      Please upload your add below.
                    </div>
                    <p className="fw-normal">
                      Don’t worry if it is not perfect simply check the{" "}
                      <i>“Turn It Into Something Amazing”</i> option below and
                      our team will work their magic
                    </p>
                  </div>

                  <div className="custom-field-wrapper">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <div className="fs-md-18 fw-medium mb-2">
                          {adsDetails?.added_style === "Static Image" &&
                            "Upload Static Image:"}
                          {adsDetails?.added_style === "Image Slideshow" &&
                            "Upload Slideshow Images:"}
                          {adsDetails?.added_style === "Live Action Video" &&
                            "Upload Videos:"}
                          {adsDetails?.added_style === "Animation" &&
                            "Upload Animation Videos:"}
                        </div>

                        <div className="info-cover">
                          <div className="banner-cover mb-2">
                            {adsDetails?.added_style === "Static Image" && (
                              <ImageUploads
                                getImage={getStaticImage}
                                uploadedImage={addedContents}
                              />
                            )}

                            {adsDetails?.added_style === "Image Slideshow" && (
                              <BannerImagesUploads
                                bannerImages={getSlideImages}
                                images={addedContents}
                                label="Add Another Image"
                              />
                            )}

                            {(adsDetails?.added_style === "Live Action Video" ||
                              adsDetails?.added_style === "Animation") && (
                              <MultiVideoUploads
                                videos={getAdsVideos}
                                addedVideos={addedContents}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-4">
                        <div className="form-group d-flex align-items-center gap-3 mt-1">
                          <input
                            type="checkbox"
                            checked={amazingTurnOn}
                            onChange={(e) => setAmazingTurnOn(e.target.checked)}
                          />

                          <label className="m-0" htmlFor="TIISA">
                            Turn It Into Something Amazing
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper text-center mb-2 pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmit}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default UploadContent;
