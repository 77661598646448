import React, { useEffect } from "react";
import CostPerPerson from "../pages/activities/CostPerPerson";
import { useNavigate, useLocation } from "react-router-dom";
import AppConstants from "../../appConstants";

function TourCostPerPersonAction() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state) {
      navigate(AppConstants.routes.tour.tourRezdyIntegration);
    }
  }, [location, navigate]);

  return (
    <>
      <CostPerPerson items={location?.state?.items} />
    </>
  );
}

export default TourCostPerPersonAction;
