import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Kangaroo from "../../assets/images/kangaroo.png";
import BestPrice from "../../assets/images/icons/best-price.png";
import TrustedBy from "../../assets/images/icons/trusted-by.png";
import Australian from "../../assets/images/icons/australian.png";
import Unbeatable from "../../assets/images/icons/unbeatable.png";
// gotta party here images
import cairnsImage from "../../assets/images/gotta-party-here/cairns.jpg";
import goldCoastImage from "../../assets/images/gotta-party-here/gold-coast.jpg";
import portDouglasImage from "../../assets/images/gotta-party-here/port-duglas.jpg";
import sunshineCoastImage from "../../assets/images/gotta-party-here/sunshine-coast.jpg";
// bar you love images
import beerGardenImage from "../../assets/images/bar-you-love/beer-garden.jpg";
import cocktailBarsImage from "../../assets/images/bar-you-love/cocktail-bars.jpg";
import hotelImage from "../../assets/images/bar-you-love/hotel.jpg";
import irishBarImage from "../../assets/images/bar-you-love/irish-bar.jpg";
import karakoeBarImage from "../../assets/images/bar-you-love/karakoe-bar.jpg";
import wineImage from "../../assets/images/bar-you-love/wine.jpg";
import BannerComponent from "../BannerComponent";
import BannerSubComponent from "../BannerSubComponent";
import StarRating from "../common/StarRating";

// banners images

import { fetchData } from "../../services";
import { generateSku } from "../util/utils";
import TestimonialsComponent from "../TestimonialsComponent";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1200,
    },
    items: 4,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 575,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile1: {
    breakpoint: {
      max: 991,
      min: 576,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1199,
      min: 992,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
};

const GOTTAPARTYHERE = [
  {
    name: "Gold Coast",
    sku: "/gold-coast",
    image: goldCoastImage,
    banners: [goldCoastImage],
  },
  {
    name: "Cairns",
    sku: "/cairns",
    image: cairnsImage,
    banners: [cairnsImage],
  },
  {
    name: "Port Douglas",
    sku: "/port-douglas",
    image: portDouglasImage,
    banners: [portDouglasImage],
  },
  {
    name: "Sunshine Coast",
    sku: "/sunshine-coast",
    image: sunshineCoastImage,
    banners: [sunshineCoastImage],
  },
  {
    name: "Gold Coast",
    sku: "/gold-coast",
    image: goldCoastImage,
    banners: [goldCoastImage],
  },
];

const LOVEBARS = [
  {
    name: "Cocktail Bars",
    sku: "/cocktail-bars",
    image: cocktailBarsImage,
    banners: [cocktailBarsImage],
  },
  {
    name: "Beer Garden",
    sku: "/beer-garden",
    image: beerGardenImage,
    banners: [beerGardenImage],
  },
  {
    name: "Irish Pubs",
    sku: "/irish-pubs",
    image: irishBarImage,
    banners: [irishBarImage],
  },
  {
    name: "Hotel Bars",
    sku: "/hotel-bars",
    image: hotelImage,
    banners: [hotelImage],
  },
  {
    name: "Karaoke Bar",
    sku: "/karaoke-bar",
    image: karakoeBarImage,
    banners: [karakoeBarImage],
  },
  {
    name: "Wine Bar",
    sku: "/wine-bar",
    image: wineImage,
    banners: [wineImage],
  },
];

function NightlifeHomePage() {
  // const location = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [banner, setBanner] = useState([]);
  const [topNightClubs, setTopNightClubs] = useState([]);
  const [topSportBars, setTopSportBars] = useState([]);
  const [topComedyClubs, setTopComedyClubs] = useState([]);
  const [topSpecialityBars, setTopSpecialityBars] = useState([]);

  //   const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      //   setLoading(true);
      const resp = await fetchData("getNightlifeHomeData");
      if (resp.type === "success") {
        // setRecords(resp.payload);
        setBanner(resp.payload.banners);
        setTopNightClubs(resp.payload.topNightClubs);
        setTopSportBars(resp.payload.topSportBars);
        setTopComedyClubs(resp.payload.topComedyClubs);
        setTopSpecialityBars(resp.payload.topSpecialityBars);
        // setLoading(false);
      }
    }
    getData();
  }, []);

  const handleClickBooking = (tag, item) => {
    const name = generateSku(item.name);
    navigate(`/night-life/${name}`, { state: { nightId: item.id } });
  };

  const handleClickViewList = (item) => {
    navigate(`/night-life/sub-page${item.sku}`, {
      state: { title: item.name, banners: item.banners },
    });
  };

  return (
    <>
      {/* BANNER */}
      <BannerComponent
        banner={banner}
        title="TOUCH - iT"
        subTitle="See it all"
        button={{ title: "Search", link: "/night-life/search" }}
      />

      {/* BEST PRICE GUARANTEED */}
      <BannerSubComponent />

      {/* TOP NIGHTCLUBS */}
      <section className="top-experiences te py-5">
        <div className="container mb-5">
          <h1 className="heading mt-4">Top Nightclubs</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {topNightClubs.map((el, index) => {
                return (
                  <div className="item col-12" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() => handleClickBooking("nightclub", el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("nightclub", el)}
                      >
                        {el.name}
                      </h4>
                      <StarRating rating={el?.total_ratings} />
                      {el?.reviews && (
                        <span className="price">
                          <ins>Based on {el?.reviews} Reviews</ins>
                        </span>
                      )}
                      {/* <span className="price">
                        {el?.cutPrice && (
                          <>
                            from <del>${el?.cutPrice}</del>
                          </>
                        )}
                        <ins>${el?.price}</ins>
                      </span> */}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>

        <div className="container mt-5">
          <h1 className="heading mt-4">Top Sports Bars</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              responsive={responsive}
            >
              {topSportBars.map((el, index) => {
                return (
                  <div className="item col-12" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() => handleClickBooking("sports-bar", el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("sports-bar", el)}
                      >
                        {el.name}
                      </h4>
                      <span className="stars">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star-o"></i>
                        (4.6)
                      </span>
                      <span className="price">
                        {el?.cutPrice && (
                          <>
                            from <del>${el?.cutPrice}</del>
                          </>
                        )}
                        <ins>${el?.price}</ins>
                      </span>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* BARS YOU'LL LOVE */}
      <section className="top-experiences take-me-there experiences py-5">
        <div className="container">
          <h1 className="heading mt-4">Bars you'll love</h1>
          <div className="row g-4 mt-0">
            {LOVEBARS.map((el, index) => {
              return (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 col-xs-6"
                  key={index}
                >
                  {/* <Link to={el.sku}> */}
                  <div
                    className="join-blocks cursor-pointer"
                    onClick={() => handleClickViewList(el)}
                  >
                    <div className="join-blocks-img">
                      <img src={el.image} className="img-fluid" alt={el.name} />
                    </div>
                    <h4>{el.name}</h4>
                  </div>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* TOP COMEDY CLUBS */}
      <section className="top-experiences top-deals py-5">
        <div className="container">
          <h1 className="heading mt-4">Top Comedy Clubs</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {topComedyClubs.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() => handleClickBooking("comedy-club", el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("comedy-club", el)}
                      >
                        {el.name}
                      </h4>
                      <span className="stars">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star-o"></i>
                        (4.6)
                      </span>
                      {/* <span className="price">
                        {el?.cutPrice && (
                          <>
                            from <del>${el?.cutPrice}</del>
                          </>
                        )}
                        <ins>${el?.price}</ins>
                      </span> */}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* TESTIMONIALS */}
      <section className="top-experiences testimonials py-5">
        <TestimonialsComponent />
      </section>

      {/* TOP SPECIALITY BARS */}
      <section className="top-experiences top-deals pt-5 pb-4">
        <div className="container">
          <h1 className="heading">Top Speciality Bars</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={3000}
              responsive={responsive}
            >
              {topSpecialityBars.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() =>
                            handleClickBooking("specality-bar", el)
                          }
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("specality-bar", el)}
                      >
                        {el.name}
                      </h4>
                      <span className="stars">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star-o"></i>
                        (4.6)
                      </span>
                      {/* <span className="price">
                        {el?.cutPrice && (
                          <>
                            from <del>${el?.cutPrice}</del>
                          </>
                        )}
                        <ins>${el?.price}</ins>
                      </span> */}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
}

export default NightlifeHomePage;
