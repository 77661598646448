import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import MenuDetailPage from "../../common/MenuDetailPage";
import AdminSidebar from "../../layouts/AdminSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";

function UpdateRestaurantMenu() {
  const location = useLocation();
  const { restaurantId } = location.state || {};
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState(false);
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!restaurantId) {
      navigate(AppConstants.routes.admin.restaurantEditListing);
    }

    async function fetchData() {
      const resp = await authCall("getCompanyDetails", {
        companyId: restaurantId,
        companyType: "Restaurant",
      });
      if (resp?.type === "success") {
        setCompanyDetails(resp?.payload);
      }
    }
    fetchData();
  }, []);

  const handleSubmitForm = async (data, val) => {
    setLoading(true);
    data.menuType = val;
    const resp = await webServices.createMenu(data);
    if (resp?.type === "success") {
      setLoading(false);
      toast.dismiss();
      toast.success("Updated successfully.");
      if (current?.user_type === "Admin") {
        navigate(AppConstants.routes.admin.restaurantList);
      } else {
        navigate(AppConstants.routes.admin.restaurantEditListing);
      }
    }
  };

  const buttons = [
    {
      id: 1,
      label: "Update",
    },
  ];

  return (
    <>
      <MenuDetailPage
        onSubmit={handleSubmitForm}
        loading={loading}
        restaurantId={restaurantId}
        heading="Update Your Menu"
        Sidebar={AdminSidebar}
        buttons={buttons}
        details={companyDetails}
        userId={current?.id}
      />
    </>
  );
}

export default UpdateRestaurantMenu;
