import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Select from "react-select";
import { QuestionCircle } from "react-bootstrap-icons";
import { Row, Col } from "react-bootstrap";
import AdminSidebar from "../layouts/AdminSidebar";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import { authCall } from "../../services";
import { handleNumericInput } from "../util/utils";
import VideoTutorialPopUp from "./VideoTutorialPopUp";

const PricePerPersonVideo = AppConstants.tutorial.pricePerPerson;

function UpdateActivityPrice() {
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [priceData, setpriceData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [activityCode, setActivityCode] = useState([]);
  const [loadingPrice, setloadingPrice] = useState(false);
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    async function fetchData() {
      const resp = await authCall("getActivityCode", { userId: current?.id });
      if (resp?.type === "success") {
        setActivityCode(resp?.payload);
      }
    }
    fetchData();
  }, []);

  const handleChange = (id, value) => {
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, touchIdLabel: value, quantity: 0 } : field
      )
    );
  };

  const handleChangeAdvPrice = (id, value) => {
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, advertisePrice: value } : field
      )
    );
  };

  const handleChangeChecked = (event, id) => {
    const checked = event.target.checked || false;
    setpriceData(
      priceData.map((field) =>
        field.id === id ? { ...field, isDisplay: checked } : field
      )
    );
  };

  const handleSubmit = async () => {
    console.log("priceData", priceData);
    toast.dismiss();
    setLoading(true);
    const resp = await webServices.createTour({
      bookingId: selectedOption?.id,
      priceOptions: priceData,
    });
    if (resp?.type === "success") {
      toast.success("Price updated successfully.");
      setLoading(false);
      // navigate(AppConstants.routes.tour.tourCompanyDetails);
    }
  };

  const completeProducts = (products, reference) => {
    return products.map((product) => {
      const referenceProduct = reference.find((ref) => ref.id === product.id);
      if (referenceProduct) {
        return { ...referenceProduct, ...product };
      } else {
        return { ...product, isDisplay: true };
      }
    });
  };

  const handleSearchProductCode = async (item) => {
    setSelectedOption(item);
    setloadingPrice(true);
    const resp = await webServices.searchActivity({ productCode: item.value });
    if (resp?.type === "success") {
      const newPrice = resp?.payload[0]?.priceOptions || [];
      const finalProduct2 = completeProducts(newPrice, item.price_options);
      setpriceData(finalProduct2);
      setloadingPrice(false);
    } else {
      setloadingPrice(false);
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(PricePerPersonVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="cost-per-person-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
              <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Update Cost Per Person</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>

                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    Please select the product code and get price list from Rezdy
                  </div>
                  <Row className="align-items-center mb-4">
                    <Col xs={6}>
                      <span className="fs-sm">Filter by Code:</span>
                      <Select
                        className="text-body mt-1"
                        placeholder="Select Code"
                        value={selectedOption}
                        onChange={handleSearchProductCode}
                        options={activityCode}
                      />
                    </Col>
                  </Row>
                  {!loadingPrice &&
                    priceData.map((el, index) => (
                      <div key={index}>
                        <div className="row">
                          <div
                            className="col-sm-1 col-md-1"
                            // style={{
                            //   display: "flex",
                            //   justifyContent: "center",
                            //   alignItems: "center",
                            // }}
                          >
                            <label htmlFor="adult">Display:</label>
                            <input
                              style={{ marginTop: 5 }}
                              type="checkbox"
                              name="dayOfWeek"
                              id={el.label}
                              checked={el.isDisplay || false}
                              onChange={(e) => {
                                handleChangeChecked(e, el.id);
                              }}
                            />
                          </div>
                          <div className="col-sm-2 col-md-2">
                            <div className="form-group">
                              <label htmlFor="adult" className="">
                                ID:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={el?.id}
                              />
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2">
                            <div className="form-group">
                              <label htmlFor="1adult2Children" className="">
                                Rezdy Label:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={el?.label}
                              />
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2">
                            <div className="form-group">
                              <label htmlFor="2adult2Child" className="">
                                Price:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={el?.price}
                              />
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2">
                            <div className="form-group">
                              <label htmlFor="2adult2Child" className="">
                                Adv. Price:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={el?.advertisePrice || ""}
                                onChange={(e) => {
                                  const inputValue = handleNumericInput(
                                    e.target.value
                                  );
                                  if (
                                    inputValue !== null &&
                                    inputValue.length < 8
                                  ) {
                                    handleChangeAdvPrice(el.id, inputValue);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3">
                            <div className="form-group">
                              <label htmlFor="2adult2Child" className="">
                                Touch-iT Site Label:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                value={el.touchIdLabel}
                                onChange={(e) =>
                                  handleChange(el.id, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* <hr /> */}
                      </div>
                    ))}
                  {!loadingPrice && priceData.length > 0 && (
                    <div className="adv-price-sec my-4 pb-4">
                      <div className="btn-wrapper text-center mt-3">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={handleSubmit}
                          disabled={loading || false}
                        >
                          {loading ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </div>
                  )}

                  {loadingPrice && (
                    <div className="btn-wrapper text-center mt-3">
                      Loading...
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default UpdateActivityPrice;
