// HeaderUser.jsx
import React from "react";
import LogoLanding from "../../../assets/images/logo-landing.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails, clearCompanyDetails } from "../../../redux/reducers/companyDetailsSlice";
import authService from "../../../services/authServices";
import AppConstants from "../../../appConstants";

const HeaderUser = ({ navigate }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const { logout, isLoggedIn } = authService;

  const handleLogout = () => {
    dispatch(clearCompanyDetails());
  };
  return (
    <>
      <header className="custom-header">
        <div className="container">
          <div className="row custom-row d-flex align-items-center">
            <div className="col-6 col-md-3">
              <div className="d-flex align-items-center gap-3">
                <button
                  type="button"
                  id="openSidebar"
                  className="btn btn-link p-0 border-0 text-white d-lg-none"
                >
                  <i className="bi bi-list fs-2"></i>
                </button>
                <Link to={AppConstants.routes.admin.dashboard} className="logo">
                  <img src={LogoLanding} className="img-responsive" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-1 col-md-4">
              <div id="cssmenuu" className="common-menu-wrapper sidebar-drawer">
                <div className="logo d-flex align-items-center justify-content-between gap-3 border-bottom d-md-none">
                  <Link to={"index"}>
                    <img
                      src={LogoLanding}
                      className="img-responsive"
                      alt="Logo"
                    />
                  </Link>
                  <button
                    type="button"
                    id="closeSidebar"
                    className="btn-link p-0 border-0 text-white"
                  >
                    <i className="bi bi-x fs-18"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-5 col-md-5">
              <div className="header-section d-flex flex-wrap align-items-center justify-content-end gap-2">
                Welcome! {current?.user_name}
                {isLoggedIn() ? (
                  <button
                    type="button"
                    className="btn logout button-new"
                    onClick={() => {
                      handleLogout();
                      logout(navigate);
                    }}
                  >
                    Logout
                  </button>
                ) : (
                  <Link to="/login" className="btn logout button-new">
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderUser;
