import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestiImg1 from "./../assets/images/testimonials/1.png";
import TestiImg2 from "./../assets/images/testimonials/2.png";
import TestiImg3 from "./../assets/images/testimonials/3.png";
import AppConstants from "../appConstants";

// HOMEPAGE
const HOMEPAGE = [
  {
    title: "Adam",
    description: `“Zooming across the water with stunning views was exhilarating. Our guide was fantastic, sharing insights about the area while keeping us safe. The adrenaline rush was matched only by the fun of exploring hidden spots. This adventure is perfect for anyone looking for a thrilling day on the water!"`,
    image: TestiImg1,
  },
  {
    title: "Carter Mitchell",
    description: `“The speed and sharp turns gave us an adrenaline rush like no other. Our guide was enthusiastic and made the ride even more enjoyable with fun commentary. The stunning scenery added to the thrill. I highly recommend this adventure for anyone looking for an unforgettable day on the water!"`,
    image: TestiImg2,
  },
  {
    title: "Brian Mitchell",
    description: `“The lively atmosphere was perfect for enjoying time with friends. The food was hearty and delicious, especially the classic pub fare. The selection of local beers was impressive, and the staff was friendly and attentive.”`,
    image: TestiImg3,
  },
  {
    title: "Lewis",
    description: `“The atmosphere was warm and inviting, making it a perfect setting for a meal. Each dish was expertly prepared, bursting with flavor and beautifully presented. The staff was attentive and friendly, enhancing our visit. All in all, a memorable meal!"`,
    image: TestiImg1,
  },
];

// BOOKING
const BOOKING = [
  {
    title: "Hannah Reed",
    description: `"Our whale watching experience was nothing short of magical! The guide's enthusiasm made the experience truly special. We encountered several whales, each moment more thrilling than the last. Highly recommend this adventure to anyone looking to experience nature up close.”`,
    image: TestiImg1,
  },
  {
    title: "Daniel",
    description: `"The jetboating trip was an unforgettable adventure! Speeding through the water and twisting around bends was thrilling. I highly recommend this experience for anyone looking for a memorable day on the water!"`,
    image: TestiImg2,
  },
  {
    title: "Megan Harris",
    description: `“Soaring high above the stunning coastline was a breathtaking experience. The crew was professional and friendly, ensuring a safe and enjoyable ride. The views were unparalleled, and the sense of freedom was exhilarating."`,
    image: TestiImg3,
  },
  {
    title: "Anderson",
    description: `"The creepy atmosphere set the perfect tone, and the puzzles were both challenging and fun. The tension kept us on our toes, and we loved the surprises along the way. A fantastic time for fans of adventure—Definitely recommend it!"`,
    image: TestiImg1,
  },
];

// DINNING
const DINNING = [
  {
    title: "King",
    description: `"The ambiance was elegant, and the service was impeccable. Each dish was a work of art, bursting with flavor and creativity. From the expertly curated wine list to the exquisite desserts, every moment was a delight. I highly recommend this culinary gem!"`,
    image: TestiImg1,
  },
  {
    title: "Jason Walker",
    description: `“The vibrant atmosphere set the perfect tone, and the service was warm and welcoming. Each dish was bursting with authentic flavors, from the fresh guacamole to the delectable tacos. A true fiesta for the taste buds. Highly recommend for anyone craving delicious Mexican cuisine!"`,
    image: TestiImg2,
  },
  {
    title: "Rachel Scott",
    description: `“The ambiance was charming, and the service was impeccable. Each dish was a work of art, from the flavorful coq au vin to the heavenly pastries. Every bite was a celebration of French cuisine.”`,
    image: TestiImg3,
  },
  {
    title: "Eric",
    description: `“The relaxed atmosphere and friendly service made us feel right at home. Each dish celebrated fresh, local ingredients. A great way to get a taste of Australia!"`,
    image: TestiImg1,
  },
];

// NIGHTLIFE
const NIGHTLIFE = [
  {
    title: "Jessica",
    description: `“The atmosphere was cozy and inviting, perfect for relaxing with friends. The drink menu was impressive, featuring unique cocktails crafted with care. The bartenders were friendly and knowledgeable, adding to the fun. I highly recommend this spot for a laid-back night out!"`,
    image: TestiImg1,
  },
  {
    title: "Nathan Price",
    description: `“The extensive wine selection featured exquisite options, and the staff provided expert recommendations. Pairing our drinks with delicious cheese and charcuterie elevated the experience.”`,
    image: TestiImg2,
  },
  {
    title: "Olivia",
    description: `“The lineup of comedians had us laughing from start to finish, with a great mix of styles. The intimate setting made it feel personal, and the energy was electric. If you’re looking for a fun night out, it’s definitely worth checking out!"`,
    image: TestiImg3,
  },
  {
    title: "Edwards",
    description: `“The atmosphere was lively, filled with fellow fans cheering on their teams. The food was delicious—perfect for game day—especially the wings and nachos. The staff was attentive and friendly. Perfect for an exciting night out with friends!"`,
    image: TestiImg1,
  },
];

function TestimonialsComponent() {
  const location = useLocation();
  const { pathname } = location;
  const [testimonial, setTestimonial] = useState(HOMEPAGE);

  useEffect(() => {
    if (AppConstants.routes.home === pathname) {
      setTestimonial(HOMEPAGE);
    } else if (pathname.startsWith(AppConstants.routes.booking)) {
      setTestimonial(BOOKING);
    } else if (pathname.startsWith(AppConstants.routes.dinning)) {
      setTestimonial(DINNING);
    } else if (pathname.startsWith(AppConstants.routes.nightlifelanding)) {
      setTestimonial(NIGHTLIFE);
    }
  }, [pathname]);

  return (
    <>
      <div className="container">
        <h1 className="heading">Testimonials</h1>
        <div className="carousel-wrapper mt-4">
          <Carousel
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1200,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 575,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              mobile1: {
                breakpoint: {
                  max: 991,
                  min: 576,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1199,
                  min: 992,
                },
                items: 3,
                partialVisibilityGutter: 30,
              },
            }}
          >
            {testimonial.map((el) => (
              <div className="item">
                <div className="join-blocks-img">
                  <img
                    src={el.image}
                    className="img-fluid d-none"
                    alt="Momento"
                  />
                </div>
                <div className="join-blocks">
                  <h4>{el.title}</h4>
                  <span className="stars">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </span>
                  <p>{el.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default TestimonialsComponent;
