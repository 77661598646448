import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import AdminSidebar from "../../layouts/AdminSidebar";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { authCall } from "../../../services";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartPopUp from "../ChartPopUp";

// Register necessary components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  layout: {},
};

// const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

function NightLifeDashboard() {
  const [touchLocations, setTouchLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [chartType, setChartType] = useState("");
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const [startDT, setStartDT] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );

  const [dayReveals, setDayReveals] = useState(null);
  const [dayOfWeekReveals, setDayOfWeekReveals] = useState(null);
  const [hourReveals, setHourReveals] = useState(null);
  const [weekReveals, setWeekReveals] = useState([]);
  const [dayRevealLoading, setDayRevealLoading] = useState(false);
  const [type, setType] = useState("");

  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await webServices.getTouchCounts("Night");
      if (resp?.type === "success") {
        setTouchLocations(resp?.payload);
        setLoading(false);
      }
      // day wise clicks
      setDayRevealLoading(true);
      const resp2 = await authCall("getDailyReveals", {
        touchType: "Night",
        startDate: moment().subtract(27, "days").format("YYYY-MM-DD"),
        endDate,
      });
      if (resp2?.type === "success") {
        setDayReveals(resp2?.payload);
        setDayRevealLoading(false);
      }

      // day of week reveal clicks
      const resp3 = await authCall("getDayOfWeekReveals", {
        touchType: "Night",
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate,
      });
      if (resp3?.type === "success") {
        setDayOfWeekReveals(resp3?.payload);
      }

      // per hours reveal clicks
      const resp4 = await authCall("getPerHoursReveals", {
        touchType: "Night",
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate,
      });
      if (resp4?.type === "success") {
        setHourReveals(resp4?.payload);
      }

      // week reveal clicks
      const resp5 = await authCall("getWeekReveals", {
        touchType: "Night",
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate,
      });
      if (resp5?.type === "success") {
        setWeekReveals(resp5?.payload);
      }
    }
    fetchData();
  }, []);

  const filterChart = (title, Chart, chartData, type) => {
    setTitle(title);
    setShow(true);
    setChartType(Chart);
    setChartData(chartData);
    setType(type);
    const stdate =
      type === "day"
        ? moment().subtract(6, "days").format("YYYY-MM-DD")
        : moment().subtract(30, "days").format("YYYY-MM-DD");
    setStartDT(stdate);
    console.log(stdate);
  };

  const handleChangeFiter = async (startDate, endDate, typ) => {
    if (typ === "day") {
      const resp = await authCall("getDailyReveals", {
        touchType: "Night",
        startDate,
        endDate,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else if (typ === "week") {
      const resp = await authCall("getDayOfWeekReveals", {
        touchType: "Night",
        startDate,
        endDate,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    } else {
      const resp = await authCall("getPerHoursReveals", {
        touchType: "Night",
        startDate,
        endDate,
      });
      if (resp?.type === "success") {
        setChartData(resp?.payload);
      }
    }
  };

  return (
    <>
      <div className="dashboard-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title lh-1">Dashboard:</div>
                <div className="details-wrapper pt-3">
                  <div className="sub-title lh-sm mb-3 mb-lg-4">
                    Below you will see how your listing is preforming:
                  </div>

                  <div className="row pb-3">
                    <div className="col-lg-5">
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">Number of Drink Codes Reveals</div>
                          {!dayReveals && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayReveals && (
                            <Line data={dayReveals} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Number of Drink Codes Reveals",
                                Line,
                                dayReveals,
                                "day"
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                          Total Reveals Days Of The Week
                          </div>
                          {!dayOfWeekReveals && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {dayOfWeekReveals && (
                            <Bar data={dayOfWeekReveals} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Total Reveals Days Of The Week",
                                Bar,
                                dayOfWeekReveals,
                                "week"
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div className="chart-wrapper mb-4">
                        <div className="chart-cover p-3">
                          <div className="fw-medium mb-1">
                          Total Reveals Per Hour
                          </div>
                          {!hourReveals && (
                            <div
                              style={{
                                height: "136px",
                                width: "273px",
                                padding: "50px",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                          )}
                          {hourReveals && (
                            <Bar data={hourReveals} options={options} />
                          )}
                        </div>
                        <div className="btn-wrapper text-center mt-3">
                          <button
                            type="button"
                            className="btn btn-custom"
                            onClick={() =>
                              filterChart(
                                "Total Reveals Per Hour",
                                Bar,
                                hourReveals,
                                "hour"
                              )
                            }
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-7 mb-4 mb-lg-0">
                      <div
                        className="table-responsive rounded-3"
                        style={{ border: "2px solid var(--theme-color1)" }}
                      >
                        <table
                          className="table theade-bg table-bordered m-0"
                          style={{ border: 0 }}
                        >
                          <thead>
                            <tr>
                              <th className="fs-md-18">Location</th>
                              <th className="fs-md-18 text-center">Drink Codes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {touchLocations.map((el, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="name fw-semibold">
                                    {el?.machine_details?.vanue_name}
                                  </div>
                                  <div className="fs-14">
                                    {el?.machine_details?.street_address},{" "}
                                    {el?.machine_details?.city},{" "}
                                    {el?.machine_details?.state}{" "}
                                    {el?.machine_details?.post_code}
                                  </div>
                                </td>
                                <td>
                                  <div className="fs-md-18 count fw-semibold text-center">
                                    {el.total * 10}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="btn-wrapper text-center mt-4">
                        <Link to={AppConstants.routes.admin.runAdd}>
                          <button type="button" className="btn btn-custom">
                            Run An Add
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive table-bordered rounded-3 m-0">
                    <table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th colSpan="2" className="fs-md-18 text-center">
                            Days 1<sup className="fw-medium">st</sup> - 7
                            <sup className="fw-medium">th</sup>
                          </th>
                          <th colSpan="2" className="fs-md-18 text-center">
                            Days 8<sup className="fw-medium">th</sup> - 15
                            <sup className="fw-medium">th</sup>
                          </th>
                          <th colSpan="2" className="fs-md-18 text-center">
                            Days 15<sup className="fw-medium">th</sup> - 23
                            <sup className="fw-medium">rd</sup>
                          </th>
                          <th colSpan="2" className="fs-md-18 text-center">
                            Days 24<sup className="fw-medium">th</sup> - 30
                            <sup className="fw-medium">th</sup>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {weekReveals.map((el, index) => (
                            <>
                              <td key={index}>Drink Codes</td>
                              <td>{el.total}</td>
                            </>
                          ))}
                        </tr>
                        <tr>
                          {weekReveals.map((el, index) => (
                            <>
                              <td key={index}>Amount</td>
                              <td>$ {(el.total * 0.1).toFixed(2)}</td>
                            </>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChartPopUp
        handleClose={handleClose}
        modalStatus={show}
        title={title}
        type={type}
        Chart={chartType}
        data={chartData}
        options={options}
        handleChangeFiter={handleChangeFiter}
        startDt={startDT}
        endDt={endDate}
      />
    </>
  );
}

export default NightLifeDashboard;
