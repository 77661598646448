import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import rectangle from "../../../../assets/images/site/Rectangle 14.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import CalendertIcon from "../../../../assets/images/site/CalenderIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import StopwatchIcon from "../../../../assets/images/site/StopwatchIcon.png";
import { authCall } from "../../../../services";
import { handleNumericInput } from "../../../util/utils";

import "./BookNow.scss";

const BookNow = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const initialField = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    demoDate: "",
    demoTime: "",
    meetingAddress: "",
  };
  const [values, setValues] = useState(initialField);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    const timePicker = document.getElementById("timePicker");
    if (timePicker) {
      timePicker.showPicker();
    } else {
      document.getElementById("timePcker").click();
    }
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phoneNumber: inputValue,
      });
    }
  };

  const handleBookRequest = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      const resp = await authCall("sendMailActivityDemoRequest", values);
      if (resp?.type === "success") {
        setLoading(false);
        toast.dismiss();
        toast.success("Your demo request sent successfully!");
        restForm();
      }
    } else {
      setLoading(false);
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const restForm = () => {
    setValues(initialField);
  };

  return (
    <div
      className="bookNow_wrapper mt-5 container"
      style={{ position: "relative" }}
    >
      <div className="gradient-line"></div>
      <img src={rectangle} alt="Book Now" className="centered-image" />
      <div className="overlay-text">STEP ONE: BOOK A DEMO</div>
      <div className="contact-form-wrapper">
        <form
          className="contact-form mb-5"
          style={{ margin: "0px 35px" }}
          onSubmit={handleBookRequest}
        >
          <div className="row mt-3">
            {/* first name */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="firstNameInput"
                    name="firstName"
                    value={values.firstName}
                    placeholder="First Name"
                    onChange={handleChangeValue}
                  />
                </div>
                {validator.current.message(
                  "first name",
                  values.firstName,
                  "required"
                )}
              </div>
            </div>

            {/* last name */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={NameIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={31}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="lastNameInput"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChangeValue}
                    placeholder="Last Name"
                  />
                </div>
                {validator.current.message(
                  "last name",
                  values.lastName,
                  "required"
                )}
              </div>
            </div>

            {/* Mobile number */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={ContactIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={24}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="phoneNumberInput"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChangePhone}
                    placeholder="Phone Number"
                  />
                </div>
                {validator.current.message(
                  "phone number",
                  values.phoneNumber,
                  "required"
                )}
              </div>
            </div>

            {/* Email */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={EmailtIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={20}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="email"
                    id="emailInput"
                    name="email"
                    value={values.email}
                    onChange={handleChangeValue}
                    placeholder="Email Address"
                  />
                </div>
                {validator.current.message(
                  "email",
                  values.email,
                  "required|email"
                )}
              </div>
            </div>

            {/* Date picker */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="restaurantform">
                <div className="input-with-icon">
                  <img
                    src={CalendertIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={22}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="date"
                    id="datePicker"
                    name="demoDate"
                    value={values.demoDate}
                    onChange={handleChangeValue}
                    onFocus={() =>
                      document.getElementById("datePicker").showPicker()
                    } // Open calendar when the field is focused
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {validator.current.message("date", values.demoDate, "required")}
              </div>
            </div>

            {/* Time picker */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon" onClick={handleClick}>
                  <img
                    src={StopwatchIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={26}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    placeholder="hh:mm"
                    value={values.demoTime}
                    readOnly
                    style={{ cursor: "pointer" }} // Change cursor to pointer
                  />
                  <input
                    type="time"
                    id="timePicker"
                    name="demoTime"
                    value={values.demoTime}
                    onChange={handleChangeValue}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }} // Hide the time input
                  />
                </div>
                {validator.current.message("time", values.demoTime, "required")}
              </div>
            </div>

            {/* Address */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <div className="form-group ms-3" id="booknowform">
                <div className="input-with-icon">
                  <img
                    src={LocationtIcon}
                    alt="Name Icon"
                    className="input-icon"
                    height={25}
                    width={20}
                    style={{ left: "3%" }}
                  />
                  <input
                    type="text"
                    id="locationInput1"
                    name="meetingAddress"
                    value={values.meetingAddress}
                    onChange={handleChangeValue}
                    placeholder="Preferred Meeting Address"
                  />
                </div>
                {validator.current.message(
                  "meeting address",
                  values.meetingAddress,
                  "required"
                )}
              </div>
            </div>

            {/* Submit */}
            <div
              className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
              style={{ fontFamily: "Poppins", color: "#BABABA" }}
              id="contacttWrapper"
            >
              <button
                type="submit"
                className="submit-btn ms-3"
                style={{
                  marginBottom: "15px",
                  textShadow: "0px 4px 4px 0px #000000",
                }}
                disabled={loading || false}
              >
                {loading ? "Loading..." : "BOOK NOW"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookNow;
