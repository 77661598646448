import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSidebar from "../../layouts/AdminSidebar";
import CostPerPersonPage from "../../common/CostPerPersonPage";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";

function UpdateCostPerson() {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [priceData, setpriceData] = useState([]);
  const [calType, setCalType] = useState("");
  // const [advPrice, setAdvPrice] = useState(0);

  useEffect(() => {
    if (!activityId) {
      navigate(AppConstants.routes.admin.activityEditListing);
    }

    async function fetchData() {
      const resp = await webServices.getTour(activityId);
      if (resp.type === "success") {
        const { price_options, booking_calendar } = resp?.payload;
        setpriceData(price_options);
        setCalType(booking_calendar);
        // setAdvPrice(advertised_price);
      }
    }
    fetchData();
  }, [activityId]);

  if (!activityId) {
    return null;
  }

  const handleSubmit = async (data, _) => {
    setLoading(true);
    const resp = await webServices.createTour({
      bookingId: activityId,
      priceOptions: data?.priceOptions,
      // advertisedPrice: advPrice,
    });
    if (resp.type === "success") {
      setLoading(false);
      toast.success(`Updated successfully`);
      navigate(AppConstants.routes.admin.activityEditListing);
    }
    setLoading(false);
  };

  return (
    <>
      <CostPerPersonPage
        SideBar={AdminSidebar}
        priceDataList={priceData}
        btnLabel="Update"
        handleSubmit={handleSubmit}
        loading={loading}
        calanderType={calType}
      />
    </>
  );
}

export default UpdateCostPerson;
