import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../layouts/AdminSidebar";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import { authCall } from "../../services";

function BudgetRunAnAds() {
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [timeOfDay, setTimeOfDay] = useState([]);
  const [duration, setDuration] = useState([]);
  const [goalValue, setGoalValue] = useState([]);
  const [checkedDayOfWeek, setCheckedDayOfWeek] = useState([]);
  const [checkedTimeOfDay, setCheckedTimeOfDay] = useState([]);
  const [checkedDurations, setCheckedDurations] = useState(0);
  const [checkedStyles, setCheckedStyles] = useState("");
  const [plan, setPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [desirePlan, setDesirePlan] = useState([]);
  const [loadingDesirePlan, setLoadingDesirePlan] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await webServices.getPlayerGoals();
      if (resp?.type === "success") {
        const { dayOfWeek, timeOfDay, duration, goalValue } = resp?.payload;
        setDayOfWeek(dayOfWeek);
        setTimeOfDay(timeOfDay);
        setDuration(duration);
        setGoalValue(goalValue);
        setLoading(false);
      }

      setLoadingDesirePlan(true);
      const resp2 = await webServices.getPlans({
        type: "adsSubscription",
      });
      if (resp2?.type === "success") {
        setLoadingDesirePlan(false);
        setDesirePlan(resp2?.payload);
      }
    }
    fetchData();
  }, []);

  // Day of Week
  const handleCheckDayOfWeek = (event, day) => {
    if (event.target.checked) {
      setCheckedDayOfWeek([...checkedDayOfWeek, day]);
    } else {
      setCheckedDayOfWeek(
        checkedDayOfWeek.filter((item) => item.id !== day.id)
      );
    }
  };
  const calculateAverageDayOfWeek = () => {
    if (checkedDayOfWeek.length === 0) return 0;
    const total = checkedDayOfWeek.reduce((sum, item) => sum + +item.values, 0);
    return (total / checkedDayOfWeek.length).toFixed(2);
  };

  // Time of Day
  const handleCheckTimeOfDay = (event, day) => {
    if (event.target.checked) {
      setCheckedTimeOfDay([...checkedTimeOfDay, day]);
    } else {
      setCheckedTimeOfDay(
        checkedTimeOfDay.filter((item) => item.id !== day.id)
      );
    }
  };
  const calculateAverageTimeOfDay = () => {
    if (checkedTimeOfDay.length === 0) return 0;
    const total = checkedTimeOfDay.reduce((sum, item) => sum + +item.values, 0);
    return (total / checkedTimeOfDay.length).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (
      checkedDayOfWeek.length > 0 &&
      checkedTimeOfDay.length > 0 &&
      checkedDurations > 0 &&
      checkedStyles !== "" &&
      plan !== ""
    ) {
      setSubmitLoading(true);
      const values = {
        userId: current?.id,
        licenceId: current?.licence_id || null,
        dayOfWeek: checkedDayOfWeek,
        timeOfDay: checkedTimeOfDay,
        duration: checkedDurations,
        addedStyle: checkedStyles,
        monthlyAmount: +plan,
        plan,
      };
      const resp = await authCall("runAnAdd", values);
      if (resp?.type === "success") {
        setSubmitLoading(false);
        // create amount catelog in stripe for subscriptions
        const updateValue = {
          name: "Budget",
          description: `${plan} for Budget`,
          amount: +plan,
          interval: "month",
          type: "subscription",
          numberOfHotes: "",
          onlineReservation: 0,
        };
        const resp1 = await authCall("createPlanWithStripe", updateValue);
        setSubmitLoading(true);
        if(resp1?.type === 'success'){
          setSubmitLoading(false);
        }
        else{
          setSubmitLoading(false);
        }

        navigate(AppConstants.routes.admin.selectScreen, {
          state: { adsDetails: resp?.payload },
        });
      } else {
        toast.success(resp?.errormessage);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please enter the budget amount.");
      setSubmitLoading(false);
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const isCheckedAll = () => {
    const checkedAll =
      +calculateAverageDayOfWeek() > 0 &&
      +calculateAverageTimeOfDay() > 0 &&
      +checkedDurations > 0 &&
      checkedStyles !== "";
    return checkedAll || false;
  };

  const totalAmount = (value) => {
    const total =
      +value /
      (+calculateAverageDayOfWeek() +
        +calculateAverageTimeOfDay() +
        +checkedDurations);
    return formatNumber(total.toFixed(0));
  };

  return (
    <>
      <div className="gain-max-exposer py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Gain Maximum Exposure</div>
                <div className="details-wrapper pb-lg-4">
                  <div className="sub-title mb-3">
                    Run an add accross our entire network:
                  </div>
                  <div className="info-cover mb-4">
                    <div className="cover-box mb-2">
                      <div className="main-heading2 mb-1">
                        Desired Day And Time:
                      </div>
                      <p className="m-0">
                        <i>
                          Please select from the options below (all that apply):
                        </i>
                      </p>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 me-2 p-0">
                        Day of Week :
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-2 column-gap-4">
                        {loading &&
                          Array(7)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <Skeleton count={1} width={25} height={25} />
                                <label className="label-title text-custom-primary m-0">
                                  <Skeleton count={1} width={35} />
                                </label>
                              </div>
                            ))}

                        {!loading &&
                          dayOfWeek.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2"
                            >
                              <input
                                type="checkbox"
                                name="dayOfWeek"
                                id={el.label}
                                onChange={(e) => handleCheckDayOfWeek(e, el)}
                              />
                              <label
                                className="label-title text-custom-primary m-0"
                                htmlFor={el.label}
                              >
                                {el.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 me-2 p-0">
                        Time of Day :
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-2 column-gap-4">
                        {loading &&
                          Array(4)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <Skeleton count={1} width={25} height={25} />
                                <label className="label-title text-custom-primary m-0">
                                  <Skeleton count={1} width={65} />
                                </label>
                              </div>
                            ))}

                        {!loading &&
                          timeOfDay.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2"
                            >
                              <input
                                type="checkbox"
                                name="timeOfDay"
                                id={el.label}
                                onChange={(e) => handleCheckTimeOfDay(e, el)}
                              />
                              <label
                                className="label-title text-custom-primary m-0"
                                htmlFor={el.label}
                              >
                                {el.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="info-cover mb-4">
                    <div className="cover-box mb-2">
                      <div className="main-heading2 mb-1">Add Details:</div>
                      <p className="m-0">
                        <i>Please select from the options below:</i>
                      </p>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 p-0">
                        Add Style:
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-3 column-gap-4">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="LAV"
                            onChange={() =>
                              setCheckedStyles("Live Action Video")
                            }
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="LAV"
                          >
                            Live Action Video
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="Animation"
                            onChange={() => setCheckedStyles("Animation")}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="Animation"
                          >
                            Animation
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="ImageSlideshow"
                            onChange={() => setCheckedStyles("Image Slideshow")}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="ImageSlideshow"
                          >
                            Image Slideshow
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="StaticImage"
                            onChange={() => setCheckedStyles("Static Image")}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="StaticImage"
                          >
                            Static Image
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 p-0">
                        Add Length :
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-3 column-gap-4">
                        {loading &&
                          Array(3)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <Skeleton
                                  count={1}
                                  width={25}
                                  height={25}
                                  style={{ borderRadius: "50%" }}
                                />
                                <label className="label-title text-custom-primary m-0">
                                  <Skeleton count={1} width={65} />
                                </label>
                              </div>
                            ))}

                        {!loading &&
                          duration.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2"
                            >
                              <input
                                type="radio"
                                className="custom-radio m-0 cursor-pointer"
                                name="duration"
                                value={el.label}
                                id={el.label}
                                onChange={() => setCheckedDurations(el.values)}
                              />
                              <label
                                className="label-title text-custom-primary m-0"
                                htmlFor={el.label}
                              >
                                {el.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="info-cover">
                    <div className="cover-box mb-2">
                      <div className="main-heading2 mb-1">Budget:</div>
                      <p className="m-0">
                        <i>Please enter the budget amount below:</i>
                      </p>
                    </div>
                    <div className="col-3 col-xl-3 m-0">
                      <input
                        type="text"
                        className="form-control"
                        id="budgetValue"
                        placeholder=""
                        autoComplete="off"
                        name="budgetValue"
                        value={plan}
                        onChange={(e) => setPlan(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-wrapper text-end mt-4 mb-2 pt-2">
                <button
                  type="submit"
                  className="btn btn-custom"
                  onClick={handleSubmit}
                  disabled={submitLoading || false}
                >
                  {submitLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BudgetRunAnAds;
