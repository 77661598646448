import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import { handleNumericInput } from "./util/utils";
// import { authCall } from "../../services";
// import AppConstants from "../../appConstants";

function ContactFormPop({ show, close }) {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    fullName: "",
    message: "",
    mobile: "",
    email: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        mobile: inputValue,
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={close}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <form onSubmit={handleSubmit} className="custom-field-wrapper">
            <div className="form-group">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                autoComplete="off"
                onChange={(e) =>
                  setValues({
                    ...values,
                    fullName: e.target.value,
                  })
                }
              />
              {validator.current.message(
                "full name",
                values.fullName,
                "required"
              )}
            </div>

            <div className="form-group">
              <label htmlFor="mobile">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="mobile"
                autoComplete="off"
                value={values.mobile}
                onChange={handleChangePhone}
              />
              {validator.current.message(
                "mobile number",
                values.mobile,
                "required"
              )}
            </div>
            <div className="form-group">
              <label htmlFor="userEmail">Email</label>
              <input
                type="email"
                className="form-control"
                id="userEmail"
                autoComplete="off"
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
              />
              {validator.current.message(
                "email",
                values.email,
                "required|email"
              )}
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                autoComplete="off"
                rows={3}
                onChange={(e) =>
                  setValues({ ...values, message: e.target.value })
                }
              />
              {validator.current.message("message", values.message, "required")}
            </div>

            <div className="btn-wrapper text-center mt-4 pt-2">
              <button
                type="submit"
                className="btn btn-custom"
                disabled={loading || false}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactFormPop;
