import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import NightlifeMenuDetailPage from "../../common/NightlifeMenuDetailPage";
import AdminSidebar from "../../layouts/AdminSidebar";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import AppConstants from "../../../appConstants";
import { authCall, fetchData } from "../../../services";

function UpdateNightlifeMenu() {
  const location = useLocation();
  const { nightlifeId } = location.state || {};
  const navigate = useNavigate();
  const companyDetails = useSelector((state) => state.companyDetails);
  const { current } = useSelector((state) => state.user);
  const [menuDetails, setMenuDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!nightlifeId) {
      navigate(AppConstants.routes.admin.nightlifeEditListing);
    }
    async function fetchRecords() {
      const resp = await fetchData(`getNightlifeMenu/${nightlifeId}`);
      if (resp?.type === "success") {
        setMenuDetails(resp?.payload);
      }
    }
    fetchRecords();
  }, [nightlifeId]);

  const handleSubmitForm = async (data) => {
    setLoading(true);
    data.nightlifeId = nightlifeId;
    const resp = await authCall("createNightlifeMenu", data);
    if (resp?.type === "success") {
      setLoading(false);
      toast.dismiss();
      toast.success("Updated successfully.");
      if (current?.user_type === "Admin") {
        navigate(AppConstants.routes.admin.nightLifeList);
      } else {
        navigate(AppConstants.routes.admin.nightlifeEditListing);
      }
    }
  };

  const buttons = [
    // {
    //   id: 1,
    //   label: "Add Anonter Menu",
    //   type: "another",
    // },
    {
      id: 3,
      label: "Update",
      type: "done",
    },
  ];

  return (
    <>
      <NightlifeMenuDetailPage
        onSubmit={handleSubmitForm}
        loading={loading}
        nightlifeId={nightlifeId}
        heading="Update Your Menu"
        Sidebar={AdminSidebar}
        buttons={buttons}
        details={menuDetails}
        companyDetails={companyDetails}
      />
    </>
  );
}

export default UpdateNightlifeMenu;
