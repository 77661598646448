import React, { useRef } from "react";
// import LandingHeader from "../../Landing/LandingHeader/LandingHeader";
import WhyChooseKiosk from "../WhyChooseKiosk/WhyChooseKiosk";
import Hotelbooking from "../Hotelbooking/Hotelbooking";
import NightClubBanner from "../../nightClubLand/NightClubBanner/NightClubBanner";
import Header from "../../homeLayout/Header";

const HotelLanding = () => {
  const targetRef = useRef(null);

  const handleScrollToSection = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header handleScrollToSection={handleScrollToSection} />
      {/* <LandingHeader /> */}
      <NightClubBanner handleScrollToSection={handleScrollToSection} />
      <WhyChooseKiosk targetRef={targetRef} />
      <Hotelbooking id="claimkiosk" />
    </>
  );
};

export default HotelLanding;
