import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/user.actions";
import AdminSidebar from "../layouts/AdminSidebar";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";

function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    const { newPassword, confirmPassword } = values;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (newPassword && newPassword !== confirmPassword) {
      toast.error("New password and confirm password should be the same.");
      return false;
    } else if (confirmPassword && !regex.test(confirmPassword)) {
      toast.error(
        "Password should be at least 8 characters long and include at least 1 uppercase letter, 1 lowercase letter, and 1 number."
      );
      return false;
    } else {
      setLoading(true);
      const resp = await webServices.updateDetails(current.id, values);
      if (resp.type === "success") {
        setLoading(false);
        dispatch(setUser(resp.payload));
        toast.success(resp.message);
        // form reset
        setValues({
          ...values,
          password: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        toast.error(resp.errormessage);
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    navigate(AppConstants.routes.admin.setting);
  };

  return (
    <>
      <div className="change-password py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Change Password</div>
                <div className="details-wrapper py-lg-4">
                  <div className="sub-title mb-3">
                    Please update your details below:
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="my-lg-4 pt-lg-3"
                    style={{ maxWidth: "700px" }}
                  >
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-lg-4">
                      <div className="col-xs-12 col-lg-3">
                        <label htmlFor="password" className="fs-md-18 mb-lg-3">
                          Current Password:
                        </label>
                      </div>
                      <div className="col-xs-12 col-lg-9">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          autoComplete="off"
                          required
                          value={values.password}
                          onChange={(e) =>
                            setValues({ ...values, password: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-lg-4">
                      <div className="col-xs-12 col-lg-3">
                        <label
                          htmlFor="newPassword"
                          className="fs-md-18 mb-lg-3"
                        >
                          New Password:
                        </label>
                      </div>
                      <div className="col-xs-12 col-lg-9">
                        <input
                          type="password"
                          className="form-control"
                          id="newPassword"
                          autoComplete="off"
                          required
                          value={values.newPassword}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              newPassword: e.target.value,
                            })
                          }
                        />
                        <div className="text-custom-primary fs-12 mt-1">
                          <i>
                            Minimum 8 characters long, 1 upper and 1 lowercase
                            letter, 1 number.
                          </i>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-lg-4">
                      <div className="col-xs-12 col-lg-3">
                        <label
                          htmlFor="confirmPassword"
                          className="fs-md-18 mb-lg-3"
                        >
                          Confirm Password:
                        </label>
                      </div>
                      <div className="col-xs-12 col-lg-9">
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          autoComplete="off"
                          required
                          value={values.confirmPassword}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              confirmPassword: e.target.value,
                            })
                          }
                        />
                        <div className="text-custom-primary fs-12 mt-1">
                          <i>
                            Minimum 8 characters long, 1 upper and 1 lowercase
                            letter, 1 number.
                          </i>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3" style={{ justifyContent: "end" }}>
                      <div className="col-xs-12 col-md-offset-3 col-md-9">
                        <div className="btn-wrapper d-flex flex-wrap justify-content-between gap-3 mb-2">
                          <button
                            type="reset"
                            className="btn btn-custom flex-fill"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-custom flex-fill"
                            disabled={loading || false}
                          >
                            {loading ? "Changing..." : "Change"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
