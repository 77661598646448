import React, { useState } from "react";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import RestaurantDetailPage from "../common/RestaurantDetailPage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

function RestaurantDetailsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);

  const buttons = [{ id: 1, label: "Submit", type: "breakFast" }];

  const handleSubmitForm = async (data, val) => {
    data.profileLavel =
      companyDetails?.progress < 60
        ? AppConstants.routes.restaurant.restaurantDetails
        : "";
    data.progress = companyDetails?.progress < 60 ? 60 : "";
    setLoading(true);
    const resp = await webServices.createRestaurant({
      ...data,
      restaurantId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      dispatch(setCompanyDetails(resp?.payload));
      navigate(AppConstants.routes.restaurant.restaurantMenu);
    }
  };

  return (
    <>
      <RestaurantDetailPage
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        heading="Please Enter Your Restaurant Details"
        Sidebar={RestaurantSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default RestaurantDetailsPage;
