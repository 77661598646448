import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { setCompanyDetails } from "../../../redux/actions/company.actions";
import ActivitySidebar from "../../layouts/TourSidebar";
import LocationDetailsPage from "../../common/LocationDetailsPage";
// import webServices from "../../../services/webServices";

function LocationDetails({ handleSubmit, loading, stepValue, setStep, current }) {
  // const dispatch = useDispatch();
  const companyDetails  = useSelector((state) => state.companyDetails);
  // const [companytype] = useState(companyDetails?.company_type || "");
  // const [variationLoader, setVariationLoader] = useState(false);

  // const handleClickAddVariations = async () => {
  //   setVariationLoader(true);
  //   const obj = {
  //     userId: companyDetails.user_id,
  //     variationParentId: companyDetails?.variation_parent_id
  //       ? companyDetails?.variation_parent_id
  //       : companyDetails.id,
  //     activityCategory: companyDetails.activity_category,
  //     bookingCalendar: companyDetails.booking_calendar,
  //     companyPhoneNumber: companyDetails.company_phone,
  //     companyType: companyDetails.company_type,
  //     duration: companyDetails.duration,
  //     durationHours: companyDetails.duration_hours,
  //     durationMinutes: companyDetails.duration_minutes,
  //     exclusion: companyDetails.highlight_excludes,
  //     highlight: companyDetails.highlight_includes,
  //     intensity: companyDetails.intensity,
  //     address: companyDetails.location,
  //     locationDescription: companyDetails.location_description,
  //     overview: companyDetails.overview,
  //     profileLavel: 3,
  //     whatToBring: companyDetails.what_to_bring,
  //     oldMenu: companyDetails.menu,
  //     oldProfileImage: companyDetails.profile_photo,
  //     oldBannerImages: companyDetails.banners,
  //   };

  //   const resp = await webServices.createTour(obj);
  //   if (resp?.type === "success") {
  //     setVariationLoader(false);
  //     dispatch(setCompanyDetails(resp?.payload));
  //     setStep(3);
  //   }
  // };

  // const handleClickDone = () => {
  //   setStep(1);
  // };

  // const addVariationsBtn = () => {
  //   return (
  //     <button
  //       type="submit"
  //       className="btn btn-custom"
  //       style={{ fontSize: 22 }}
  //       onClick={handleClickAddVariations}
  //       disabled={variationLoader || false}
  //     >
  //       {variationLoader ? "Loading..." : "Another Variation"}
  //     </button>
  //   );
  // };

  // const doneBtn = () => {
  //   return (
  //     <button
  //       type="submit"
  //       className="btn btn-custom"
  //       style={{ fontSize: 22 }}
  //       onClick={handleClickDone}
  //     >
  //       Another Activity
  //     </button>
  //   );
  // };

  return (
    <>
      <LocationDetailsPage
        SideBar={ActivitySidebar}
        details={companyDetails}
        setStep={setStep}
        btnLabel="Submit"
        handleSubmit={handleSubmit}
        loading={loading}
        stepValue={stepValue}
        current={current}
        // AddVariationsBtn={
        //   companytype === "MultipleVariations" ? addVariationsBtn : null
        // }
        // DoneBtn={doneBtn}
      />
    </>
  );
}

export default LocationDetails;
