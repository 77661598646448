import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AdminSidebar from "../../layouts/AdminSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";
import RestaurantCategoryComp from "../../common/RestaurantCategoryComp";

function UpdateRestaurantCategory() {
  const location = useLocation();
  const navigate = useNavigate();
  const { restaurantId } = location.state || {};
  const [companyDetails, setCompanyDetails] = useState(false);
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const buttons = [
    {
      id: 1,
      label: "Update",
    },
  ];

  useEffect(() => {
    if (!restaurantId) {
      navigate(AppConstants.routes.admin.restaurantEditListing);
    }

    async function fetchData() {
      const resp = await authCall("getCompanyDetails", {
        companyId: restaurantId,
        companyType: "Restaurant",
      });
      if (resp?.type === "success") {
        setCompanyDetails(resp?.payload);
      }
    }
    fetchData();
  }, []);

  const handleSubmitForm = async (data) => {
    setLoading(true);
    const resp = await webServices.createRestaurant({
      ...data,
      restaurantId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      toast.dismiss();
      toast.success("Updated successfully.");
      if (current?.user_type === "Admin") {
        navigate(AppConstants.routes.admin.restaurantList);
      } else {
        navigate(AppConstants.routes.admin.restaurantEditListing);
      }
    }
  };

  return (
    <>
      <RestaurantCategoryComp
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        heading="Update Category"
        Sidebar={AdminSidebar}
        buttons={buttons}
      />
    </>
  );
}

export default UpdateRestaurantCategory;
