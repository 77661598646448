import React from "react";
import Modal from "react-bootstrap/Modal";

function AdsSreensListPopUp({ handleClose, modalStatus, screenList, loading }) {
  return (
    <>
      <Modal
        show={modalStatus}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Selected Screens:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <div className="table-responsive common-info-card border-0">
            <table className="table table-borderless table-custom m-0">
              <thead>
                <tr>
                  <th scope="col">Id:</th>
                  <th scope="col">Venue:</th>
                  <th scope="col">Address:</th>
                  <th scope="col">City:</th>
                  <th scope="col">State:</th>
                  <th scope="col">Post Code:</th>
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  (screenList.length > 0 ? (
                    screenList.map((el, index) => (
                      <tr key={index}>
                        <td scope="row">#{el.id}</td>
                        <td>{el.vanue_name}</td>
                        <td>{el.street_address}</td>
                        <td>{el.city}</td>
                        <td>{el.state}</td>
                        <td>{el.post_code}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No record found
                      </td>
                    </tr>
                  ))}
                {loading && (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdsSreensListPopUp;
