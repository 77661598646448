import React from "react";
import { Link } from "react-router-dom";
import LandingImg from "./../../assets/images/landing.png";
import LogoLandingImg from "./../../assets/images/logo-landing-advertising.png";
import LogoAdvertisingImg from "./../../assets/images/logo-landing-advertising.png";
import LogoNightlifeImg from "./../../assets/images/logo-landing-nightlife.png";
import LogoBookingImg from "./../../assets/images/logo-landing-booking.png";
import LogoDiningImg from "./../../assets/images/logo-landing-dining.png";
import SheratonImg from "./../../assets/images/partners/sheraton.png";
import AccorhotelsImg from "./../../assets/images/partners/accorhotels.png";
import MarriotImg from "./../../assets/images/partners/marriot.png";
import QuoteImg from "./../../assets/images/quote.png";
import "./../../assets/css-landing/menumaker.css";
import LandingPageHeader from "../layouts/LandingPageHeader";

function AdvertiseLandingPage() {
  return (
    <>
     <LandingPageHeader />

      <section className="top-experiences profile landing">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-7">
              <div className="landing-image">
                <img
                  src={LandingImg}
                  className="img-fluid position-relative z-3"
                  alt="LandingImg"
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="landing-details">
                <img
                  src={LogoLandingImg}
                  className="img-fluid large"
                  alt="LogoLandingImg"
                />
                {/* <h1
                  style={{
                    color: "#FFF",
                    fontFamily: "Arial Black, Gadget, sansSerif",
                  }}
                >
                  Coming Soon
                </h1> */}
                <p>
                  {/* Touch-it is the fastest growing digital advertising platform
                  revolutionizing the way people book and access information.
                  Our 55-inch touch screen kiosks are specifically to answer;{" "} */}
                  Touch-it is the fastest growing digital advertising platform
                  revolutionizing the way people book and access information.
                  Our 55-inch interactive touch screen kiosks are specifically
                  to answer three main questions;
                </p>
                <ul style={{ fontSize: "22px", paddingLeft: "50px" }}>
                  <li style={{ listStyleType: "disc", color: "#fff" }}>
                    What To Do?
                  </li>
                  <li style={{ listStyleType: "disc", color: "#fff" }}>
                    Where To Eat?
                  </li>
                  <li style={{ listStyleType: "disc", color: "#fff" }}>
                    What’s On?{" "}
                  </li>
                </ul>

                <style jsx>
                  {`
                    ul li::marker {
                      color: #fdb539;
                    }
                  `}
                </style>
                <div className="row ps-md-3">
                  <div className="col-sm-6">
                    {" "}
                    <Link to="https://touch-itadvertising.com/">
                      <img
                        src={LogoAdvertisingImg}
                        className="img-fluid small"
                        alt="LogoAdvertisingImg"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    {" "}
                    <Link to="https://touch-itbooking.com/">
                      <img
                        src={LogoBookingImg}
                        className="img-fluid small"
                        alt="LogoBookingImg"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    {" "}
                    <Link to="https://touch-itdining.com">
                      <img
                        src={LogoDiningImg}
                        className="img-fluid small"
                        alt="LogoDiningImg"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    {" "}
                    <Link to="https://touch-itnightlife.com">
                      <img
                        src={LogoNightlifeImg}
                        className="img-fluid small"
                        alt="LogoNightlifeImg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quote" style={{ marginBottom: 100 }}>
        <div className="container" style={{ paddingRight: "60px" }}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img src={QuoteImg} className="img-fluid" alt="" />
              <p>
                <span>
                  “If you&apos;re trying to reach holiday makers, nothing comes
                  close to <br />
                  Touch-it for driving engagement."
                </span>
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2>Available at:</h2>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img
                src={AccorhotelsImg}
                className="img-fluid partners"
                alt="AccorhotelsImg"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img
                src={SheratonImg}
                className="img-fluid partners"
                alt="SheratonImg"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img
                src={MarriotImg}
                className="img-fluid partners"
                alt="MarriotImg"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdvertiseLandingPage;
