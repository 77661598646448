import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import webServices from "../../services/webServices";
import authService from "../../services/authServices";

function MachineConfiguration() {
  const [loading, setLoading] = useState(false);
  const [machine, setMachine] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getMachineDetails();
      if (resp?.type === "success") {
        setMachine(resp?.payload);
      }
      setSelectedOption(authService.getMachineConfig());
    }
    fetchData();
  }, []);

  const handleConfigure = async () => {
    toast.dismiss();
    if (selectedOption) {
      setLoading(true);
      toast.success("Configuration successfylly.");
      authService.setMachineConfig(selectedOption);
      setLoading(false);
    }
    else{
      toast.error("Please select vanue.");
    }
  };

  return (
    <>
      <div className="change-user-details py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-lg-2 mb-4"></div>
            <div className="col-sm-8 col-lg-8">
              <div className="common-info-card">
                <div className="heading-title">Machine Configuration:</div>
                <div className="details-wrapper py-lg-4">
                  <div className="fs-18">
                    Please configuration of your machine below:
                  </div>
                  <div className="my-lg-41 pt-md-2 pt-lg-3">
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="col-xs-12 col-md-6">
                        <label htmlFor="userName" className="mt-md-1 nb-1">
                          Select Vanue:
                        </label>
                        <Select
                          className="text-body"
                          value={selectedOption}
                          onChange={setSelectedOption}
                          options={machine}
                        />
                      </div>
                    </div>

                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="main-heading2 text-white1 mb-2 mt-2">
                        Machine Details:
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Kiosk ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={selectedOption?.kiosk_id}
                          disabled
                        />
                      </div>

                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Network ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.network_id}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Licence ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.licence_id}
                        />
                      </div>
                    </div>

                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="main-heading2 mb-2 mt-2">
                        Venue Details:
                      </div>
                      <div className="col-xs-6 col-md-6">
                        <label htmlFor="firstName">Vanue Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.vanue_name}
                        />
                      </div>

                      <div className="col-xs-6 col-md-6">
                        <label htmlFor="firstName">Street Address:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.street_address}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3 mt-md-3">
                        <label htmlFor="firstName">City:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.city}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3 mt-md-3">
                        <label htmlFor="firstName">State:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.state}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3 mt-md-3">
                        <label htmlFor="firstName">Post Code:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.post_code}
                        />
                      </div>
                    </div>

                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="main-heading2 mb-2 mt-2">
                        Contact Details:
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Contact Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.contact_name}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Contact Number:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.contact_number}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Contact Email:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.contact_email}
                        />
                      </div>

                      <div className="col-xs-6 col-md-3">
                        <label htmlFor="firstName">Account ID:</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={selectedOption?.stripe_account_id}
                        />
                      </div>
                    </div>

                    <div
                      className="row pt-3"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="col-xs-3 col-md-offset-3 col-md-3">
                        <div className="btn-wrapper d-flex flex-wrap justify-content-between gap-3 mb-2">
                          <button
                            type="submit"
                            className="btn btn-custom flex-fill"
                            disabled={loading || false}
                            onClick={handleConfigure}
                          >
                            {loading ? "Processing..." : "Configure"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MachineConfiguration;
