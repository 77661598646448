import React from "react";
import { useSelector } from "react-redux";
import ActivitySidebar from "../../layouts/TourSidebar";
import StopsPage from "../../common/StopsPage";

function Stops({ handleSubmit, loading, stepValue, setStep }) {
  const companyDetails = useSelector((state) => state.companyDetails);

  return (
    <>
      <StopsPage
        SideBar={ActivitySidebar}
        majorStops={companyDetails?.major_stops}
        setStep={setStep}
        btnLabel="Submit"
        handleSubmit={handleSubmit}
        loading={loading}
        stepValue={stepValue}
      />
    </>
  );
}

export default Stops;
