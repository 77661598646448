import React from "react";
import Modal from "react-bootstrap/Modal";
import RestaurantActionAdmin from "./RestaurantActionAdmin";

function CreateRestaurantPopup({ handleClose, modalStatus }) {
  return (
    <>
      <Modal
        show={modalStatus}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="lg"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Create Restaurant:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dashboard-page">
          <RestaurantActionAdmin handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateRestaurantPopup;
