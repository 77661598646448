import React from "react";
import AboutImg from "../../../../assets/landing/about.svg";
import Calendar from "../../../../assets/landing/icons/calendar.svg";
import Dining from "../../../../assets/landing/icons/dining.svg";
import Events from "../../../../assets/landing/icons/events.svg";
import NightEvents from "../../../../assets/landing/icons/nightEvents.svg";
import "./About.scss";

const About = () => {
  return (
    <div className="about_wrapper mt-5 container">
      <div
        className="heading"
        style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px" }}
        id="aboutheading"
      >
        ABOUT KIOSK
      </div>
      <div
        className="text-content text-center"
        style={{ color: "#DADADA", fontSize: "17px" }}
      >
        These, state-of-the-art, 55-inch touch screens, are set to revolutionise
        the way <br /> Gold Coast visitors will explore, book and pay for.
      </div>
      <div className="row mt-5 ">
        <div className="col-12 col-lg-6 text-center mt-4">
          <img src={AboutImg} className="w-100" height={513} width={513} />
        </div>
        <div
          className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-4"
          style={{ fontFamily: "Poppins" }}
          id="aboutWrapper"
        >
          <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
            <div className="icon-wrapper">
              <img src={Calendar} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3" style={{ fontSize: "18px" }}>
              Book and pay for local activities/adventures
            </div>
          </div>
          <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
            <div className="icon-wrapper">
              <img src={Dining} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3" style={{ fontSize: "18px" }}>
              Browse and reserve different dining options
            </div>
          </div>
          <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
            <div className="icon-wrapper">
              <img src={NightEvents} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3" style={{ fontSize: "18px" }}>
              Explore different nightlife event
            </div>
          </div>
          <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
            <div className="icon-wrapper">
              <img src={Events} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3" style={{ fontSize: "17px" }}>
              Discover local events{" "}
            </div>
          </div>
          <div className="text-wrapper ms-2" style={{ fontSize: "17px" }}>
            And ultimately see what our beautiful Gold Coast region has to
            offer. They are designed to empower the user to self-book, all
            within the convenience of their hotel lobby and other key locations
            on the Gold Coast
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
