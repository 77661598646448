import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/actions/user.actions";
import AdminSidebar from "../layouts/AdminSidebar";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";

function ChangeEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    newEmail: "",
    confirmEmail: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (values.newEmail !== values.confirmEmail) {
      toast.error("New email and confirm email should be same.");
      return false;
    }
    setLoading(true);
    const resp = await webServices.updateDetails(current.id, values);
    if (resp.type === "success") {
      setLoading(false);
      dispatch(setUser(resp.payload));
      toast.success(resp.message);
      setValues({ ...values, email: "", newEmail: "", confirmEmail: "" });
    } else {
      toast.error(resp.errormessage);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(AppConstants.routes.admin.setting);
  };

  return (
    <>
      <div className="change-email-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Change Email:</div>
                <div className="details-wrapper py-lg-4">
                  <div className="sub-title mb-3">
                    Please update your details below:
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="my-lg-4 pt-md-2 pt-lg-3"
                    style={{ maxWidth: "600px" }}
                  >
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="currentEmail"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          Current Email:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          id="currentEmail"
                          autoComplete="off"
                          required
                          value={values.email}
                          onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="newEmail"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          New Email:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          id="newEmail"
                          autoComplete="off"
                          required
                          value={values.newEmail}
                          onChange={(e) =>
                            setValues({ ...values, newEmail: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-lg-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="reEnterEmail"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          Re-Enter Email:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="email"
                          className="form-control"
                          id="reEnterEmail"
                          autoComplete="off"
                          required
                          value={values.confirmEmail}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              confirmEmail: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row pt-3" style={{ justifyContent: "end" }}>
                      <div className="col-xs-12 col-md-offset-3 col-md-9">
                        <div className="btn-wrapper d-flex flex-wrap justify-content-between gap-3 mb-2">
                          <button
                            type="reset"
                            className="btn btn-custom flex-fill"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-custom flex-fill"
                            disabled={loading || false}
                          >
                            {loading ? "Changing..." : "Change"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeEmail;
