import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import { setCompanyDetails } from "../redux/reducers/companyDetailsSlice";
import { setUser } from "../redux/actions/user.actions";
import webServices from "../services/webServices";
import AppConstants from "../appConstants";
import VideoTutorialPopUp from "./admin/VideoTutorialPopUp";

const ActivityCompanyTypeVideo = AppConstants.tutorial.activityCompanyType5;

function CompanyType({ navigate }) {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [videoURL, setVideoURL] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedOption === "") {
      toast.error("Please select an option.");
    } else {
      setLoading(true);

      let redirectPath = "";
      let data = {};
      // Restaurant
      if (selectedOption === "restaurant") {
        const resp = await webServices.createRestaurant({
          userId: current?.id,
          profileLavel: AppConstants.routes.restaurant.desiredServices,
        });
        if (resp?.type === "success") {
          const resp2 = await webServices.updateDetails(current?.id, {
            companyType: "Restaurant",
            userType: "Restaurant",
            companyId: resp?.payload?.id,
          });
          data = resp2?.payload;
          dispatch(setCompanyDetails(resp?.payload));
          redirectPath = AppConstants.routes.restaurant.desiredServices;
        }
      }
      // Night Life
      else if (selectedOption === "NightLife") {
        const resp = await webServices.createNightLife({
          userId: current?.id,
          profileLavel: AppConstants.routes.nightLife.category,
        });
        if (resp?.type === "success") {
          const resp2 = await webServices.updateDetails(current?.id, {
            companyType: "NightLife",
            userType: "Bar",
            companyId: resp?.payload?.id,
          });
          data = resp2?.payload;
          dispatch(setCompanyDetails(resp?.payload));
          redirectPath = AppConstants.routes.nightLife.category;
        }
      }
      // Activity
      else {
        const resp = await webServices.createTour({
          userId: current?.id,
          companyType: selectedOption,
          profileLavel: 2,
        });
        if (resp?.type === "success") {
          const resp2 = await webServices.updateDetails(current?.id, {
            companyType: "Activity",
            userType: "Activity",
            companyId: resp?.payload?.id,
          });
          if (resp2?.type === "success") {
            data = resp2?.payload;
            redirectPath = AppConstants.routes.tour.createCompany;
          }
        }
      }
      setLoading(false);
      dispatch(setUser(data));
      navigate(redirectPath);
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(ActivityCompanyTypeVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="company-type-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>What type of company do you run?</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper">
                  <div className="sub-title">
                    This help us tailer your profile for you
                  </div>
                  <form>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        id="compType1"
                        value="OneOffActivity"
                        checked={selectedOption === "OneOffActivity"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType1"
                      >
                        One Off Activity
                      </label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType2"
                        value="MultipleVariations"
                        checked={selectedOption === "MultipleVariations"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType2"
                      >
                        Activity With Multiple Variations{" "}
                        <i>(For example Escape Room)</i>
                      </label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType3"
                        value="Tour"
                        checked={selectedOption === "Tour"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType3"
                      >
                        Tour{" "}
                        <i>
                          (An event where you travel from Point A to Point B)
                        </i>
                      </label>
                    </div>
                    {/* <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType4"
                        value="TicketOptions"
                        checked={selectedOption === "TicketOptions"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType4"
                      >
                        Activity With Different Ticket Options{" "}
                        <i>(For example a theme park)</i>
                      </label>
                    </div> */}
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType5"
                        value="restaurant"
                        checked={selectedOption === "restaurant"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType5"
                      >
                        Restaurant
                      </label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType6"
                        value="NightLife"
                        checked={selectedOption === "NightLife"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType6"
                      >
                        Bar Or Other Nightlife Venue
                      </label>
                    </div>
                    <div className="btn-wrapper text-center pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmit}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default CompanyType;
