import { createSlice } from "@reduxjs/toolkit";

// Initial state for company details
const initialState = {
  companyDetails: {},
};

// Create the slice
const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {
    // Action to set company details
    setCompanyDetails(state, action) {
      return { ...state, ...action.payload };
    },
    // Action to clear company details (e.g., on logout)
    clearCompanyDetails() {
      return initialState;
    },
  },
});

// Export the actions to use in components
export const { setCompanyDetails, clearCompanyDetails } =
  companyDetailsSlice.actions;

// Export the reducer to use in the Redux store
export default companyDetailsSlice.reducer;
