import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ActivityPayment from "../../Stripe/ActivityPayment";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";
import { authCall } from "../../services";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function BookingCheckout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const { details } = location.state || {};
  const [perHotel, setPerHotel] = useState(0);
  const [maxPerMonth, setMaxPerMonth] = useState(0);
  const [onlineReservationPrice, setOnlineReservationPrice] = useState(0);

  const [totalSubscribeAmount, setTotalSubscribeAmount] = useState(0);
  const [hotelPlan, setHotelPlan] = useState(0);
  const [payment] = useState(1);
  const [totalAdvPrice, setTotalAdvPrice] = useState(0);

  useEffect(() => {
    if (!details) {
      navigate(AppConstants.routes.tour);
    }
    const totalAdvPrice = details?.priceOptions.reduce(
      (sum, item) => sum + +item.advertisePrice,
      0
    );
    const totalPrice =
      details?.bookingDetails?.booking_calendar === "FareHarbor"
        ? details?.totalAmount / 100
        : details?.totalAmount;
    const advPrice =
      totalAdvPrice > 0 ? Math.abs(totalAdvPrice - totalPrice) : 0;
    setTotalAdvPrice(advPrice);
  }, [details]);

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getPlans({
        type: "restaurant",
      });
      if (resp?.type === "success") {
        setHotelPlan(resp?.payload?.["Gold Coast"].amount || 0);
        setPerHotel(resp?.payload?.["Per Hotel"].amount || 0);
        setMaxPerMonth(resp?.payload?.["Max Hotel Price"].amount || 0);
        setOnlineReservationPrice(
          resp?.payload?.["Online Reservation"].amount || 0
        );
      }
    }
    // fetchData();
  }, []);

  const handleSubmitSubmit = async () => {
    navigate(AppConstants.routes.admin.dashboard);
  };

  return (
    <>
      {console.log("booking details", details)}
      <div className="company-payment-page py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="common-info-card">
                <div className="heading-title">Checkout</div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="details-wrapper">
                      <div
                        style={{
                          color: "#00ffe7",
                          fontSize: 25,
                          fontWeight: 500,
                          marginBottom: 25,
                        }}
                      >
                        Booking Details
                      </div>
                      {totalAdvPrice > 0 && (
                        <div className="price-header d-flex justify-content-between gap-2 border-bottom border-light pb-2 mb-3">
                          <div className="plan">Total Saving:</div>
                          <div className="price">
                            ${totalAdvPrice.toFixed(2)}
                          </div>
                        </div>
                      )}
                      <div className="item-wrapper border-bottom border-light pb-2 mb-2">
                        {details?.priceOptions.map((el) => {
                          return (
                            el?.quantity > 0 && (
                              <div className="d-flex justify-content-between gap-2 mb-1">
                                <div className="plan">
                                  {el?.quantity} x{" "}
                                  {el?.touchIdLabel
                                    ? el?.touchIdLabel
                                    : el?.label}
                                </div>
                                <div className="price">
                                  ${(el?.price * el?.quantity).toFixed(2)}
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>

                      <div className="price-footer d-flex justify-content-between gap-2">
                        <div className="plan fs-18">Total:</div>
                        <div className="price fs-18">
                          $
                          {details?.bookingDetails?.booking_calendar ===
                          "FareHarbor"
                            ? (details?.totalAmount / 100).toFixed(2)
                            : details?.totalAmount.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 details-wrapper">
                    <ActivityPayment
                      payment={
                        details?.bookingDetails?.booking_calendar ===
                        "FareHarbor"
                          ? (details?.totalAmount / 100).toFixed(2)
                          : details?.totalAmount.toFixed(2)
                      }
                      activityDetails={details}
                      // userDetails={current}
                      // parentCallBack={handleCallBack}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingCheckout;
