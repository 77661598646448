import React from "react";
import EarlyRegisterImg from "../../../../assets/images/site/EarlyRegisterImg.png";
import Handshake from "../../../../assets/images/icons/handahake.svg";
import "./EarlyRegistration.scss";

const EarlyRegistration = () => {
  return (
    <div className="earlyresigter_wrapper mt-5 container">
      <div className="heading mt-3" id="earlyregisterheading" style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px" }}>EARLY REGISTRATION IS KEY!!!</div>
      <div className="row align-items-cente mt-4" style={{ height: "100%" }}>
        <div
          className="col-12 col-lg-6 d-flex flex-column align-items-start"
          style={{ fontFamily: "Poppins" }}
        >
          <div className="text-content" style={{marginTop:"-10px"}}>
            To celebrate our collaboration and encourage early participation,
            we’re thrilled to offer everyone who signs up before <span style={{color:"#EEAD20"}}>December 1</span> a
            complimentary <span style={{color:"#EEAD20"}}>“One-month, 30-second ad spot in our ad roll”,</span> which
            plays when the kiosks are not in use. Don’t miss out on this great
            opportunity!
          </div>

          <div
            className="d-flex align-items-center py-3 px-4 feature-wrapper mt-3 mb-3"
            style={{ backgroundColor: "#1C1C1C" }}
          >
            <div className="icon-wrapper">
              <img src={Handshake} height={60} width={64} />
            </div>
            <div
              className="text-wrapper ms-3"
              style={{ fontSize: "24px", color: "#EEAD20" }}
            >
              RECEIVE ONE MONTH FREE ADVERTISING. JUST BY JOINING TODAY
            </div>
          </div>

          <div className="text-content">
            Imagine having your company showcased on full 55-inch screens
            throughout hotels, shopping centres, and other key locations across
            the Gold Coast, simply by taking action today.
          </div>
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-4" id="earlyregister">
          <img
            src={EarlyRegisterImg}
            className="w-100"
            height={700}
            width={482}
            alt="EarlyRegisterImg"
          />
        </div>
      </div>
    </div>
  );
};

export default EarlyRegistration;
