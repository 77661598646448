import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-bootstrap/Modal";
import MultiVideoUploads from "../../util/MultiVideoUploads";
import { authCall } from "../../../services";

function UploadFinalContentPopUp({
  handleClose,
  modalStatus,
  selectedAd,
  handleClickCancel,
}) {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [addedContents, setAddedContents] = useState("");

  const getAdsVideos = (files) => {
    setAddedContents(files);
  };

  const handleSubmit = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      const resp = await authCall("uploadAdsContents", {
        adsId: selectedAd?.id,
        adFinalContent: addedContents,
        status: "1",
      });
      if (resp?.type === "success") {
        setLoading(false);
        toast.success(resp?.message);
        handleClickCancel(selectedAd?.id);
      } else {
        toast.error(resp?.errormessage);
        setLoading(false);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <Modal
        show={modalStatus}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Upload Final Content</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper p-3">
          <div className="img-wrapper" style={{ minHeight: 200 }}>
            <div className="info-cover mt-2">
              <div className="row">
                <div className="col-lg-3 mb-4 mb-lg-0"></div>
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <div className="info-cover">
                    <div className="main-heading2 mb-1">Upload Content:</div>
                    <div className="profile-cover mb-3">
                      <p className="fs-12">
                        <i>
                          (Please upload ads final content. After upload final
                          content ad will active)
                        </i>
                      </p>
                      <MultiVideoUploads
                        videos={getAdsVideos}
                        addedVideos={addedContents}
                        hideAddAnother={true}
                      />
                      {validator.current.message(
                        "upload content",
                        addedContents,
                        "required"
                      )}
                    </div>
                  </div>

                  <div className="btn-wrapper pt-2">
                    <button
                      type="submit"
                      className="btn btn-custom"
                      onClick={handleSubmit}
                      disabled={loading || false}
                    >
                      {loading ? "Uploading..." : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UploadFinalContentPopUp;
