import React, { useState } from "react";
import MenuDetailPage from "../common/MenuDetailPage";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

function RestaurantMenuPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (data, val, act) => {
    setLoading(true);
    data.menuType = val;
    data.userId = current?.id;
    const resp = await webServices.createMenu(data);
    if (resp?.type === "success") {
      setLoading(false);
      // update restaurant
      const restoData = {
        profileLavel:
          companyDetails?.progress < 80
            ? AppConstants.routes.restaurant.restaurantMenu
            : "",
        progress: companyDetails?.progress < 80 ? 80 : "",
      };
      const restoResp = await webServices.createRestaurant({
        ...restoData,
        restaurantId: companyDetails?.id,
      });
      // end update restaurant

      dispatch(setCompanyDetails(restoResp?.payload));
      if (act?.type === "done") {
        navigate(AppConstants.routes.restaurant.newRestaurantOption);
      }
    }
  };

  const buttons = [
    {
      id: 1,
      label: "Add Anonter Menu",
      type: "another",
    },
    {
      id: 3,
      label: "Done",
      type: "done",
    },
  ];

  return (
    <>
      <MenuDetailPage
        onSubmit={handleSubmitForm}
        loading={loading}
        restaurantId={companyDetails?.id}
        heading="Please Create Your Menu"
        Sidebar={RestaurantSidebar}
        buttons={buttons}
        details={companyDetails}
        userId={current?.id}
      />
    </>
  );
}

export default RestaurantMenuPage;
