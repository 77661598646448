// UserLayout.js
import React, { useEffect, useState } from "react";
import HeaderUser from "./HeaderUser";
import FooterUser from "./FooterUser";
import { Navigate } from "react-router-dom";
import AppConstants from "../../../appConstants";
import PageLoader from "../../util/PageLoader";
import authService from "../../../services/authServices";

function UserLayout({ children, navigate }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    setIsAuthenticated(authService.isLoggedIn());
  }, []);

  if (isAuthenticated === null) {
    return <PageLoader />;
  }

  if (isAuthenticated === false) {
    return <Navigate to={AppConstants.routes.login} />;
  }

  return (
    <div>
      <HeaderUser navigate={navigate} />
      <div className="main-content">{children}</div>
      <FooterUser />
    </div>
  );
}

export default UserLayout;
