import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Button } from "react-bootstrap";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";

function EventsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const isArchivedList =
    AppConstants.routes.admin.archivedEvents.includes(pathname);
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState("");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getEvents", {
        eventType: isArchivedList ? "archieved" : "live",
      });
      if (resp?.type === "success") {
        setEventList(resp?.payload);
        setLoading(false);
      }
    }
    fetchData();
  }, [isArchivedList]);

  const handleChangeStatus = async (id, status) => {
    setLoadingStatus(`loading${id}`);
    const resp = await authCall("createEvents", { eventId: id, status });
    if (resp?.type === "success") {
      const updatedItems = eventList.filter((item) => item.id !== id);
      setEventList(updatedItems);
      setLoadingStatus("");
    }
  };

  const handleEdit = (details) => {
    navigate(AppConstants.routes.admin.updateEvents, {
      state: { details, actionType: isArchivedList ? "archieved" : "live" },
    });
  };

  const handleDelete = (el) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(el)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (el) => {
    const resp = await authCall("deleteEvent", {
      eventId: el.id,
      isSoft: false
    });
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = eventList.filter((item) => item.id !== el.id);
      setEventList(updatedItems);
      toast.success("Deleted successfully.");
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  {isArchivedList ? "Archived" : "Live"} Events
                </div>
                <div className="details-wrapper py-lg-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          <th scope="col">Image:</th>
                          <th scope="col">Title:</th>
                          <th scope="col">Start Date & Time:</th>
                          <th scope="col">End Date & Time:</th>
                          <th scope="col">Price:</th>
                          <th scope="col">Address:</th>
                          <th scope="col">Main Event:</th>
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (eventList.length > 0 ? (
                            eventList.map((el, index) => (
                              <tr key={index}>
                                <td>#{el.id}</td>
                                <td>
                                  <img
                                    src={el?.is_atdw === '1' ? el?.atdw_event_photo : el?.event_photo}
                                    alt="el.event_photo"
                                    style={{ width: 80, borderRadius: 5 }}
                                  />
                                </td>
                                <td>{el.title}</td>
                                <td>
                                  <span>
                                    {moment(el.event_start_time).format(
                                      "MMM D, YYYY"
                                    )}
                                  </span>
                                  ,
                                  <br />
                                  <span className="fs-14">
                                    {moment(el.event_start_time).format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {moment(el.event_end_time).format(
                                      "MMM D, YYYY"
                                    )}
                                  </span>
                                  ,
                                  <br />
                                  <span className="fs-14">
                                    {moment(el.event_end_time).format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {el.event_price ? el.event_price : "Free"}
                                </td>
                                <td>
                                  {el.street_address}, <br />
                                  {el.city}, {el.state}
                                  <br /> {el.post_code}
                                </td>
                                <td>{el.is_main_event === "1" ? "Yes" : "No"}</td>
                                <td>
                                  <div className="icon-container">
                                    <Badge
                                      bg={isArchivedList ? "success" : "danger"}
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleChangeStatus(
                                          el.id,
                                          isArchivedList ? "1" : "2"
                                        )
                                      }
                                      title={
                                        isArchivedList
                                          ? "Click to Remove Archive"
                                          : "Click to Archive"
                                      }
                                    >
                                      {loadingStatus === `loading${el.id}`
                                        ? "Loading..."
                                        : isArchivedList
                                        ? "Make Live"
                                        : "Archive"}
                                    </Badge>

                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventsList;
