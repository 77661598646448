import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { authCall } from "../../services";
import AvailabilityCalendarFareHarbor from "./AvailabilityCalendarFareHarbor";
import AppConstants from "../../appConstants";

function BookingDetails() {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const { orderId, shortName } = useParams();
  const [details, setDetails] = useState({});
  const [priceDetails, setPriceDetails] = useState([]);
  const [available, setAvailable] = useState([]);
  const [selectedDT, setSelectedDT] = useState(null);
  const [notAvailable, setNotAvailable] = useState(false);
  const [searchAvail, setSearchAvail] = useState(false);
  const [isRebook, setIsRebook] = useState(false);
  const [rebookStatus, setRebookStatus] = useState(false);
  const [values, setValues] = useState({
    fullName: details?.contact?.name || "",
    email: details?.contact?.email || "",
    contactNumber: details?.contact?.phone || "",
  });

  useEffect(() => {
    async function fetchData() {
      const resp = await authCall("getBookingDetails", {
        bookingId: orderId,
        shortName: shortName,
      });
      if (resp?.type === "success") {
        const customerDetails = resp?.payload;
        setDetails(customerDetails);
        setPriceDetails(customerDetails?.customers);
        setValues({
          fullName: customerDetails?.contact?.name || "",
          email: customerDetails?.contact?.email || "",
          contactNumber: customerDetails?.contact?.phone || "",
        });
      }
    }
    fetchData();
  }, [orderId, shortName]);

  const selectedDateFareharbor = async (date) => {
    if (!date) {
      toast.dismiss();
      toast.error("Booking date not selected.");
      setSelectedDT(null);
    } else {
      setSearchAvail(true);
      setSelectedDT(date);
      const sarachData = {
        shortName: details?.company?.shortname,
        date: date,
        productCode: details?.availability?.item?.pk,
        capacity: details?.customer_count,
      };
      const resp = await authCall(
        "fareharborSearchAvailabilityByDate",
        sarachData
      );
      if (resp.type === "success") {
        setAvailable(resp?.payload);
        setSearchAvail(false);
        if (resp?.payload?.length === 0) {
          setNotAvailable(true);
        }
      }
    }
  };

  const handleClickBookingTime = (startTime, endTime, item) => {
    // setStartTimeLocal(startTime);
    // setEndTimeLocal(endTime);
    // setBookingDate(item);
    console.log(startTime, endTime, item);
  };

  // const handleClickRebook = async () => {
  //   toast.dismiss();
  //   if (totalPeople === 0) {
  //     toast.error("Please enter number of people.");
  //   } else if (!selectedDT) {
  //     toast.error("Please select booking date.");
  //   } else if (!startTimeLocal && !endTimeLocal) {
  //     toast.error("Please select booking time slot.");
  //   } else {
  //     setRebookStatus(true);
  //     const bookingData = {
  //       shortName: "",
  //       availabilityPk: "",
  //       body: {
  //         rebooking: "0bb2b486-c95e-4ea0-9564-ae2c8755a5c2",
  //         contact: {
  //           email: values.fullName,
  //           name: values.email,
  //           phone: values.contactNumber,
  //         },
  //         customers: [
  //           {
  //             customer_type_rate: 2525802008,
  //           },
  //           {
  //             customer_type_rate: 2525802008,
  //           },
  //           {
  //             customer_type_rate: 2525802008,
  //           },
  //         ],
  //       },
  //     };
  //     const resp = await authCall("fareharborRebooking", sarachData);
  //     if (resp.type === "success") {
  //       navigate(AppConstants.routes.tour.bookingSuccess, {
  //         state: {
  //           details: resp?.payload?.booking,
  //         },
  //       });

  //       setRebookStatus(false);
  //     }
  //   }
  // };

  return (
    <>
      <div className="company-payment-page py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="common-info-card">
                <div className="heading-title">Booking Details</div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="details-wrapper">
                      <div
                        style={{
                          color: "#00ffe7",
                          fontSize: 25,
                          fontWeight: 500,
                          marginBottom: 25,
                        }}
                      >
                        Booking Details
                      </div>
                      <div className="item-wrapper border-bottom border-light pb-2 mb-2">
                        {priceDetails.map((el, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex justify-content-between gap-2 mb-1"
                            >
                              <div className="plan">
                                {
                                  el?.customer_type_rate?.customer_prototype
                                    ?.display_name
                                }
                              </div>
                              <div className="price">
                                $
                                {(
                                  el?.customer_type_rate?.customer_prototype
                                    ?.total_including_tax / 100
                                ).toFixed(2)}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="price-footer d-flex justify-content-between gap-2">
                        <div className="plan fs-18">Total:</div>
                        <div className="price fs-18">
                          ${details?.amount_paid_display}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isRebook ? (
                    <div className="col-lg-6">
                      <div className="details-wrapper">
                        <div
                          style={{
                            color: "#00ffe7",
                            fontSize: 25,
                            fontWeight: 500,
                            marginBottom: 25,
                          }}
                        >
                          Customer Details
                        </div>
                        <div className="item-wrapper pb-2 mb-2">
                          <div className="d-flex justify-content-between gap-2 mb-1">
                            <div className="plan fs-18">
                              <strong>Name:</strong> {details?.contact?.name}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between gap-2 mb-1">
                            <div className="plan fs-18">
                              <strong>Email Id:</strong>{" "}
                              {details?.contact?.email}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between gap-2 mb-1">
                            <div className="plan fs-18">
                              <strong>Contact Number:</strong>{" "}
                              {details?.contact?.phone}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6">
                      <div className="row mb-3 item-wrapper">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <label htmlFor="stripeDeviceId" className="fs-md-18">
                            Full Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            autoComplete="off"
                            value={values.fullName}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                fullName: e.target.value,
                              })
                            }
                          />
                          {validator.current.message(
                            "full name",
                            values.fullName,
                            "required"
                          )}
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <label htmlFor="email" className="fs-md-18">
                            Email ID:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            autoComplete="off"
                            value={values.email}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                email: e.target.value,
                              })
                            }
                          />
                          {validator.current.message(
                            "email id",
                            values.email,
                            "required"
                          )}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <label htmlFor="contactNumber" className="fs-md-18">
                            Contact Number:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="contactNumber"
                            autoComplete="off"
                            value={values.contactNumber}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                contactNumber: e.target.value,
                              })
                            }
                          />
                          {validator.current.message(
                            "contactNumber id",
                            values.contactNumber,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="details-wrapper">
                        <AvailabilityCalendarFareHarbor
                          selectedDate={selectedDateFareharbor}
                          totalPeople={details?.customer_count}
                          activityCode={details?.company?.shortname}
                          fareharborPKId={details?.availability?.item?.pk}
                        />

                        {searchAvail ? (
                          <div className="fw-medium d-flex align-items-center justify-content-center gap-2 text-danger">
                            Searching...{" "}
                            <Spinner animation="border" variant="warning" />
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          className="check-wrapper d-flex flex-wrap gap-2 overflow-auto mb-3 mt-2"
                          style={{ maxHeight: "100px" }}
                        >
                          {available.length > 0 &&
                            available.map((el, index) => {
                              const startTime = moment
                                .utc(el?.start_at)
                                .format("HH:mm");
                              const endTime = moment
                                .utc(el?.end_at)
                                .format("HH:mm");
                              return (
                                <div
                                  key={index}
                                  className="d-flex align-items-center gap-1"
                                >
                                  <input
                                    type="radio"
                                    name="timeslot"
                                    className="custom-radio m-0 cursor-pointer"
                                    onChange={() =>
                                      handleClickBookingTime(
                                        el?.startTimeLocal
                                          ? el?.startTimeLocal
                                          : el?.start_at,
                                        el?.endTimeLocal
                                          ? el?.endTimeLocal
                                          : el?.end_at,
                                        el
                                      )
                                    }
                                  />
                                  <label
                                    className="bg-danger rounded-2"
                                    htmlFor="Morning"
                                  >
                                    {startTime === "00:00"
                                      ? "No fixed time. Available all day"
                                      : `${startTime}-${endTime}`}
                                  </label>
                                </div>
                              );
                            })}
                          {notAvailable && (
                            <div className="search-status">
                              <div className="fs-6 fw-medium text-danger lh-sm">
                                Unfortunately, there are no available trips on
                                the selected day OR the group is too big for the
                                available spots. Please select another day OR
                                Please break your group into smaller groups.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-1 mb-5">
                    {!isRebook ? (
                      <>
                        <button
                          onClick={() => setIsRebook(true)}
                          className="btn btn-custom"
                        >
                          Rebook
                        </button>
                        <button
                          // onClick={() => handleSubmit(button)}
                          className="btn btn-custom-red"
                          // disabled={loading}
                        >
                          {/* {loading ? "Loading..." : button.label} */}
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        // onClick={handleClickRebook}
                        className="btn btn-custom"
                        disabled={rebookStatus}
                      >
                        {rebookStatus ? "Loading..." : "Rebook Now"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingDetails;
