import React from 'react';
import './PageLoader.css'; // Add styles for the loader

const PageLoader = () => {
  return (
    <div className="loader">
      Loading...
    </div>
  );
};

export default PageLoader;
