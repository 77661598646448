import React from "react";
import RezdyIntegration from "../pages/activities/RezdyIntegration";
import { useNavigate } from "react-router-dom";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

function TourRezdyIntegrationAction() {
  const navigate = useNavigate();
  
  const handleClick = async (items) => {
    const req = {
      activityName: items?.name,
      overview: items?.description,
      companyName: items?.supplierName
    }
    const resp = await webServices.createTour(req);
    if (resp.type === "success") {
      navigate(AppConstants.routes.tour.tourCostPersion, { state: { items } });
    }
  };

  

  return (
    <>
      <RezdyIntegration
        handleClick={handleClick}
        handleClickProductCode={handleClick}
      />
    </>
  );
}

export default TourRezdyIntegrationAction;
