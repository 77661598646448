import React from "react";
import { HouseFill } from "react-bootstrap-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AppConstants from "../../appConstants";

const LandingPageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <header className="landing-page">
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div id="cssmenu">
                <ul>
                  <li>
                    <Link to={AppConstants.routes.landing}>
                      <HouseFill
                        style={{
                          color: [AppConstants.routes.landing].includes(
                            pathname
                          )
                            ? "var(--theme-color3)"
                            : "",
                        }}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={AppConstants.routes.advertising}
                      style={{
                        color: [AppConstants.routes.advertising].includes(
                          pathname
                        )
                          ? "var(--theme-color3)"
                          : "",
                      }}
                    >
                      advertising
                    </Link>
                  </li>
                  <li>
                    <Link to={AppConstants.routes.home}>booking</Link>
                  </li>
                  <li>
                    <Link
                      to={AppConstants.routes.dinning}
                      style={{
                        color: [AppConstants.routes.dinning].includes(pathname)
                          ? "var(--theme-color3)"
                          : "",
                      }}
                    >
                      dining
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={AppConstants.routes.nightlifelanding}
                      style={{
                        color: [AppConstants.routes.nightlifelanding].includes(
                          pathname
                        )
                          ? "var(--theme-color3)"
                          : "",
                      }}
                    >
                      nightlife
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={AppConstants.routes.contact}
                      style={{
                        color: [AppConstants.routes.contact].includes(pathname)
                          ? "var(--theme-color3)"
                          : "",
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={AppConstants.routes.tutorial}
                      style={{
                        color: [AppConstants.routes.tutorial].includes(pathname)
                          ? "var(--theme-color3)"
                          : "",
                      }}
                    >
                      tutorial
                    </Link>
                  </li>
                  <li>
                    <div className="social text-center">
                      <Link
                        to={AppConstants.routes.login}
                        className="btn logout"
                        style={{ padding: "10px 30px" }}
                      >
                        Login
                      </Link>
                      <div
                        className="add-another mt-1"
                        onClick={() => navigate(AppConstants.routes.register)}
                      >
                        Register
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingPageHeader;
