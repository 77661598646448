import React, { useState, useEffect, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useLocation } from "react-router-dom";
import { QuestionCircle } from "react-bootstrap-icons";
import VideoTutorialPopUp from "../admin/VideoTutorialPopUp";
import AppConstants from "../../appConstants";

const StopVideos = AppConstants.tutorial.stopVideos;

function StopsPage({
  majorStops,
  handleSubmit,
  stepValue,
  setStep,
  SideBar,
  loading,
  btnLabel,
}) {
  const location = useLocation();
  const { pathname } = location;
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const [videoURL, setVideoURL] = useState("");
  const showTutorialVideo = [
    AppConstants.routes.admin.updateStops,
    AppConstants.routes.tour.createCompany,
  ].includes(pathname);

  const [fields, setFields] = useState([
    { id: 1, stopName: "", stopDescription: "" },
  ]);

  useEffect(() => {
    if (majorStops) {
      setFields(majorStops);
    }
  }, [majorStops]);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      stopName: "",
      stopDescription: "",
    };
    setFields([...fields, newField]);
  };

  const handleChange = (id, fieldName, value) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, [fieldName]: value } : field
      )
    );
  };

  const handleSubmitForm = async () => {
    if (validator.current.allValid()) {
      const data = {
        majorStops: fields,
        ...(stepValue && {
          profileLavel: stepValue,
        }),
      };
      handleSubmit(data, stepValue);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const removeStop = (id) => {
    const newFields = fields.filter((el) => el.id !== id);
    setFields(newFields);
  };

  const handleClickTutorial = () => {
    setVideoURL(StopVideos);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="tour-stops-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <SideBar setStep={setStep} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Please Add All Major Stop On Your Tour</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    {showTutorialVideo && <QuestionCircle />}
                  </span>
                </div>

                <div className="details-wrapper">
                  <div className="fs-18 fw-medium mb-3">
                    These details will give the customer a complete picture of
                    the tour
                  </div>

                  <div className="custom-field-wrapperr mb-lg-2">
                    {fields.map((field) => (
                      <div key={field.id}>
                        <div className="form-group">
                          <label htmlFor="StopName" className="fs-md-18 mb-2">
                            Stop Name:
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            autoComplete="off"
                            value={field.stopName}
                            onChange={(e) => {
                              if (e.target.value.length < 21) {
                                handleChange(
                                  field.id,
                                  "stopName",
                                  e.target.value
                                );
                              }
                            }}
                          />
                          <div
                            className="main-heading2 fs-14 fw-light mt-1"
                            style={{ float: "right" }}
                          >
                            <span id="charCount">{field.stopName.length}</span>
                            /20
                          </div>
                          {validator.current.message(
                            "stop name",
                            field.stopName,
                            "required"
                          )}
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="StopDescription"
                            className="fs-md-18 mb-2"
                          >
                            Stop Description:
                          </label>
                          <textarea
                            rows="5"
                            className="form-control"
                            autoComplete="off"
                            value={field.stopDescription}
                            onChange={(e) => {
                              if (e.target.value.length < 51) {
                                handleChange(
                                  field.id,
                                  "stopDescription",
                                  e.target.value
                                );
                              }
                            }}
                          ></textarea>
                          <div
                            className="main-heading2 fs-14 fw-light mt-1"
                            style={{ float: "right" }}
                          >
                            <span id="charCount">
                              {field.stopDescription.length}
                            </span>
                            /50
                          </div>
                          {validator.current.message(
                            "stop description",
                            field.stopDescription,
                            "required"
                          )}
                          {fields.length > 1 && (
                            <>
                              <br />
                              <div className="text-red text-end">
                                <span
                                  className="text-red cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() => removeStop(field.id)}
                                >
                                  Remove
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <hr className="divider my-4" />
                      </div>
                    ))}
                    <div className="fw-medium text-end">
                      <span
                        className="add-another glyphicon glyphicon-plus"
                        aria-hidden="true"
                        onClick={addField}
                      >
                        Add Another Stop
                      </span>
                    </div>
                    <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 gap-md-4 pt-3">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        disabled={loading || false}
                        onClick={handleSubmitForm}
                      >
                        {loading ? "Loading..." : btnLabel}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default StopsPage;
