export const handleNumericInput = (value) => {
  // if (/^\d*$/.test(value)) {
  //   return value;
  // }
  const regex = /^-?\d*\.?\d*$/;
  if (regex.test(value)) {
    return value;
  }
  return null;
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const generateSku = (str) => {
  return str
    .toLowerCase()
    .replace(/&/g, "and")
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "")
    .substring(0, 50);
};
