import React, { useState } from "react";
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const appearance = {
  theme: "night",
  variables: {
    fontSize: "12px",
    fontFamily: "Sohne, system-ui, sans-serif",
    fontWeightNormal: "500",
    borderRadius: "8px",
    colorBackground: "#0A2540",
    colorPrimary: "#EFC078",
    accessibleColorOnColorPrimary: "#1A1B25",
    colorText: "white",
    colorTextSecondary: "white",
    colorTextPlaceholder: "#ABB2BF",
    tabIconColor: "white",
    logoColor: "dark",
  },
  rules: {
    ".Input": {
      backgroundColor: "#212D63",
      border: "1px solid var(--colorPrimary)",
    },
  },
};
const options = {
  layout: {
    type: "tabs",
    defaultCollapsed: true,
  },
};

const cardElementOptions = {
  style: {
    base: {
      theme: "night",
      fontSize: "12px",
      fontFamily: "Sohne, system-ui, sans-serif",
      fontWeight: "500",
      borderRadius: "8px",
      color: "white",
      backgroundColor: "#0A2540",
      "::placeholder": {
        color: "#ABB2BF",
      },
    },
    invalid: {
      color: "#EFC078",
    },
  },
  variables: {
    fontSize: "12px",
    fontFamily: "Sohne, system-ui, sans-serif",
    fontWeightNormal: "500",
    borderRadius: "8px",
    colorBackground: "#0A2540",
    colorPrimary: "#EFC078",
    accessibleColorOnColorPrimary: "#1A1B25",
    colorText: "white",
    colorTextSecondary: "white",
    colorTextPlaceholder: "#ABB2BF",
    tabIconColor: "white",
    logoColor: "dark",
  },
  rules: {
    ".Input": {
      backgroundColor: "#212D63",
      border: "1px solid #EFC078",
    },
  },
};

const DiningSubscriptionPaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");

  const elementStyle = {
    style: {
      base: {
        color: "white",
        "::placeholder": {
          color: "#ABB2BF",
        },
        padding: "10px",

        fontSize: "12px",
        fontFamily: "Sohne, system-ui, sans-serif",
        fontWeightNormal: "500",
        borderRadius: "8px",
        colorBackground: "#0A2540",
        colorPrimary: "#EFC078",
        accessibleColorOnColorPrimary: "#1A1B25",
        colorTextSecondary: "white",
        tabIconColor: "white",
        logoColor: "dark",
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        email: email,
      },
    });

    if (error) {
      console.error("Payment error:", error.message);
    } else {
      const response = await fetch(
        "http://localhost/touchit-backend/public/api/v1/createDiningSubscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            name: "testing",
            payment_method_id: paymentMethod.id,
          }),
        }
      );

      const data = await response.json();
      if (data.error) {
        alert("Subscription failed: " + data.error);
      } else {
        alert("Subscription successful!");
      }

      console.log("Payment method created:", paymentMethod);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="dining-subscription">
      <div className="row">
        <div className="col-12 col-xl-6">
          <div style={{ marginBottom: "15px" }}>
            <label htmlFor="newCategory" className="mb-xl-1">
              Card Number:
            </label>
            <CardNumberElement
              className="stripe-input"
              options={elementStyle}
            />
          </div>
        </div>

        <div className="col-12 col-xl-3">
          <div style={{ marginBottom: "15px" }}>
            <label htmlFor="newCategory" className="mb-xl-1">
              Expiry Date:
            </label>
            <CardExpiryElement
              className="stripe-input"
              options={elementStyle}
            />
          </div>
        </div>
        <div className="col-12 col-xl-3">
          <div style={{ marginBottom: "15px" }}>
            <label htmlFor="newCategory" className="mb-xl-1">
              CVV:
            </label>
            <CardCvcElement className="stripe-input" options={elementStyle} />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          Powered by <span>stripe</span>
        </div>
        <button
          type="submit"
          style={{
            marginTop: "15px",
            padding: "10px",
            width: "100%",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Pay
        </button>
      </div>
    </form>
  );
};

export default DiningSubscriptionPaymentForm;
