import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import AppConstants from "../appConstants";
import { authCall } from "../services";

export default function ActivityCheckoutForm({
  payment,
  provider,
  activityDetails,
}) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.home}`,
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (paymentIntent?.status === "succeeded") {
      let res = {};
      if (activityDetails?.bookingDetails?.booking_calendar === "FareHarbor") {
        res = await authCall("fareharborBooking", {
          activityDetails: activityDetails?.bookingDetails,
          shortName: activityDetails?.bookingDetails?.activity_code,
          availabilityPk: activityDetails?.bookingDate?.pk,
          body: {
            agent: +process.env.REACT_APP_FAREHARBOR_AGENT_CODE,
            contact: {
              email: email,
              name: username,
              phone: mobile,
            },
            customers: [
              {
                customer_type_rate:
                  activityDetails?.bookingDate?.customer_type_rates[0]?.pk,
              },
            ],
            desk: +process.env.REACT_APP_FAREHARBOR_DESK_CODE,
            note: activityDetails?.bookingDetails?.what_to_bring,
          },
        });
      } else {
        res = await authCall("activityBookings", {
          activityDetails: activityDetails?.bookingDetails,
          payment_intent_id: paymentIntent.id,
          provider: "acct_1Pkm0gPFKNiIrfKQ",
          amount: payment,
          bookingDetails: {
            customer: {
              firstName: username,
              phone: mobile,
              email: email,
            },
            items: [
              {
                productCode: "P1QMTU",
                startTime: "2025-02-04T07:00:00Z",
                endTime: "2025-02-04T08:30:00Z",
                startTimeLocal: "2025-02-04 17:00:00",
                endTimeLocal: "2025-02-04 18:30:00",
                quantities: [
                  {
                    optionLabel: "Adult",
                    value: 1,
                  },
                ],
              },
            ],
            payments: [
              {
                amount: payment,
                type: "CASH",
                label: "Paid in cash",
              },
            ],
            // creditCard: {
            //   cardToken: paymentIntent.id,
            // },
          },
        });
      }

      if (res?.type === "success") {
        navigate(AppConstants.routes.tour.bookingSuccess, {
          state: {
            details: {
              ...paymentIntent,
              ...(res?.payload?.bookingDetails?.booking &&
                res?.payload?.bookingDetails?.booking),
              ...(res?.payload?.pk && res?.payload),
            },
          },
        });
      }
    } else {
      setMessage(error?.message);
    }

    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occured.");
    // }

    setIsLoading(false);
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="stripe-custom-form"
    >
      <div className="fs-18 fw-semibold mb-3" style={{ color: "#00ffe7" }}>
        Customer Details
      </div>

      <div className="mb-2">
        <label className="form-label mb-0">Name</label>
        <input
          className="stripe-custom-input form-control shadow-none text-light"
          type="text"
          placeholder="Name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>

      <div className="mb-2">
        <label className="form-label mb-0">Mobile</label>
        <input
          className="stripe-custom-input form-control shadow-none text-light"
          type="tel"
          placeholder="Mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
      </div>

      <div className="mb-2">
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={(e) => setEmail(e.value.email)}
        />
      </div>
      <hr className="border-light my-4" />
      <div className="fs-18 fw-semibold mb-3" style={{ color: "#00ffe7" }}>
        Credit Card Details
      </div>
      <PaymentElement id="payment-element" />
      <div style={{ textAlign: "center" }}>
        Powered by <span>stripe</span>
      </div>
      <div className="btn-wrapper mt-4 text-center">
        <button
          type="submit"
          className="btn btn-custom"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          {isLoading ? "Loading..." : `Pay $${payment}`}
        </button>
      </div>
    </form>
  );
}
