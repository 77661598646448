import React from "react";
import { useSelector } from "react-redux";
import rezdyLogo from "./../../../assets/images/admin/Rezdy.png";
import resovaLogo from "./../../../assets/images/admin/Resova.png";
import fareHarborLogo from "./../../../assets/images/admin/fareHarbor.png";

import ActivitySidebar from "../../layouts/TourSidebar";

function BookingCalendar({ handleSubmit, loading, stepValue, setStep }) {
  const companyDetails  = useSelector((state) => state.companyDetails);

  const handleSubmitForm = (type) => {
    const data = {
      bookingCalendar: type,
      profileLavel: stepValue,
    };
    handleSubmit(data, stepValue, companyDetails.id);
  };

  return (
    <>
      <div className="booking-calendar-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <ActivitySidebar setStep={setStep} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Please Select Your Booking Calendar
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-4 pb-md-3">
                    This will ensure real time updates in your booking calendar
                  </div>
                  <div className="logo-wrapper d-flex flex-wrap justify-content-center gap-4 gap-md-5 mb-4 pb-md-3">
                    <div className="rezdy-logo">
                      <div className="btn-wrapper text-center mt-4 pt-2">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={() => handleSubmitForm("Rezdy")}
                          disabled={loading || false}
                        >
                          <img
                            src={rezdyLogo}
                            alt="Rezdy Logo"
                            className="img-responsive"
                            width={140}
                          />
                        </button>
                      </div>
                      {/* <Link onClick={() => handleSubmitForm("Rezdy")}>
                        <img
                          src={rezdyLogo}
                          alt="Rezdy Logo"
                          className="img-responsive"
                        />
                      </Link> */}
                    </div>
                    <div className="resova-logo">
                      <div className="btn-wrapper text-center mt-4 pt-2">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={() => handleSubmitForm("Resova")}
                          disabled={loading || false}
                        >
                          <img
                            src={resovaLogo}
                            alt="Resova Logo"
                            className="img-responsive"
                            width={140}
                          />
                        </button>
                      </div>
                      {/* <Link onClick={() => handleSubmitForm("Rezdy")}>
                        <img
                          src={resovaLogo}
                          alt="Resova Logo"
                          className="img-responsive"
                        />
                      </Link> */}
                    </div>
                    <div className="resova-logo">
                      <div className="btn-wrapper text-center mt-4 pt-2">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          onClick={() => handleSubmitForm("FareHarbor")}
                          disabled={loading || false}
                        >
                          <img
                            src={fareHarborLogo}
                            alt="Resova Logo"
                            className="img-responsive"
                            width={140}
                            height={32}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="description text-center fs-18 fw-medium mb-4 pb-2">
                    At present we integrate with Rezdy, Resova, and FareHarbor.
                  </div>
                  {/* <form className="form-wrapper">
                    <div className="main-heading mb-2">Neither</div>
                    <div
                      className="field-wrapper"
                      style={{ maxWidth: "518px" }}
                    >
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <label htmlFor="preferredSupplier" className="fs-18">
                            Name Of Preferred Supplier:
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            id="preferredSupplier"
                            autoComplete="off"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <label htmlFor="bookingEmail" className="fs-18">
                            Email Address For Bookings:
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="email"
                            className="form-control"
                            id="bookingEmail"
                            autoComplete="off"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper text-center mt-4 pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={() => handleSubmitForm("Rezdy")}
                      >
                        Submit
                      </button>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingCalendar;
