import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setCompanyDetails } from "../../../redux/reducers/companyDetailsSlice";
import { useNavigate } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { PencilSquare, Trash, Plus, Eye, Display } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import BookingTextMessagePreviewPopUp from "../../pages/activities/BookingTextMessagePreviewPopUp";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import VideoTutorialPopUp from "../VideoTutorialPopUp";
import CreateRestaurantPopup from "../restaurant/CreateRestaurantPopup";
import webServices from "../../../services/webServices";
import NightlifeEventListPopup from "../checking/NightlifeEventListPopup";

const EditListingVideo = AppConstants.tutorial.editListing;

function NighlifeEditListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState({});
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [showEvent, setShowEvent] = useState(false);
  const [nightlifeId, setNightlifeId] = useState("");

  const [selectedTag, setSelectedTag] = useState({
    Breakfast: null,
    Lunch: null,
    Dinner: null,
  });

  const [selectedMenus, setSelectedMenus] = useState({});

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getEditListing", {
        userId: current?.id,
        userType: "Nightlife",
      });
      if (resp?.type === "success") {
        const menulist = resp?.payload;
        setList(menulist);
        setFilterList(menulist);
        setLoading(false);

        // Initialize selected menus
        const initialSelection2 = menulist.reduce((acc, restaurant) => {
          acc[restaurant.id] = {
            breakfast:
              restaurant?.menus?.Breakfast?.find(
                (menu) => menu.front_page_tag > 0
              )?.id || null,
            lunch:
              restaurant?.menus?.Lunch?.find((menu) => menu.front_page_tag > 0)
                ?.id || null,
            dinner:
              restaurant?.menus?.Dinner?.find((menu) => menu.front_page_tag > 0)
                ?.id || null,
            all_day_menu:
              restaurant?.menus?.all_day_menu?.find(
                (menu) => menu.front_page_tag > 0
              )?.id || null,
          };
          return acc;
        }, {});
        setSelectedMenus(initialSelection2);
      }
    }
    fetchData();
  }, [companyDetails]);

  const handleCategory = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateCategory, {
      state: { nightlifeId: id },
    });
  };

  const handleDetails = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateVanueDetails, {
      state: { nightlifeId: id },
    });
  };

  const handleMenu = (id) => {
    navigate(AppConstants.routes.admin.nightlifeUpdateMenu, {
      state: { nightlifeId: id },
    });
  };

  const handleEvent = (id) => {
    setShowEvent(true);
    setNightlifeId(id);
  };

  const handleCloseEvent = () => {
    setShowEvent(false);
  };

  const handleDelete = (id) => {
    toast.warning(
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          variant="success"
          className="btn-sm"
          onClick={() => deleteItem(id)}
        >
          Yes
        </Button>
        <Button
          variant="danger"
          className="btn-sm"
          style={{ marginLeft: "2px" }}
          onClick={closeToast}
        >
          No
        </Button>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const deleteItem = async (id) => {
    const resp = await authCall("deleteRestaurant", {
      restaurantId: id,
      isSoft: true,
    });
    if (resp?.type === "success") {
      closeToast();
      const updatedItems = list.filter((item) => item.id !== id);
      setFilterList(updatedItems);
      toast.success("Deleted successfully.");
    }
  };

  const closeToast = () => {
    toast.dismiss();
  };

  // For Bootstrap Model
  const handleClose = () => setShow(false);

  const handleShow = (item) => {
    setDetails(item);
    setShow(true);
  };

  const handleViewPublic = (el) => {
    navigate(AppConstants.routes.tour.home, { state: { activityId: el.id } });
  };

  const handleClickTutorial = () => {
    setVideoURL(EditListingVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  const handleMenuSelect = async (restaurantId, menuType, menuId) => {
    setSelectedMenus((prevSelectedMenus) => {
      const updatedMenus = { ...prevSelectedMenus };
      updatedMenus[restaurantId][menuType] = menuId;
      return updatedMenus;
    });

    await authCall("createMenuFrontPage", {
      menuId: menuId,
      restaurantId: restaurantId,
      menuType: menuType,
    });
  };

  // Add new restauratnt
  const [modalStatus, setModalStatus] = useState(false);

  const handleAdd = async () => {
    const resp = await webServices.createRestaurant({
      userId: current?.id,
      profileLavel: AppConstants.routes.restaurant.desiredServices,
    });
    if (resp?.type === "success") {
      setModalStatus(true);
      dispatch(setCompanyDetails(resp?.payload));
    }
  };

  const closeCreateRestaurantModal = () => {
    setModalStatus(false);
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Edit Listing</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    {/* <QuestionCircle /> */}
                  </span>
                </div>
                <div className="details-wrapper py-lg-4">
                  <Row className="align-items-center mb-4">
                    <Col xs={4}></Col>
                    <Col xs={8} className="text-end">
                      <Button
                        className="btn btn-custom btn-custom-sm fs-16"
                        onClick={handleAdd}
                      >
                        <Plus />
                        Create Nighlife
                      </Button>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          <th scope="col">Venue Name:</th>
                          {/* <th scope="col">Desire Services:</th> */}
                          <th scope="col">Category:</th>
                          <th scope="col">Venue Details:</th>
                          <th scope="col">Menu:</th>
                          <th scope="col">Events:</th>
                          <th scope="col">Status:</th>
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (filterList.length > 0 ? (
                            filterList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                <td>{el.company_name}</td>
                                {/* <td className="text-blue">
                                  <div
                                    onClick={() => handleDesireService(el.id)}
                                    className="cursor-pointer"
                                  >
                                    <PencilSquare /> Edit
                                  </div>
                                </td> */}
                                <td className="text-blue">
                                  <div
                                    onClick={() => handleCategory(el.id)}
                                    className="cursor-pointer"
                                  >
                                    <PencilSquare /> Edit
                                  </div>
                                </td>
                                <td className="text-blue">
                                  <div
                                    onClick={() => handleDetails(el.id)}
                                    className="cursor-pointer"
                                  >
                                    <PencilSquare /> Edit
                                  </div>
                                </td>

                                <td className="text-blue">
                                  {el.is_menu_available === "Yes" && (
                                    <div
                                      onClick={() => handleMenu(el.id)}
                                      className="cursor-pointer"
                                    >
                                      <PencilSquare /> Edit
                                    </div>
                                  )}
                                </td>
                                <td className="text-blue">
                                  <div
                                    onClick={() => handleEvent(el.id)}
                                    className="cursor-pointer"
                                  >
                                    <PencilSquare /> Edit
                                  </div>
                                </td>
                                <td>
                                  <Badge bg={el.status_bg}>{el.status}</Badge>
                                </td>
                                <td>
                                  <div className="icon-container">
                                    <Eye
                                      // onClick={() => handleShow(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <Display
                                      // onClick={() => handleViewPublic(el)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                      title="Click to public view"
                                    />
                                    <Trash
                                      onClick={() => handleDelete(el.id)}
                                      className="cursor-pointer"
                                      style={{
                                        color: "red",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingTextMessagePreviewPopUp
        show={show}
        handleClose={handleClose}
        // details={details}
        details={{
          activity_name: details?.activity_name,
          company_phone: details.company_phone,
          location: details?.location,
          what_to_bring: details.what_to_bring,
          locationDescription: details?.location_description,
          isBookingCancel: details?.is_booking_cancel === "1" || false,
        }}
      />
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
      <CreateRestaurantPopup
        modalStatus={modalStatus}
        handleClose={closeCreateRestaurantModal}
      />
      <NightlifeEventListPopup
        show={showEvent}
        handleClose={handleCloseEvent}
        selectedNightlifeId={nightlifeId}
      />
    </>
  );
}

export default NighlifeEditListing;
