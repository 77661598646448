import React from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();
  const handleClickHome = () => {
    navigate("/");
  };
  return (
    <div className="login-page py-4">
      <div className="container">
        <div className="display-5 fw-bold text-center mt-5">
          <div>404</div>
          <div>Page Not Found</div>
        </div>
        <div className="btn-wrapper text-center mt-4 pt-2">
          <button type="button" className="btn btn-custom" onClick={handleClickHome}>Go Home</button>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
