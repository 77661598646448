import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RestaurantSidebar from "../layouts/RestaurantSidebar";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import checkIcon from "./../../assets/images/admin/check-icon.png";

function MenuPreview() {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
  });

  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="restaurants-page restaurants-menu-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="items-wrapper">
                <div
                  id="mostPopular"
                  className="cards-wrapper custom-field-wrapper "
                >
                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-3">
                    <div className="main-heading2" id="menuLable">
                      Menu Preview
                    </div>
                    <select
                      className="form-select rounded-pill"
                      id="filterMenu"
                      style={{ maxWidth: "280px" }}
                    >
                      <option value="" hidden>
                        Select
                      </option>

                      <option value=""></option>
                    </select>
                  </div>

                  <div className="col-lg-4 col-sm-6 mb-3">
                    <div className="info-card d-flex gap-3 p-3">
                      <div className="info-sec d-flex flex-column justify-content-between">
                        <div className="mb-1">
                          <div className="title fs-18 fw-semibold text-truncate mb-1">
                            Menu
                          </div>
                          <div className="fs-14 description mb-1">des</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between gap-3">
                          <div className="item-price fw-semibold">$200</div>
                          <div className="action-wrapper d-flex gap-2">
                            <a href="menu-upload" className="btn-link">
                              <span
                                className="glyphicon glyphicon-edit fs-18 text-blue"
                                title="Edit"
                                aria-hidden="true"
                              ></span>
                            </a>
                            <span
                              className="glyphicon glyphicon-trash text-danger"
                              title="Delete"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="img-sec position-relative">
                        <img
                          src="{{ asset('assets/uploads/menu-items/').'/'.$menuItem->item_img }}"
                          className="img-responsive rounded-1"
                          alt="Lunch Menu"
                        />
                        <button
                          type="button"
                          className="btn btn-default add-btn rounded-pill px-3 viewMenuDetails"
                          data-id="{{$menuItem->id}}"
                          data-toggle="modal"
                          data-target="#addToCartModel"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuPreview;
