import React, { useEffect, useState } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import webServices from "../../services/webServices";
import QRCodeImg from "../../assets/images/QR_code.svg";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppConstants from "../../appConstants";
import { authCall } from "../../services";
import BannerComponent from "../BannerComponent";
import StarRating from "../common/StarRating";

const currentHour = +`${moment().hour()}.${moment().minute()}`;
let labelOfMenu;
if (currentHour >= 6 && currentHour <= 11) {
  labelOfMenu = "Breakfast";
} else if (currentHour > 11 && currentHour <= 16) {
  labelOfMenu = "Lunch";
} else if (currentHour > 16 && currentHour <= 22) {
  labelOfMenu = "Dinner";
} else {
  labelOfMenu = "Close";
}

function RestaurantDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { restaurantId } = location.state || {};
  const [details, setDetails] = useState({});
  const [hours, setHours] = useState([]);
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState([]);
  const [masterMenu, setMasterMenu] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [skelArray] = useState(new Array(6).fill());
  const [loading, setLoading] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [banners, setBanners] = useState([]);
  const [menuTabSelected, setMenuTabSelected] = useState(labelOfMenu);

  // For Bootstrap Model
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!restaurantId) {
      navigate(AppConstants.routes.home, { replace: true });
    }
  }, [restaurantId, navigate]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await authCall("getRestaurantDetails", {
        currentTime: moment().format("HH:mm"),
        restaurantId: restaurantId,
      });
      if (resp?.type === "success") {
        setDetails(resp?.payload);
        // setHours(
        //   resp?.payload?.service_hours
        //     ? Object.entries(resp?.payload?.service_hours)
        //     : []
        // );
        setHours(
          resp?.payload?.service_hours
            ? Object.entries(resp?.payload?.service_hours)
            : []
        );
        setBanners(resp?.payload?.bannersImages);
        setLoading(false);
        getMenulist(resp?.payload?.open_allday);
      }
    }
    fetchData();
  }, []);

  const getMenulist = async (alldaymenu, type = "") => {
    setLoadingMenu(true);
    const resp = await authCall("getMenuDetails", {
      menuCode: currentHour,
      menuType: type,
      restaurantId: restaurantId,
      allDayMenu: alldaymenu === "1" ? "all_day_menu" : "",
    });
    if (resp?.type === "success") {
      const menu_items = resp?.payload?.menu_items || [];
      setMasterMenu(menu_items);
      // const category = categories[0];
      // const filterItem = menu_items.filter(
      //   (item) => item.category === category
      // );
      setMenu(menu_items);
      // setSelectedCategory(category);
      // setCategories(categories);
      setLoadingMenu(false);
    } else {
      setLoadingMenu(false);
    }
  };

  const handleSelectCategory = (cat) => {
    const filterItem = masterMenu.filter((item) => item.category === cat);
    setMenu(filterItem);
    setSelectedCategory(cat);
  };

  const handleGetMenus = (type) => {
    setMenuTabSelected(type);
    getMenulist("", type);
  };

  return (
    <>
      {console.log(details?.total_ratings)}
      <div className="restaurant-page long-screen-page">
        {/* BANNER */}
        <BannerComponent
          banner={banners}
          title={details.name}
          subTitle="Taste it all"
          button={{ title: "Back" }}
        />

        <section className="restaurant-main-info pt-5 pb-4 mb-2 mb-md-3">
          <div className="container-fluid" style={{ maxWidth: "1010px" }}>
            <div className="bg-box w-100 p-3 py-4 p-sm-4">
              <div className="row">
                <div className="col-12 col-md-4 mb-3">
                  <div className="d-flex gap-3">
                    <div className="icon-sec">
                      <i className="bi bi-shop"></i>
                    </div>
                    <div className="info-sec">
                      <div
                        className="label-name mb-1 text-custom-primary"
                        style={{ textDecoration: "underline" }}
                      >
                        Restaurnat Name
                      </div>
                      <div className="label-value text-white text-capitalize">
                        {loading && <Skeleton count={1} width={200} />}
                        {!loading && details.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                  <div className="d-flex gap-3">
                    <div className="icon-sec">
                      <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="info-sec">
                      <div
                        className="label-name mb-1 text-custom-primary"
                        style={{ textDecoration: "underline" }}
                      >
                        Address
                      </div>
                      <div className="label-value text-white text-capitalize">
                        {loading ? (
                          <Skeleton count={1} width={200} />
                        ) : (
                          <>
                            {details?.restaurant_address?.streetName
                              ? `${details?.restaurant_address?.streetName}, `
                              : ""}
                            {details?.restaurant_address?.city
                              ? `${details?.restaurant_address?.city}, `
                              : ""}
                            {details?.restaurant_address?.state
                              ? `${details?.restaurant_address?.state?.label}, `
                              : ""}
                            {details?.restaurant_address?.zipCode
                              ? `${details?.restaurant_address?.zipCode}`
                              : ""}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                  <div className="d-flex gap-3">
                    <div className="icon-sec">
                      <i className="bi bi-telephone-outbound"></i>
                    </div>
                    <div className="info-sec">
                      <div
                        className="label-name mb-1 text-custom-primary"
                        style={{ textDecoration: "underline" }}
                      >
                        Phone Number
                      </div>
                      <div className="label-value text-white text-capitalize">
                        {loading && <Skeleton count={1} width={200} />}
                        {!loading && details.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-7 col-md-7 mb-3">
                  <div className="d-flex gap-3">
                    <div className="icon-sec">
                      <i className="bi bi-clock"></i>
                    </div>
                    <div className="info-sec">
                      <div
                        className="label-name mb-1 text-custom-primary"
                        style={{ textDecoration: "underline" }}
                      >
                        Opening Hours
                      </div>
                      <div
                        className="info-sec d-flex1 flex-wrap gap-2 text-white text-capitalize"
                        style={{ lineHeight: 2 }}
                      >
                        {loading && (
                          <>
                            <Skeleton count={1} width={420} />
                            <Skeleton count={1} width={310} />
                          </>
                        )}
                        {!loading &&
                          hours.map(([day, hours], index) => (
                            <div key={index}>
                              {details?.open_allday !== "1" ? (
                                <>
                                  <span className="text-custom-primary">
                                    {moment().day(day).format("ddd")} :{" "}
                                  </span>
                                  {hours?.breakfast &&
                                    !hours?.breakfast?.close && (
                                      <>
                                        <span className="fs-14">
                                          {moment(
                                            hours?.breakfast?.openningTime,
                                            "h:mm A"
                                          ).format("hh:mm A")}{" "}
                                          -{" "}
                                          {moment(
                                            hours?.breakfast?.closingTime,
                                            "h:mm A"
                                          ).format("hh:mm A")}
                                        </span>
                                        {", "}
                                      </>
                                    )}
                                  {hours?.lunch && !hours?.lunch?.close && (
                                    <>
                                      <span className="fs-14">
                                        {moment(
                                          hours?.lunch?.openningTime,
                                          "h:mm A"
                                        ).format("hh:mm A")}{" "}
                                        -{" "}
                                        {moment(
                                          hours?.lunch?.closingTime,
                                          "h:mm A"
                                        ).format("hh:mm A")}
                                      </span>
                                      {", "}
                                    </>
                                  )}
                                  {hours?.dinner && !hours?.dinner?.close && (
                                    <>
                                      <span className="fs-14">
                                        {moment(
                                          hours?.dinner?.openningTime,
                                          "h:mm A"
                                        ).format("hh:mm A")}{" "}
                                        -{" "}
                                        {moment(
                                          hours?.dinner?.closingTime,
                                          "h:mm A"
                                        ).format("hh:mm A")}
                                      </span>
                                    </>
                                  )}
                                  {/* <span className="text-red">Closed</span> */}
                                </>
                              ) : (
                                <>
                                  <>
                                    <span className="text-custom-primary">
                                      {moment().day(day).format("ddd")} :{" "}
                                    </span>
                                    {!hours?.close ? (
                                      <span className="fs-14">
                                        {moment(
                                          hours?.openningTime,
                                          "h:mm A"
                                        ).format("hh:mm A")}{" "}
                                        -{" "}
                                        {moment(
                                          hours?.closingTime,
                                          "h:mm A"
                                        ).format("hh:mm A")}
                                      </span>
                                    ) : (
                                      <span className="text-red">Closed</span>
                                    )}
                                  </>
                                </>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="d-flex flex-wrap align-items-center justify-content-center gap-3 column-gap-lg-4 pt-3">
                        <div className="bg-box review-box d-flex flex-wrap align-items-center gap-3 text-center px-3 py-2">
                          <div className="review-stars fs-18 d-flex gap-2 m-0 text-white">
                            <StarRating rating={details?.total_ratings || 0} />
                          </div>
                          <div className="rating d-flex align-items-center gap-1">
                            <div className="m-0">
                              {details?.reviews || 0} Reviews
                            </div>
                          </div>
                        </div>
                        {/* <button
                  type="button"
                  className="btn btn-custom2 rounded-pill"
                  onClick={handleShow}
                >
                  MAKE A RESERVATION
                </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {details?.map_image && (
                  <div className="col-5 col-md-5 mb-3">
                    <div className="mt-3">
                      {/* <img
                        src={details?.map_image}
                        style={{
                          width: 375,
                          height: 315,
                          border: "2px solid #fff",
                          borderRadius: 15,
                        }}
                      /> */}
                      <div
                        style={{
                          // height: "65vh",
                          overflow: "hidden",
                          position: "relative",
                          // width: "100%",
                          width: "375px",
                          height: "315px",
                          display: "flex",
                          justifyContent: "center",
                          border: "2px solid #fff",
                          borderRadius: 15,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            height: "100%",
                            width: "calc(100vw + 600px)",
                          }}
                        >
                          <iframe
                            class="border-card"
                            src={`${details?.map_image}&output=embed`}
                            width="100%"
                            height="100%"
                            allowfullscreen=""
                            loading="lazy"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section
          className={`restaurant-menu-listing pb-5 ${
            menu.length === 0 ? "d-none" : ""
          }`}
        >
          <div className="container-fluid" style={{ maxWidth: "1010px" }}>
            <div className="text-center">
              {/* <div className="fs-3 fw-bold text-custom-primary mb-3">
                {labelOfMenu !== "Close" && `${labelOfMenu} Menu`}
              </div> */}
              <div
                className="d-flex justify-content-center mb-3"
                style={{ gap: "7rem" }}
              >
                <div className="tab-button rounded-2 d-inline-flex flex-column flex-sm-row flex-sm-wrap justify-content-center gap-2 p-3 py-sm-2">
                  <button type="button" className={`btn btn-custom2 fs-20`}>
                    <span className={`text-custom-primary`}>
                      Popular Choices
                    </span>

                    <div className="fs-12 font-italic">
                      *<i>Subject to availability</i>
                    </div>
                  </button>
                </div>
              </div>

              <div className="bg-box tab-button rounded-2 d-inline-flex flex-column flex-sm-row flex-sm-wrap justify-content-center gap-2 p-3 py-sm-2">
                {details?.open_breakfast === "1" && (
                  <button
                    type="button"
                    className={`btn btn-custom2 ${
                      menuTabSelected === "Breakfast" ? "active" : ""
                    } fs-20`}
                    onClick={() => handleGetMenus("Breakfast")}
                  >
                    <span
                      className={`${
                        menuTabSelected === "Breakfast"
                          ? ""
                          : "text-custom-primary"
                      }`}
                    >
                      Breakfast Menu
                    </span>
                    {details?.breakfastOpenHour?.closeToday !== "" && (
                      <div className="text-center fs-14 text-red">
                        <span>{details?.breakfastOpenHour?.closeToday}</span>
                      </div>
                    )}

                    {details?.breakfastOpenHour?.openTomorrow !== "" && (
                      <div className="text-center fs-14">
                        <span>{details?.breakfastOpenHour?.openTomorrow}</span>
                      </div>
                    )}

                    {details?.breakfastOpenHour?.openTime !== "" &&
                      details?.breakfastOpenHour?.closeTime !== "" && (
                        <div className="text-center fs-14">
                          <span>
                            {moment(
                              details?.breakfastOpenHour?.openTime,
                              "h:mm A"
                            ).format("hh:mm A")}
                          </span>{" "}
                          -{" "}
                          <span>
                            {moment(
                              details?.breakfastOpenHour?.closeTime,
                              "h:mm A"
                            ).format("hh:mm A")}
                          </span>
                        </div>
                      )}
                  </button>
                )}

                {details?.open_lunch === "1" && (
                  <button
                    type="button"
                    className={`btn btn-custom2 ${
                      menuTabSelected === "Lunch" ? "active" : ""
                    } fs-20`}
                    onClick={() => handleGetMenus("Lunch")}
                  >
                    <span
                      className={`${
                        menuTabSelected === "Lunch" ? "" : "text-custom-primary"
                      }`}
                    >
                      Lunch Menu
                    </span>
                    {details?.lunchOpenHour?.closeToday !== "" && (
                      <div className="text-center fs-14 text-red">
                        <span>{details?.lunchOpenHour?.closeToday}</span>
                      </div>
                    )}

                    {details?.lunchOpenHour?.openTomorrow !== "" && (
                      <div className="text-center fs-14">
                        <span>{details?.lunchOpenHour?.openTomorrow}</span>
                      </div>
                    )}

                    {details?.lunchOpenHour?.openTime !== "" &&
                      details?.lunchOpenHour?.closeTime !== "" && (
                        <div className="text-center fs-14">
                          <span>
                            {moment(
                              details?.lunchOpenHour?.openTime,
                              "h:mm A"
                            ).format("hh:mm A")}
                          </span>{" "}
                          -{" "}
                          <span>
                            {moment(
                              details?.lunchOpenHour?.closeTime,
                              "h:mm A"
                            ).format("hh:mm A")}
                          </span>
                        </div>
                      )}
                  </button>
                )}

                {details?.open_dinner === "1" && (
                  <button
                    type="button"
                    className={`btn btn-custom2 ${
                      menuTabSelected === "Dinner" ? "active" : ""
                    } fs-20`}
                    onClick={() => handleGetMenus("Dinner")}
                  >
                    <span
                      className={`${
                        menuTabSelected === "Dinner"
                          ? ""
                          : "text-custom-primary"
                      }`}
                    >
                      Dinner Menu
                    </span>
                    {details?.dinnerOpenHour?.closeToday !== "" && (
                      <div className="text-center fs-14 text-red">
                        <span>{details?.dinnerOpenHour?.closeToday}</span>
                      </div>
                    )}

                    {details?.dinnerOpenHour?.openTomorrow !== "" && (
                      <div className="text-center fs-14">
                        <span>{details?.dinnerOpenHour?.openTomorrow}</span>
                      </div>
                    )}

                    {details?.dinnerOpenHour?.openTime !== "" &&
                      details?.dinnerOpenHour?.closeTime !== "" && (
                        <div className="text-center fs-14">
                          <span>
                            {moment(
                              details?.dinnerOpenHour?.openTime,
                              "h:mm A"
                            ).format("hh:mm A")}
                          </span>{" "}
                          -{" "}
                          <span>
                            {moment(
                              details?.dinnerOpenHour?.closeTime,
                              "h:mm A"
                            ).format("hh:mm A")}
                          </span>
                        </div>
                      )}
                  </button>
                )}

                {details?.open_allday === "1" && (
                  <button type="button" className={`btn btn-custom2 fs-20`}>
                    <span className="text-custom-primary">All Day Menu</span>
                    <div className="text-center fs-14">
                      {details?.alldayOpenHour?.openTime !== "" &&
                        details?.alldayOpenHour?.closeTime !== "" && (
                          <div className="text-center fs-14">
                            <span>
                              {moment(
                                details?.alldayOpenHour?.openTime,
                                "h:mm A"
                              ).format("hh:mm A")}
                            </span>{" "}
                            -{" "}
                            <span>
                              {moment(
                                details?.alldayOpenHour?.closeTime,
                                "h:mm A"
                              ).format("hh:mm A")}
                            </span>
                          </div>
                        )}
                    </div>
                  </button>
                )}
              </div>
            </div>
            <div className="bg-box list-wrapper p-3 pt-2 pt-sm-3 p-sm-4">
              <div className="row mb-2 mb-md-3">
                {loadingMenu &&
                  skelArray.map((el, index) => (
                    <div key={index} className="col-sm-6 col-lg-4 mt-3 mt-md-4">
                      <div className="bg-box overflow-hidden rounded-4">
                        <div className="img-wrapper">
                          {/* <SkeletonTheme
                          baseColor="#202020"
                          highlightColor="#444"
                        > */}
                          <Skeleton
                            count={1}
                            height={117}
                            inline
                            style={{ display: "block", borderRadius: 0 }}
                          />
                          {/* </SkeletonTheme> */}
                        </div>
                        <div className="info-wrapper p-3">
                          <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
                            <div className="fs-18 fw-medium text-capitalize">
                              <Skeleton count={1} width={230} />
                            </div>
                          </div>
                          <div className="fs-18 fw-semibold text-custom-primary2 text-capitalize mb-2">
                            <Skeleton count={1} width={100} />
                          </div>
                          <div className="fs-16 text-truncate-2">
                            <Skeleton count={2} width={230} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {!loadingMenu &&
                  menu.map((el) => (
                    <div className="col-sm-6 col-lg-4 mt-3 mt-md-4">
                      <div
                        className="bg-box overflow-hidden rounded-4"
                        style={{ minHeight: "373px" }}
                      >
                        <div className="img-wrapper">
                          <img
                            src={el.itemPhoto}
                            className="img-fluid object-fit-cover w-100"
                            alt={el.itemName}
                            style={{ height: "200px" }}
                          />
                        </div>
                        <div className="info-wrapper p-3">
                          <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
                            <div className="fs-18 fw-medium text-capitalize">
                              {el.itemName}
                            </div>
                            {/* <button type="button" className="btn btn-custom2">
                          Add
                        </button> */}
                          </div>
                          <div className="fs-18 d-flex justify-content-between fw-semibold text-custom-primary2 text-capitalize mb-2">
                            {/* <span className="text-left">$ {el.itemPrice}</span> */}
                            <span className="text-right fs-12">
                              {el?.vegan && "(VE)"} {el?.vegetarian && "(VG)"}{" "}
                              {el?.glutenFree && "(GF)"}{" "}
                              {el?.dairyFree && "(DF)"} {el?.nutFree && "(NF)"}{" "}
                            </span>
                          </div>
                          <div className="fs-16 text-truncate-2---">
                            {el.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {menu.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "30px",
                      fontSize: "18px",
                    }}
                  >
                    No Menu Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            Scan To Make A Reservation:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4">
          <div className="qr-cover text-center">
            <img
              src={QRCodeImg}
              className="img-fluid object-fit-contain bg-white"
              alt={QRCodeImg}
              style={{ height: "296px", width: "296px" }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RestaurantDetails;
