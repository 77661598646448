import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import rectangle1 from "../../../../assets/images/site/Rectangle 14.png";
import rectangle2 from "../../../../assets/images/site/Rectangle 15.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import CalendertIcon from "../../../../assets/images/site/CalenderIcon.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import StopwatchIcon from "../../../../assets/images/site/StopwatchIcon.png";
import "./NightClubBook.scss";

// Event details
import EventLogo1 from "../../../../assets/images/site/EventLogo1.png";
import EventLogo2 from "../../../../assets/images/site/EventLogo2.png";
import uploadLogo from "../../../../assets/images/site/uploadLogo.png";
import EventLogo3 from "../../../../assets/images/site/EventLogo3.png";
import EventLogo4 from "../../../../assets/images/site/EventLogo4.png";
import "../EventDetails/EventDetails";

import { authCall } from "../../../../services";
import { handleNumericInput } from "../../../util/utils";

const NightClubBook = () => {
  useEffect(() => {
    const datePicker = document.getElementById("datePicker");
    const formattedDateElement = document.getElementById("formattedDate");

    // Check if the elements exist before adding event listeners
    if (datePicker) {
      datePicker.addEventListener("change", function () {
        const selectedDate = new Date(this.value);
        const options = { day: "numeric", month: "long", year: "numeric" };
        const formattedDate = selectedDate.toLocaleDateString("en-GB", options);

        // Display the formatted date
        if (formattedDateElement) {
          formattedDateElement.textContent = formattedDate;
        }
      });
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (datePicker) {
        datePicker.removeEventListener("change", () => {});
      }
    };
  }, []); // Empty dependency array to run only once

  const [time, setTime] = useState("");
  const timePickerRef = useRef(null);
  const [formattedDate, setFormattedDate] = useState("");

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = selectedDate.toLocaleDateString("en-GB", options); // Use 'formattedDate' here
    setFormattedDate(formattedDate); // Set the state with 'formattedDate'
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleClick = () => {
    const timePicker = document.getElementById("timePicker");
    if (timePicker) {
      timePicker.showPicker();
    } else {
      document.getElementById("timePcker").click();
    }
  };

  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const initialField = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    demoDate: "",
    demoTime: "",
    meetingAddress: "",
    listResto: "",
    isLightSpeed: "",
    sevenRoom: "",
    isZipPhotoUploaded: "",
    isMenuUploaded: "",
    uploadZipPhoto: "",
    uploadMenu: "",
  };

  const [eventDetailsField, setEventDetailsField] = useState([
    {
      id: 1,
      eventName: "",
      eventPhoto: "",
      eventDate: "",
      eventTime: "",
      eventDescription: "",
      eventPromotions: "",
      isRemoveEvent: true,
    },
  ]);

  const [promotionDetailsField, setPromotionDetailsField] = useState([
    {
      id: 1,
      promotionName: "",
      promotionPhoto: "",
      promotionDate: "",
      promotionTime: "",
      promotionDescription: "",
      promotionExtra: "",
    },
  ]);

  const [fileUpload, setFileUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressMenu, setUploadProgressMenu] = useState(0);

  const [values, setValues] = useState(initialField);
  const [loading, setLoading] = useState(false);

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phoneNumber: inputValue,
      });
    }
  };

  const handleBookRequest = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      values.eventDetails = eventDetailsField;
      values.promotionDetails = promotionDetailsField;
      console.log(values);
      setLoading(true);
      const resp = await authCall("sendMailNightlifeDemoRequest", values);
      if (resp?.type === "success") {
        setLoading(false);
        restForm();
        toast.dismiss();
        toast.success("Your demo request sent successfully!");
      }
    } else {
      setLoading(false);
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const restForm = () => {
    setValues(initialField);
  };

  const handleUploadFiles = async (event) => {
    const { name } = event.target;
    const selectedFile = event.target.files[0];

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("type", name);
    setFileUpload(true);

    try {
      const url = "https://touch-it.com.au/api/public/api/v1";
      // const url = "http://localhost/touch-it/public/api/v1";
      await axios
        .post(`${url}/uploadeFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (name === "uploadMenu") {
              setUploadProgressMenu(percentCompleted);
            } else {
              setUploadProgress(percentCompleted);
            }
            setFileUpload(false);
          },
        })
        .then((response) => {
          const result = response?.data;
          if (name === "uploadMenu") {
            setValues({
              ...values,
              uploadMenu: result?.payload?.fileName,
              isMenuUploaded: "Yes",
            });
          } else {
            setValues({
              ...values,
              uploadZipPhoto: result?.payload?.fileName,
              isZipPhotoUploaded: "Yes",
            });
          }
        })
        .catch((error) => error);
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  // event details
  const maxDescriptionLength = 200;
  const [description, setDescription] = useState("");
  const handleDescriptionChange = (e) => {
    const input = e.target.value;
    if (input.length <= maxDescriptionLength) {
      setDescription(input);
    }
  };

  const handleClickAddAnotherEvent = () => {
    const newField = {
      id: eventDetailsField.length + 1,
      eventName: "",
      eventPhoto: "",
      eventDate: "",
      eventTime: "",
      eventDescription: "",
      eventPromotions: "",
      isRemoveEvent: false,
    };
    setEventDetailsField([...eventDetailsField, newField]);
  };

  const handleClickRemoveAnotherEvent = (id) => {
    const newFields = eventDetailsField.filter((el) => el.id !== id);
    setEventDetailsField(newFields);
  };

  const handleChangeEventDetails = (id, fieldName, value) => {
    setEventDetailsField(
      eventDetailsField.map((field) =>
        field.id === id ? { ...field, [fieldName]: value } : field
      )
    );
  };

  const handleChangePromotionDetails = (id, fieldName, value) => {
    setPromotionDetailsField(
      promotionDetailsField.map((field) =>
        field.id === id ? { ...field, [fieldName]: value } : field
      )
    );
  };

  const handleClickRemoveAnotherPromotion = (id) => {
    const newFields = promotionDetailsField.filter((el) => el.id !== id);
    setPromotionDetailsField(newFields);
  };

  const handleClickAddAnotherPromotion = () => {
    const newField = {
      id: promotionDetailsField.length + 1,
      promotionName: "",
      promotionPhoto: "",
      promotionDate: "",
      promotionTime: "",
      promotionDescription: "",
      promotionExtra: "",
    };
    setPromotionDetailsField([...promotionDetailsField, newField]);
  };

  return (
    <>
      <div
        className="nightClubBook_wrapper mt-5 container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          STEP ONE: BOOK A DEMO
        </div>
        <div className="contact-form-wrapper">
          <div
            className="contact-form mb-5"
            id="contactformrest"
            style={{ margin: "0px 35px" }}
          >
            <div className="row mt-5" id="contacttWrapper">
              {/* first name */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={NameIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={31}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="firstNameInput"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChangeValue}
                      placeholder="First Name"
                    />
                  </div>
                  {validator.current.message(
                    "first name",
                    values.firstName,
                    "required"
                  )}
                </div>
              </div>

              {/* last name */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={NameIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={31}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="lastNameInput"
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "last name",
                    values.lastName,
                    "required"
                  )}
                </div>
              </div>

              {/* Phone number */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={ContactIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={24}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="phoneNumberInput"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChangePhone}
                      placeholder="Phone Number"
                    />
                  </div>
                  {validator.current.message(
                    "phone number",
                    values.phoneNumber,
                    "required"
                  )}
                </div>
              </div>

              {/* Email */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={EmailtIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={20}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="email"
                      id="emailInput"
                      name="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "email",
                    values.email,
                    "required|email"
                  )}
                </div>
              </div>

              {/* Date picker */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={CalendertIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={22}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="date"
                      id="datePicker"
                      name="demoDate"
                      value={values.demoDate}
                      onChange={handleChangeValue}
                      onFocus={() =>
                        document.getElementById("datePicker").showPicker()
                      } // Open calendar when the field is focused
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {validator.current.message(
                    "date",
                    values.demoDate,
                    "required"
                  )}
                </div>
              </div>

              {/* Time picker  */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon" onClick={handleClick}>
                    <img
                      src={StopwatchIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={26}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      placeholder="hh:mm"
                      value={values.demoTime}
                      readOnly
                      style={{ cursor: "pointer" }} // Change cursor to pointer
                    />
                    <input
                      type="time"
                      id="timePicker"
                      name="demoTime"
                      value={values.demoTime}
                      onChange={handleChangeValue}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }} // Hide the time input
                    />
                  </div>

                  {validator.current.message(
                    "time",
                    values.demoTime,
                    "required"
                  )}
                </div>
              </div>
            </div>

            {/* Address */}
            <div className="row">
              <div
                className="col-lg-12 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div
                  className="form-group ms-3"
                  id="locationInput"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="input-with-icon">
                    <img
                      src={LocationtIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={25}
                      width={20}
                      style={{ left: "1.5%" }}
                    />
                    <input
                      type="text"
                      id="locationInput2"
                      name="meetingAddress"
                      placeholder="Preferred Meeting Address"
                      value={values.meetingAddress}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "meeting address",
                    values.meetingAddress,
                    "required"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Event details */}
      <div
        className="eventDetails_wrapper mt-5 container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          EVENT DETAILS
        </div>
        <div className="contact-form-wrapper">
          <div className="contact-form mb-5" style={{ margin: "0px 35px" }}>
            <div className="text-content">
              <p
                className="text-center mb-0 mt-3"
                style={{ fontSize: "17px", color: "#BABABA" }}
              >
                Please enter your up comming events : (for example Gold Coast
                500 celebrations )
              </p>
            </div>
            {eventDetailsField.map((ef, index) => (
              <>
                <div key={index} className="row mt-0">
                  {/* event name */}
                  <div
                    className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-4"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform">
                      <div className="input-with-icon">
                        <img
                          src={EventLogo1}
                          alt="Name Icon"
                          className="input-icon"
                          height={31}
                          width={25}
                          style={{ left: "3%" }}
                        />
                        <input
                          type="text"
                          id="eventName"
                          name="eventName"
                          placeholder="Event Name"
                          value={ef.eventName}
                          onChange={(e) =>
                            handleChangeEventDetails(
                              ef.id,
                              "eventName",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* event photo */}
                  <div
                    className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-4"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform">
                      <div
                        className="input-with-icon"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={EventLogo2}
                          alt="MenuLogo"
                          style={{
                            position: "absolute",
                            left: "5px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: "25px", // Adjust the size as needed
                            height: "25px",
                            zIndex: 3,
                            left: "3%",
                          }}
                        />
                        {/* Placeholder Text */}
                        <span
                          className="placeholder-text"
                          style={{
                            position: "absolute",
                            left: "50px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#BABABA",
                            pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                            fontSize: "16px",
                          }}
                        >
                          Event Photo
                        </span>

                        {/* Hidden File Input */}
                        <input
                          type="file"
                          id="fileUpload"
                          name="fileUpload"
                          className="upload-input"
                          style={{
                            width: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            zIndex: 2,
                          }}
                          required
                        />

                        {/* Custom Button with Icon */}
                        <button
                          type="button"
                          className="custom-upload-btn"
                          onClick={() =>
                            document.getElementById("fileUpload").click()
                          }
                          style={{
                            position: "absolute",
                            right: "10px", // Align button to the right
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            zIndex: 3,
                          }}
                        >
                          <img
                            src={uploadLogo}
                            alt="Upload Icon"
                            style={{
                              width: "46px",
                              height: "47px",
                              margin: "2px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* event date */}
                  <div
                    className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform">
                      <div className="input-with-icon">
                        <img
                          src={CalendertIcon}
                          alt="Name Icon"
                          className="input-icon"
                          height={22}
                          width={25}
                          style={{ left: "3%" }}
                        />
                        <input
                          type="date"
                          id="evnetdatePicker1"
                          value={ef.eventDate}
                          // onChange={handleDateChange} // Handle date change
                          onChange={(e) =>
                            handleChangeEventDetails(
                              ef.id,
                              "eventDate",
                              e.target.value
                            )
                          }
                          onFocus={() =>
                            document
                              .getElementById("evnetdatePicker1")
                              .showPicker()
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* event time */}
                  <div
                    className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform">
                      <div className="input-with-icon" onClick={handleClick}>
                        <img
                          src={StopwatchIcon}
                          alt="Name Icon"
                          className="input-icon"
                          height={26}
                          width={25}
                          style={{ left: "3%" }}
                        />
                        <input
                          id="timeInput"
                          type="text"
                          placeholder="20:00"
                          value={time}
                          readOnly
                          style={{ cursor: "pointer" }} // Change cursor to pointer
                        />
                        <input
                          type="time"
                          id="eventTimePicker"
                          ref={timePickerRef}
                          value={ef.eventTime}
                          // onChange={handleTimeChange}
                          onChange={(e) =>
                            handleChangeEventDetails(
                              ef.id,
                              "eventTime",
                              e.target.value
                            )
                          }
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            opacity: 0,
                            cursor: "pointer",
                          }} // Hide the time input
                        />
                      </div>
                    </div>
                  </div>

                  {/* event description */}
                  <div
                    className="col-lg-12 d-flex flex-column align-items-center justify-content-between mt-lg-3"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventdesc">
                      <div className="input-with-icon">
                        <img
                          src={EventLogo3}
                          alt="Name Icon"
                          className="input-icon"
                          height={30}
                          width={25}
                          style={{ left: "1.5%" }}
                        />
                        <input
                          type="text"
                          id="location"
                          name="location"
                          placeholder="Event Description"
                          value={ef.eventDescription}
                          // onChange={handleDescriptionChange}
                          onChange={(e) =>
                            handleChangeEventDetails(
                              ef.id,
                              "eventDescription",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <small style={{ color: "#BABABA", fontSize: "14px" }}>
                        {ef.eventDescription.length}/{maxDescriptionLength}{" "}
                        characters
                      </small>
                    </div>
                  </div>

                  {/* event promotion */}
                  <div
                    className="col-lg-8 d-flex flex-column  align-items-center justify-content-between mt-lg-2"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div
                      className="form-group ms-3"
                      id="eventLocationInput"
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="input-with-icon"
                        style={{ maxWidth: "100%" }}
                      >
                        <img
                          src={EventLogo4}
                          alt="Name Icon"
                          className="input-icon"
                          height={25}
                          width={25}
                          style={{ left: "2%" }}
                        />
                        <input
                          type="text"
                          id="eventLocationInput"
                          name="location"
                          placeholder="Event Promotions : (two free beers on entry)"
                          value={ef.eventPromotion}
                          onChange={(e) =>
                            handleChangeEventDetails(
                              ef.id,
                              "eventPromotion",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* remove field */}
                  {eventDetailsField.length > 1 && (
                    <div
                      className="col-lg-4 d-flex flex-column align-items-center mt-lg-2"
                      style={{ fontFamily: "Poppins", color: "#BABABA" }}
                      id="contacttWrapper"
                    >
                      <button
                        className="eventButton ms-3"
                        style={{ width: "100%", backgroundColor: "red" }}
                        onClick={() => handleClickRemoveAnotherEvent(ef.id)}
                      >
                        REMOVE EVENT
                      </button>
                    </div>
                  )}
                </div>
                <hr />
              </>
            ))}
            <div className="row mt-0">
              <div
                className="col-lg-4 d-flex flex-column align-items-center mt-lg-2"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <button
                  className="eventButton ms-3"
                  style={{ width: "100%" }}
                  onClick={handleClickAddAnotherEvent}
                >
                  ADD ANOTHER EVENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Promotion Details */}
      <div
        className="eventDetails_wrapper mt-5 container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle1} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          PROMOTION DETAILS
        </div>
        <div className="contact-form-wrapper">
          <div className="contact-form mb-5" style={{ margin: "0px 35px" }}>
            <div className="text-content">
              <p
                className="text-center mb-0 mt-3"
                style={{ fontSize: "17px", color: "#BABABA" }}
              >
                Please enter your weekly promotion details : (for example :
                Wednesday night womens night)
              </p>
            </div>

            {promotionDetailsField.map((pd, index) => (
              <>
                <div className="row mt-0">
                  {/* promotion name */}
                  <div
                    key={index}
                    className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform2">
                      <div className="input-with-icon">
                        <img
                          src={EventLogo1}
                          alt="Name Icon"
                          className="input-icon"
                          height={26}
                          width={25}
                          style={{ left: "3%" }}
                        />
                        <input
                          type="text"
                          id="promotionName"
                          name="promotionName"
                          placeholder="Promotion name"
                          values={pd.promotionName}
                          onChange={(e) => {
                            handleChangePromotionDetails(
                              pd.id,
                              "promotionName",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* promotion photo */}
                  <div
                    className="col-6 col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-4"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform2">
                      <div
                        className="input-with-icon"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={EventLogo2}
                          alt="MenuLogo"
                          style={{
                            position: "absolute",
                            left: "5px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: "25px", // Adjust the size as needed
                            height: "25px",
                            zIndex: 3,
                            left: "3%",
                          }}
                        />
                        {/* Placeholder Text */}
                        <span
                          className="placeholder-text"
                          style={{
                            position: "absolute",
                            left: "50px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            color: "#BABABA",
                            pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                            fontSize: "16px",
                          }}
                        >
                          Promotion Photo
                        </span>

                        {/* Hidden File Input */}
                        <input
                          type="file"
                          id="fileUpload"
                          name="fileUpload"
                          className="upload-input"
                          style={{
                            width: "100%",
                            opacity: 0,
                            cursor: "pointer",
                            zIndex: 2,
                          }}
                          required
                        />

                        {/* Custom Button with Icon */}
                        <button
                          type="button"
                          className="custom-upload-btn"
                          onClick={() =>
                            document.getElementById("fileUpload").click()
                          }
                          style={{
                            position: "absolute",
                            right: "10px", // Align button to the right
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            zIndex: 3,
                          }}
                        >
                          <img
                            src={uploadLogo}
                            alt="Upload Icon"
                            style={{
                              width: "46px",
                              height: "47px",
                              margin: "2px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* promotion date */}
                  <div
                    className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-4"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform2">
                      <div className="input-with-icon">
                        <img
                          src={CalendertIcon}
                          alt="Name Icon"
                          className="input-icon"
                          height={21}
                          width={25}
                          style={{ left: "3%" }}
                        />
                        <input
                          type="date"
                          id="evnetdatePicker2"
                          // onChange={handleDateChange} // Handle date change
                          values={pd.promotionDate}
                          onChange={(e) => {
                            handleChangePromotionDetails(
                              pd.id,
                              "promotionDate",
                              e.target.value
                            );
                          }}
                          onFocus={() =>
                            document
                              .getElementById("evnetdatePicker2")
                              .showPicker()
                          } // Open calendar when the field is focused
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* promotion time */}
                  <div
                    className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-4"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventform2">
                      <div className="input-with-icon" onClick={handleClick}>
                        <img
                          src={StopwatchIcon}
                          alt="Name Icon"
                          className="input-icon"
                          height={25}
                          width={25}
                          style={{ left: "3%" }}
                        />
                        <input
                          id="timeInput"
                          type="text"
                          placeholder="20:00"
                          value={time}
                          readOnly
                          style={{ cursor: "pointer" }} // Change cursor to pointer
                        />
                        <input
                          type="time"
                          id="eventTimePicker2"
                          value={time}
                          // onChange={handleTimeChange}
                          values={pd.promotionTime}
                          onChange={(e) => {
                            handleChangePromotionDetails(
                              pd.id,
                              "promotionTime",
                              e.target.value
                            );
                          }}
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            opacity: 0,
                            cursor: "pointer",
                          }} // Hide the time input
                        />
                      </div>
                    </div>
                  </div>

                  {/* promotion description */}
                  <div
                    className="col-12 col-lg-12 d-flex flex-column align-items-center justify-content-between gap-4 mt-lg-3"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div className="form-group ms-3" id="eventdesc">
                      <div className="input-with-icon">
                        <img
                          src={EventLogo3}
                          alt="Name Icon"
                          className="input-icon"
                          height={30}
                          width={25}
                          style={{ left: "1.5%" }}
                        />
                        <input
                          type="text"
                          id="location"
                          name="location"
                          placeholder="Promotion Description"
                          // value={description}
                          // onChange={handleDescriptionChange}
                          value={pd.promotionDescription}
                          onChange={(e) => {
                            handleChangePromotionDetails(
                              pd.id,
                              "promotionDescription",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <small style={{ color: "#BABABA", fontSize: "14px" }}>
                        {pd.promotionDescription.length}/{maxDescriptionLength}{" "}
                        characters
                      </small>
                    </div>
                  </div>

                  <div
                    className="col-12 col-lg-8 d-flex flex-column align-items-center mt-lg-3"
                    style={{ fontFamily: "Poppins", color: "#BABABA" }}
                    id="contacttWrapper"
                  >
                    <div
                      className="form-group ms-3"
                      id="locationInput"
                      style={{ maxWidth: "100%" }}
                    >
                      <div
                        className="input-with-icon"
                        style={{ maxWidth: "100%" }}
                      >
                        <img
                          src={EventLogo4}
                          alt="Name Icon"
                          className="input-icon"
                          height={25}
                          width={25}
                          style={{ left: "2%" }}
                        />
                        <input
                          type="text"
                          id="location"
                          name="location"
                          placeholder="Promotion Extras : (two free beers on entry)"
                        />
                      </div>
                    </div>
                  </div>

                  {promotionDetailsField.length > 1 && (
                    <div
                      className="col-12 col-lg-4 d-flex flex-column align-items-center mt-2"
                      style={{ fontFamily: "Poppins", color: "#BABABA" }}
                      id="contacttWrapper"
                    >
                      <button
                        className="eventButton ms-3"
                        style={{ backgroundColor: "red" }}
                        onClick={() => handleClickRemoveAnotherPromotion(pd.id)}
                      >
                        REMOVE PROMOTION
                      </button>
                    </div>
                  )}
                </div>
                <hr />
              </>
            ))}
            <div className="row mt-0">
              <div
                className="col-lg-4 d-flex flex-column align-items-center mt-lg-2"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <button
                  className="eventButton ms-3"
                  style={{ width: "100%" }}
                  onClick={handleClickAddAnotherPromotion}
                >
                  ADD ANOTHER PROMOTION
                </button>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {/* <button className="menuButton text-center mt-5">BOOK NOW</button> */}
            <button
              type="submit"
              className="menuButton text-center mt-5"
              disabled={loading || false}
              onClick={handleBookRequest}
            >
              {loading ? "Loading..." : "BOOK NOW"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NightClubBook;
