import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/user.actions";
import AdminSidebar from "../layouts/AdminSidebar";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";

function UpdateDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    userName: current?.user_name || "",
    firstName: current?.first_name || "",
    lastName: current?.last_name || "",
    mobile: current?.mobile_number || "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    toast.dismiss();
    const resp = await webServices.updateDetails(current.id, values);
    if (resp.type === "success") {
      setLoading(false);
      dispatch(setUser(resp.payload));
      toast.success(resp.message);
    } else {
      toast.error(resp.errormessage);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(AppConstants.routes.admin.setting);
  };
  return (
    <>
      <div className="change-user-details py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Update User Details:</div>
                <div className="details-wrapper py-lg-4">
                  <div className="sub-title mb-3">
                    Please update your details below:
                  </div>
                  <form
                    onSubmit={handleSubmit}
                    className="my-lg-4 pt-md-2 pt-lg-3"
                    style={{ maxWidth: "600px" }}
                  >
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="userName"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          User Name:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          id="userName"
                          autoComplete="off"
                          required
                          value={values.userName}
                          onChange={(e) =>
                            setValues({ ...values, userName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="firstName"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          First Name:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          autoComplete="off"
                          required
                          value={values.firstName}
                          onChange={(e) =>
                            setValues({ ...values, firstName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-md-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="lastName"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          Last Name:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          autoComplete="off"
                          required
                          value={values.lastName}
                          onChange={(e) =>
                            setValues({ ...values, lastName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap align-items-center mb-3 mb-lg-4">
                      <div className="col-xs-12 col-md-3">
                        <label
                          htmlFor="mobNumber"
                          className="fs-md-18 mt-md-1 nb-1"
                        >
                          Mobile:
                        </label>
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          id="mobNumber"
                          autoComplete="off"
                          required
                          value={values.mobile}
                          onChange={(e) =>
                            setValues({ ...values, mobile: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="row pt-3" style={{ justifyContent: "end" }}>
                      <div className="col-xs-12 col-md-offset-3 col-md-9">
                        <div className="btn-wrapper d-flex flex-wrap justify-content-between gap-3 mb-2">
                          <button
                            type="reset"
                            className="btn btn-custom flex-fill"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-custom flex-fill"
                            disabled={loading || false}
                          >
                            {loading ? "Updating..." : "Update"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateDetails;
