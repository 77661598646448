// DinningHomePage.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// gotta eat here images
import cairnsRestaurnats from "../../assets/images/gotta-eat-here/cairns-restaurnats.jpg";
import goldCoastRestaurants from "../../assets/images/gotta-eat-here/gold-coast-restaurants.png";
import portDouglasRestaurants from "../../assets/images/gotta-eat-here/port-douglas-restaurants.jpg";
import sunshineCoastRestaurants from "../../assets/images/gotta-eat-here/sunshine-coast-restaurants.jpg";
// experienct of food images
import chineseCuisine from "../../assets/images/experiences/chinese-cuisine.jpg";
import frenchCuisine from "../../assets/images/experiences/french-cuisine.jpeg";
import germanCuisine from "../../assets/images/experiences/german-cuisine.jpg";
import italianCuisine from "../../assets/images/experiences/italian-cuisine.png";
import spanishCuisine from "../../assets/images/experiences/spanish-cuisine.jpg";
import vietnameseCuisine from "../../assets/images/experiences/vietnamese-cuisine.jpg";
import thaiCuisine from "../../assets/images/experiences/thai-cuisine.jpg";
// import steakhouse from "../../assets/images/experiences/steakhouse.jpg";

import asian from "../../assets/images/experiences/asian.jpg";
import australiancrusine from "../../assets/images/experiences/australiancrusine.jpg";
import icecream from "../../assets/images/experiences/icecream.jpg";
import indiancrusine from "../../assets/images/experiences/indiancrusine.jpg";
import thaicrusine from "../../assets/images/experiences/thaicrusine.jpg";
import mediterranean from "../../assets/images/experiences/mediterranean.jpg";



import BannerComponent from "../BannerComponent";
import BannerSubComponent from "../BannerSubComponent";
import StarRating from "../common/StarRating";

// banners images

import { fetchData } from "../../services";
import { generateSku } from "../util/utils";
import TestimonialsComponent from "../TestimonialsComponent";
import AppConstants from "../../appConstants";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1200,
    },
    items: 4,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 575,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile1: {
    breakpoint: {
      max: 991,
      min: 576,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1199,
      min: 992,
    },
    items: 3,
    partialVisibilityGutter: 30,
  },
};

const GOTTAEATHERE = [
  {
    name: "Gold Coast",
    sku: "/gold-coast",
    image: goldCoastRestaurants,
    banners: [goldCoastRestaurants],
  },
  {
    name: "Cairns",
    sku: "/cairns",
    image: cairnsRestaurnats,
    banners: [cairnsRestaurnats],
  },
  {
    name: "Port Douglas",
    sku: "/port-douglas",
    image: portDouglasRestaurants,
    banners: [portDouglasRestaurants],
  },
  {
    name: "Sunshine Coast",
    sku: "/sunshine-coast",
    image: sunshineCoastRestaurants,
    banners: [sunshineCoastRestaurants],
  },
  {
    name: "Gold Coast",
    sku: "/gold-coast",
    image: goldCoastRestaurants,
    banners: [goldCoastRestaurants],
  },
];

// const LOVEEXPERIENCE = [
//   {
//     name: "French Cuisine",
//     sku: "/french-cuisine",
//     image: frenchCuisine,
//     banners: [frenchCuisine],
//   },
//   {
//     name: "Italian Cuisine",
//     sku: "/italian-cuisine",
//     image: italianCuisine,
//     banners: [italianCuisine],
//   },
//   {
//     name: "Spanish Cuisine",
//     sku: "/spanish-cuisine",
//     image: spanishCuisine,
//     banners: [spanishCuisine],
//   },
//   {
//     name: "Steakhouse",
//     sku: "/steakhouse",
//     image: steakhouse,
//     banners: [steakhouse],
//   },
//   {
//     name: "Chinese Cuisine",
//     sku: "/chinese-cuisine",
//     image: chineseCuisine,
//     banners: [chineseCuisine],
//   },
//   {
//     name: "Thai Cuisine",
//     sku: "/thai-cuisine",
//     image: thaiCuisine,
//     banners: [thaiCuisine],
//   },
// ];

const LOVEEXPERIENCE = [
  {
    name: "Australian Cuisine",
    sku: "/australian-cuisine",
    image: australiancrusine,
    banners: [australiancrusine],
  },
  {
    name: "Mediterranean Cuisine",
    sku: "/mediterranean-cuisine",
    image: mediterranean,
    banners: [mediterranean],
  },
  {
    name: "Indian Cuisine",
    sku: "/indian-cuisine",
    image: indiancrusine,
    banners: [indiancrusine],
  },
  {
    name: "Thai Cuisine",
    sku: "/thai-cuisine",
    image: thaicrusine,
    banners: [thaicrusine],
  },
  {
    name: "Asian Cuisine",
    sku: "/asian-cuisine",
    image: asian,
    banners: [asian],
  },
  {
    name: "Ice Cream",
    sku: "/ice-cream",
    image: icecream,
    banners: [icecream],
  },
 
 
];

function DinningHomePage() {
  const navigate = useNavigate();
  const [banner, setBanner] = useState([]);
  const [australianCuisine, setAustralianCuisine] = useState([]);
  const [itilianCuisine, setItilianCuisine] = useState([]);
  const [thaiCuisine, setThaiCuisine] = useState([]);

  useEffect(() => {
    async function getData() {
      const resp = await fetchData("getRestaurantHomeData");
      if (resp.type === "success") {
        setBanner(resp.payload.banners);
        setAustralianCuisine(resp.payload.australianCuisine);
        setItilianCuisine(resp.payload.itilianCuisine);
        setThaiCuisine(resp.payload.thaiCuisine);
      }
    }
    getData();
  }, []);

  const handleClickBooking = (tag, item) => {
    const name = generateSku(item.name);
    navigate(`/dining/${name}`, { state: { restaurantId: item.id } });
  };

  const handleClickViewList = (item) => {
    navigate(`/dining/sub-page${item.sku}`, {
      state: { title: item.name, banners: item.banners },
    });
  };

  return (
    <>
      {/* BANNER */}
      <BannerComponent
        banner={banner}
        title="TOUCH - iT"
        subTitle="Taste it all"
        button={{ title: "Search", link: AppConstants.routes.dinningSearch }}
      />

      {/* BEST PRICE GUARANTEED */}
      <BannerSubComponent />

      {/* INTERNATIONAL CUISINE */}
      <section className="top-experiences te py-5">
        <div className="container">
          <h1 className="heading mt-4">International Cuisine</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {australianCuisine.map((el, index) => {
                return (
                  <div className="item col-12" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() =>
                            handleClickBooking("australian-cuisine", el)
                          }
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() =>
                          handleClickBooking("australian-cuisine", el)
                        }
                      >
                        {el.name}
                      </h4>
                      <StarRating rating={el?.total_ratings} />
                      <span className="price">
                        <ins>Based on {el?.reviews} Reviews</ins>
                      </span>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* CUISINE YOU'LL LOVE */}
      <section className="top-experiences take-me-there experiences py-5">
        <div className="container">
          <h1 className="heading mt-4">Cuisine you'll love</h1>
          <div className="row g-4 mt-0">
            {LOVEEXPERIENCE.map((el, index) => {
              return (
                <div
                  className="col-lg-4 col-md-4 col-sm-6 col-xs-6"
                  key={index}
                >
                  {/* <Link to={el.sku}> */}
                  <div
                    className="join-blocks cursor-pointer"
                    onClick={() => handleClickViewList(el)}
                  >
                    <div className="join-blocks-img">
                      <img src={el.image} className="img-fluid" alt={el.name} />
                    </div>
                    <h4>{el.name}</h4>
                  </div>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* CAFE DINING */}
      <section className="top-experiences top-deals py-5">
        <div className="container">
          <h1 className="heading mt-4">Cafe Dining</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {itilianCuisine.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() =>
                            handleClickBooking("itilian-cuisine", el)
                          }
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() =>
                          handleClickBooking("itilian-cuisine", el)
                        }
                      >
                        {el.name}
                      </h4>
                      <StarRating rating={el?.total_ratings} />
                      <span className="price">
                        <ins>Based on {el?.reviews} Reviews</ins>
                      </span>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {/* TESTIMONIALS */}
      <section className="top-experiences testimonials py-5">
        <TestimonialsComponent />
      </section>

      {/* POPULAR EATS */}
      <section className="top-experiences top-deals pt-5 pb-4">
        <div className="container">
          <h1 className="heading">Popular Eats</h1>
          <div className="carousel-wrapper mt-4">
            <Carousel
              autoPlay={true}
              autoPlaySpeed={3000}
              infinite={true}
              responsive={responsive}
            >
              {thaiCuisine.map((el, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="join-blocks">
                      <div className="join-blocks-img">
                        <img
                          src={el.profile_photo}
                          className="img-fluid cursor-pointer"
                          alt={el.name}
                          onClick={() => handleClickBooking("thai-cuisine", el)}
                        />
                      </div>
                      <h4
                        className="cursor-pointer"
                        onClick={() => handleClickBooking("thai-cuisine", el)}
                      >
                        {el.name}
                      </h4>
                      <StarRating rating={el?.total_ratings} />
                      <span className="price">
                        <ins>Based on {el?.reviews} Reviews</ins>
                      </span>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
}

export default DinningHomePage;
