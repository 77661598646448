import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { State } from "country-state-city";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../layouts/AdminSidebar";
import { handleNumericInput, validateEmail } from "../util/utils";
import { authCall } from "../../services";
import AppConstants from "../../appConstants";

const USERTYPE = [
  { value: "Licensee", label: "Licensee" },
  { value: "Promoter", label: "Promoter" },
  { value: "Advertiser", label: "Advertiser" },
  { value: "Designer", label: "Designer" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#bababa",
    border: "2px solid #fff",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007BFF"
      : state.isFocused
      ? "#e0e0e0"
      : "#fff",
    color: state.isSelected ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#000",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    zIndex: 9999,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#383636",
    // fontWeight: 600,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#383636",
  }),
};

function MachineAdd() {
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const { details } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [onboardingLoading, setOnboardingLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [licenceUserList, setLicenceUserList] = useState([]);
  const [selectedLicensee, setSelectedLicensee] = useState("");

  const [values, setValues] = useState({
    machineId: details?.id || "",
    stripeDeviceId: details?.stripe_device_id || "",
    kioskSerialId: details?.kiosk_serial_id || "",
    licenceId: current?.licence_id,
    displayUnitId: details?.display_unit_id,
    venuName: details?.vanue_name || "",
    streetAddress: details?.street_address || "",
    city: details?.city || "",
    state: details?.state || "",
    postCode: details?.post_code || "",
    contactName: details?.contact_name || "",
    contactNumber: details?.contact_number || "",
    contactEmail: details?.contact_email || "",
  });

  useEffect(() => {
    const data = State.getStatesOfCountry("AU");
    const options = data.map((s) => ({
      value: s.name,
      label: s.name,
    }));
    setStates(options);
    setSelectedState(
      details?.state ? { value: details?.state, label: details?.state } : ""
    );
    setSelectedLicensee(
      details?.licence_id
        ? { value: details?.licence_id, label: details?.licence_id }
        : ""
    );
  }, []);

  useEffect(() => {
    async function fetchData() {
      const resp = await authCall("getLicenceUsers");
      if (resp?.type === "success") {
        setLicenceUserList(resp?.payload);
      }
    }
    if (!current?.licence_id) {
      fetchData();
    }
  }, []);

  const validateForm = () => {
    let isNoError = true;
    Object.keys(values).forEach((key) => {
      if (!values[key] && key !== "licenceId" && key !== "machineId") {
        isNoError = false;
      }
    });

    return isNoError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      // upldate machine
      if (details?.id) {
        const res = await authCall("addMachine", values);
        if (res?.type === "success") {
          toast.success(res.message);
          setLoading(false);
          const navi =
            details?.is_archived === 1
              ? AppConstants.routes.admin.archivedMachine
              : AppConstants.routes.admin.listMachine;
          navigate(navi);
        }
      } else {
        setOnboardingLoading(true);
        // const resp = await authCall("create-account-link", {
        //   userId: current?.id,
        //   email: current?.email,
        //   metaData: { details: "Activity provider stripe custom account" },
        //   returnUrl: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.onboardStatus}`,
        //   refreshUrl: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.tour.onboardStatus}`,
        // });
        // if (resp?.type === "success") {
        //   // setLoading(false);
        //   window.location.href = resp?.payload?.accountLink;
        // }

        console.log("values", values);
        // const customAccountData = {
        //   email: values.contactEmail,
        //   metaData: {
        //     licencee_id: current?.licence_id,
        //     kiosk_id: values.kioskId,
        //     venue_name: values.venuName,
        //     contact_name: values.contactName,
        //     contact_number: values.contactNumber,
        //   },
        //   returnUrl:
        //     details?.is_archived === 1
        //       ? "http://localhost:3000/admin/archived-machines"
        //       : "http://localhost:3000/admin/machines",
        // };
        // const resp = await authCall("create-account-link", customAccountData);
        // if (resp?.type === "success") {
        //   // save to database
        //   values.stripeAccountId = resp?.payload?.accountId || "";
        // await authCall("addMachine", values);

        //   window.location.href = resp?.payload?.accountLink;
        // } else {
        //   toast.error(resp.errormessage);
        //   setLoading(false);
        // }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeState = (selected) => {
    setValues({ ...values, state: selected?.label });
    setSelectedState(selected);
  };

  const handleChangeLicensee = (selected) => {
    setValues({ ...values, licenceId: selected?.label });
    setSelectedLicensee(selected);
  };

  const handleChangeZipcode = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 8) {
      setValues({ ...values, postCode: inputValue });
    }
  };

  return (
    <>
      {onboardingLoading && (
        <div className="loader">Loading Stripe onboarding...</div>
      )}
      {!onboardingLoading && (
        <div className="add-machine-page py-4">
          <div className="container custom-container">
            <div className="row">
              <div className="col-sm-4 col-lg-3 mb-4">
                <AdminSidebar />
              </div>
              <div className="col-sm-8 col-lg-9">
                <div className="common-info-card">
                  <div className="heading-title">
                    {details?.id ? "Update" : "Add"} Your Kiosk Machine:
                  </div>
                  <div className="details-wrapper pb-lg-4">
                    <form
                      onSubmit={handleSubmit}
                      className="custom-field-wrapper"
                    >
                      <div className="sub-title mb-3">
                        This will add a kiosk to the network
                      </div>
                      <div className="info-cover mb-2">
                        <div className="main-heading2 mb-2">Kiosk Details:</div>
                        <div className="row mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label
                              htmlFor="stripeDeviceId"
                              className="fs-md-18"
                            >
                              Stripe Device Serial ID:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="stripeDeviceId"
                              autoComplete="off"
                              value={values.stripeDeviceId}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  stripeDeviceId: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "stripe device serial id",
                              values.stripeDeviceId,
                              "required"
                            )}
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="kioskSerialId" className="fs-md-18">
                              Kiosk Serial ID:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="kioskSerialId"
                              autoComplete="off"
                              value={values.kioskSerialId}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  kioskSerialId: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "kiosk serial id",
                              values.kioskSerialId,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="licenceId" className="fs-md-18">
                              Licensee ID:
                            </label>
                            <Select
                              styles={customStyles}
                              className="text-body"
                              placeholder="Select Licensee id"
                              value={selectedLicensee}
                              onChange={handleChangeLicensee}
                              options={licenceUserList}
                            />
                            {validator.current.message(
                              "lisensee id",
                              values.licenceId,
                              "required"
                            )}
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="displayUnitId" className="fs-md-18">
                              Display Unit ID (Broadsign):
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="displayUnitId"
                              autoComplete="off"
                              value={values.displayUnitId}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  displayUnitId: e.target.value,
                                })
                              }
                            />
                            <Link
                              className="fs-12"
                              to="https://broadsign.com/"
                              target="_blank"
                            >
                              Click here to open Broadsign, to get Display Unit
                              ID
                            </Link>
                            {validator.current.message(
                              "unit display id",
                              values.displayUnitId,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>

                      <hr className="divider my-4" />

                      <div className="info-cover mb-2">
                        <div className="main-heading2 mb-2">
                          Address Details:
                        </div>
                        <div className="row mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="venueName" className="fs-md-18">
                              Venue Name:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="venueName"
                              autoComplete="off"
                              value={values.venuName}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  venuName: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "vanue name",
                              values.venuName,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="streetAddress" className="fs-md-18">
                              Street Address:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress"
                              autoComplete="off"
                              value={values.streetAddress}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  streetAddress: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "street address",
                              values.streetAddress,
                              "required"
                            )}
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="city" className="fs-md-18">
                              City:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              autoComplete="off"
                              name="address.city"
                              value={values.city}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  city: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "city",
                              values.city,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="state" className="fs-md-18">
                              State:
                            </label>
                            <Select
                              styles={customStyles}
                              className="text-body"
                              placeholder="Select State"
                              value={selectedState}
                              onChange={handleChangeState}
                              options={states}
                            />
                            {validator.current.message(
                              "state",
                              values.state,
                              "required"
                            )}
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="postCode" className="fs-md-18">
                              Post Code:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="postCode"
                              autoComplete="off"
                              value={values.postCode}
                              onChange={(e) => handleChangeZipcode(e)}
                            />
                            {validator.current.message(
                              "post code",
                              values.postCode,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>

                      <hr className="divider my-4" />

                      <div className="info-cover mb-2">
                        <div className="main-heading2 mb-2">
                          Contact Details:
                        </div>
                        <div className="row mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="contactName" className="fs-md-18">
                              Full Name:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contactName"
                              autoComplete="off"
                              value={values.contactName}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  contactName: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "contact name",
                              values.contactName,
                              "required"
                            )}
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="contactNumber" className="fs-md-18">
                              Mobile Number:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contactNumber"
                              autoComplete="off"
                              value={values.contactNumber}
                              onChange={(e) => {
                                const inputValue = handleNumericInput(
                                  e.target.value
                                );
                                if (
                                  inputValue !== null &&
                                  inputValue.length < 11
                                ) {
                                  setValues({
                                    ...values,
                                    contactNumber: e.target.value,
                                  });
                                }
                              }}
                            />
                            {validator.current.message(
                              "contact mobile number",
                              values?.contactNumber,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor="contactEmail" className="fs-md-18">
                              Email ID:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contactEmail"
                              autoComplete="off"
                              value={values.contactEmail}
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  contactEmail: e.target.value,
                                })
                              }
                            />
                            {validator.current.message(
                              "contact email",
                              values.contactEmail,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>

                      <hr className="divider my-5" />

                      <div className="btn-wrapper text-center mt-4 mb-2 pt-2">
                        <button
                          type="submit"
                          className="btn btn-custom"
                          disabled={loading || false}
                        >
                          {loading
                            ? "Loading..."
                            : details?.id
                            ? "Update"
                            : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MachineAdd;
