import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { QuestionCircle } from "react-bootstrap-icons";
import ActivitySidebar from "../../layouts/TourSidebar";
import VideoTutorialPopUp from "../../admin/VideoTutorialPopUp";
import AppConstants from "../../../appConstants";

const ActivityCompanyTypeVideo = AppConstants.tutorial.activityCompanyType;

function ActivityCompanyType({ handleSubmit, setStep }) {
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    setSelectedOption(companyDetails?.company_type);
  }, [companyDetails]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    if (!selectedOption) {
      toast.error("Please select an option.");
    } else {
      setLoading(true);
      const obj = { profileLavel: 3, companyType: selectedOption };
      handleSubmit(obj, 3);
    }
  };

  const handleClickTutorial = () => {
    setVideoURL(ActivityCompanyTypeVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="company-type-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <ActivitySidebar setStep={setStep} />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>What type of company do you run?</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>

                <div className="details-wrapper">
                  <div className="sub-title">
                    This help us tailer your profile for you
                  </div>
                  <form>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        id="compType1"
                        value="OneOffActivity"
                        checked={selectedOption === "OneOffActivity" || false}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType1"
                      >
                        One Off Activity
                      </label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType2"
                        value="MultipleVariations"
                        checked={
                          selectedOption === "MultipleVariations" || false
                        }
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType2"
                      >
                        Activity With Multiple Variations{" "}
                        <i>(For example Escape Room)</i>
                      </label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType3"
                        value="Tour"
                        checked={selectedOption === "Tour" || false}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType3"
                      >
                        Tour{" "}
                        <i>
                          (An event where you travel from Point A to Point B)
                        </i>
                      </label>
                    </div>
                    {/* <div className="form-group d-flex align-items-center gap-3 mt-4">
                      <input
                        type="radio"
                        className="custom-radio m-0 companyType cursor-pointer"
                        name="companyType"
                        id="compType4"
                        value="TicketOptions"
                        checked={selectedOption === "TicketOptions"}
                        onChange={handleOptionChange}
                      />
                      <label
                        className="form-check-label m-0"
                        htmlFor="compType4"
                      >
                        Activity With Different Ticket Options{" "}
                        <i>(For example a theme park)</i>
                      </label>
                    </div> */}
                    <div className="btn-wrapper text-center pt-2">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={handleSubmitForm}
                        disabled={loading || false}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default ActivityCompanyType;
