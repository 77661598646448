import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AppConstants from "../../appConstants";
const AdminSidebar = () => {
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const location = useLocation();
  const { pathname } = location;
  const [activityDetails, setActivityDetails] = useState({});
  const [progress, setProgress] = useState(12);

  const isDashboardActive = [AppConstants.routes.admin.dashboard].includes(
    pathname
  );

  const isRunAnAddActive = [AppConstants.routes.admin.runAdd].includes(
    pathname
  );

  //  Restaurant edit list active
  const isEditListActive = [
    AppConstants.routes.admin.updateRestaurantDetails,
    AppConstants.routes.admin.breakfastMenu,
    AppConstants.routes.admin.lunchMenu,
    AppConstants.routes.admin.dinnerMenu,
  ].includes(pathname);

  const isRestDetailsActive = [
    AppConstants.routes.admin.updateRestaurantDetails,
  ].includes(pathname);

  const isBreakfastActive = [AppConstants.routes.admin.breakfastMenu].includes(
    pathname
  );

  const isLunchActive = [AppConstants.routes.admin.lunchMenu].includes(
    pathname
  );

  const isDinnerActive = [AppConstants.routes.admin.dinnerMenu].includes(
    pathname
  );

  const isSettingActive = [AppConstants.routes.admin.setting].includes(
    pathname
  );

  // Night lift Edit List Active
  const isEditListNightActive = [
    AppConstants.routes.admin.updateVenueDtails,
    AppConstants.routes.admin.updateEventDtails,
    AppConstants.routes.admin.eventList,
  ].includes(pathname);

  const isVenueDetailsActive = [
    AppConstants.routes.admin.updateVenueDtails,
  ].includes(pathname);

  const isEventDetailsActive = [AppConstants.routes.admin.eventList].includes(
    pathname
  );

  // Activities edit list active
  const isActivityEditListingActive = [
    AppConstants.routes.admin.activityEditListing,
    AppConstants.routes.admin.updateCostPerson,
    AppConstants.routes.admin.updateCompanyDetails,
    AppConstants.routes.admin.updateStops,
    AppConstants.routes.admin.updateLocationDetails,
  ].includes(pathname);

  const isEditListActivityActive = [
    AppConstants.routes.admin.updateCostPerson,
    AppConstants.routes.admin.updateCompanyDetails,
    AppConstants.routes.admin.updateStops,
    AppConstants.routes.admin.updateLocationDetails,
  ].includes(pathname);

  // Ads Active
  const isAdsActive = [
    AppConstants.routes.admin.createAds,
    AppConstants.routes.admin.listAds,
    AppConstants.routes.admin.adsReports,
  ].includes(pathname);

  const isCreateAdsActive = [AppConstants.routes.admin.createAds].includes(
    pathname
  );

  const isListAdsActive = [
    AppConstants.routes.admin.listAds,
    AppConstants.routes.admin.adsReports,
  ].includes(pathname);

  const isCostPersonActive = [
    AppConstants.routes.admin.updateCostPerson,
  ].includes(pathname);

  const isCompanyDetailsActive = [
    AppConstants.routes.admin.updateCompanyDetails,
  ].includes(pathname);

  const isStopActive = [AppConstants.routes.admin.updateStops].includes(
    pathname
  );

  const isLocationDetailsActive = [
    AppConstants.routes.admin.updateLocationDetails,
  ].includes(pathname);

  // Checking active
  // Ads Active
  const isCheckingActive = [AppConstants.routes.admin.tags].includes(pathname);

  const isTagsActive = [AppConstants.routes.admin.tags].includes(pathname);

  // admin events Active
  const isAdminEventActive = [
    AppConstants.routes.admin.addEvents,
    AppConstants.routes.admin.updateEvents,
    AppConstants.routes.admin.liveEvents,
    AppConstants.routes.admin.archivedEvents,
  ].includes(pathname);

  const isCreateAdminEventActive = [
    AppConstants.routes.admin.addEvents,
  ].includes(pathname);
  const isLiveEventActive = [AppConstants.routes.admin.liveEvents].includes(
    pathname
  );
  const isArchivedEventActive = [
    AppConstants.routes.admin.archivedEvents,
  ].includes(pathname);

  useEffect(() => {
    const storedData = localStorage.getItem("productDetails");
    if (storedData) {
      setActivityDetails(JSON.parse(storedData));
    }
  }, []);

  const handleClickMenu = (url, type) => {
    navigate(url, { state: { type } });
  };

  const SidebarList = [
    // DASHBOARD
    {
      menuName: "Dashboard",
      userTypes: ["Admin", "Super Admin", "Restaurant", "Bar", "Activity"],
      isMenuActive: [AppConstants.routes.admin.dashboard].includes(pathname),
      link: AppConstants.routes.admin.dashboard,
      isSubMenu: false,
    },
    // RESTAURANT
    /*{
      menuName: "Edit Listing",
      userTypes: ["Restaurant"],
      isMenuActive: [
        AppConstants.routes.admin.updateCostPerson,
        AppConstants.routes.admin.updateCompanyDetails,
        AppConstants.routes.admin.updateStops,
        AppConstants.routes.admin.updateLocationDetails,
      ].includes(pathname),
      isSubMenu: true,
      subMenu: [
        {
          subMenuName: "Restaurant Details",
          isMenuActive: [
            AppConstants.routes.admin.updateRestaurantDetails,
          ].includes(pathname),
          link: AppConstants.routes.admin.updateRestaurantDetails,
          type: "",
        },
        {
          subMenuName: "Breakfast Menu",
          isMenuActive: [AppConstants.routes.admin.breakfastMenu].includes(
            pathname
          ),
          link: AppConstants.routes.admin.breakfastMenu,
          type: "Breakfast",
        },
        {
          subMenuName: "Lunch Menu",
          isMenuActive: [AppConstants.routes.admin.lunchMenu].includes(
            pathname
          ),
          link: AppConstants.routes.admin.lunchMenu,
          type: "Lunch",
        },
        {
          subMenuName: "Dinner Menu",
          isMenuActive: [AppConstants.routes.admin.dinnerMenu].includes(
            pathname
          ),
          link: AppConstants.routes.admin.dinnerMenu,
          type: "Dinner",
        },
      ],
    },*/
    {
      menuName: "Edit Listing",
      userTypes: ["Restaurant"],
      isMenuActive: [
        AppConstants.routes.admin.restaurantEditListing,
        AppConstants.routes.admin.updateRestaurantDetails,
        AppConstants.routes.admin.updateRestaurantCategory,
        AppConstants.routes.admin.updateRestaurantMenu,
      ].includes(pathname),
      link: AppConstants.routes.admin.restaurantEditListing,
      isSubMenu: false,
    },

    // NIGHTLIFE
    {
      menuName: "Edit Listing",
      userTypes: ["Bar"],
      isMenuActive: [
        AppConstants.routes.admin.nightlifeEditListing,
        AppConstants.routes.admin.nightlifeUpdateCategory,
        AppConstants.routes.admin.nightlifeUpdateVanueDetails,
        AppConstants.routes.admin.updateEventDtails,
      ].includes(pathname),
      link: AppConstants.routes.admin.nightlifeEditListing,
      isSubMenu: false,
    },
    // {
    //   menuName: "Edit Listing",
    //   userTypes: ["Bar"],
    //   isMenuActive: [
    //     AppConstants.routes.admin.updateVenueDtails,
    //     AppConstants.routes.admin.updateEventDtails,
    //     AppConstants.routes.admin.eventList,
    //   ].includes(pathname),
    //   isSubMenu: true,
    //   subMenu: [
    //     {
    //       subMenuName: "Venue Details",
    //       isMenuActive: [AppConstants.routes.admin.updateVenueDtails].includes(
    //         pathname
    //       ),
    //       link: AppConstants.routes.admin.updateVenueDtails,
    //       type: "",
    //     },
    //     {
    //       subMenuName: "Events List",
    //       isMenuActive: [AppConstants.routes.admin.eventList].includes(
    //         pathname
    //       ),
    //       link: AppConstants.routes.admin.eventList,
    //       type: "",
    //     },
    //   ],
    // },
    // ACTIVITY
    {
      menuName: "Edit Listing",
      userTypes: ["Activity"],
      isMenuActive: [
        AppConstants.routes.admin.activityEditListing,
        AppConstants.routes.admin.updateCostPerson,
        AppConstants.routes.admin.updateCompanyDetails,
        AppConstants.routes.admin.updateStops,
        AppConstants.routes.admin.updateLocationDetails,
      ].includes(pathname),
      link: AppConstants.routes.admin.activityEditListing,
      isSubMenu: false,
    },
    // RUNNING ADS
    {
      menuName: "Ads",
      userTypes: ["Activity", "Restaurant", "Bar"],
      isMenuActive: [
        AppConstants.routes.admin.createAds,
        AppConstants.routes.admin.listAds,
        AppConstants.routes.admin.adsReports,
        AppConstants.routes.admin.selectScreen,
        AppConstants.routes.admin.uploadContent,
      ].includes(pathname),
      isSubMenu: true,
      subMenu: [
        {
          subMenuName: " Create an Ad",
          isMenuActive: [
            AppConstants.routes.admin.createAds,
            AppConstants.routes.admin.selectScreen,
            AppConstants.routes.admin.uploadContent,
          ].includes(pathname),
          link: AppConstants.routes.admin.createAds,
          type: "",
        },
        {
          subMenuName: "List Ads",
          isMenuActive: [AppConstants.routes.admin.listAds].includes(pathname),
          link: AppConstants.routes.admin.listAds,
          type: "",
        },
      ],
    },
    // BILLING
    // {
    //   menuName: "Billing",
    //   userTypes: ["Activity", "Bar", "Restaurant"],
    //   isMenuActive: [].includes(pathname),
    //   link: "",
    //   isSubMenu: false,
    // },
    // SETTING
    {
      menuName: "Setting",
      userTypes: ["Activity", "Bar", "Restaurant"],
      isMenuActive: [
        AppConstants.routes.admin.setting,
        AppConstants.routes.admin.updateDetails,
        AppConstants.routes.admin.changeEmail,
        AppConstants.routes.admin.changePassword,
        AppConstants.routes.admin.updateActivityPrice,
      ].includes(pathname),
      link: AppConstants.routes.admin.setting,
      isSubMenu: false,
    },
    // CHECKING
    {
      menuName: "Checking",
      userTypes: ["Admin"],
      isMenuActive: [
        AppConstants.routes.admin.tags,
        AppConstants.routes.admin.activityList,
        AppConstants.routes.admin.nightLifeList,
        AppConstants.routes.admin.restaurantList,
        AppConstants.routes.admin.nightlifeEvents,
        AppConstants.routes.admin.planSubscription,
        AppConstants.routes.admin.adminUpdateCostPerson,
        AppConstants.routes.admin.adminUpdateCompanyDetails,
        AppConstants.routes.admin.adminUpdateStopDetails,
        AppConstants.routes.admin.adminUpdateLocationDetails,
        AppConstants.routes.admin.adminUpdateCompanyCategory,
        // restaurnt update
        AppConstants.routes.admin.updateRestaurantDetails,
        AppConstants.routes.admin.updateRestaurantCategory,
        AppConstants.routes.admin.updateRestaurantMenu,
        //nightlife update
        AppConstants.routes.admin.nightlifeUpdateCategory,
        AppConstants.routes.admin.nightlifeUpdateVanueDetails,
        AppConstants.routes.admin.nightlifeUpdateMenu,
        AppConstants.routes.admin.updateEventDtails,
      ].includes(pathname),
      isSubMenu: true,
      subMenu: [
        {
          subMenuName: "Activity Listing",
          isMenuActive: [
            AppConstants.routes.admin.activityList,
            AppConstants.routes.admin.adminUpdateCostPerson,
            AppConstants.routes.admin.adminUpdateCompanyDetails,
            AppConstants.routes.admin.adminUpdateStopDetails,
            AppConstants.routes.admin.adminUpdateLocationDetails,
            AppConstants.routes.admin.adminUpdateCompanyCategory,
          ].includes(pathname),
          link: AppConstants.routes.admin.activityList,
          type: "",
        },
        {
          subMenuName: "Restaurant Listing",
          isMenuActive: [
            AppConstants.routes.admin.restaurantList,
            AppConstants.routes.admin.updateRestaurantDetails,
            AppConstants.routes.admin.updateRestaurantCategory,
            AppConstants.routes.admin.updateRestaurantMenu,
          ].includes(pathname),
          link: AppConstants.routes.admin.restaurantList,
          type: "",
        },
        {
          subMenuName: "Nightlife Listing",
          isMenuActive: [
            AppConstants.routes.admin.nightLifeList,
            // nightlife update
            AppConstants.routes.admin.nightlifeUpdateCategory,
            AppConstants.routes.admin.nightlifeUpdateVanueDetails,
            AppConstants.routes.admin.nightlifeUpdateMenu,
            AppConstants.routes.admin.updateEventDtails,
          ].includes(pathname),
          link: AppConstants.routes.admin.nightLifeList,
          type: "",
        },
        {
          subMenuName: "Event Listing",
          isMenuActive: [AppConstants.routes.admin.nightlifeEvents].includes(
            pathname
          ),
          link: AppConstants.routes.admin.nightlifeEvents,
          type: "",
        },
        {
          subMenuName: "Tags",
          isMenuActive: [AppConstants.routes.admin.tags].includes(pathname),
          link: AppConstants.routes.admin.tags,
          type: "",
        },
        {
          subMenuName: "Plans & Subscriptions",
          isMenuActive: [AppConstants.routes.admin.planSubscription].includes(
            pathname
          ),
          link: AppConstants.routes.admin.planSubscription,
          type: "",
        },
      ],
    },
    // ADMIN ADS
    {
      menuName: "Ads",
      userTypes: ["Admin", "Super Admin"],
      isMenuActive: [
        AppConstants.routes.admin.createAds,
        AppConstants.routes.admin.listAds,
      ].includes(pathname),
      isSubMenu: true,
      subMenu: [
        {
          subMenuName: "Submit An Ad",
          isMenuActive: [AppConstants.routes.admin.createAds].includes(
            pathname
          ),
          link: AppConstants.routes.admin.createAds,
          type: "",
        },
        {
          subMenuName: "Live Ads",
          isMenuActive: [AppConstants.routes.admin.listAds].includes(pathname),
          link: AppConstants.routes.admin.listAds,
          type: "",
        },
      ],
    },
    // MACHINE
    {
      menuName: "Machines",
      userTypes: ["Admin", "Super Admin"],
      isMenuActive: [
        AppConstants.routes.admin.addMachine,
        AppConstants.routes.admin.listMachine,
        AppConstants.routes.admin.archivedMachine,
      ].includes(pathname),
      isSubMenu: true,
      subMenu: [
        {
          subMenuName: "Add Machine",
          isMenuActive: [AppConstants.routes.admin.addMachine].includes(
            pathname
          ),
          link: AppConstants.routes.admin.addMachine,
          type: "",
        },
        {
          subMenuName: "List Machine",
          isMenuActive: [AppConstants.routes.admin.listMachine].includes(
            pathname
          ),
          link: AppConstants.routes.admin.listMachine,
          type: "",
        },
        {
          subMenuName: "Archived Machine",
          isMenuActive: [AppConstants.routes.admin.archivedMachine].includes(
            pathname
          ),
          link: AppConstants.routes.admin.archivedMachine,
          type: "",
        },
      ],
    },
    // WHATS'S ON
    {
      menuName: "Whats's On",
      userTypes: ["Admin"],
      isMenuActive: [
        AppConstants.routes.admin.addEvents,
        AppConstants.routes.admin.updateEvents,
        AppConstants.routes.admin.liveEvents,
        AppConstants.routes.admin.archivedEvents,
        AppConstants.routes.admin.importEvents
      ].includes(pathname),
      isSubMenu: true,
      subMenu: [
        {
          subMenuName: "Add Events",
          isMenuActive: [AppConstants.routes.admin.addEvents].includes(
            pathname
          ),
          link: AppConstants.routes.admin.addEvents,
          type: "",
        },
        {
          subMenuName: "Import Events",
          isMenuActive: [AppConstants.routes.admin.importEvents].includes(
            pathname
          ),
          link: AppConstants.routes.admin.importEvents,
          type: "",
        },
        {
          subMenuName: "Live Events",
          isMenuActive: [AppConstants.routes.admin.liveEvents].includes(
            pathname
          ),
          link: AppConstants.routes.admin.liveEvents,
          type: "",
        },
        {
          subMenuName: " Archived Events",
          isMenuActive: [AppConstants.routes.admin.archivedEvents].includes(
            pathname
          ),
          link: AppConstants.routes.admin.archivedEvents,
          type: "",
        },
      ],
    },
    // USERS
    {
      menuName: "Users",
      userTypes: ["Admin", "Super Admin"],
      isMenuActive: [
        AppConstants.routes.admin.users,
        AppConstants.routes.admin.userAdd,
        AppConstants.routes.admin.userUpdate,
      ].includes(pathname),
      link: AppConstants.routes.admin.users,
      isSubMenu: false,
    },
    // USERS
    {
      menuName: "Upload Content",
      userTypes: ["Admin", "Designer"],
      isMenuActive: [AppConstants.routes.admin.desigUploadContent].includes(
        pathname
      ),
      link: AppConstants.routes.admin.desigUploadContent,
      isSubMenu: false,
    },
  ];

  return (
    <>
      <div className="progrss-bar-sec p-4">
        <div className="menu-wrapper mb-3">
          {SidebarList.map((el, index) => {
            if (el.userTypes.includes(current?.user_type)) {
              return (
                <div key={index} className="dropdown mt-2">
                  {el?.isSubMenu ? (
                    <>
                      <Link
                        className={`${
                          el.isMenuActive ? "active" : ""
                        } btn-link`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#menuId${index}`}
                        aria-expanded="true"
                        aria-controls={`menuId${index}`}
                      >
                        {el.menuName}
                      </Link>

                      <div
                        id={`menuId${index}`}
                        className={`${el.isMenuActive ? "show" : ""} collapse`}
                      >
                        <ul>
                          {el.subMenu.map((sub, ind) => (
                            <li key={ind}>
                              <span
                                onClick={() =>
                                  handleClickMenu(sub.link, sub.type)
                                }
                              >
                                <Link
                                  className={`${
                                    sub.isMenuActive ? "active" : ""
                                  } btn-link`}
                                >
                                  {sub.subMenuName}
                                </Link>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <Link
                      className={`${el.isMenuActive ? "active" : ""} btn-link`}
                      to={el.link}
                    >
                      {el.menuName}
                    </Link>
                  )}
                </div>
              );
            }
          })}

          {/* Restaurant edit listing */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isEditListActive ? "active" : ""} btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#editListing"
              aria-expanded="true"
              aria-controls="editListing"
            >
              Edit Listing
            </Link>
            <div
              id="editListing"
              className={`${isEditListActive ? "show" : ""} collapse`}
            >
              <ul>
                <li>
                  <Link
                    className={`${
                      isRestDetailsActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.updateRestaurantDetails}
                  >
                    Restaurant Details
                  </Link>
                </li>
                <li>
                  <span
                    onClick={() =>
                      handleClickMenu(
                        AppConstants.routes.admin.breakfastMenu,
                        "Breakfast"
                      )
                    }
                  >
                    <Link
                      className={`${
                        isBreakfastActive ? "active" : ""
                      } btn-link`}
                    >
                      Breakfast Menu
                    </Link>
                  </span>
                </li>
                <li>
                  <span
                    onClick={() =>
                      handleClickMenu(
                        AppConstants.routes.admin.lunchMenu,
                        "Lunch"
                      )
                    }
                  >
                    <Link
                      className={`${isLunchActive ? "active" : ""} btn-link`}
                    >
                      Lunch Menu
                    </Link>
                  </span>
                </li>
                <li>
                  <span
                    onClick={() =>
                      handleClickMenu(
                        AppConstants.routes.admin.dinnerMenu,
                        "Dinner"
                      )
                    }
                  >
                    <Link
                      className={`${isDinnerActive ? "active" : ""} btn-link`}
                    >
                      Dinner Menu
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Night life edit listing */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isEditListNightActive ? "active" : ""} btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#editListing"
              aria-expanded="true"
              aria-controls="editListing"
            >
              Edit Listing
            </Link>
            <div
              id="editListing"
              className={`${isEditListNightActive ? "show" : ""} collapse`}
            >
              <ul>
                <li>
                  <Link
                    className={`${
                      isVenueDetailsActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.updateVenueDtails}
                  >
                    Venue Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      isEventDetailsActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.eventList}
                  >
                    Events List
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Activities edit listing */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isEditListActivityActive ? "active" : ""} btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#editListing"
              aria-expanded="true"
              aria-controls="editListing"
            >
              Edit Listing
            </Link>
            <div
              id="editListing"
              className={`${isEditListActivityActive ? "show" : ""} collapse`}
            >
              <ul>
                <li>
                  <Link
                    className={`${isCostPersonActive ? "active" : ""} btn-link`}
                    to={AppConstants.routes.admin.updateCostPerson}
                  >
                    Cost Per Person
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      isCompanyDetailsActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.updateCompanyDetails}
                  >
                    Tour Details
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`${
                      isStopActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.updateStops}
                  >
                    Stops
                  </Link>
                </li> */}
                <li>
                  <Link
                    className={`${
                      isLocationDetailsActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.updateLocationDetails}
                  >
                    Location Details
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="dropdown mt-2 d-none">
            <Link
              className={`${
                isActivityEditListingActive ? "active" : ""
              } btn-link`}
              to={AppConstants.routes.admin.activityEditListing}
            >
              Edit Listing
            </Link>
          </div>

          {/* Ads */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isAdsActive ? "active" : ""} btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#ads"
              aria-expanded="true"
              aria-controls="ads"
            >
              Ads
            </Link>
            <div id="ads" className={`${isAdsActive ? "show" : ""} collapse`}>
              <ul>
                <li>
                  <Link
                    className={`${isCreateAdsActive ? "active" : ""} btn-link`}
                    to={AppConstants.routes.admin.createAds}
                  >
                    Create an Ad
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${isListAdsActive ? "active" : ""} btn-link`}
                    to={AppConstants.routes.admin.listAds}
                  >
                    List Ads
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* setting */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isSettingActive ? "active" : ""} btn-link`}
              to={AppConstants.routes.admin.setting}
            >
              Settings
            </Link>
          </div>

          {/* Machine */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#machines"
              aria-expanded="true"
              aria-controls="machines"
            >
              Machines
            </Link>
            <div id="machines" className={`collapse`}>
              <ul>
                <li>
                  <Link
                    className={`btn-link`}
                    to={AppConstants.routes.admin.addMachine}
                  >
                    Add Machine
                  </Link>
                </li>
                <li>
                  <Link
                    className={`btn-link`}
                    to={AppConstants.routes.admin.listMachine}
                  >
                    List Machine
                  </Link>
                </li>
                <li>
                  <Link
                    className={`btn-link`}
                    to={AppConstants.routes.admin.archivedMachine}
                  >
                    Archived Machine
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Checking */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isCheckingActive ? "active" : ""} btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#checking"
              aria-expanded="true"
              aria-controls="machines"
            >
              Checking
            </Link>
            <div
              id="checking"
              className={`${isCheckingActive ? "show" : ""} collapse`}
            >
              <ul>
                <li>
                  <Link
                    className={`${isTagsActive ? "active" : ""} btn-link`}
                    to={AppConstants.routes.admin.tags}
                  >
                    Tags
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* What's On */}
          <div className="dropdown mt-2 d-none">
            <Link
              className={`${isAdminEventActive ? "active" : ""} btn-link`}
              data-bs-toggle="collapse"
              data-bs-target="#liveEvents"
              aria-expanded="true"
              aria-controls="liveEvents"
            >
              Whats's On
            </Link>
            <div
              id="liveEvents"
              className={`${isAdminEventActive ? "show" : ""} collapse`}
            >
              <ul>
                <li>
                  <Link
                    className={`${
                      isCreateAdminEventActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.addEvents}
                  >
                    Add Events
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${isLiveEventActive ? "active" : ""} btn-link`}
                    to={AppConstants.routes.admin.liveEvents}
                  >
                    Live Events
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      isArchivedEventActive ? "active" : ""
                    } btn-link`}
                    to={AppConstants.routes.admin.archivedEvents}
                  >
                    Archived Events
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
