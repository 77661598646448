import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const CATEGORIES = [
  {
    name: "arcadeBar",
    label: "Arcade Bar",
  },
  {
    name: "bearGarden",
    label: "Bear Garden",
  },
  {
    name: "cigarBar",
    label: "Cigar Bar",
  },
  {
    name: "cocktailBars",
    label: "Cocktail Bars",
  },
  {
    name: "comedyClubs",
    label: "Comedy Clubs",
  },
  {
    name: "danceBar",
    label: "Dance Bar",
  },
  {
    name: "diveBar",
    label: "Dive Bar",
  },
  {
    name: "duelingInstruments",
    label: "Dueling Instruments",
  },
  {
    name: "hotelBar",
    label: "Hotel Bar",
  },
  {
    name: "irisBars",
    label: "Iris Bars",
  },
  {
    name: "karaokeBar",
    label: "Karaoke Bar",
  },
  {
    name: "liveMusicVenues",
    label: "Live Music Venues",
  },
  {
    name: "nightclub",
    label: "Nightclub",
  },
  {
    name: "nostalgiaBars",
    label: "Nostalgia Bars",
  },
  {
    name: "pubOrTaven",
    label: "Pub or Taven",
  },
  {
    name: "rsl",
    label: "RSL",
  },
  {
    name: "speakeasies",
    label: "Speakeasies",
  },
  {
    name: "specialityBar",
    label: "Speciality Bar",
  },
  {
    name: "sportsBar",
    label: "Sports Bar",
  },
  {
    name: "taven",
    label: "Taven",
  },
  {
    name: "tikiBars",
    label: "Tiki Bars",
  },
  {
    name: "wineBar",
    label: "Wine Bar",
  },
  {
    name: "other",
    label: "Other",
  },
];

function NightlifeCategoryComp({ onSubmit, Sidebar, loading, details, buttons }) {
  const [selectedCategories, setSelectedCategories] = useState("");
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    setSelectedCategories(details?.category || "");
    setOtherValue(details?.category_other || "");
  }, [details]);

  const handleCheckboxChange = (value) => {
    setSelectedCategories(value);
  };

  const handleSubmit = async () => {
    if (selectedCategories === "") {
      toast.dismiss();
      toast.error("Please select category.");
    } else {
      const data = {
        categories: selectedCategories,
        nightlifeCategoryOther: otherValue,
      };
      onSubmit(data);
    }
  };

  return (
    <>
      <div className="category-venue-page py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <Sidebar details={details} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">
                  Which Category Best Represents Your Venue?
                </div>
                <div className="details-wrapper">
                  <div className="sub-title mb-4">
                    Please select the three most relevant categories from the
                    list below:
                  </div>
                  <div action="">
                    <div className="row row-cols-sm-2 row-cols-lg-3">
                      {CATEGORIES.map((cat, index) => (
                        <div
                          key={index}
                          className="form-group d-flex align-items-center gap-3"
                        >
                          <input
                            // type="checkbox"
                            type="radio"
                            className="custom-radio m-0 cursor-pointer"
                            // name={cat.name}
                            name="restaurantCategories"
                            id={cat.name}
                            value={cat.label}
                            checked={selectedCategories === cat.label}
                            onChange={(e) =>
                              handleCheckboxChange(e.target.value)
                            }
                          />
                          <label
                            // className="form-check-label m-0"
                            className="m-0"
                            htmlFor={cat.name}
                          >
                            {cat.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {selectedCategories === "Other" && (
                      <input
                        type="text"
                        className="form-control form-group d-flex align-items-center gap-3"
                        style={{ width: 250 }}
                        id="categoryName"
                        name="categoryName"
                        placeholder="Add Category..."
                        autoComplete="off"
                        value={otherValue}
                        onChange={(e) => setOtherValue(e.target.value)}
                      />
                    )}

                    <div className="btn-wrapper text-center my-2">
                      {buttons.map((button) => (
                        <button
                          key={button.id}
                          onClick={handleSubmit}
                          className="btn btn-custom"
                          disabled={loading || false}
                        >
                          {loading ? "Loading..." : button.label}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NightlifeCategoryComp;
