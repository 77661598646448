import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import AppConstants from "../../appConstants";

const NightLifeSidebar = ({ details }) => {
  const location = useLocation();
  const { pathname } = location;

  const isCategoryActive =
    [AppConstants.routes.nightLife.category].includes(pathname) ||
    details?.progress > 0;

  const isVenueDetailsActive =
    [AppConstants.routes.nightLife.vanueDetails].includes(pathname) ||
    details?.progress > 25;

  const isMenuActive =
    [AppConstants.routes.nightLife.nightLifeMenu].includes(pathname) ||
    details?.progress > 50;

  const isEventDetailsActive =
    [AppConstants.routes.nightLife.eventDetails].includes(pathname) ||
    details?.progress > 50;

  const isNighlifeOptionActive =
    [AppConstants.routes.nightLife.newNighlightOption].includes(pathname) ||
    details?.progress > 75;

  return (
    <>
      {console.log("veeen", details)}
      <div className="progrss-bar-sec p-4">
        <div className="progress-label">
          {`${details?.progress || 0} % Progress`}
        </div>
        <div className="progress-bar-cover mb-3">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${details?.progress}%` }}
            ></div>
          </div>
        </div>
        <div className="menu-wrapper mb-3">
          <ul>
            <li>
              <Link
                to={
                  isCategoryActive ? AppConstants.routes.nightLife.category : ""
                }
                className={`${isCategoryActive ? "active" : ""} btn-link`}
              >
                Venue Category
              </Link>
            </li>

            <li>
              <Link
                to={
                  isVenueDetailsActive
                    ? AppConstants.routes.nightLife.vanueDetails
                    : ""
                }
                className={`${isVenueDetailsActive ? "active" : ""} btn-link`}
              >
                Venue Details
              </Link>
            </li>
            {details?.is_menu_available === "Yes" && (
              <li>
                <Link
                  to={
                    isMenuActive
                      ? AppConstants.routes.nightLife.nightLifeMenu
                      : ""
                  }
                  className={`${isMenuActive ? "active" : ""} btn-link`}
                >
                  Menu
                </Link>
              </li>
            )}
            <li>
              <Link
                to={
                  isEventDetailsActive
                    ? AppConstants.routes.nightLife.eventDetails
                    : ""
                }
                className={`${isEventDetailsActive ? "active" : ""} btn-link`}
              >
                Event Details
              </Link>
            </li>
            <li>
              <Link
                to={
                  isNighlifeOptionActive
                    ? AppConstants.routes.nightLife.newNightlifeOption
                    : ""
                }
                className={`${isNighlifeOptionActive ? "active" : ""} btn-link`}
              >
                Nightlife Option
              </Link>
            </li>
            {/* <li>
              <Link
                to={AppConstants.routes.nightLife.payments}
                className="btn-link"
              >
                Billing
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NightLifeSidebar;
