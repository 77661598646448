import React from "react";
import BannerImg from "../../../../assets/images/site/Banner kiosk img.png";
import Sun from "../../../../assets/images/site/sun 1.png";
import Polygon from "../../../../assets/images/site/Polygon 3.png";
import Underline from "../../../../assets/images/site/72_underline 1.png";
import rectangle3 from "../../../../assets/images/site/Rectangle 31.png";
// import SampleVideo from "../../../../assets/videos/Sample.mp4";
import "./NightClubBanner.scss";
import ContactFormPop from "../../../ContactFormPop";
import { ContactPageComponent } from "../../..";
import ContactPage from "../../../advertisement/ContactPage";
import Register from "../../../Register";
import { useNavigate } from "react-router-dom";

const NightClubBanner = ({ handleScrollToSection }) => {
  const navigate = useNavigate();

  const handlekioskClick = () => {
    const registerSection = document.getElementById("claimkiosk");
    if (registerSection) {
      registerSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section className="banner2" style={{ marginTop: "80px" }}>
        <div
          className="container-fluid"
          id="bannerBackground2"
          style={{ height: "812px" }}
        >
          <div className="row">
            {/* Left Side: Text Section */}
            <div className="col-lg-6 order-md-first">
              <div className="banner2-txt" id="nightfirst">
                {/* Parent div to handle consistent alignment */}
                <div style={{ marginLeft: "30px" }}>
                  {" "}
                  <div className="heading text-start" id="hotelbannerheading">
                    Bring Your Hotel Into The 21st Century
                  </div>
                  <div
                    className="heading text-start mt-4"
                    id="hotelbannerheading2"
                    style={{ zIndex: "10", color: "#FFFFFF" }}
                  >
                    Receive Your{" "}
                    <span style={{ color: "#0E0E0E" }}>100% Free</span> To{" "}
                    <br />
                    Use Interactive Touch{" "}
                    <span>
                      <img src={Sun} alt="Sun" width={28} height={28} />
                    </span>
                    <br /> Screen Information{" "}
                    <span style={{ color: "#111111" }}>Kiosk</span>{" "}
                    <span>
                      <img
                        src={Polygon}
                        alt="Polygon"
                        width={17}
                        height={27}
                        style={{ marginLeft: "0px" }}
                      />
                    </span>
                  </div>
                  <div className="text-end" id="underlinebanner">
                    <img
                      src={Underline}
                      alt="Underline"
                      width={347}
                      height={16}
                      style={{
                        zIndex: "10",
                        position: "relative",
                        marginTop: "-30px",
                        left: "-50px",
                      }} // Set marginLeft to 0px to align
                    />
                  </div>
                </div>

                <div
                  className="video-container3 text-center mt-2"
                  style={{ marginBottom: "25px", borderRadius: "20px" }}
                >
                  <video
                    width="100%"
                    height="100%"
                    controls
                    style={{ borderRadius: "20px" }}
                  >
                    <source
                      src="https://touch-it.com.au/tutorial_videos/kiosk_claim.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>

                  <img
                    src={rectangle3}
                    alt="Overlay"
                    className="overlay-image2"
                  />

                  <div
                    className="overlay-text2"
                    onClick={handleScrollToSection}
                    style={{ cursor: "pointer" }}
                  >
                    CLAIM YOUR KIOSK NOW!
                  </div>
                </div>
              </div>
            </div>

            {/* Right Side: Static Image */}
            <div
              className="col-lg-3 order-md-last mt-3"
              style={{ zIndex: "10" }}
            >
              <div
                className="static-image-container text-center mt-5 mb-0"
                id="hotelImage"
              >
                <img src={BannerImg} alt="Static" className="img-fluid mt-4" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NightClubBanner;
