import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const ImageGridModal = ({
  images,
  bannerTag,
  show,
  handleClose,
  handlePrimaryChange,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFullSizeModalOpen, setIsFullSizeModalOpen] = useState(false);
  const [checked, setChecked] = useState("");

  useEffect(() => {
    setChecked(bannerTag);
  }, [bannerTag]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsFullSizeModalOpen(true);
  };

  const closeFullSizeModal = () => setIsFullSizeModalOpen(false);

  const handleChecked = (val, image) => {
    setChecked(val);
    handlePrimaryChange(image);
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Banner Images:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image-grid">
            {images.map((image, index) => (
              <div key={index} className="image-item">
                <img
                  src={image.bannerPath}
                  alt={image.bannerName}
                  onClick={() => handleImageClick(image.bannerPath)}
                />
                <div className="primary-radio1">
                  <div className="form-group d-flex align-items-center gap-2">
                    <input
                      type="radio"
                      className="custom-radio m-0 cursor-pointer"
                      name="bannerImages"
                      value={image.bannerName}
                      style={{
                        height: 18,
                        width: 18,
                        minHeight: 18,
                        minWidth: 18,
                      }}
                      onChange={(e) => handleChecked(e.target.value, image)}
                      checked={checked === image.bannerName || false}
                    />
                    <label className="fs-12 m-0" htmlFor="Set for home page">
                      Set for home banner
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isFullSizeModalOpen}
        centered
        onHide={closeFullSizeModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Full Size View:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" className="img-fluid" />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageGridModal;
