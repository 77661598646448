import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import LandingImg from "./../../assets/images/landing.png";
import LogoLandingImg from "./../../assets/images/logo-landing.png";
import SheratonImg from "./../../assets/images/partners/sheraton.png";
import AccorhotelsImg from "./../../assets/images/partners/accorhotels.png";
import MarriotImg from "./../../assets/images/partners/marriot.png";
import QuoteImg from "./../../assets/images/quote.png";
import "./../../assets/css-landing/menumaker.css";
import { handleNumericInput } from "../util/utils";
import LandingPageHeader from "../layouts/LandingPageHeader";

function ContactPage() {
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [values, setValues] = useState({
    fullName: "",
    message: "",
    mobile: "",
    email: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        mobile: inputValue,
      });
    }
  };

  return (
    <>
      <LandingPageHeader />

      <section className="top-experiences profile landing">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-7">
              <div className="landing-image">
                <img
                  src={LandingImg}
                  className="img-fluid position-relative z-3"
                  alt="LandingImg"
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="landing-details">
                <img
                  src={LogoLandingImg}
                  className="img-fluid large"
                  alt="LogoLandingImg"
                />
                <form onSubmit={handleSubmit} className="custom-field-wrapper">
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      autoComplete="off"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          fullName: e.target.value,
                        })
                      }
                    />
                    {validator.current.message(
                      "full name",
                      values.fullName,
                      "required"
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      autoComplete="off"
                      value={values.mobile}
                      onChange={handleChangePhone}
                    />
                    {validator.current.message(
                      "mobile number",
                      values.mobile,
                      "required"
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="userEmail">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="userEmail"
                      autoComplete="off"
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                    />
                    {validator.current.message(
                      "email",
                      values.email,
                      "required|email"
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control"
                      id="message"
                      autoComplete="off"
                      rows={3}
                      onChange={(e) =>
                        setValues({ ...values, message: e.target.value })
                      }
                    />
                    {validator.current.message(
                      "message",
                      values.message,
                      "required"
                    )}
                  </div>

                  <div className="btn-wrapper text-center mt-4 pt-2">
                    <button
                      type="submit"
                      className="btn btn-custom"
                      disabled={loading || false}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quote" style={{ marginBottom: 100 }}>
        <div className="container" style={{ paddingRight: "60px" }}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img src={QuoteImg} className="img-fluid" alt="" />
              <p>
                <span>
                  “If you&apos;re trying to reach holiday makers, nothing comes
                  close to <br />
                  Touch-it for driving engagement."
                </span>
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h2>Available at:</h2>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img
                src={AccorhotelsImg}
                className="img-fluid partners"
                alt="AccorhotelsImg"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img
                src={SheratonImg}
                className="img-fluid partners"
                alt="SheratonImg"
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <img
                src={MarriotImg}
                className="img-fluid partners"
                alt="MarriotImg"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactPage;
