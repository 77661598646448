import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import GeneralLogo from "../../../assets/images/logo-new.png";
import LogoBooking from "../../../assets/images/booking-logo-new.png";
import LogoDinning from "../../../assets/images/dining-logo-new.png";
import LogoNightlife from "../../../assets/images/nightlife-logo-new.png";
import ContactFormPop from "../../ContactFormPop";

import { Link } from "react-router-dom";
import AppConstants from "../../../appConstants";

const MENU = [
  { name: "BOOKING", sku: "booking" },
  {
    name: "DINING",
    sku: "dining",
  },
  {
    name: "NIGHTLIFE",
    sku: "night-life",
  },
  {
    name: "CONTACT",
    sku: "contact",
  },
];

const Header = ({ handleScrollToSection }) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  var logo = GeneralLogo;
  if (pathname.startsWith(AppConstants.routes.booking)) {
    logo = LogoBooking;
  } else if (pathname.startsWith(AppConstants.routes.dinning)) {
    logo = LogoDinning;
  } else if (pathname.startsWith(AppConstants.routes.nightlifelanding)) {
    logo = LogoNightlife;
  }

  const handleClickViewList = (item) => {
    if (item.sku === "contact") {
      setShow(true);
    } else {
      navigate(`/${item.sku}`);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <header className="custom-header custom-header-new">
        <div className="container-fluid px-lg-5">
          <div className="row custom-row d-flex align-items-center">
            <div className="col-6 col-md-2">
              <div className="d-flex align-items-center gap-3">
                <button
                  type="button"
                  id="openSidebar"
                  className="btn btn-link p-0 border-0 text-white d-lg-none"
                >
                  <i className="bi bi-list fs-2"></i>
                </button>
                <Link to="/" className="logo">
                  <img src={logo} className="img-responsive" alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-1 col-md-2">
              <div className="header-section" style={{ marginLeft: 10 }}>
                <Link
                  to={AppConstants.routes.home}
                  className="btn logout button-new"
                >
                  Home
                </Link>
              </div>
            </div>

            <div className="col-1 col-md-6">
              <div id="cssmenuu" className="common-menu-wrapper sidebar-drawer">
                <div className="logo d-flex align-items-center justify-content-between gap-3 border-bottom d-md-none">
                  <Link to={"index"}>
                    <img
                      src={GeneralLogo}
                      className="img-responsive"
                      alt="Logo"
                    />
                  </Link>
                  <button
                    type="button"
                    id="closeSidebar"
                    className="btn-link p-0 border-0 text-white"
                  >
                    <i className="bi bi-x fs-18"></i>
                  </button>
                </div>
                <ul
                  className="d-flex flex-column flex-lg-row flex-lg-wrap align-items-lg-center justify-content-center row-gap-2 column-gap-4 m-0 p-0"
                  style={{ fontSize: 22, fontWeight: 600, letterSpacing: 2 }}
                >
                  {MENU.map((el, index) => (
                    <li key={index}>
                      <div
                        className={`cursor-pointer ${
                          pathname === `/${el.sku}` ? "text-custom-primary" : ""
                        }`}
                        onClick={() => handleClickViewList(el)}
                      >
                        {el.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-5 col-md-2">
              <div className="header-section d-flex flex-wrap align-items-center justify-content-end gap-2">
                {pathname === "/register" || pathname === "/venue" ? (
                  <button
                    className="btn logout button-new"
                    onClick={handleScrollToSection}
                  >
                    Register
                  </button>
                ) : (
                  <Link
                    to={AppConstants.routes.register}
                    className="btn logout button-new"
                  >
                    Register
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      <ContactFormPop show={show} close={handleClose} />
    </>
  );
};

export default Header;
