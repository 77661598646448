import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import rectangle2 from "../../../../assets/images/site/Rectangle 15.png";
import NameIcon from "../../../../assets/images/site/NameIcon.png";
import ContactIcon from "../../../../assets/images/site/contactIcon.png";
import CalendertIcon from "../../../../assets/images/site/CalenderIcon.png";
import EmailtIcon from "../../../../assets/images/site/EmailIcon.png";
import StopwatchIcon from "../../../../assets/images/site/StopwatchIcon.png";
import LocationtIcon from "../../../../assets/images/site/LocationIcon.png";
import "./BookDemo.scss";
import { authCall } from "../../../../services";
import { handleNumericInput } from "../../../util/utils";

import uploadLogo from "../../../../assets/images/site/uploadLogo.png";
import MenuLogo1 from "../../../../assets/images/site/MenuLogo1.png";
import MenuLogo2 from "../../../../assets/images/site/MenuLogo2.png";
import MenuLogo3 from "../../../../assets/images/site/MenuLogo3.png";
import MenuImagesUploadsRegister from "../../../util/MenuImagesUploadsRegister";

const MAX_SIZE_MB = 25;

const BookDemo = () => {
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const initialField = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    demoDate: "",
    demoTime: "",
    meetingAddress: "",
    listResto: "",
    isLightSpeed: "",
    sevenRoom: "",
    isZipPhotoUploaded: "",
    isMenuUploaded: "",
    uploadZipPhoto: "",
    uploadMenu: "",
  };
  const [fileUpload, setFileUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressMenu, setUploadProgressMenu] = useState(0);
  const [zipFileSizeVal, setZipFileSizeVal] = useState("");
  const [menuFileSizeVal, setMenuFileSizeVal] = useState("");

  const [values, setValues] = useState(initialField);
  const [loading, setLoading] = useState(false);

  const handleChangePhone = (e) => {
    const inputValue = handleNumericInput(e.target.value);
    if (inputValue !== null && inputValue.length < 11) {
      setValues({
        ...values,
        phoneNumber: inputValue,
      });
    }
  };

  const handleBookRequest = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      console.log(values);
      setLoading(true);
      const resp = await authCall("sendMailDinningDemoRequest", values);
      if (resp?.type === "success") {
        setLoading(false);
        restForm();
        toast.dismiss();
        setUploadProgress(0);
        setUploadProgressMenu(0);
        toast.success("Your demo request sent successfully!");
      }
    } else {
      setLoading(false);
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const restForm = () => {
    setValues(initialField);
  };

  const handleClick = () => {
    const timePicker = document.getElementById("timePicker");
    if (timePicker) {
      timePicker.showPicker();
    } else {
      document.getElementById("timePcker").click();
    }
  };

  const handleUploadFiles = async (event) => {
    const { name } = event.target;
    const selectedFile = event.target.files[0];
    const fileSizeMB = selectedFile.size / (1024 * 1024);

    if (fileSizeMB > MAX_SIZE_MB) {
      if (name === "uploadMenu") {
        setMenuFileSizeVal("The file size must be less than 25 MB.");
      } else {
        setZipFileSizeVal("The file size must be less than 25 MB.");
      }
    } else if (
      name === "uploadMenu" &&
      !selectedFile.type.startsWith("image/")
    ) {
      setMenuFileSizeVal("The file should image.");
    } else if (
      name === "uploadZip" &&
      selectedFile.type !== "application/zip"
    ) {
      setZipFileSizeVal("The file should zip.");
    } else {
      setZipFileSizeVal("");
      setMenuFileSizeVal("");
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("type", name);
      setFileUpload(true);

      try {
        // const url = "https://touch-it.com.au/api/public/api/v1";
        const url = "http://localhost/touch-it/public/api/v1";
        await axios
          .post(`${url}/uploadeFile`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (name === "uploadMenu") {
                setUploadProgressMenu(percentCompleted);
              } else {
                setUploadProgress(percentCompleted);
              }
              setFileUpload(false);
            },
          })
          .then((response) => {
            const result = response?.data;
            if (name === "uploadMenu") {
              setValues({
                ...values,
                uploadMenu: result?.payload?.fileName,
                isMenuUploaded: "Yes",
              });
            } else {
              setValues({
                ...values,
                uploadZipPhoto: result?.payload?.fileName,
                isZipPhotoUploaded: "Yes",
              });
            }
          })
          .catch((error) => error);
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
  };

  const [bannerImages, setBannerImages] = useState([]);

  const getBannerImages = (banners) => {
    setBannerImages(banners);
    // setIsBannerImageChange(true);
    // setValues({ ...values, bannerImageUploaded: "uploaded" });
  };

  return (
    <>
      <div
        className="bookDemo_wrapper mt-5 container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          STEP ONE: BOOK A DEMO
        </div>
        <div className="contact-form-wrapper">
          <div
            className="contact-form mb-5"
            id="contactformrest"
            style={{ margin: "0px 35px" }}
          >
            <div className="row mt-5" id="contacttWrapper">
              {/* first name */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={NameIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={31}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="firstNameInput"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChangeValue}
                      placeholder="First Name"
                    />
                  </div>
                  {validator.current.message(
                    "first name",
                    values.firstName,
                    "required"
                  )}
                </div>
              </div>

              {/* last name */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={NameIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={31}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="lastNameInput"
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "last name",
                    values.lastName,
                    "required"
                  )}
                </div>
              </div>

              {/* Phone number */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={ContactIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={24}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      id="phoneNumberInput"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChangePhone}
                      placeholder="Phone Number"
                    />
                  </div>
                  {validator.current.message(
                    "phone number",
                    values.phoneNumber,
                    "required"
                  )}
                </div>
              </div>

              {/* Email */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={EmailtIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={20}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="email"
                      id="emailInput"
                      name="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "email",
                    values.email,
                    "required|email"
                  )}
                </div>
              </div>

              {/* Date picker */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon">
                    <img
                      src={CalendertIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={22}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="date"
                      id="datePicker"
                      name="demoDate"
                      value={values.demoDate}
                      onChange={handleChangeValue}
                      onFocus={() =>
                        document.getElementById("datePicker").showPicker()
                      } // Open calendar when the field is focused
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {validator.current.message(
                    "date",
                    values.demoDate,
                    "required"
                  )}
                </div>
              </div>

              {/* Time picker  */}
              <div
                className="col-lg-6 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div className="form-group ms-3" id="restaurantform">
                  <div className="input-with-icon" onClick={handleClick}>
                    <img
                      src={StopwatchIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={26}
                      width={20}
                      style={{ left: "3%" }}
                    />
                    <input
                      type="text"
                      placeholder="hh:mm"
                      value={values.demoTime}
                      readOnly
                      style={{ cursor: "pointer" }} // Change cursor to pointer
                    />
                    <input
                      type="time"
                      id="timePicker"
                      name="demoTime"
                      value={values.demoTime}
                      onChange={handleChangeValue}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }} // Hide the time input
                    />
                  </div>

                  {validator.current.message(
                    "time",
                    values.demoTime,
                    "required"
                  )}
                </div>
              </div>
            </div>

            {/* Address */}
            <div className="row">
              <div
                className="col-lg-12 d-flex flex-column align-items-center justify-content-between gap-lg-4 mt-lg-3"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
                id="contacttWrapper"
              >
                <div
                  className="form-group ms-3"
                  id="locationInput"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="input-with-icon">
                    <img
                      src={LocationtIcon}
                      alt="Name Icon"
                      className="input-icon"
                      height={25}
                      width={20}
                      style={{ left: "1.5%" }}
                    />
                    <input
                      type="text"
                      id="locationInput2"
                      name="meetingAddress"
                      placeholder="Preferred Meeting Address"
                      value={values.meetingAddress}
                      onChange={handleChangeValue}
                    />
                  </div>
                  {validator.current.message(
                    "meeting address",
                    values.meetingAddress,
                    "required"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MENU SECTION */}
      <div
        className="menuSection_wrapper container"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          RESTAURANT SECTION
        </div>

        <div className="contact-form-wrapper">
          <div className="contact-form mb-5 mt-3">
            <MenuImagesUploadsRegister
              bannerImages={getBannerImages}
              images={bannerImages}
            />
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          {fileUpload ? (
            <button
              type="button"
              className="menuButton text-center mt-5"
              disabled
            >
              File Uploading...
            </button>
          ) : (
            <button
              type="submit"
              className="menuButton text-center mt-5"
              disabled={loading || false}
              onClick={handleBookRequest}
            >
              {loading ? "Loading..." : "BOOK NOW"}
            </button>
          )}
        </div>
      </div>

      {/* DISPLAY NONE LIGHTSPEED AND SEVENROOM */}
      <div
        className="menuSection_wrapper container d-none"
        style={{ position: "relative" }}
      >
        <div className="gradient-line"></div>
        <img src={rectangle2} alt="Book Now" className="centered-image" />
        <div className="overlay-text" style={{ fontFamily: "Inter" }}>
          RESTAURANT SECTION
        </div>

        <div className="contact-form-wrapper">
          <div className="contact-form mb-5 mt-3">
            <div
              className="form-group mt-5"
              id="menuform"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="input-with-icon" style={{ position: "relative" }}>
                <img
                  src={MenuLogo1}
                  alt="MenuLogo"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "26px", // Adjust the size as needed
                    height: "26px",
                    zIndex: 3,
                  }}
                />
                {/* Placeholder Text */}
                <span
                  className="placeholder-text"
                  style={{
                    position: "absolute",
                    left: "55px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#BABABA",
                    pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                    fontSize: "17px",
                  }}
                >
                  Upload a zipped file containing a photo of every dish
                </span>

                {/* Hidden File Input */}
                <input
                  type="file"
                  id="fileUpload"
                  name="uploadZip"
                  // onChange={handleFileChange}
                  onChange={handleUploadFiles}
                  className="upload-input styled-input"
                  style={{
                    width: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    zIndex: 2,
                    borderRadius: "4px",
                    border: "1px solid #282828",
                    borderColor: "#191919",
                  }}
                  required
                />

                {/* Custom Button with Icon */}
                <button
                  type="button"
                  className="custom-upload-btn"
                  onClick={() => document.getElementById("fileUpload").click()}
                  style={{
                    position: "absolute",
                    right: "10px", // Align button to the right
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    zIndex: 3,
                  }}
                >
                  <img
                    src={uploadLogo}
                    alt="Upload Icon"
                    style={{ width: "46px", height: "47px", margin: "2px" }}
                  />
                </button>
              </div>
            </div>
            {uploadProgress > 0 && (
              <div className="progress-bar-cover mb-3">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${uploadProgress}%`,
                      backgroundColor: "var(--theme-color1)",
                    }}
                  >
                    {uploadProgress}%
                  </div>
                </div>
              </div>
            )}
            {zipFileSizeVal !== "" && (
              <div className="srv-validation-message">{zipFileSizeVal}</div>
            )}

            <div
              className="form-group mt-3"
              id="menuform"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="input-with-icon" style={{ position: "relative" }}>
                <img
                  src={MenuLogo2}
                  alt="MenuLogo"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "26px", // Adjust the size as needed
                    height: "26px",
                    zIndex: 3,
                  }}
                />

                {/* Placeholder Text */}
                <span
                  className="placeholder-text"
                  style={{
                    position: "absolute",
                    left: "55px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#BABABA",
                    pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                    fontSize: "17px",
                  }}
                >
                  Upload a copy of your menu
                </span>

                {/* Hidden File Input */}
                <input
                  type="file"
                  id="uploadMenuPhoto"
                  name="uploadMenu"
                  className="upload-input"
                  // onChange={handleFileChange}
                  onChange={handleUploadFiles}
                  style={{
                    width: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                />

                {/* Custom Button with Icon */}
                <button
                  type="button"
                  className="custom-upload-btn"
                  onClick={() =>
                    document.getElementById("uploadMenuPhoto").click()
                  }
                  style={{
                    position: "absolute",
                    right: "10px", // Align button to the right
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    zIndex: 3,
                  }}
                >
                  <img
                    src={uploadLogo}
                    alt="Upload Icon"
                    style={{ width: "46px", height: "47px", margin: "2px" }}
                  />
                </button>
              </div>
            </div>
            {uploadProgressMenu > 0 && (
              <div className="progress-bar-cover mb-3">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${uploadProgressMenu}%`,
                      backgroundColor: "var(--theme-color1)",
                    }}
                  >
                    {uploadProgressMenu}%
                  </div>
                </div>
              </div>
            )}
            {validator.current.message(
              "upload menu",
              values.isMenuUploaded,
              "required"
            )}
            {menuFileSizeVal !== "" && (
              <div className="srv-validation-message">{menuFileSizeVal}</div>
            )}

            <div
              className="form-group mt-3"
              id="menuform"
              style={{ maxWidth: "100%", width: "100%" }}
            >
              <div className="input-with-icon">
                <img
                  src={MenuLogo3}
                  alt="Name Icon"
                  className="input-icon"
                  height={30}
                  width={26}
                  style={{ left: "1.2%" }}
                />
                <input
                  type="text"
                  id="listResto"
                  name="listResto"
                  value={values.listResto}
                  onChange={handleChangeValue}
                  placeholder="Enter the link to your Uber Eats or Door Dash listing"
                />
              </div>
            </div>

            {/* Radio Buttons Starts */}

            <div className="row text-center mt-3">
              <div
                className="col-6 col-lg-6 d-flex flex-column mt-3"
                id="radiohead"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group text-start mt-3" id="radio-buttons">
                  <label
                    style={{
                      fontSize: "17px",
                      color: "#BABABA",
                      marginLeft: "55px",
                    }}
                  >
                    Do you use LightSpeed POS?
                  </label>
                </div>
                {validator.current.message(
                  "lightspeep pos",
                  values.isLightSpeed,
                  "required"
                )}
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn1"
              >
                <button
                  onClick={() => setValues({ ...values, isLightSpeed: "Yes" })}
                  style={{
                    backgroundColor:
                      values.isLightSpeed === "Yes" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  Yes
                </button>
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn2"
              >
                <button
                  onClick={() => setValues({ ...values, isLightSpeed: "No" })}
                  style={{
                    backgroundColor:
                      values.isLightSpeed === "No" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  No
                </button>
              </div>
            </div>

            <div className="row text-center mt-3">
              <div
                className="col-6 col-lg-6 d-flex flex-column mt-3"
                id="radiohead"
                style={{ fontFamily: "Poppins", color: "#BABABA" }}
              >
                <div className="form-group text-start mt-3" id="radio-buttons">
                  <label
                    style={{
                      fontSize: "17px",
                      color: "#BABABA",
                      marginLeft: "55px",
                    }}
                  >
                    Do you use SevenRooms for online reservations?
                  </label>
                </div>
                {validator.current.message(
                  "sevenroom online",
                  values.sevenRoom,
                  "required"
                )}
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn1"
              >
                <button
                  onClick={() => setValues({ ...values, sevenRoom: "Yes" })}
                  style={{
                    backgroundColor:
                      values.sevenRoom === "Yes" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  Yes
                </button>
              </div>

              <div
                className="col-2 col-lg-2 d-flex flex-column align-items-center justify-content-center mt-2"
                id="radiobtn2"
              >
                <button
                  onClick={() => setValues({ ...values, sevenRoom: "No" })}
                  style={{
                    backgroundColor:
                      values.sevenRoom === "No" ? "#E36D4B" : "#555",
                    color: "#FFFFFF",
                    maxWidth: "100px",
                    width: "100%",
                    borderRadius: "10px",
                    border: "none",
                    padding: "8px",
                    cursor: "pointer",
                  }}
                >
                  No
                </button>
              </div>
            </div>

            {/* Radio Buttons Ends */}
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          {fileUpload ? (
            <button
              type="button"
              className="menuButton text-center mt-5"
              disabled
            >
              File Uploading...
            </button>
          ) : (
            <button
              type="submit"
              className="menuButton text-center mt-5"
              disabled={loading || false}
              onClick={handleBookRequest}
            >
              {loading ? "Loading..." : "BOOK NOW"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BookDemo;
