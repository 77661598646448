import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";

import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { EyeFill, Plus, PencilSquare } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { handleNumericInput } from "../../util/utils";

const USERTYPE = [
  { value: 1, label: "Activity" },
  { value: 2, label: "Restaurant" },
  { value: 3, label: "Bar" },
];

const SHOWCOMMISION = ["Activity", "Licensee", "Promoter"];
const USERS = ["Licensee", "Promoter", "Advertiser", "Designer"];

function NightlifeEventListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { type } = location?.state || {};
  const [machinesList, setMachinesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [loadingStatus, setLoadingStatus] = useState("");
  const [eventList, setEventList] = useState([]);
  const [userList, serList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: type ? type : "Activity",
    label: type ? type : "Activity",
  });

  const getList = async (type, status = false) => {
    if (!status) {
      setLoading(true);
    }

    const resp = await authCall("getNightLifeEvents", {
      nightLifeId: type,
    });
    if (resp?.type === "success") {
      setEventList(resp?.payload);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, [pathname]);

  const handleEdit = (details) => {
    navigate(AppConstants.routes.admin.userUpdate, { state: { details } });
  };

  const handleViewDetails = (details) => {
    navigate(AppConstants.routes.nightLife.nightLifeEventDetails, {
      state: { items: details },
    });
  };

  const handleChangeStatus = async (id, newStatus) => {
    const updatedItems = eventList
      .map((item) => (item.id === id ? { ...item, status: newStatus } : item))
      .sort((a, b) => a.status.localeCompare(b.status));
    setEventList(updatedItems);
    await authCall("nightLifeEvent", { eventId: id, status: newStatus });
  };

  return (
    <>
      <div className="update-machine-page common-table-design py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Event Listing</div>
                <div className="details-wrapper py-lg-4">
                  <div className="table-responsive">
                    <table className="table table-borderless table-custom m-0">
                      <thead>
                        <tr>
                          <th scope="col">Id:</th>
                          <th scope="col">Event Name</th>
                          <th scope="col">Contact:</th>
                          <th scope="col">Phone:</th>
                          <th scope="col">Start Date & Time</th>
                          <th scope="col">End Date & Time</th>
                          <th scope="col">Recurring</th>
                          <th scope="col">Cost</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action:</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading &&
                          (eventList.length > 0 ? (
                            eventList.map((el, index) => (
                              <tr key={index}>
                                <td scope="row">#{el.id}</td>
                                <td>{el.event_name}</td>
                                <td>{el.user_name}</td>
                                <td>{el.mobile_number}</td>
                                <td>
                                  {moment(el.event_start_date).format(
                                    "MMM D, YYYY hh:mm A"
                                  )}
                                </td>
                                <td>
                                  {moment(el.event_end_date).format(
                                    "MMM D, YYYY hh:mm A"
                                  )}
                                </td>
                                <td>{el.event_recurring}</td>
                                <td>${el.event_cost}</td>
                                <td>
                                  <div className="field-cover">
                                    <select
                                      className={`form-control text-white form-select ${
                                        el.status === "0"
                                          ? "bg-dark"
                                          : el.status === "1"
                                          ? "bg-success"
                                          : "bg-danger"
                                      }`}
                                      value={el.status}
                                      id="approvedStatus"
                                      style={{ width: 120, paddingLeft: 6 }}
                                      onChange={(e) =>
                                        handleChangeStatus(
                                          el.id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="" hidden>
                                        Select
                                      </option>
                                      <option
                                        value="0"
                                        selected={
                                          el.status === "0" ? "selected" : ""
                                        }
                                      >
                                        None
                                      </option>
                                      <option
                                        value="1"
                                        selected={
                                          el.status === "1" ? "selected" : ""
                                        }
                                      >
                                        Approve
                                      </option>
                                      <option
                                        value="2"
                                        selected={
                                          el.status === "2" ? "selected" : ""
                                        }
                                      >
                                        Reject
                                      </option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="icon-container">
                                    <PencilSquare
                                      onClick={() => handleEdit(el)}
                                      className="cursor-pointer"
                                      title="Click to edit"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                    <EyeFill
                                      onClick={() => handleViewDetails(el)}
                                      title="Click to view details"
                                      className="cursor-pointer"
                                      style={{
                                        color: "#ddb12e",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">
                                No record found
                              </td>
                            </tr>
                          ))}
                        {loading && (
                          <tr>
                            <td colSpan={10} className="text-center">
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NightlifeEventListing;
