import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NightLifeSidebar from "../layouts/NightLifeSidebar";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/reducers/companyDetailsSlice";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
import NightLifeVenueDetailsComp from "../common/NightLifeVenueDetailsComp";

const buttons = [
  {
    id: 1,
    label: "Add Event",
  },
  {
    id: 2,
    label: "Dashboard",
  },
];

const buttonsForMenu = [
  {
    id: 1,
    label: "Submit",
  }
];

function VenueDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async (data) => {
    data.profileLavel =
      companyDetails?.progress < 50
        ? AppConstants.routes.nightLife.vanueDetails
        : "";
    data.progress = companyDetails?.progress < 50 ? 50 : "";
    setLoading(true);
    const resp = await webServices.createNightLife({
      ...data,
      nightLifeId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      dispatch(setCompanyDetails(resp?.payload));
      if (resp?.payload?.is_menu_available === "Yes") {
        navigate(AppConstants.routes.nightLife.nightLifeMenu);
      } else {
        if (data.buttonId === 2) {
          navigate(AppConstants.routes.admin.dashboard);
        } else {
          navigate(AppConstants.routes.nightLife.eventDetails);
        }
      }
    }
  };

  return (
    <>
      <NightLifeVenueDetailsComp
        onSubmit={handleSubmitForm}
        loading={loading}
        details={companyDetails}
        heading="Please Enter Your Venue Details:"
        Sidebar={NightLifeSidebar}
        buttons={buttons}
        buttonsForMenu={buttonsForMenu}
      />
    </>
  );
}

export default VenueDetails;
