import React, { useEffect, useState } from "react";
import uploadLogo from "../../assets/images/site/uploadLogo.png";
import MenuLogo1 from "../../assets/images/site/MenuLogo1.png";
import { toast } from "react-toastify";
import CropperModal from "./CropperModal";

const MenuImagesUploadsRegister = ({ bannerImages, images, label }) => {
  // const fileInputBannerRef = useRef(null);
  const [bannerField, setBannerField] = useState([
    { id: 1, value: "", isNew: true },
  ]);
  const [isBanner, setIsBanner] = useState(0);
  const [imageForCrop, setImageForCrop] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (images && images.length > 0) {
      setBannerField(images);
    }
  }, [images]);

  const handleBrowseClick = (id) => {
    // fileInputBannerRef.current.click();
    document.getElementById(`fileInput-${id}`).click();
    setIsBanner(id);
  };

  const onChange = (id, e) => {
    e.preventDefault();
    if (id) {
      setIsBanner(id);
    }

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageForCrop(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setShow(true);
  };

  const getCropData = (val) => {
    const images = bannerField.map((field) =>
      field.id === isBanner ? { ...field, value: val, isNew: true } : field
    );
    setBannerField(images);
    bannerImages(images);
  };

  const removeBannerImage = (index) => {
    const updatedFiles = bannerField.filter((_, i) => i !== index);
    setBannerField(updatedFiles);
    bannerImages(updatedFiles);
  };

  const handleClose = () => {
    setShow(false);
  };

  const addBannerField = () => {
    if (bannerField.length > 4) {
      toast.dismiss();
      toast.error("Maximum upload limit reached. You can upload up to 5 images at a time.");
    } else {
      const newFields = [];
      const newField = { id: bannerField.length + 1, value: "", isNew: true };
      newFields.push(newField);
      setBannerField([...bannerField, ...newFields]);
    }
  };

  return (
    <>
      <div className="banner-cover mb-2">
        <div className="wrapper">
          {bannerField.map((el, index) => (
            <div key={el.id}>
              {el.value === "" && (
                <div
                  className="form-group mt-5"
                  id="menuform"
                  style={{ maxWidth: "100%", width: "100%" }}
                >
                  <div
                    className="input-with-icon"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={MenuLogo1}
                      alt="MenuLogo"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "26px", // Adjust the size as needed
                        height: "26px",
                        zIndex: 3,
                      }}
                    />
                    {/* Placeholder Text */}
                    <span
                      className="placeholder-text"
                      style={{
                        position: "absolute",
                        left: "55px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#BABABA",
                        pointerEvents: "none", // Make sure the text doesn't interfere with clicking
                        fontSize: "17px",
                      }}
                    >
                      Upload Image {index + 1}
                    </span>

                    {/* Hidden File Input */}
                    <input
                      type="file"
                      // id="fileUpload"
                      id={`filee-${el.id}`}
                      name="uploadZip"
                      // onChange={handleFileChange}
                      // onChange={handleUploadFiles}
                      onChange={(e) => onChange(el.id, e)}
                      className="upload-input styled-input"
                      style={{
                        width: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        zIndex: 2,
                        borderRadius: "4px",
                        border: "1px solid #282828",
                        borderColor: "#191919",
                      }}
                      required
                    />

                    {/* Custom Button with Icon */}
                    <button
                      type="button"
                      className="custom-upload-btn"
                      onClick={() =>
                        document.getElementById(`filee-${el.id}`).click()
                      }
                      style={{
                        position: "absolute",
                        right: "10px", // Align button to the right
                        top: "50%",
                        transform: "translateY(-50%)",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        zIndex: 3,
                      }}
                    >
                      <img
                        src={uploadLogo}
                        alt="Upload Icon"
                        style={{ width: "46px", height: "47px", margin: "2px" }}
                      />
                    </button>
                  </div>
                </div>
              )}
              {el.value !== "" && (
                <>
                  <div style={{ width: "120px" }} className="img-wrapper">
                    <img
                      src={el.value}
                      className="img-fluid rounded"
                      alt="cropped"
                    />
                    <input
                      type="file"
                      // ref={fileInputBannerRef}
                      accept="image/*"
                      id={`fileInput-${el.id}`}
                      style={{ display: "none" }}
                      onChange={(e) => onChange("", e)}
                    />
                  </div>

                  <div className="d-flex flex-wrap align-items-center gap-2 mt-2 mb-3">
                    <button
                      type="button"
                      className="btn btn-custom btn-custom-sm"
                      onClick={() => handleBrowseClick(el.id)}
                    >
                      Change
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => removeBannerImage(index)}
                    >
                      <i className="bi bi-trash3-fill"></i> Remove
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <div
          className="add-another fw-medium text-end mt-2"
          onClick={addBannerField}
        >
          <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>{" "}
          {label ? label : "Add Another Menu Image"}
        </div>
      </div>
      <CropperModal
        show={show}
        handleClose={handleClose}
        image={imageForCrop}
        sendCropedDataToParent={getCropData}
      />
    </>
  );
};

export default MenuImagesUploadsRegister;
