// Home.jsx
import React from "react";
import BookingCalendar from "../pages/activities/BookingCalendar";
import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
import webServices from "../../services/webServices";
import AppConstants from "../../appConstants";
// import { getBookingDetails } from "../../redux/actions/booking.actions";

function TourBookingCalendarAction() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { bookingDetails } = useSelector((state) => state.booking);
  const handleClick = async () => {
    const resp = await webServices.createTour({ bookingCalendar: "Rezdy" });
    if (resp.type === "success") {
      localStorage.setItem("productDetails", JSON.stringify(resp.payload));
      navigate(AppConstants.routes.tour.tourRezdyIntegration);
    }
  };

  return (
    <>
      <BookingCalendar handleClick={handleClick} />
    </>
  );
}

export default TourBookingCalendarAction;
