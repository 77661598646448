import React from "react";
import { useSelector } from "react-redux";
import ActivitySidebar from "../../layouts/TourSidebar";
import CostPerPersonPage from "../../common/CostPerPersonPage";
import CostPerPersonPageFareharbor from "../../common/CostPerPersonPageFareharbor";

function CostPerPerson({ handleSubmit, loading, stepValue, setStep }) {
  const companyDetails = useSelector((state) => state.companyDetails);

  return (
    <>
      {(companyDetails.booking_calendar === "Rezdy" ||
        companyDetails.booking_calendar === "Resova") && (
        <CostPerPersonPage
          SideBar={ActivitySidebar}
          priceDataList={companyDetails?.price_options || []}
          setStep={setStep}
          btnLabel="Submit"
          handleSubmit={handleSubmit}
          loading={loading}
          stepValue={companyDetails?.variations ? 6 : stepValue}
          calanderType={companyDetails.booking_calendar}
        />
      )}
      {companyDetails.booking_calendar === "FareHarbor" && (
        <CostPerPersonPageFareharbor
          SideBar={ActivitySidebar}
          priceDataList={companyDetails?.price_options || []}
          setStep={setStep}
          btnLabel="Submit"
          handleSubmit={handleSubmit}
          loading={loading}
          stepValue={companyDetails?.variations ? 6 : stepValue}
          calanderType={companyDetails.booking_calendar}
        />
      )}
    </>
  );
}

export default CostPerPerson;
