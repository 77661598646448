import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCompanyDetails } from "../../../redux/reducers/companyDetailsSlice";
import webServices from "../../../services/webServices";
import ActivityCompanyType from "./ActivityCompanyType";
import BookingCalendar from "./BookingCalendar";
import RezdyIntegration from "./RezdyIntegration";
import ResovaIntegration from "./ResovaIntegration";
import FareHarborIntegration from "./FareHarborIntegration";
import CompanyCategory from "./CompanyCategory";
import CostPerPerson from "./CostPerPerson";
import ActivityDetails from "./ActivityDetails";
import Stops from "./Stops";
import LocationDetails from "./LocationDetails";
import ExtraVariations from "./ExtraVariations";
import Payment from "./Payment";
import PageLoader from "../../util/PageLoader";

const ActivityAction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const companyDetails = useSelector((state) => state.companyDetails);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  // To disabled back button
  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    const handlePopState = (event) => {
      return false;
      // window.history.pushState(null, "", window.location.href);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const fetchCompanyData = async () => {
    const resp2 = await webServices.getCompnayDetails({
      companyType: "Activity",
      // userId: current?.id,
      companyId: current?.company_id,
    });
    if (resp2?.type === "success") {
      setStep(+resp2?.payload?.profile_lavel || step);
      dispatch(setCompanyDetails(resp2?.payload));
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, [current]);

  const handleSubmit = async (data, stepValue) => {
    setLoading(true);
    const resp = await webServices.createTour({
      ...data,
      bookingId: companyDetails.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      setStep(stepValue);
      dispatch(setCompanyDetails(resp?.payload));
    }
  };

  const renderData = () => {
    switch (step) {
      case 1:
        return (
          <ActivityCompanyType
            handleSubmit={handleSubmit}
            stepValue={2}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <BookingCalendar
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={3}
            setStep={setStep}
          />
        );
      case 3:
        return (
          <>
            {companyDetails.booking_calendar === "Rezdy" && (
              <RezdyIntegration
                handleSubmit={handleSubmit}
                loadingAdd={loading}
                stepValue={4}
                setStep={setStep}
              />
            )}
            {companyDetails.booking_calendar === "FareHarbor" && (
              <FareHarborIntegration
                handleSubmit={handleSubmit}
                loadingAdd={loading}
                stepValue={4}
                setStep={setStep}
              />
            )}
            {companyDetails.booking_calendar === "Resova" && (
              <ResovaIntegration
                handleSubmit={handleSubmit}
                loadingAdd={loading}
                stepValue={4}
                setStep={setStep}
              />
            )}
          </>
        );
      case 4:
        return (
          <CostPerPerson
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={5}
            setStep={setStep}
          />
        );
      case 5:
        return (
          <CompanyCategory
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={6}
            setStep={setStep}
          />
        );
      case 6:
        return (
          <ActivityDetails
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={8}
            setStep={setStep}
          />
        );
      case 7:
        return (
          <Stops
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={8}
            setStep={setStep}
          />
        );
      case 8:
        return (
          <LocationDetails
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={9}
            setStep={setStep}
          />
        );
      case 9:
        return (
          <ExtraVariations
            handleSubmit={handleSubmit}
            loading={loading}
            stepValue={10}
            setStep={setStep}
          />
        );
      case 10:
        return (
          <Payment
            companyDetails={companyDetails}
            loading={loading}
            current={current}
            setStep={setStep}
          />
        );

      default:
        return <PageLoader />;
    }
  };

  return (
    <>
      {console.log("companyDetailscompanyDetails", current)}
      {renderData()}
    </>
  );
};

export default ActivityAction;
