import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import AppConstants from "../appConstants";
import { authCall } from "../services";


export default function CheckoutForm({ payment, userDetails, parentCallBack, machines }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(userDetails?.email || "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_SITE_URL}${AppConstants.routes.admin.dashboard}`,
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    
    if (paymentIntent?.status === "succeeded") {
      console.log('paymentIntent', paymentIntent);
      // parentCallBack(paymentIntent);
      // navigate(AppConstants.routes.admin.dashboard)
      const res = await authCall("createTransfersNew", {
        payment_intent_id: paymentIntent.id,
        machines: machines,
        amount: payment
      });
    } else {
      setMessage(error?.message);
    }

    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occured.");
    // }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        // Access the email value like so:
        onChange={(event) => {
          setEmail(event.value.email);
        }}
        //
        // Prefill the email field like so:
        options={{ defaultValues: { email: email } }}
      />
      <PaymentElement id="payment-element" />
      <div style={{ textAlign: "center" }}>
        Powered by <span>stripe</span>
      </div>
      <div className="btn-wrapper mt-4 text-center">
        <button
          type="submit"
          className="btn btn-custom"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          {isLoading ? "Loading..." : `Pay $${payment}`}
        </button>
      </div>

      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button> */}
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
}
