import React, { useEffect, useState } from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

const minDate = moment().subtract(90, "days").format("YYYY-MM-DD");
const maxDate = moment().format("YYYY-MM-DD");

function ChartPopUp({
  handleClose,
  modalStatus,
  title,
  type,
  Chart,
  data,
  options,
  handleChangeFiter,
  startDt,
  endDt,
}) {
  // const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(()=>{
    setStartDate(startDt);
    setEndDate(endDt);
  },[]);

  const handleFilterStartDT = (date) => {
    if (date && endDate) {
      handleChangeFiter(date, endDate, type);
    }
  };

  const handleFilterEndDT = (date) => {
    if (startDate && date) {
      handleChangeFiter(startDate, date, type);
    }
  };

  return (
    <>
      <Modal
        show={modalStatus}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Chart Details:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dashboard-page custom-field-wrapper p-3 ">
          <div className="row">
            <div className="col-6 form-group">
              <label htmlFor="">Start Date:</label>
              <input
                type="date"
                className="form-control rounded-pill"
                id="StartDate"
                name="start-date"
                min={minDate}
                max={maxDate}
                value={startDate}
                autoComplete="off"
                onChange={(e) => {
                  setStartDate(e.target.value);
                  handleFilterStartDT(e.target.value);
                }}
              />
            </div>
            <div className="col-6 form-group">
              <label htmlFor="">End Date:</label>
              <input
                type="date"
                className="form-control rounded-pill"
                id="EndDate"
                name="end-date"
                min={minDate}
                max={maxDate}
                autoComplete="off"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  handleFilterEndDT(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="chart-cover p-3">
            <div className="fw-medium mb-1">{title}</div>
            <Chart data={data} options={options} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChartPopUp;
