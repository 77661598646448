import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import ImageUploads from "../util/ImageUploads";
import { generateSku } from "../util/utils";

function NightlifeMenuDetailPage({
  onSubmit,
  loading,
  nightlifeId,
  heading,
  Sidebar,
  buttons,
  details,
  companyDetails,
}) {
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [MENUTYPE, setMENUTYPE] = useState([]);
  const [menuId, setMenuId] = useState("");

  const [values, setValues] = useState({
    menuId: "",
    nightlifeId: nightlifeId,
    menuName: details?.menu_name || "",
  });

  const initialItem = {
    id: 1,
    itemName: "",
    description: "",
    itemPhoto: "",
    isItemPhotoUploaded: false,
    vegan: false,
    vegetarian: false,
    glutenFree: false,
    dairyFree: false,
    nutFree: false,
  };

  const [items, setItems] = useState([initialItem]);

  useEffect(() => {
    setValues({
      ...values,
      menuId: details?.id || "",
      nightlifeId: nightlifeId,
      menuName: details?.menu_name || "",
    });
    setItems(details?.menu_items || [initialItem]);
  }, [details]);

  // CHOICES
  const [choices, setChoices] = useState([
    { id: 1, choiceList: "", choiceName: "", additionalPrice: "" },
  ]);
  const [selectedOption, setSelectedOption] = useState(MENUTYPE[0]);
  const [selectedOptionMenu, setSelectedOptionMenu] = useState([]);

  const [options, setOptions] = useState([
    { id: 1, optionList: "", optionName: "", additionalPrice: "" },
  ]);

  const addChoiceField = () => {
    const newField = {
      id: choices.length + 1,
      choiceName: "",
      additionalPrice: "",
    };

    setChoices([...choices, newField]);
  };

  const addChoiceListField = () => {
    const newField = {
      id: choices.length + 1,
      optionList: "",
      choiceName: "",
      additionalPrice: "",
    };

    setChoices([...choices, newField]);
  };

  const addOptionField = () => {
    const newField = { id: 1, optionName: "", additionalPrice: "" };
    setOptions([...options, newField]);
  };

  const addOptionListField = () => {
    const newField = {
      id: 1,
      optionList: "",
      optionName: "",
      additionalPrice: "",
    };
    setOptions([...options, newField]);
  };

  const handleChangeChoice = (id, e) => {
    const { name, value } = e.target;
    console.log(id, name, value);
    setChoices(
      choices.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );
  };

  const handleChangeOption = (id, e) => {
    const { name, value } = e.target;
    setOptions(
      options.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );
  };

  // END CHOICES

  const handleSubmit = async (item) => {
    if (validator.current.allValid()) {
      values.items = items;
      values.buttonId = item?.id;
      if (item?.type === "another") {
        resetForm();
      }
      onSubmit(values);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handlePreview = async () => {
    if (validator.current.allValid()) {
      values.menuId = menuId;
      values.items = items;
      onSubmit(values, selectedOption?.value);
      const name = generateSku(details.name);
      navigate(`/dining/${name}`, { state: { restaurantId: details.id } });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  // reset form
  const resetForm = () => {
    setSelectedOption([]);
    setSelectedOptionMenu([]);
    setValues({ ...values, menuName: "" });
    setItems([initialItem]);
    setMenuId("");
  };

  const getItemPhoto = (id) => (img) => {
    setItems(
      items.map((field) =>
        field.id === id
          ? { ...field, itemPhoto: img, isItemPhotoUploaded: true }
          : field
      )
    );
  };

  const addNewItems = () => {
    const newField = { ...initialItem, id: items.length + 1 };
    setItems([...items, newField]);
  };

  const removeItems = (id) => {
    const newItems = items.filter((el) => el.id !== id);
    setItems(newItems);
  };

  const handleChangeMenuName = (e) => {
    const { value } = e.target;
    setValues({ ...values, menuName: value });
  };

  const handleChangeItem = (id, e) => {
    const { name, value } = e.target;
    setItems(
      items.map((field) =>
        field.id === id ? { ...field, [name]: value } : field
      )
    );
  };

  const handleChangeDiataries = (id, e) => {
    const { name, checked } = e.target;
    setItems(
      items.map((field) =>
        field.id === id ? { ...field, [name]: checked } : field
      )
    );
  };

  return (
    <>
      <div className="restaurants-page restaurant-lunch-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <Sidebar details={companyDetails} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">{heading}</div>
                <div className="details-wrapper p-3 pb-4">
                  <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-4">
                    <div className="fs-18 fw-medium">
                      This menu will be displayed in your nightlife
                    </div>
                    {/* <div
                      className="preview text-custom-primary cursor-pointer"
                      onClick={handlePreview}
                    >
                      Preview
                    </div> */}
                  </div>
                  <div className="row mb-5 mt-3">
                    <div className="col-6">
                      <label htmlFor="menuName" className="mb-xl-1">
                        Menu Name
                        <span className="text-danger">*</span>:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="menuName"
                        placeholder=""
                        autoComplete="off"
                        name="menuName"
                        value={values.menuName}
                        onChange={(e) => handleChangeMenuName(e)}
                      />
                      {validator.current.message(
                        "menu name",
                        values.menuName,
                        "required"
                      )}
                    </div>
                  </div>

                  {items.map((el, index) => (
                    <div key={index} className="info-cover">
                      <div className="main-heading2 mb-1">
                        Item {index + 1}:
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 col-xl-6">
                          <label htmlFor="newCategory" className="mb-xl-1">
                            Item Name
                            <span className="text-danger">*</span>:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="itemName"
                            placeholder=""
                            autoComplete="off"
                            name="itemName"
                            value={el.itemName}
                            onChange={(e) => handleChangeItem(el.id, e)}
                          />
                          {validator.current.message(
                            "item name",
                            el.itemName,
                            "required"
                          )}
                        </div>

                        {/* <div className="col-12 col-xl-6">
                              <label htmlFor="newCategory" className="mb-xl-1">
                                Item Price
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="itemPrice"
                                placeholder=""
                                autoComplete="off"
                                name="itemPrice"
                                value={el.itemPrice}
                                onChange={(e) => {
                                  const inputValue = handleNumericInput(
                                    e.target.value
                                  );
                                  if (
                                    inputValue !== null &&
                                    inputValue.length < 8
                                  ) {
                                    handleChangeItem(el.id, e);
                                  }
                                }}
                              />
                              {validator.current.message(
                                "item price",
                                el.itemPrice,
                                "required"
                              )}
                            </div> */}
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 col-xl-6">
                          <div className="d-flex align-items-center justify-content-between gap-2">
                            <label htmlFor="newCategory" className="mb-xl-1">
                              Description
                              <span className="text-danger">*</span>:
                            </label>
                            <div className="main-heading2 fs-12 fw-light">
                              <span id="charCount">
                                {el.description.length}
                              </span>
                              /100
                            </div>
                          </div>
                          <textarea
                            className="form-control"
                            id="itemDescription"
                            rows="4"
                            placeholder=""
                            name="description"
                            value={el.description}
                            onChange={(e) => handleChangeItem(el.id, e)}
                          ></textarea>
                          {validator.current.message(
                            "item price",
                            el.description,
                            "required"
                          )}
                        </div>

                        <div className="col-12 col-xl-6">
                          <label htmlFor="newCategory" className="mb-xl-1">
                            Item Photo
                            <span className="text-danger">*</span>:
                          </label>
                          <ImageUploads
                            getImage={getItemPhoto(el.id)}
                            uploadedImage={el.itemPhoto}
                          />
                          {validator.current.message(
                            "item photo",
                            el.itemPhoto,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-6 col-xl-2">
                          <div className="fs-md-18 d-flex mb-3 gap-2">
                            <input
                              type="checkbox"
                              name="vegan"
                              checked={el.vegan}
                              onChange={(e) => handleChangeDiataries(el.id, e)}
                            />
                            <label>Vegan</label>
                          </div>
                        </div>
                        <div className="col-6 col-xl-2">
                          <div className="fs-md-18 d-flex mb-3 gap-2">
                            <input
                              type="checkbox"
                              name="vegetarian"
                              checked={el.vegetarian}
                              onChange={(e) => handleChangeDiataries(el.id, e)}
                            />
                            <label>Vegetarian</label>
                          </div>
                        </div>
                        <div className="col-6 col-xl-2">
                          <div className="fs-md-18 d-flex mb-3 gap-2">
                            <input
                              type="checkbox"
                              name="nutFree"
                              checked={el.nutFree}
                              onChange={(e) => handleChangeDiataries(el.id, e)}
                            />
                            <label>Nut Free</label>
                          </div>
                        </div>

                        <div className="col-6 col-xl-2">
                          <div className="fs-md-18 d-flex mb-3 gap-2">
                            <input
                              type="checkbox"
                              name="dairyFree"
                              checked={el.dairyFree}
                              onChange={(e) => handleChangeDiataries(el.id, e)}
                            />
                            <label>Dairy Free</label>
                          </div>
                        </div>
                        <div className="col-6 col-xl-3">
                          <div className="fs-md-18 d-flex mb-3 gap-2">
                            <input
                              type="checkbox"
                              name="glutenFree"
                              checked={el.glutenFree}
                              onChange={(e) => handleChangeDiataries(el.id, e)}
                            />
                            <label>Gluten Free</label>
                          </div>
                        </div>
                      </div>

                      <div style={{ textAlign: "right" }}>
                        <span
                          className="glyphicon glyphicon-plus cursor-pointer text-red"
                          aria-hidden="true"
                          onClick={() => removeItems(el.id)}
                        >
                          Remove Item
                        </span>
                      </div>
                      <hr className="my-3" />
                    </div>
                  ))}
                  <div style={{ textAlign: "right" }}>
                    <span
                      className="glyphicon glyphicon-plus add-another"
                      aria-hidden="true"
                      onClick={addNewItems}
                    >
                      Add Another Item
                    </span>
                  </div>
                </div>

                <div className="col-md-6 d-none">
                  <div className="info-cover mb-4">
                    <div className="main-heading2 mb-1">Add a choice:</div>
                    <p className="fs-14 mb-3">
                      <i>
                        "A choice requires the customer to choose one option on
                        the list"
                      </i>
                    </p>
                    <div className="item-wrapper">
                      <div id="form-choice-cointainer">
                        {choices.map((el, index) => (
                          <div key={index}>
                            {Object.entries(el).map(
                              ([key, value]) =>
                                key !== "id" && (
                                  <div
                                    key={key}
                                    className="form-group row d-flex flex-wrap align-items-center"
                                  >
                                    <div className="col-12 col-xl-4">
                                      <label
                                        htmlFor="choiceList"
                                        className="mb-xl-0"
                                      >
                                        {key}:
                                      </label>
                                    </div>
                                    <div className="col-12 col-xl-8">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="choiceList"
                                        placeholder=""
                                        autoComplete="off"
                                        name={key}
                                        onChange={(e) =>
                                          handleChangeChoice(el.id, e)
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                            )}
                            <hr className="my-3" />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex flex-wrap gap-2 mt-3">
                        <div
                          className="add-another me-2"
                          onClick={addChoiceField}
                        >
                          <span
                            className="glyphicon glyphicon-plus"
                            aria-hidden="true"
                          ></span>{" "}
                          Add Another Choice
                        </div>
                        <div
                          className="add-another"
                          onClick={addChoiceListField}
                        >
                          <span
                            className="glyphicon glyphicon-plus"
                            aria-hidden="true"
                          ></span>{" "}
                          Add Another Choice list
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="info-cover mb-2">
                    <div className="main-heading2 mb-1">Add an option:</div>
                    <p className="fs-14 mb-4">
                      <i>
                        "An option allows customer to choose one option from the
                        list. For example the choice of eight salads for a
                        subway sub"
                      </i>
                    </p>
                    <div className="item-wrapper">
                      <div id="form-container-option">
                        {options.map((el) => (
                          <div key={el.id}>
                            {Object.entries(el).map(
                              ([key, value]) =>
                                key !== "id" && (
                                  <div
                                    key={key}
                                    className="form-group row d-flex flex-wrap align-items-center"
                                  >
                                    <div className="col-12 col-xl-4">
                                      <label
                                        htmlFor="choiceList"
                                        className="mb-xl-0"
                                      >
                                        {key}:
                                      </label>
                                    </div>
                                    <div className="col-12 col-xl-8">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="choiceList"
                                        placeholder=""
                                        autoComplete="off"
                                        name={key}
                                        onChange={(e) =>
                                          handleChangeOption(el.id, e)
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                            )}
                            <hr className="my-3" />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex flex-wrap gap-2 mt-3">
                        <div
                          className="add-another me-2"
                          onClick={addOptionField}
                        >
                          <span
                            className="glyphicon glyphicon-plus"
                            aria-hidden="true"
                          ></span>{" "}
                          Add Another option
                        </div>
                        <div
                          className="add-another"
                          onClick={addOptionListField}
                        >
                          <span
                            className="glyphicon glyphicon-plus"
                            aria-hidden="true"
                          ></span>{" "}
                          Add Another option List
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-1 mb-5">
                  {buttons.map((button) => (
                    <button
                      key={button.id}
                      onClick={() => handleSubmit(button)}
                      className="btn btn-custom"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : button.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NightlifeMenuDetailPage;
