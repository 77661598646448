import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { EyeFill, Plus, PencilSquare, Trash } from "react-bootstrap-icons";
import AdminSidebar from "../../layouts/AdminSidebar";
import { authCall } from "../../../services";
import AppConstants from "../../../appConstants";
import webServices from "../../../services/webServices";
import { handleNumericInput } from "../../util/utils";
import ImageUploads from "../../util/ImageUploads";
import RestaurantSubscriptionPayment from "../../../Stripe/RestaurantSubscriptionPayment";
import DiningSubscriptionPaymentForm from "../../../Stripe/DiningSubscriptionPaymentForm";

const MENUTYPE = [
  { value: "Breakfast", label: "Breakfast" },
  { value: "Lunch", label: "Lunch" },
  { value: "Dinner", label: "Dinner" },
];

function RestaurantUserProfilePopup({ show, handleClose, details }) {
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { current } = useSelector((state) => state.user);
  const [typeName, setTypeName] = useState("");
  const [evenList, setEvenList] = useState([]);

  const [menuNames, setMenuNames] = useState([]);
  const [menuId, setMenuId] = useState("");
  const [selectedOption, setSelectedOption] = useState(MENUTYPE[0]);

  const initialItem = {
    id: 1,
    category: "",
    existingCategory: "",
    isNewCategory: false,
    itemName: "",
    itemPrice: "",
    description: "",
    itemPhoto: "",
    isItemPhotoUploaded: false,
    itemPhotoOriginal: "",
    vegan: false,
    vegetarian: false,
    glutenFree: false,
    dairyFree: false,
    nutFree: false,
  };

  const initValue = {
    userName: "",
    menuName: "",
    password: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
  };

  const [items, setItems] = useState([initialItem]);
  const [values, setValues] = useState(initValue);

  const handleSubmit = async (item) => {
    if (validator.current.allValid()) {
      values.menuId = menuId;
      values.items = items;
      console.log(values);
      // if (item?.type === "another") {
      //   resetForm();
      // }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  // reset form
  const resetForm = () => {
    setValues({ ...values, menuName: "" });
    setItems([initialItem]);
    setMenuId("");
  };

  const getItemPhoto = (id) => (img) => {
    setItems(
      items.map((field) =>
        field.id === id
          ? { ...field, itemPhoto: img, isItemPhotoUploaded: true }
          : field
      )
    );
  };

  const addNewItems = () => {
    const newField = { ...initialItem, id: items.length + 1 };
    setItems([...items, newField]);
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const removeItems = (id) => {
    const newItems = items.filter((el) => el.id !== id);
    setItems(newItems);
  };

  const handleChangeItem = (id, e) => {
    const { name, value } = e.target;
    if (name === "description") {
      if (value.length <= 100) {
        setItems(
          items.map((field) =>
            field.id === id ? { ...field, [name]: value } : field
          )
        );
      }
    } else {
      setItems(
        items.map((field) =>
          field.id === id ? { ...field, [name]: value } : field
        )
      );
    }
  };

  const handleChangeDiataries = (id, e) => {
    const { name, checked } = e.target;
    setItems(
      items.map((field) =>
        field.id === id ? { ...field, [name]: checked } : field
      )
    );
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  return (
    <>
      {console.log("current", current)}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="custom-modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="fw-bold">Complete Your Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-field-wrapper___ p-3">
          {/* User Information */}
          <div className="userInformation mb-4">
            <div className="row">
              <div className="main-heading2 mb-1">User Information</div>
              <div className="col-6 col-xl-6 mt-2">
                <label htmlFor="userName" className="mb-xl-1">
                  User Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  placeholder=""
                  autoComplete="off"
                  name="userName"
                  value={values.userName}
                  onChange={(e) => handleChangeValue(e)}
                />
                {validator.current.message(
                  "user name",
                  values.userName,
                  "required"
                )}
              </div>
              <div className="col-6 col-xl-6 mt-2">
                <label htmlFor="password" className="mb-xl-1">
                  Password:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder=""
                  autoComplete="off"
                  name="password"
                  value={values.password}
                  onChange={(e) => handleChangeValue(e)}
                />
                {validator.current.message(
                  "password",
                  values.password,
                  "required"
                )}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 col-xl-6 mt-2">
                <label htmlFor="firstName" className="mb-xl-1">
                  First Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder=""
                  autoComplete="off"
                  name="firstName"
                  value={values.firstName}
                  onChange={(e) => handleChangeValue(e)}
                />
                {validator.current.message(
                  "first name",
                  values.firstName,
                  "required"
                )}
              </div>
              <div className="col-6 col-xl-6 mt-2">
                <label htmlFor="lastName" className="mb-xl-1">
                  Last Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder=""
                  autoComplete="off"
                  name="lastName"
                  value={values.lastName}
                  onChange={(e) => handleChangeValue(e)}
                />
                {validator.current.message(
                  "last name",
                  values.lastName,
                  "required"
                )}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6 col-xl-6 mt-2">
                <label htmlFor="mobile" className="mb-xl-1">
                  Mobile:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  placeholder=""
                  autoComplete="off"
                  name="mobile"
                  value={values.mobile}
                  onChange={(e) => handleChangeValue(e)}
                />
                {validator.current.message("mobile", values.mobile, "required")}
              </div>
              <div className="col-6 col-xl-6 mt-2">
                <label htmlFor="email" className="mb-xl-1">
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder=""
                  autoComplete="off"
                  name="email"
                  value={values.email}
                  onChange={(e) => handleChangeValue(e)}
                />
                {validator.current.message("email", values.email, "required")}
              </div>
            </div>
          </div>

          {/* Card Information */}
          <div className="userInformation mb-4">
            <div className="row">
              <div className="main-heading2 mb-1">Card Information</div>
              {/* <RestaurantSubscriptionPayment
                payment={1}
                // userDetails={current}
                // parentCallBack={handleCallBack}
              /> */}
              <DiningSubscriptionPaymentForm />
            </div>
          </div>

          {/* Menu Information */}
          <div className="menuInformation mt-1">
            <div className="row">
              <div className="main-heading2 mb-1">Menu Information</div>
              <div className="col-12 col-xl-6 mt-2">
                <label htmlFor="newCategory" className="mb-xl-0">
                  Menu Type:
                </label>
                <Select
                  styles={{ zIndex: 10000 }}
                  className="text-body mt-1"
                  placeholder="Select"
                  value={selectedOption}
                  onChange={handleChange}
                  options={MENUTYPE}
                />
                {validator.current.message(
                  "menu type",
                  selectedOption?.label,
                  "required"
                )}
              </div>
            </div>
            <div className="row">
              <div className="details-wrapper p-3 pb-4">
                {items.map((el, index) => (
                  <div key={index} className="info-cover mt-2">
                    <div className="main-heading2" style={{ fontSize: 20 }}>
                      Item {index + 1}:
                    </div>
                    <div className="row mt-2">
                      <div className="col-12 col-xl-6">
                        <label htmlFor="newCategory" className="mb-xl-1">
                          Item Name
                          <span className="text-danger">*</span>:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="itemName"
                          placeholder=""
                          autoComplete="off"
                          name="itemName"
                          value={el.itemName}
                          onChange={(e) => handleChangeItem(el.id, e)}
                        />
                        {/* {validator.current.message(
                          "item name",
                          el.itemName,
                          "required"
                        )} */}
                      </div>

                      {/* <div className="col-12 col-xl-6">
                              <label htmlFor="newCategory" className="mb-xl-1">
                                Item Price
                                <span className="text-danger">*</span>:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="itemPrice"
                                placeholder=""
                                autoComplete="off"
                                name="itemPrice"
                                value={el.itemPrice}
                                onChange={(e) => {
                                  const inputValue = handleNumericInput(
                                    e.target.value
                                  );
                                  if (
                                    inputValue !== null &&
                                    inputValue.length < 8
                                  ) {
                                    handleChangeItem(el.id, e);
                                  }
                                }}
                              />
                              {validator.current.message(
                                "item price",
                                el.itemPrice,
                                "required"
                              )}
                            </div> */}
                    </div>

                    <div className="row mt-3">
                      <div className="col-12 col-xl-6">
                        <div className="d-flex align-items-center justify-content-between gap-2">
                          <label htmlFor="newCategory" className="mb-xl-1">
                            Description
                            <span className="text-danger">*</span>:
                          </label>
                          <div className="main-heading2 fs-12 fw-light">
                            <span id="charCount">{el.description.length}</span>
                            /100
                          </div>
                        </div>
                        <textarea
                          className="form-control"
                          id="itemDescription"
                          rows="4"
                          placeholder=""
                          name="description"
                          value={el.description}
                          onChange={(e) => handleChangeItem(el.id, e)}
                        ></textarea>
                        {validator.current.message(
                          "item price",
                          el.itemName !== "" && el.description,
                          "required"
                        )}
                      </div>

                      <div className="col-12 col-xl-6">
                        <label htmlFor="newCategory" className="mb-xl-1">
                          Item Photo
                          <span className="text-danger">*</span>:
                        </label>
                        <ImageUploads
                          getImage={getItemPhoto(el.id)}
                          uploadedImage={el.itemPhoto}
                        />
                        {validator.current.message(
                          "item photo",
                          el.itemName !== "" && el.itemPhoto,
                          "required"
                        )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-6 col-xl-2">
                        <div className="fs-md-18 d-flex mb-3 gap-2">
                          <input
                            type="checkbox"
                            name="vegan"
                            checked={el.vegan}
                            onChange={(e) => handleChangeDiataries(el.id, e)}
                          />
                          <label>Vegan</label>
                        </div>
                      </div>
                      <div className="col-6 col-xl-2">
                        <div className="fs-md-18 d-flex mb-3 gap-2">
                          <input
                            type="checkbox"
                            name="vegetarian"
                            checked={el.vegetarian}
                            onChange={(e) => handleChangeDiataries(el.id, e)}
                          />
                          <label>Vegetarian</label>
                        </div>
                      </div>
                      <div className="col-6 col-xl-2">
                        <div className="fs-md-18 d-flex mb-3 gap-2">
                          <input
                            type="checkbox"
                            name="nutFree"
                            checked={el.nutFree}
                            onChange={(e) => handleChangeDiataries(el.id, e)}
                          />
                          <label>Nut Free</label>
                        </div>
                      </div>

                      <div className="col-6 col-xl-3">
                        <div className="fs-md-18 d-flex mb-3 gap-2">
                          <input
                            type="checkbox"
                            name="dairyFree"
                            checked={el.dairyFree}
                            onChange={(e) => handleChangeDiataries(el.id, e)}
                          />
                          <label>Dairy Free</label>
                        </div>
                      </div>
                      <div className="col-6 col-xl-3">
                        <div className="fs-md-18 d-flex mb-3 gap-2">
                          <input
                            type="checkbox"
                            name="glutenFree"
                            checked={el.glutenFree}
                            onChange={(e) => handleChangeDiataries(el.id, e)}
                          />
                          <label>Gluten Free</label>
                        </div>
                      </div>
                    </div>

                    <div style={{ textAlign: "right" }}>
                      <span
                        className="glyphicon glyphicon-plus cursor-pointer text-red"
                        aria-hidden="true"
                        onClick={() => removeItems(el.id)}
                      >
                        Remove Item
                      </span>
                    </div>
                    <hr className="my-3" />
                  </div>
                ))}
                <div style={{ textAlign: "right" }}>
                  <span
                    className="glyphicon glyphicon-plus add-another"
                    aria-hidden="true"
                    onClick={addNewItems}
                  >
                    Add Another Item
                  </span>
                </div>
              </div>

              <div className="btn-wrapper d-flex flex-column flex-md-row flex-wrap justify-content-center row-gap-3 column-gap-4 mt-1 mb-5">
                <button
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                  className="btn btn-custom"
                  disabled={loading}
                >
                  Close
                </button>

                <button
                  onClick={handleSubmit}
                  className="btn btn-custom"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Done"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RestaurantUserProfilePopup;
