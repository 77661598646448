import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyDetails } from "../../redux/actions/company.actions";
import webServices from "../../services/webServices";
import DesiredServices from "./DesiredServices";
import RestaurantCategory from "./RestaurantCategory";
import RestaurantDetails from "./RestaurantDetails";
import RestaurantMenu from "./RestaurantMenu";
import LunchMenu from "./LunchMenu";
import DinnerMenu from "./DinnerMenu";
import RestaurantPayment from "./RestaurantPayment";
import PageLoader from "../util/PageLoader";

const RestaurantAction = () => {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.user);
  const [companyDetails, setCompanyDetailss] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [step, setStep] = useState(0);

  const fetchRestaurantData = async () => {
    setLoadingDetails(true);
    const resp2 = await webServices.getCompnayDetails({
      companyType: "Restaurant",
      userId: current?.id,
    });
    if (resp2?.type === "success") {
      setCompanyDetailss(resp2?.payload);
      setLoadingDetails(false);
      setStep(resp2?.payload?.profile_lavel);
      dispatch(setCompanyDetails(resp2?.payload));
    }
  };

  useEffect(() => {
    fetchRestaurantData();
  }, []);

  const handleSubmit = async (data, stepValue) => {
    setLoading(true);

    const resp = await webServices.createRestaurant({
      ...data,
      restaurantId: companyDetails?.id,
    });
    if (resp?.type === "success") {
      setLoading(false);
      setStep(stepValue);
      dispatch(setCompanyDetails(resp?.payload));
      setCompanyDetailss(resp?.payload);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmitMenu = async (data, stepValue, profileLavel) => {
    setLoading(true);
    const resp = await webServices.createMenu({
      ...data,
      userId: current?.id,
      restaurantId: companyDetails?.id,
    });

    if (resp.type === "success") {
      setLoading(false);

      // update in restaurant
      const updateData = {
        profileLavel: profileLavel,
        restaurantId: profileLavel > 1 ? companyDetails?.id : "",
        userId: current?.id,
      };
      const res = await webServices.createRestaurant(updateData);
      dispatch(setCompanyDetails(res?.payload));
      if (profileLavel === 1) {
        fetchRestaurantData();
      }
      setStep(stepValue);
      toast.dismiss();
      toast.success(`Menu created successfully.`);
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const renderData = () => {
    switch (step) {
      case 1:
        return (
          <DesiredServices
            companyDetails={companyDetails}
            handleSubmit={handleSubmit}
            loading={loading}
            loadingDetails={loadingDetails}
            stepValue={2}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <RestaurantCategory
            companyDetails={companyDetails}
            handleSubmit={handleSubmit}
            loading={loading}
            loadingDetails={loadingDetails}
            stepValue={3}
            setStep={setStep}
          />
        );
      case 3:
        return (
          <RestaurantDetails
            companyDetails={companyDetails}
            handleSubmit={handleSubmit}
            loading={loading}
            loadingDetails={loadingDetails}
            setStep={setStep}
          />
        );
      case 4:
        return (
          <RestaurantMenu
            companyDetails={companyDetails}
            handleSubmit={handleSubmitMenu}
            loading={loading}
            loadingDetails={loadingDetails}
            userId={current?.id}
            setStep={setStep}
          />
        );
      // case 5:
      //   return (
      //     <LunchMenu
      //       companyDetails={companyDetails}
      //       handleSubmit={handleSubmitMenu}
      //       loading={loading}
      //       loadingDetails={loadingDetails}
      //       setStep={setStep}
      //     />
      //   );
      // case 6:
      //   return (
      //     <DinnerMenu
      //       companyDetails={companyDetails}
      //       handleSubmit={handleSubmitMenu}
      //       loading={loading}
      //       loadingDetails={loadingDetails}
      //       setStep={setStep}
      //     />
      //   );
      case 5:
        return (
          <RestaurantPayment
            companyDetails={companyDetails}
            handleSubmit={handleSubmitMenu}
            loading={loading}
            loadingDetails={loadingDetails}
            current={current}
            setStep={setStep}
          />
        );

      default:
        return <PageLoader />;
    }
  };

  return <>{renderData()}</>;
};

export default RestaurantAction;
