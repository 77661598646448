import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BannerImg from "../../../../assets/images/site/Banner kiosk img.png";
// import CurveLogo from "../../../../assets/images/site/curve 1.png"
import "./NewBanner.scss";

const NewBanner = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    const registerSection = document.getElementById("register");
    if (registerSection) {
      registerSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <section className="banner2" style={{ marginTop: "90px" }}>
        <div
          className="container-fluid"
          id="bannerBackground"
          style={{ height: "812px" }}
        >
          <div className="row">
            {/* Left Side: Text Section */}
            <div className="col-lg-4 order-md-first">
              <div className="banner2-txt mt-0">
                <div
                  className="heading text-center mt-0 mb-3"
                  id="bannerheading"
                >
                  REGISTER NOW
                </div>

                <p
                  className="bannertext1 mt-0"
                  style={{
                    fontSize: "27px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    lineHeight: "49px",
                  }}
                  id="register2ndtext"
                >
                  AND RECEIVE ONE MONTH'S <br /> FREE ADVERTISING
                </p>

                {/* <p
                  className="bannertext1"
                  style={{
                    fontSize: "27px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    lineHeight:"49px"
                  }}
                  id="register2ndtext"
                >
                  
                </p> */}

                <p
                  className="bannertext1"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    lineHeight: "33px",
                  }}
                  id="registerlasttext"
                >
                  Touch-iT is an Australian owned company that has created an
                  innovative, easy to use, self-service, information kiosk
                </p>

                <button
                  className="bannerbutton text-center mt-3"
                  onClick={handleRegisterClick}
                  style={{ width: "50%" }}
                >
                  REGISTER NOW
                </button>
              </div>
            </div>

            {/* Right Side: Static Image */}
            <div className="col-lg-8 order-md-last">
              <div className="static-image-container text-center mt-5 mb-0">
                <img src={BannerImg} alt="Static" className="img-fluid mt-4" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewBanner;
