import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { QuestionCircle } from "react-bootstrap-icons";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../../layouts/AdminSidebar";
import webServices from "../../../services/webServices";
import AppConstants from "../../../appConstants";
import { authCall } from "../../../services";
import AdsPlanConfimPopUp from "./AdsPlanConfimPopUp";
import VideoTutorialPopUp from "../VideoTutorialPopUp";

const RunAdVideo = AppConstants.tutorial.runAds;

function RunAnAdd() {
  const location = useLocation();
  const { details } = location.state || {};
  const navigate = useNavigate();
  const { current } = useSelector((state) => state.user);
  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [timeOfDay, setTimeOfDay] = useState([]);
  const [duration, setDuration] = useState([]);
  const [goalValue, setGoalValue] = useState([]);
  const [checkedDayOfWeek, setCheckedDayOfWeek] = useState([]);
  const [checkedTimeOfDay, setCheckedTimeOfDay] = useState([]);
  const [checkedDurations, setCheckedDurations] = useState(0);
  const [checkedDurationsLabel, setCheckedDurationsLavel] = useState(0);
  const [checkedStyles, setCheckedStyles] = useState("");
  const [plan, setPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [desirePlan, setDesirePlan] = useState([]);
  const [loadingDesirePlan, setLoadingDesirePlan] = useState(false);
  const [dayOfWeekCheckedId, setDayOfWeekCheckedId] = useState([]);
  const [checkedTimeOfDayId, setCheckedTimeOfDayId] = useState([]);
  const [show, setShow] = useState(false);
  const [daysRemain, setDaysRemain] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [saveAmount, setSaveAmount] = useState(0);
  const [upgradeEndDate, setUpgradeEndDate] = useState("");
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const resp = await webServices.getPlayerGoals();
      if (resp?.type === "success") {
        const { dayOfWeek, timeOfDay, duration, goalValue } = resp?.payload;
        setDayOfWeek(dayOfWeek);
        setTimeOfDay(timeOfDay);
        setDuration(duration);
        setGoalValue(goalValue);
        setLoading(false);
      }

      setLoadingDesirePlan(true);
      const resp2 = await webServices.getPlans({
        type: "adsSubscription",
      });
      if (resp2?.type === "success") {
        setLoadingDesirePlan(false);
        setDesirePlan(resp2?.payload);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const day_of_week = details?.day_of_week || [];
    const time_of_day = details?.time_of_day || [];
    const added_style = details?.added_style;
    const durations = details?.durations;
    const desired_plan = details?.desired_plan;
    const amount = details?.amount;

    // checked style
    setCheckedStyles(added_style);

    // checked duration
    const checkedDuratios = duration.filter(
      (item) => +item.label === durations
    );
    setCheckedDurationsLavel(durations);
    setCheckedDurations(checkedDuratios[0]?.values);

    // check day of week
    setCheckedDayOfWeek(day_of_week);
    const checkedIds = day_of_week
      .filter((item) => item.values > 0)
      .map((item) => item.id);
    setDayOfWeekCheckedId(checkedIds);

    // check time of day
    setCheckedTimeOfDay(time_of_day);
    const checkedTimeOfDayIds = time_of_day
      .filter((item) => item.values > 0)
      .map((item) => item.id);
    setCheckedTimeOfDayId(checkedTimeOfDayIds);

    setPlan(desired_plan);
    setMonthlyAmount(amount);
  }, [details, duration]);

  const handleCheckboxChange = (id) => {
    const changeStatus = dayOfWeekCheckedId.includes(id)
      ? dayOfWeekCheckedId.filter((itemId) => itemId !== id)
      : [...dayOfWeekCheckedId, id];
    setDayOfWeekCheckedId(changeStatus);
  };

  const handleCheckboxChangeTimeOfDay = (id) => {
    const changeStatus = checkedTimeOfDayId.includes(id)
      ? checkedTimeOfDayId.filter((itemId) => itemId !== id)
      : [...checkedTimeOfDayId, id];
    setCheckedTimeOfDayId(changeStatus);
  };

  // Day of Week
  const handleCheckDayOfWeek = (event, day) => {
    if (event.target.checked) {
      setCheckedDayOfWeek([...checkedDayOfWeek, day]);
    } else {
      setCheckedDayOfWeek(
        checkedDayOfWeek.filter((item) => item.id !== day.id)
      );
    }
  };
  const calculateAverageDayOfWeek = () => {
    if (checkedDayOfWeek.length === 0) return 0;
    const total = checkedDayOfWeek.reduce((sum, item) => sum + +item.values, 0);
    return (total / checkedDayOfWeek.length).toFixed(2);
  };

  // Time of Day
  const handleCheckTimeOfDay = (event, day) => {
    if (event.target.checked) {
      setCheckedTimeOfDay([...checkedTimeOfDay, day]);
    } else {
      setCheckedTimeOfDay(
        checkedTimeOfDay.filter((item) => item.id !== day.id)
      );
    }
  };
  const calculateAverageTimeOfDay = () => {
    if (checkedTimeOfDay.length === 0) return 0;
    const total = checkedTimeOfDay.reduce((sum, item) => sum + +item.values, 0);
    return (total / checkedTimeOfDay.length).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const goalPlan = handleGoalValue();
    setPlan(goalPlan);
    toast.dismiss();
    if (
      checkedDayOfWeek.length > 0 &&
      checkedTimeOfDay.length > 0 &&
      checkedDurationsLabel > 0 &&
      checkedStyles !== "" &&
      goalPlan !== "" &&
      monthlyAmount != 0
    ) {
      setSubmitLoading(true);
      const values = {
        adsId: details?.id,
        userId: current?.id,
        licenceId: current?.licence_id || null,
        dayOfWeek: checkedDayOfWeek,
        timeOfDay: checkedTimeOfDay,
        duration: checkedDurationsLabel,
        addedStyle: checkedStyles,
        monthlyAmount: +monthlyAmount,
        plan: goalPlan,
      };
      const resp = await authCall("runAnAdd", values);
      if (resp?.type === "success") {
        setSubmitLoading(false);
        const updatedAdsDetails = {
          ...details,
          id: details?.id ? details?.id : resp?.payload?.id,
          added_style: resp?.payload?.added_style,
          amount: +monthlyAmount,
          desired_plan: goalPlan,
          skipPayment: +details?.amount === +monthlyAmount || false,
        };
        navigate(AppConstants.routes.admin.selectScreen, {
          state: { adsDetails: updatedAdsDetails },
        });
      } else {
        toast.success(resp?.errormessage);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please select the desired plan.");
      setSubmitLoading(false);
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  const isCheckedAll = () => {
    const checkedAll =
      +calculateAverageDayOfWeek() > 0 &&
      +calculateAverageTimeOfDay() > 0 &&
      +checkedDurations > 0 &&
      checkedStyles !== "";
    return checkedAll || false;
  };

  const totalAmount = (value) => {
    const total =
      +value /
      (+calculateAverageDayOfWeek() +
        +calculateAverageTimeOfDay() +
        +checkedDurations);
    return formatNumber(total.toFixed(0));
  };

  const close = () => {
    setShow(false);
  };

  const handleConfirm = () => {
    if (+details.amount === +monthlyAmount) {
      toast.dismiss();
      toast.error("You have already this plan. Please select different plan.");
    } else {
      setShow(true);
      const startDate = moment(details?.start_date);
      const endDate = startDate.clone().add(30, "days").format("YYYY-MM-DD");
      const today = moment();
      const daysRem = moment(endDate).diff(today, "days");
      const newEndDate = moment(endDate).format("Do [of] MMMM YYYY");
      const upgradeEndDate = today.clone().add(30, "days").format("YYYY-MM-DD");
      setDaysRemain(daysRem);
      setEndDate(newEndDate);
      setUpgradeEndDate(moment(upgradeEndDate).format("Do [of] MMMM YYYY"));

      //for upgrade
      const save = ((details.amount / 30) * daysRem).toFixed(0);
      setSaveAmount(save);
    }
  };

  const handleConfirmSubmit = async () => {
    if (
      checkedDayOfWeek.length > 0 &&
      checkedTimeOfDay.length > 0 &&
      checkedDurationsLabel > 0 &&
      checkedStyles !== "" &&
      plan !== "" &&
      monthlyAmount != 0
    ) {
      setSubmitLoading(true);
      const values = {
        adsId: details?.id,
        userId: current?.id,
        licenceId: current?.licence_id || null,
        dayOfWeek: checkedDayOfWeek,
        timeOfDay: checkedTimeOfDay,
        duration: checkedDurationsLabel,
        addedStyle: checkedStyles,
        monthlyAmount: +monthlyAmount,
        plan,
      };
      const resp = await authCall("runAnAdd", values);
      if (resp?.type === "success") {
        setSubmitLoading(false);
        const updatedAdsDetails = {
          ...details,
          amount:
            details?.amount > monthlyAmount
              ? monthlyAmount
              : monthlyAmount - saveAmount,
          desired_plan: plan,
          updateAds: true,
        };
        navigate(AppConstants.routes.admin.selectScreen, {
          state: { adsDetails: updatedAdsDetails },
        });
      } else {
        toast.success(resp?.errormessage);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please select the desired plan.");
      setSubmitLoading(false);
    }
  };

  const handleGoalValue = () => {
    const goalValue = desirePlan.filter((el) => +el.amount === +monthlyAmount);
    const total = totalAmount(goalValue[0]?.goal_value);
    return total;
  };

  const handleClickTutorial = () => {
    setVideoURL(RunAdVideo);
  };

  const tutoralClose = () => {
    setVideoURL("");
  };

  return (
    <>
      <div className="gain-max-exposer py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title d-flex flex-wrap justify-content-between gap-2">
                  <span>Gain Maximum Exposure</span>
                  <span
                    className="cursor-pointer"
                    onClick={handleClickTutorial}
                  >
                    <QuestionCircle />
                  </span>
                </div>
                <div className="details-wrapper pb-lg-4">
                  <div className="sub-title mb-3">
                    Run an add accross our entire network:
                  </div>
                  <div className="info-cover mb-4">
                    <div className="cover-box mb-2">
                      <div className="main-heading2 mb-1">
                        Desired Day And Time:
                      </div>
                      <p className="m-0">
                        <i>
                          Please select from the options below (all that apply):
                        </i>
                      </p>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 me-2 p-0">
                        Day of Week :
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-2 column-gap-4">
                        {loading &&
                          Array(7)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <Skeleton count={1} width={25} height={25} />
                                <label className="label-title text-custom-primary m-0">
                                  <Skeleton count={1} width={35} />
                                </label>
                              </div>
                            ))}
                        {!loading &&
                          dayOfWeek.map((el, index) => (
                            <>
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <input
                                  type="checkbox"
                                  name="dayOfWeek"
                                  id={el.label}
                                  checked={dayOfWeekCheckedId.includes(el.id)}
                                  onChange={(e) => {
                                    handleCheckDayOfWeek(e, el);
                                    handleCheckboxChange(el.id);
                                  }}
                                />
                                <label
                                  className="label-title text-custom-primary m-0"
                                  htmlFor={el.label}
                                >
                                  {el.label}
                                </label>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 me-2 p-0">
                        Time of Day :
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-2 column-gap-4">
                        {loading &&
                          Array(4)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <Skeleton count={1} width={25} height={25} />
                                <label className="label-title text-custom-primary m-0">
                                  <Skeleton count={1} width={65} />
                                </label>
                              </div>
                            ))}

                        {!loading &&
                          timeOfDay.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2"
                            >
                              <input
                                type="checkbox"
                                name="timeOfDay"
                                id={el.label}
                                checked={checkedTimeOfDayId.includes(el.id)}
                                onChange={(e) => {
                                  handleCheckTimeOfDay(e, el);
                                  handleCheckboxChangeTimeOfDay(el.id);
                                }}
                              />
                              <label
                                className="label-title text-custom-primary m-0"
                                htmlFor={el.label}
                              >
                                {el.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="info-cover mb-4">
                    <div className="cover-box mb-2">
                      <div className="main-heading2 mb-1">Add Details:</div>
                      <p className="m-0">
                        <i>Please select from the options below:</i>
                      </p>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 p-0">
                        Add Style:
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-3 column-gap-4">
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="LAV"
                            checked={
                              checkedStyles === "Live Action Video" || false
                            }
                            onChange={() =>
                              setCheckedStyles("Live Action Video")
                            }
                            disabled={details?.id || false}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="LAV"
                          >
                            Live Action Video
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="Animation"
                            checked={checkedStyles === "Animation" || false}
                            onChange={() => setCheckedStyles("Animation")}
                            disabled={details?.id || false}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="Animation"
                          >
                            Animation
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="ImageSlideshow"
                            checked={
                              checkedStyles === "Image Slideshow" || false
                            }
                            onChange={() => setCheckedStyles("Image Slideshow")}
                            disabled={details?.id || false}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="ImageSlideshow"
                          >
                            Image Slideshow
                          </label>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            type="radio"
                            className="custom-radio cursor-pointer m-0"
                            name="dayOfWeek"
                            id="StaticImage"
                            checked={checkedStyles === "Static Image" || false}
                            onChange={() => setCheckedStyles("Static Image")}
                            disabled={details?.id || false}
                          />
                          <label
                            className="label-title text-custom-primary m-0"
                            htmlFor="StaticImage"
                          >
                            Static Image
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap row-gap-2 column-gap-2 mb-3">
                      <div className="title-name fw-semibold col-lg-2 p-0">
                        Add Length :
                      </div>
                      <div className="radio-wrapper d-flex flex-wrap row-gap-3 column-gap-4">
                        {loading &&
                          Array(3)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center gap-2"
                              >
                                <Skeleton
                                  count={1}
                                  width={25}
                                  height={25}
                                  style={{ borderRadius: "50%" }}
                                />
                                <label className="label-title text-custom-primary m-0">
                                  <Skeleton count={1} width={65} />
                                </label>
                              </div>
                            ))}
                        {!loading &&
                          duration.map((el, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center gap-2"
                            >
                              <input
                                type="radio"
                                className="custom-radio m-0 cursor-pointer"
                                name="duration"
                                value={el.label}
                                id={el.label}
                                checked={
                                  +checkedDurationsLabel === +el.label || false
                                }
                                disabled={details?.id || false}
                                onChange={() => {
                                  setCheckedDurations(el.values);
                                  setCheckedDurationsLavel(el.label);
                                }}
                              />
                              <label
                                className="label-title text-custom-primary m-0"
                                htmlFor={el.label}
                              >
                                {el.label} Seconds
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="info-cover">
                    <div className="cover-box mb-2">
                      <div className="main-heading2 mb-1">Desired Views:</div>
                      <p className="m-0">
                        <i>
                          Please select the desired plan from the list below:
                        </i>
                      </p>
                    </div>
                    {desirePlan.length === 0 && (
                      <div className="row g-2">
                        {Array(8)
                          .fill()
                          .map((_, index) => (
                            <div key={index} className="col-6 col-md-3">
                              <Skeleton
                                count={1}
                                height={70}
                                className="w-100 rounded"
                              />
                            </div>
                          ))}
                      </div>
                    )}
                    {desirePlan.length > 0 && (
                      <div className="row g-2">
                        {desirePlan.map((el, index) => (
                          <div key={index} className="col-6 col-md-3">
                            <div className="stylist-wrapper">
                              <input
                                type="radio"
                                className="d-none m-0"
                                name="planType"
                                id={el?.amount}
                                checked={
                                  totalAmount(el?.goal_value) === plan || false
                                }
                                value={el?.goal_value}
                                // checked={
                                //   el?.amount === +monthlyAmount || false
                                // }
                                // disabled={!isCheckedAll() || false}
                                // onClick={() => {
                                //   setPlan(totalAmount(el?.goal_value));
                                //   setMonthlyAmount(el?.amount);
                                // }}
                              />
                              <div
                                className={`${
                                  +el.amount === +monthlyAmount
                                    ? "label-title-selected"
                                    : "bg-secondary bg-opacity-25"
                                } stylist-box w-100 rounded pointer`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (isCheckedAll()) {
                                    setPlan(totalAmount(el?.goal_value));
                                    setMonthlyAmount(el?.amount);
                                  }
                                }}
                                disabled={!isCheckedAll() || false}
                              >
                                <div
                                  className="plan-type fw-semibold fs-12"
                                  style={{ padding: "10px 0 0 10px" }}
                                >
                                  <sup>$</sup>
                                  {el?.amount}
                                  <sup>/month</sup>
                                </div>
                                <div className="text-center" htmlFor="option1">
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    Monthly Plays:
                                  </span>
                                  <div
                                    className="stylist-name text-capitalize"
                                    style={{
                                      fontWeight: 600,
                                      paddingBottom: 3,
                                    }}
                                  >
                                    {isCheckedAll()
                                      ? totalAmount(el?.goal_value)
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="btn-wrapper text-end mt-4 mb-2 pt-2">
                {details ? (
                  <button
                    type="submit"
                    className="btn btn-custom"
                    onClick={
                      +details.amount === +monthlyAmount
                        ? handleSubmit
                        : handleConfirm
                    }
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-custom"
                    onClick={handleSubmit}
                    disabled={submitLoading || false}
                  >
                    {submitLoading ? "Loading..." : "Submit"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdsPlanConfimPopUp
        modalStatus={show}
        handleClose={close}
        planType={details?.amount > monthlyAmount ? "downgrade" : "upgrade"}
        data={{
          endDays: daysRemain,
          endDate: endDate,
          startDate: endDate,
          amount: monthlyAmount,
          views: plan,
          save: saveAmount,
          upgradeEndDate: upgradeEndDate,
        }}
        handleConfirm={handleConfirmSubmit}
      />
      <VideoTutorialPopUp videoURL={videoURL} closeVideo={tutoralClose} />
    </>
  );
}

export default RunAnAdd;
