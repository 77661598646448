import React from "react";
import OurMachinesImg from "../../../../assets/images/site/OurMachinesImg.png";
import fuelIcon from "../../../../assets/images/icons/fuelicon.svg";
import contactIcon from "../../../../assets/images/icons/contacticon.svg";
import fingerIcon from "../../../../assets/images/icons/finger.svg";
import paymentIcon from "../../../../assets/images/icons/paymenticon.svg";
import "./OurMachines.scss";

const OurMachines = () => {
  return (
    <div className="ourmachines">
    <div className="ourmachine_wrapper mt-5 container">
      <div className="heading" style={{ fontFamily: "Arial Rounded MT Std", fontSize: "25px" }} id="machineheading">OUR Machines</div>
      <div className="row mt-3 align-items-center" style={{ height: "100%" }}>
        <div className="col-12 col-lg-6 d-flex flex-column justify-content-between gap-4 mt-lg-0">
          <img
            src={OurMachinesImg}
            className="w"
            height={688}
            // width={522}
            alt="OurMachinesImg"
            style={{maxWidth:"522px", width:"100%"}}
          />
        </div>
        <div className="col-12 col-lg-6 d-flex flex-column align-items-start mt-0 mb-5">
          <div className="d-flex align-items-center py-3 px-4 feature-wrapper">
            <div className="icon-wrapper">
              <img src={fuelIcon} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3">
              Our kiosks are self standing, 205cm tall by 96cm wide, ensuring
              your listing gets noticed
            </div>
          </div>

          <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
            <div className="icon-wrapper">
              <img src={contactIcon} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3">
              Our kiosks are some of the brightest on the market, ensuring your
              listing will catch your customers attention
            </div>
          </div>

          <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
            <div className="icon-wrapper">
              <img src={fingerIcon} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3">
              Our kiosk offers a full capacitive touch experience to ensure a
              smooth, user experience
            </div>
          </div>

          <div className="d-flex align-items-center py-3 px-4 feature-wrapper mt-5">
            <div className="icon-wrapper">
              <img src={paymentIcon} height={34} width={34} />
            </div>
            <div className="text-wrapper ms-3">
              Our kiosks have an inbuilt payment terminal ensuring bookings and
              food deliveries can be created without exposing credit card
              information
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default OurMachines;
