import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import moment from "moment";
import AppConstants from "../../appConstants";
import webServices from "../../services/webServices";
import nightlifeBanner from "./../../assets/images/banner/nightlife-banner2.png";
import { authCall } from "../../services";
import authService from "../../services/authServices";
import BannerComponent from "../BannerComponent";
import StarRating from "../common/StarRating";

function NightLife() {
  const navigate = useNavigate();
  const location = useLocation();
  const { nightId } = location.state || {};
  const { getIpAddress, getMachineConfig } = authService;
  const [venueDetails, setVenueDetails] = useState({});
  const [banners, setBanners] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [events, setEvents] = useState([]);
  const [hours, setHours] = useState([]);
  const [isReveal, setIsReveal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!nightId) {
      navigate(AppConstants.routes.admin.dashboard, { replace: true });
    }
  }, [nightId, navigate]);

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.nightLifeDetails(nightId);
      if (resp?.type === "success") {
        setVenueDetails(resp?.payload);
        setEvents(resp?.payload?.events);
        setBanners(resp?.payload?.banners);
        setHighlights(resp?.payload?.highlights);
        setExclusions(resp?.payload?.exclusions);
        setHours(
          resp?.payload?.service_hours
            ? Object.entries(resp?.payload?.service_hours)
            : []
        );
      }
    }
    fetchData();
  }, []);

  const handleClickEventDetails = (items) => {
    navigate(AppConstants.routes.nightLife.nightLifeEventDetails, {
      state: { items },
    });
  };

  const handleClickReveal = async () => {
    setIsReveal(true);
    const ipDetails = await getIpAddress();
    const machine = getMachineConfig();
    await authCall("touchCounts", {
      code: venueDetails?.drink_code,
      ipAddress: ipDetails?.ip,
      ipDetails,
      machineId: machine?.id,
      companyId: venueDetails?.id,
      companyUserId: venueDetails?.user_id,
      touchType: "Night",
    });
  };

  return (
    <>
      <div className="nightlife-page long-screen-page">
        {/* BANNER */}
        <BannerComponent
          banner={banners}
          title={venueDetails?.name}
          subTitle="Surfers Paradise"
          button={{ title: "Back" }}
        />
        <section className="banner d-none">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7 col-lg-8 order-md-last">
                <div
                  id="myCarousel"
                  className="custom-carousel-wrapper carousel slide carousel-fade"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#myCarousel"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#myCarousel"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                  </div>

                  <div className="carousel-inner">
                    <div className="carousel-item item active">
                      <img
                        src={require("../../assets/images/banner/nightlife-banner.jpg")}
                        alt="Booking"
                        className="d-block w-100"
                      />
                    </div>
                    <div className="carousel-item item">
                      <img
                        src={require("../../assets/images/banner/slider-img-2.jpg")}
                        alt="Booking"
                        className="d-block w-100"
                      />
                    </div>
                  </div>

                  <button
                    className="carousel-control-prev d-md-none"
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next d-md-none"
                    type="button"
                    data-bs-target="#myCarousel"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 order-md-first">
                <div className="banner-txt">
                  <h2 className="animated fadeInRight">{venueDetails?.name}</h2>
                  <p>Surfers Paradise</p>
                  <Link href="#" className="book-now">
                    Enjoy!..
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="nightlife-card-info pt-4">
          <div className="container-fluid" style={{ maxWidth: "1010px" }}>
            <div className="row">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="bg-box overview-box p-3 p-md-4 mb-4">
                  <div className="heading main-heading2 mb-2">Overview</div>
                  <div className="description fs-md-18">
                    {venueDetails.overview}
                  </div>
                </div>
                <div className="bg-box highlights-box p-3 p-md-4 mb-4">
                  <div className="heading main-heading2 mb-2">
                    Highlights & inclusions
                  </div>
                  <div className="wrapper">
                    {highlights.map((el, index) => (
                      <div key={index} className="d-flex gap-3 fs-md-18 mt-2">
                        <i className="bi bi-check-circle text-custom-primary"></i>
                        {el.value}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bg-box review-box d-flex flex-wrap align-items-center justify-content-center gap-3 text-center mb-4 p-3">
                  <div
                    className="review-stars d-flex gap-2 m-0"
                    style={{ fontSize: "20px" }}
                  >
                    <StarRating rating={venueDetails?.total_ratings || 0} />
                  </div>
                  <div className="rating d-flex align-items-center gap-1 fs-18">
                    <div className="m-0">
                      {" "}
                      {venueDetails?.reviews || 0} Reviews
                    </div>
                  </div>
                </div>

                {isReveal && (
                  <div className="bg-box highlight-banner p-3 p-md-4 mb-4">
                    <div className="wrapper">
                      <button
                        type="button"
                        className="btn btn-custom2 rounded-0 px-3 mb-3"
                      >
                        Drink Code: {venueDetails?.drink_code}
                      </button>
                      <div className="reveal-info d-flex justify-content-between gap-4">
                        <div className="left-sec">
                          <div className="info-box mt-3">
                            <div className="fs-16 fw-bold">Phone :</div>
                            <div className="fs-14 text-custom-secondary">
                              {venueDetails?.phone}
                            </div>
                          </div>

                          <div className="info-box mt-3">
                            <div className="fs-16 fw-bold">Address :</div>
                            <div className="fs-14 text-custom-secondary">
                              {venueDetails?.address?.streetName},
                              {venueDetails?.address?.city},
                              {venueDetails?.address?.state?.value},
                              {venueDetails?.address?.zipCode}
                            </div>
                          </div>

                          <div className="info-box mt-3">
                            <div className="fs-16 fw-bold">Hours :</div>
                            <div className="fs-14">
                              {hours.map(([day, hours], index) => (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between gap-2 text-custom-secondary"
                                >
                                  <div className="left-part">{day}</div>
                                  {!hours?.close ? (
                                    <div className="right-part">
                                      {moment(
                                        hours?.openningTime,
                                        "h:mm A"
                                      ).format("hh:mm A")}{" "}
                                      -{" "}
                                      {moment(
                                        hours?.closingTime,
                                        "h:mm A"
                                      ).format("hh:mm A")}
                                    </div>
                                  ) : (
                                    <span className="text-red">Closed</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="right-sec">
                          <div className="d-flex flex-column gap-4">
                            <div className="img-wrapper rounded-circle">
                              <img
                                src={require("../../assets/images/banner/nightlife-1.jpg")}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <div className="img-wrapper rounded-circle">
                              <img
                                src={require("../../assets/images/banner/nightlife-1.jpg")}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <div className="img-wrapper rounded-circle">
                              <img
                                src={require("../../assets/images/banner/nightlife-1.jpg")}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <div className="img-wrapper rounded-circle">
                              <img
                                src={require("../../assets/images/banner/nightlife-1.jpg")}
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!isReveal && (
                  <div
                    className="bg-box highlight-banner rounded-5 position-relative mb-4 cursor-pointer"
                    onClick={handleClickReveal}
                  >
                    <img
                      src={venueDetails.bg_image_path}
                      className="img-fluid w-100 border-0 rounded-4"
                      alt="NightLife"
                    />
                    <div
                      className="moon-wrapper position-absolute start-0 top-0"
                      style={{
                        backgroundImage: `url(${require("../../assets/images/gallery/half-moon.png")})`,
                      }}
                    >
                      <div className="position-absolute start-0 top-50 translate-middle-y p-2">
                        <div className="heading-title">
                          {venueDetails?.offer_text}
                        </div>
                        <div className="heading-title mb-2">
                          {venueDetails?.offer_text2}
                        </div>
                        <button
                          type="button"
                          className="btn btn-custom2 rounded-0 px-3"
                          style={{ marginLeft: "-0.5rem" }}
                        >
                          Click to Reveal
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {exclusions.length > 0 && (
                  <div className="bg-box exclusion-box p-3 p-md-4 mb-4">
                    <div className="heading main-heading2 mb-2">Exclusions</div>
                    <div className="wrapper">
                      {exclusions.map((el, index) => (
                        <div key={index} className="d-flex gap-3 fs-md-18 mt-2">
                          <i className="bi bi-x-circle text-red"></i>
                          {el.value}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="list-wrapper">
          <div className="container-fluid" style={{ maxWidth: "1010px" }}>
            <div className="bg-box list-wrapper p-3 p-md-4">
              <div className="heading main-heading2">Events</div>
              <div className="row mb-lg-2">
                {events?.map((el, index) => (
                  <div key={index} className="col-sm-6 col-lg-4 mt-3 mt-md-4">
                    <div className="bg-box overflow-hidden rounded-4">
                      <div className="img-wrapper">
                        <img
                          src={el.profile_image_path}
                          className="img-fluid object-fit-cover w-100 cursor-pointer"
                          alt={el.event_name}
                          style={{ maxHeight: "117px" }}
                          onClick={() => handleClickEventDetails(el)}
                        />
                      </div>
                      <div className="info-wrapper p-3">
                        <div
                          className="fs-18 fw-medium text-truncate text-capitalize mb-2 cursor-pointer"
                          onClick={() => handleClickEventDetails(el)}
                        >
                          {el.event_name}
                        </div>
                        <div className="info-wrapper">
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <div className="w-50 fs-14 text-custom-primary text-capitalize">
                              Date
                            </div>
                            <div className="w-50 fs-14 text-white text-capitalize">
                              {moment(el.event_start_date).format("DD MMM Y")}
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <div className="w-50 fs-14 text-custom-primary text-capitalize">
                              Start Time
                            </div>
                            <div className="w-50 fs-14 text-white text-capitalize">
                              {moment(el.event_start_time, "h:mm A").format(
                                "hh:mm A"
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2 mb-2">
                            <div className="w-50 fs-14 text-custom-primary text-capitalize">
                              End Time
                            </div>
                            <div className="w-50 fs-14 text-white text-capitalize">
                              {moment(el.event_end_time, "h:mm A").format(
                                "hh:mm A"
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <div className="w-50 fs-14 text-custom-primary text-capitalize">
                              Cost
                            </div>
                            <div className="w-50 fs-14 text-white text-capitalize">
                              ${el?.event_cost}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default NightLife;
