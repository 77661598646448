import React, { useEffect, useState } from "react";
import { State } from "country-state-city";
import AdminSidebar from "../../layouts/AdminSidebar";
import { toast } from "react-toastify";
import webServices from "../../../services/webServices";
import checkIcon from "./../../../assets/images/admin/check-icon.png";
import { authCall } from "../../../services";
import ImageUploads from "../../util/ImageUploads";
import BannerImagesUploads from "../../util/BannerImagesUploads";

const DAYS = {
  Monday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Tuesday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Wednesday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Thursday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Friday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Saturday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
  Sunday: {
    openningTime: "12:00",
    closingTime: "12:00",
  },
};

function UpdateVenueDetails() {
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState([]);
  const [states] = useState(State.getStatesOfCountry("AU"));
  const [backgroundImgPreview, setBackgroundImgPreview] = useState("");
  const [bannerImages, setBannerImages] = useState([]);
  const [isProfileImageChange, setIsProfileImageChange] = useState(false);
  const [isBannerImageChange, setIsBannerImageChange] = useState(false);
  const [isBackgroundImageUpload, setIsBackgroundImageUpload] = useState(false);


  const [highlights, setHighlights] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
    { id: 5, value: "" },
    { id: 6, value: "" },
  ]);
  const [exclusions, setExclusions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
  ]);
  const [values, setValues] = useState({
    name: "",
    phone: "",
    address: {
      streetName: "",
      city: "",
      state: "",
      zipCode: "",
    },
    highlights: [],
    exclusions: [],
    overview: "",
    profileImage: "",
    bannerImage: "",
    openHours: DAYS,
    offerText: "",
    offerText2: "",
    backgroundImage: "",
    backgroundImageOther: "",
    isBackgroundImageUpload: false,
  });

  const venueDetails = async () => {
    const req = {
      nightLifeId: 1,
    };
    const resp = await authCall("venueDetails", req);
    if (resp?.type === "success") {
      const details = resp?.payload || {};
      setValues({
        ...values,
        nightLifeId: details?.id,
        name: details?.name || "",
        phone: details?.phone || "",
        address: {
          streetName: details?.address?.streetName || "",
          city: details?.address?.city || "",
          state: details?.address?.state || "",
          zipCode: details?.address?.zipCode || "",
        },
        overview: details?.overview || "",
        profileImage: details?.profile_photo || "",
        bannerImage: "",
        openHours: details?.service_hours || DAYS,
        offerText: details?.offer_text || "",
        offerText2: details?.offer_text2 || "",
        backgroundImage: details?.background_images || "",
        backgroundImageOther: "",
      });
      setExclusions(details?.exclusions);
      setHighlights(details?.highlights);
      setProfileImage(details?.profile_image_path || "");
      setBannerImages(details?.banners_path || bannerImages);
      setBackgroundImgPreview(details?.background_images_path || "");
    }
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      const resp = await webServices.getNightLifeBackground();
      if (resp?.type === "success") {
        setBackground(resp?.payload);
      }
    }
    fetchData();
    venueDetails();
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setValues((prevValues) => ({
        ...prevValues,
        [parent]: {
          ...prevValues[parent],
          [child]: value,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name === "phone" && value.length > 10) {
      setValues({ ...values, phone: value.slice(0, 10) });
    }
  };

  const handleChangeHighlight = (id, value) => {
    setHighlights(
      highlights.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeExclusion = (id, value) => {
    setExclusions(
      exclusions.map((field) =>
        field.id === id ? { ...field, value: value } : field
      )
    );
  };

  const handleChangeHours = (day, field, value) => {
    setValues({
      ...values,
      openHours: {
        ...values.openHours,
        [day]: {
          ...values.openHours[day],
          [field]: value,
        },
      },
    });
  };

  const handleSubmit = async (val) => {
    values.highlights = highlights;
    values.exclusions = exclusions;
    values.profileImage = isProfileImageChange ? profileImage : "";
    values.bannerImage = isBannerImageChange ? bannerImages : "";
    values.backgroundImageOther = isBackgroundImageUpload ? backgroundImgPreview : "";

    setLoading(true);
    const resp = await webServices.createNightLife(values);
    if (resp.type === "success") {
      setLoading(false);
      toast.success("Updated successfully.");
      setIsProfileImageChange(false);
      setIsBannerImageChange(false);
      setIsBackgroundImageUpload(false);
      window.scrollTo(0, 0);
    }
  };

  const handleChangeBackground = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = background.find(
      (option) => option.image === selectedValue
    );
    setBackgroundImgPreview(selectedObject.image_path);
    setValues({ ...values, backgroundImage: selectedObject.image });
  };

  const getBannerImages = (banners) => {
    setBannerImages(banners);
    setIsBannerImageChange(true);
  };

  const getProfileImage = (img) => {
    setProfileImage(img);
    setIsProfileImageChange(true);
  };

  const getBackgroundImage = (img) => {
    setBackgroundImgPreview(img);
    setIsBackgroundImageUpload(true);
  };

  return (
    <>
      <div className="restaurant-details-page venue-details-page py-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4">
              <AdminSidebar />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="common-info-card">
                <div className="heading-title">Update Venue Details:</div>
                <div className="details-wrapper">
                  <div className="sub-title mb-3">
                    These details will appear on your venue listing
                  </div>
                  <div className="custom-field-wrapperr">
                    <div className="row">
                      <div className="col-12">
                        <div className="main-heading2 mb-3">Venue Details:</div>
                      </div>
                      <div className="col-lg-6 col-xl-5">
                        <div className="form-group mb-3">
                          <label htmlFor="VenueName" className="fs-md-18 mb-1">
                            Venue Name :
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            id="VenueName"
                            placeholder=""
                            autoComplete="off"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-5">
                        <div className="form-group mb-3">
                          <label htmlFor="VenuePhone" className="fs-md-18 mb-1">
                            Venue Phone :
                          </label>
                          <input
                            type="number"
                            className="form-control rounded-pill"
                            id="VenuePhone"
                            placeholder=""
                            autoComplete="off"
                            name="phone"
                            maxlength={10}
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="location-details-page info-cover mb-4 pb-lg-3">
                          <div className="main-heading2 text-white mb-3">
                            Restaurant Address:
                          </div>
                          <div className="address-wrapper">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="address1"
                                  >
                                    Address Line 1{" "}
                                    <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="address1"
                                    placeholder=""
                                    autoComplete="off"
                                    name="address.streetName"
                                    value={values.address.streetName}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="city"
                                  >
                                    City <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    placeholder=""
                                    autoComplete="off"
                                    name="address.city"
                                    value={values.address.city}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="State"
                                  >
                                    State <span className="text-red">*</span>
                                  </label>
                                  <select
                                    id="State"
                                    className="form-select"
                                    name="address.state"
                                    onChange={handleChange}
                                  >
                                    <option value="" hidden>
                                      Select
                                    </option>
                                    {states.map((el, index) => (
                                      <option
                                        key={index}
                                        value={`${el.name} - ${el.isoCode}`}
                                        selected={
                                          values.address.state ===
                                            `${el.name} - ${el.isoCode}` ||
                                          false
                                        }
                                      >
                                        {el.name} - {el.isoCode}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group mb-2">
                                  <label
                                    className="fs-14 fw-medium"
                                    htmlFor="zipcode"
                                  >
                                    Post Code{" "}
                                    <span className="text-red">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="zipcode"
                                    placeholder=""
                                    autoComplete="off"
                                    maxLength="6"
                                    name="address.zipCode"
                                    value={values.address.zipCode}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 right-section">
                        <div className="table-responsive p-3 p-sm-0 mb-4">
                          <div
                            className="info-cover breakfast-timing"
                            style={{ minWidth: "400px" }}
                          >
                            <div className="main-heading2 mb-2">Hours:</div>
                            <div className="title-cover">
                              <div className="row">
                                <div className="col-4 mb-1">&nbsp;</div>
                                <div className="col-8 d-flex gap-3">
                                  <div className="fs-14 fw-semibold flex-fill">
                                    Opening Time
                                  </div>
                                  <div className="fs-14 fw-semibold flex-fill">
                                    Closing Time
                                  </div>
                                </div>
                              </div>
                            </div>
                            {Object.entries(values.openHours).map(
                              ([day, hours], index) => (
                                <div key={index} className="day-cover row">
                                  <div className="col-4 mb-1">{day}:</div>
                                  <div className="col-8 d-flex gap-3">
                                    <div className="flex-fill mb-2">
                                      <input
                                        type="time"
                                        className="form-control"
                                        autoComplete="off"
                                        value={hours.openningTime}
                                        onChange={(e) =>
                                          handleChangeHours(
                                            day,
                                            "openningTime",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex-fill mb-2">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={hours.closingTime}
                                        autoComplete="off"
                                        onChange={(e) =>
                                          handleChangeHours(
                                            day,
                                            "closingTime",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="info-cover mb-1 mb-lg-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Highlight:
                      </div>
                      <div className="row">
                        {highlights.map((field) => (
                          <div key={field.id} className="col-sm-6">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Hightlight"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeHighlight(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="info-cover mb-3 mb-lg-4">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Exclusion:
                      </div>
                      <div className="row">
                        {exclusions.map((field) => (
                          <div key={field.id} className="col-sm-6">
                            <div className="form-group d-flex align-items-center gap-2">
                              <div className="icon">
                                <img
                                  src={checkIcon}
                                  className="img-responsive"
                                  alt="Check"
                                />
                              </div>
                              <input
                                type="text"
                                className="form-control rounded-pill"
                                id=""
                                placeholder="Exclusion"
                                autoComplete="off"
                                value={field.value}
                                onChange={(e) =>
                                  handleChangeExclusion(
                                    field.id,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="info-cover mb-4 pb-2">
                      <div className="main-heading2 text-white mb-2 pb-1">
                        Overview:
                      </div>
                      <textarea
                        id="overview"
                        rows="5"
                        className="form-control"
                        placeholder="Please provide a 75-to-150-word descripition of activity."
                        autoComplete="off"
                        name="overview"
                        value={values.overview}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="event-details-page info-cover mb-4">
                      <div className="row">
                        <div className="col-lg-5 mb-4 mb-lg-0">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">Images:</div>
                            <div className="profile-cover mb-3">
                              <div className="main-heading2 text-white mb-1">
                                Profile Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (This is the image shown on the search results
                                  page)
                                </i>
                              </p>
                              <ImageUploads
                                getImage={getProfileImage}
                                uploadedImage={profileImage}
                              />
                            </div>
                            <BannerImagesUploads
                              bannerImages={getBannerImages}
                              images={bannerImages}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="info-cover">
                            <div className="main-heading2 mb-1">
                              Drink Offer:
                            </div>
                            <div className="offer-cover">
                              <div className="main-heading2 text-white mb-1">
                                Offer Text:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Describe your offer below in 5 words or less.
                                  For example Three Free Cocktails)
                                </i>
                              </p>
                              <div className="row custom-row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText"
                                      maxLength={10}
                                      value={values.offerText}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id=""
                                      className="form-control rounded-pill"
                                      placeholder=""
                                      autoComplete="off"
                                      name="offerText2"
                                      value={values.offerText2}
                                      maxLength={10}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="banner-cover mb-2">
                              <div className="main-heading2 text-white mb-1">
                                Select Background Images:
                              </div>
                              <p className="fs-12">
                                <i>
                                  (Select the background image from the list
                                  below)
                                </i>
                              </p>
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <select
                                      className="form-select rounded-pill"
                                      name="backgroundImage"
                                      onChange={handleChangeBackground}
                                    >
                                      <option value="" hidden="">
                                        Select
                                      </option>
                                      {background.map((el, index) => (
                                        <option
                                          key={index}
                                          value={el.image}
                                          selected={
                                            el.image ===
                                              values.backgroundImage || false
                                          }
                                        >
                                          {el.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="custom-input-file mb-2">
                                    <ImageUploads
                                      getImage={getBackgroundImage}
                                      uploadedImage=""
                                      isBackground={true}
                                    />
                                  </div>{" "}
                                </div>
                                {backgroundImgPreview && (
                                  <div className="col-sm-6">
                                    <div
                                      className="wrapper"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <div className="preview-box rounded-3">
                                        <img
                                          src={backgroundImgPreview}
                                          className="img-fluid border-0 rounded-3"
                                          alt={backgroundImgPreview}
                                        />
                                        <div
                                          className="moon-wrapper position-absolute start-0 top-0 h-100"
                                          style={{
                                            backgroundImage: `url(${require("../../../assets/images/gallery/half-moon.png")})`,
                                          }}
                                        >
                                          <div className="rel-text">
                                            <div className="text-truncate">
                                              {values.offerText}
                                            </div>
                                            <div className="text-truncate mb-1">
                                              {values.offerText2}
                                            </div>
                                            <button
                                              type="button"
                                              className="btn btn-custom2 rounded-0"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              Click to Reveal
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center fw-medium mt-2">
                                        <i>Preview</i>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-wrapper d-flex flex-column flex-md-row flex-md-wrap justify-content-center gap-3">
                      <button
                        type="submit"
                        className="btn btn-custom"
                        onClick={() => handleSubmit("submit")}
                        disabled={loading || false}
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateVenueDetails;
