import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

// import { authCall } from "../services";

const stripePromise = loadStripe(
  "pk_test_51Orr2IA3kborvutgkvumMoyDEYYbH0OUnJYBTlzt125Odz8MjZCn85c0YXdbGttxDNSgiFIiy1QGqTR4jGv0E1kL00mB8Ru2eI"
);

const SubscriptionCheckout = () => {
  // const stripe = useStripe();
  // const [clientSecret, setClientSecret] = useState("");
  // const [paymentIntentId, setPaymentIntentId] = useState("");

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await authCall("createPaymentIntent", {});
  //     if (res?.type === "success") {
  //       setClientSecret(res?.payload?.client_secret);
  //       setPaymentIntentId(res?.payload?.id);
  //     }
  //   }
  //   fetchData();
  // }, []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);

  //   const { error, paymentIntent } = await stripe.confirmCardPayment(
  //     clientSecret,
  //     {
  //       payment_method: {
  //         card: cardElement,
  //       },
  //     }
  //   );

  //   if (error) {
  //     console.error(error);
  //   } else if (paymentIntent.status === "succeeded") {
  //     // Call the backend to handle the payment success
  //     const res = await authCall("handlePaymentSuccess", {
  //       payment_intent_id: paymentIntentId,
  //     });
  //     if (res?.type === "success") {
  //       console.log("Payment successful!");
  //     }
  //   }
  // };
  const options = {
    clientSecret:
      "cs_test_a1ku3xZ2rfh885Ix71IK7qESrnmhK7CqVw42E7NNBz7hWgeFCpkDaP2yuC_secret_fidwbEhqYWAnPydgaGdgYWFgYScpJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ3dgYWx3YGZxSmtGamh1aWBxbGprJz8na2BzYHcneCUl",
  };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};

export default SubscriptionCheckout;
